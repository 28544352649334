// Libararies
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import Table from '@shared/design/components/Table';
import TableBuilder from '@shared/design/components/Table/components/TableBuilder';
import WorkflowRunStatus from '@shared/modules/Workflow/enums/WorkflowRunStatus';
import WorkflowRunForm from '@shared/modules/Workflow/forms/WorkflowRunForm';
import useCancelWorkflowRunMutation from '@shared/modules/Workflow/hooks/useCancelWorkflowRunMutation';
import StatusBadge from 'modules/App/components/StatusBadge';
import WorkflowRunDetailsDrawer from 'modules/Organization/Settings/Workflow/components/WorkflowRunDetailsDrawer';

const Container = Styled.View`
  flex: 1;
`;

const LinkText = Styled.Text`
  ${Typography.Link};
  cursor: pointer;
`;

const WorkflowRunDetailsButton = ({workflowRun, refetch}: any) => {
  const workflowRunDetailsDrawer = useDrawer({name: 'Workflow Run Details Drawer'});
  return (
    <React.Fragment>
      <LinkText onPress={workflowRunDetailsDrawer.handleOpen}>View Details</LinkText>
      <WorkflowRunDetailsDrawer
        key={workflowRunDetailsDrawer.key}
        isOpen={workflowRunDetailsDrawer.isOpen}
        handleClose={workflowRunDetailsDrawer.handleClose}
        workflowRun={workflowRun}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const CancelAutomationRunModal = ({isOpen, handleClose, refetch, workflowRun}: any) => {
  const {navigator} = useNavigationDOM();
  const {submitting, handleSubmit} = useCancelWorkflowRunMutation({
    workflowRunForm: WorkflowRunForm.new({workflowRunId: workflowRun.id}),
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: () => {
      handleClose();
    },
  });

  return (
    <ActionModal
      color={colors.orange.status}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      title='Cancel Automation Run'
      message='This automation run will stop at the current step and will not be able to be resumed. Are you sure you want to cancel this automation run?'
      primaryActionText='Cancel Run'
      secondaryActionText='Close'
      handleSecondaryAction={handleClose}
      isSubmitting={submitting}
    />
  );
};

const getWorkflowRunsTableColumnDefinitions = ({navigator, refetch}: any) => [
  // Status
  {
    flex: 1,
    headerLabel: 'Status',
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellComponent: (workflowRun) => {
      const statusOption = WorkflowRunStatus.getStatusOption(workflowRun.status);
      return <StatusBadge {...statusOption} />;
    },
    mobileOptions: {
      isInHeader: true,
    },
  },
  // Started at
  {
    flex: 1,
    headerLabel: 'Started at',
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellText: (workflowRun) => Datetime.convertToDisplayDatetime(workflowRun.createdAt),
    mobileOptions: {
      isInHeader: true,
    },
  },
  // Completed at
  {
    flex: 1,
    headerLabel: 'Completed at',
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellText: (workflowRun) =>
      workflowRun.completedAt ? Datetime.convertToDisplayDatetime(workflowRun.completedAt) : '-',
  },
  // Project
  {
    flex: 1,
    headerLabel: 'Project',
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellComponent: (workflowRun) => {
      const {project} = workflowRun;
      return (
        <React.Fragment>
          {project ? (
            <LinkText
              onPress={() => navigator.push(`/projects/${project.uuid}`)}
              numberOfLines={1}
            >{`Project ${project.identifier}`}</LinkText>
          ) : (
            <TableBuilder.PrimaryText>-</TableBuilder.PrimaryText>
          )}
        </React.Fragment>
      );
    },
  },
  // View Details
  {
    flex: 1,
    headerLabel: 'Details',
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellComponent: (workflowRun) => {
      return <WorkflowRunDetailsButton workflowRun={workflowRun} refetch={refetch} />;
    },
  },
  {
    flex: 0.1,
    actions: (workflowRun: any) => {
      const actions = [];
      if ([WorkflowRunStatus.IN_PROGRESS, WorkflowRunStatus.PENDING].includes(workflowRun.status)) {
        actions.push({
          text: 'Cancel automation run',
          onPress: ({handleOpen}: any) => {
            handleOpen();
          },
          actionHook: {
            hook: useModal,
            hookArgument: {name: 'Cancel Automation Run Modal'},
            renderComponent: ({isOpen, handleClose}: any) => {
              return (
                <CancelAutomationRunModal
                  isOpen={isOpen}
                  handleClose={handleClose}
                  refetch={refetch}
                  workflowRun={workflowRun}
                />
              );
            },
          },
        });
      }
      return actions;
    },
  },
];

const WorkflowRunsTable = ({workflowRuns, refetch}: any) => {
  const {navigator} = useNavigationDOM();
  return (
    <Container>
      <Table
        columnDefinitions={getWorkflowRunsTableColumnDefinitions({
          navigator,
          refetch,
        })}
        emptyStateText='No automation runs'
        items={workflowRuns}
        isScrollable
      />
      <Space height={32} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowRunsTable.fragment = gql`
  ${WorkflowRunDetailsDrawer.fragment}
  fragment WorkflowRunsTable on WorkflowRun {
    id
    status
    completedAt
    createdAt
    uuid
    project {
      id
      uuid
      identifier
    }
    ...WorkflowRunDetailsDrawer
  }
`;

export default WorkflowRunsTable;
