// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {RoomTypeModel} from '@supermove/models';
import {withFragment, uuid} from '@supermove/utils';

// App
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import {ItemAttachmentFormType} from '@shared/modules/Inventory/forms/ItemAttachmentForm';
import ItemFormV2 from '@shared/modules/Inventory/forms/ItemFormV2';
import RoomItemsForm, {RoomItemsFormType} from '@shared/modules/Inventory/forms/RoomItemsForm';

export interface InventoryRoomsFormType {
  inventoryId: string;
  jobId: number;
  roomItemsForms: RoomItemsFormType[];
  notes: string;
  // Private
  lastSyncedAt: Date;
  selectedRoomIndex: number;
  isDirty: boolean;
}

const edit = withFragment(
  (inventory, {jobId, selectedRoomIndex}) => ({
    inventoryId: (inventory as any).id,
    jobId,
    roomItemsForms: (inventory as any).roomsForJobUuids.map((roomItemsForms: any, index: any) =>
      RoomItemsForm.edit(roomItemsForms, {isCollapsed: index !== 0}),
    ),
    notes: (inventory as any).notes,
    // Private
    lastSyncedAt: (inventory as any).lastSyncedAt,
    selectedRoomIndex: selectedRoomIndex || 0,
    isDirty: false,
  }),
  gql`
    ${RoomItemsForm.edit.fragment}

    fragment InventoryRoomsForm_edit on Inventory {
      id
      lastSyncedAt
      notes
      # NOTE(cooper): We use the jobUuids to filter rooms to only those that are associated with the current job
      # Only applicable for driver inventory
      roomsForJobUuids(jobUuids: $jobUuids) {
        id
        ...RoomItemsForm_edit
      }
    }
  `,
);

const toForm = ({
  inventoryId,
  jobId,
  roomItemsForms,
  selectedRoomIndex,
  notes,
  isDirty,
  lastSyncedAt,
}: any) => ({
  inventoryId,
  jobId,
  roomItemsForms: roomItemsForms.map((roomItemsForm: any) => RoomItemsForm.toForm(roomItemsForm)),
  notes,

  // Private
  lastSyncedAt,
  selectedRoomIndex,
  isDirty,
});

const toMutation = ({inventoryId, jobId, roomItemsForms, notes}: any) => ({
  inventoryId,
  jobId,
  notes,
  roomItemsForms: _.reduce(
    roomItemsForms,
    // @ts-expect-error TS(2769): No overload matches this call.
    (filteredForms, roomItemsForm) => {
      // Do not send to mutation if form hasn't changed
      if (!roomItemsForm.isDirty) {
        return filteredForms;
      }
      return [...filteredForms, RoomItemsForm.toMutation(roomItemsForm)];
    },
    [],
  ),
});

const getInfo = (inventoryRoomsForm: any) => {
  let takeCount = 0;
  let leaveCount = 0;
  let roomCount = 0;
  let volume = 0;
  let weight = 0;
  let price = 0;
  let hasUnsavedChanges = false;
  let itemCount = 0;
  let cartonCount = 0;
  const {roomItemsForms} = inventoryRoomsForm;
  if (inventoryRoomsForm.isDirty) {
    hasUnsavedChanges = true;
  }
  roomItemsForms.forEach((roomItemsForm: any) => {
    if (!roomItemsForm.isDeleted) {
      roomCount += 1;
    }
    if (roomItemsForm.isDirty) {
      hasUnsavedChanges = true;
    }
    const roomItemsFormInfo = RoomItemsForm.getInfo(roomItemsForm);
    takeCount += roomItemsFormInfo.takeCount;
    leaveCount += roomItemsFormInfo.leaveCount;
    volume += roomItemsFormInfo.volume;
    weight += roomItemsFormInfo.weight;
    price += roomItemsFormInfo.price;
    hasUnsavedChanges = roomItemsFormInfo.hasUnsavedChanges || hasUnsavedChanges;
    itemCount += roomItemsFormInfo.itemCount;
    cartonCount += roomItemsFormInfo.cartonCount;
  });
  return {
    takeCount,
    leaveCount,
    roomCount,
    volume: _.round(volume, 2),
    weight: _.round(weight, 2),
    price,
    hasUnsavedChanges,
    itemCount,
    cartonCount,
  };
};

const getFirstActiveRoomIndex = (inventoryRoomsForm: any) => {
  return _.findIndex(inventoryRoomsForm.roomItemsForms, (roomItemsForm, index) => {
    return !(roomItemsForm as any).isDeleted && inventoryRoomsForm.selectedRoomIndex !== index;
  });
};

const getCountOfActiveRooms = (inventoryRoomsForm: any) => {
  return inventoryRoomsForm.roomItemsForms.filter((roomItemsForm: any) => {
    return !roomItemsForm.isDeleted;
  }).length;
};

const handleTakeToggle = ({inventoryRoomsForm, isChecked, roomItemsFormIndex, itemIndex}: any) => {
  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${itemIndex}`;
  // Update the takeCount and leaveCount based on if the take checkbox is checked
  // We swap the values in the takeCount and leaveCount so that if we are taking an item the leave count is always 0 and vice versa
  const itemForm = _.get(inventoryRoomsForm, `values.${field}`);
  inventoryRoomsForm.setFieldValue(`${field}.take`, isChecked);
  if (isChecked) {
    inventoryRoomsForm.setFieldValue(`${field}.takeCount`, itemForm.leaveCount);
    inventoryRoomsForm.setFieldValue(`${field}.leaveCount`, 0);
  } else {
    inventoryRoomsForm.setFieldValue(`${field}.leaveCount`, itemForm.takeCount);
    inventoryRoomsForm.setFieldValue(`${field}.takeCount`, 0);
  }
  setDirtyForms({inventoryRoomsForm, roomItemsFormIndex, itemIndex});
};

const handleQuantityChange = ({
  inventoryRoomsForm,
  quantity,
  item,
  roomItemsFormIndex,
  itemIndex,
}: any) => {
  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${itemIndex}`;
  // Don't allow user to go below 0
  let itemCount = item.take ? item.takeCount : item.leaveCount;
  itemCount += quantity;
  if (itemCount < 0) {
    itemCount = 0;
  }
  if (item.take) {
    inventoryRoomsForm.setFieldValue(`${field}.takeCount`, itemCount);
  } else {
    inventoryRoomsForm.setFieldValue(`${field}.leaveCount`, itemCount);
  }
  setDirtyForms({inventoryRoomsForm, roomItemsFormIndex, itemIndex});
  return itemCount;
};

const handleDeleteToggle = ({
  inventoryRoomsForm,
  isDeleted,
  roomItemsFormIndex,
  itemIndex,
}: any) => {
  inventoryRoomsForm.setFieldValue(
    `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${itemIndex}.isDeleted`,
    isDeleted,
  );
  setDirtyForms({inventoryRoomsForm, roomItemsFormIndex, itemIndex});
};

const handleDuplicate = ({inventoryRoomsForm, roomItemsFormIndex, itemForm}: any) => {
  const {name} = itemForm;
  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms`;
  const existingItems = _.get(inventoryRoomsForm.values, field);
  const index = existingItems.length;

  const getNextDuplicateName = (existingNames: any, baseName: any) => {
    let duplicateCount = 1;
    const baseNameExists = existingNames.includes(baseName);
    if (baseNameExists) {
      while (existingNames.includes(`${baseName} (${duplicateCount})`)) {
        duplicateCount += 1;
      }
    }

    return duplicateCount > 1 ? `${baseName} (${duplicateCount})` : `${baseName} (1)`;
  };
  const newName = getNextDuplicateName(
    existingItems.filter((item: any) => !item.isDeleted).map((item: any) => item.name),
    name,
  );
  const newItemForm = {
    ...itemForm,
    name: newName,
    isDirty: true,
    // Temp item ID used so that we have a unique identifier to delete the correct row in the virtualized table
    // Without a unique identifier we can't identify which row to rerender in the table
    itemId: `NEW_${uuid()}`,
    uuid: uuid(),
    // Note(Hammad): We are resetting the id fields in order for the mutation to create new attachments. The attached file itself is reused.
    itemAttachmentForms: itemForm.itemAttachmentForms.map(
      (attachmentForm: ItemAttachmentFormType) => ({
        ...attachmentForm,
        attachmentId: undefined,
        itemId: undefined,
        uuid: uuid(),
      }),
    ),
    index,
  };

  inventoryRoomsForm.setFieldValue(field, [...existingItems, newItemForm]);
  setDirtyForms({inventoryRoomsForm, roomItemsFormIndex, itemIndex: index});

  return newItemForm;
};

const handleSelectRoom = ({
  inventoryRoomsForm,
  index,
  setSearchTerm,
  setFilteredCategoryIds,
}: {
  inventoryRoomsForm: Form<{inventoryRoomsForm: InventoryRoomsFormType}>;
  index: number;
  setSearchTerm: (query: string) => void;
  setFilteredCategoryIds: (categoryIds: string[]) => void;
}) => {
  const selectedRoomIndex = _.get(
    inventoryRoomsForm,
    'values.inventoryRoomsForm.selectedRoomIndex',
  );
  const isCollapsedField = `inventoryRoomsForm.roomItemsForms.${index}.isCollapsed`;

  // Note(Hammad) if the room is already selected, toggle the collapsed state.
  if (selectedRoomIndex === index) {
    const isCollapsed = _.get(inventoryRoomsForm.values, isCollapsedField);
    inventoryRoomsForm.setFieldValue(isCollapsedField, !isCollapsed);
  } else {
    inventoryRoomsForm.setFieldValue(isCollapsedField, false);
  }

  inventoryRoomsForm.setFieldValue('inventoryRoomsForm.selectedRoomIndex', index);
  // Update search to select default item type categories
  const roomPrimaryCategoryId = _.get(
    inventoryRoomsForm.values,
    `inventoryRoomsForm.roomItemsForms.${index}.primaryCategoryId`,
  );
  if (roomPrimaryCategoryId) {
    setFilteredCategoryIds([_.toString(roomPrimaryCategoryId)]);
  } else {
    setFilteredCategoryIds([]);
  }
};

const handleDeleteRoom = ({inventoryRoomsForm, index, resetToSummary}: any) => {
  inventoryRoomsForm.setFieldValue(`inventoryRoomsForm.roomItemsForms.${index}.isDeleted`, true);
  inventoryRoomsForm.setFieldValue(`inventoryRoomsForm.roomItemsForms.${index}.isDirty`, true);
  // Set the selected room index if the selected room is the one being deleted
  if (inventoryRoomsForm.values.inventoryRoomsForm.selectedRoomIndex === index) {
    setTimeout(() => {
      inventoryRoomsForm.setFieldValue(
        `inventoryRoomsForm.selectedRoomIndex`,
        resetToSummary
          ? null
          : getFirstActiveRoomIndex(inventoryRoomsForm.values.inventoryRoomsForm),
      );
    }, 0);
  }
};

const setDirtyForms = ({inventoryRoomsForm, roomItemsFormIndex, itemIndex}: any) => {
  inventoryRoomsForm.setFieldValue(
    `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${itemIndex}.isDirty`,
    true,
  );
  inventoryRoomsForm.setFieldValue(
    `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.isDirty`,
    true,
  );
};

const addRoomToInventoryRoomsForm = withFragment(
  ({
    inventoryRoomsForm,
    roomType,
    inventoryId,
    setFilteredCategoryIds,
  }: {
    inventoryRoomsForm: Form<{inventoryRoomsForm: InventoryRoomsFormType}>;
    roomType: RoomTypeModel;
    inventoryId: number;
    setFilteredCategoryIds: (categoryIds: string[]) => void;
  }) => {
    const roomItemsForm = RoomItemsForm.new({
      inventoryId,
      roomTypeId: roomType.id,
      name: roomType.name,
      primaryCategoryId: roomType.primaryCategoryId,
    });
    const currentRoomItemsForms = inventoryRoomsForm.values.inventoryRoomsForm.roomItemsForms;
    inventoryRoomsForm.setFieldValue('inventoryRoomsForm.roomItemsForms', [
      ...currentRoomItemsForms,
      roomItemsForm,
    ]);
    // Set new room to selected room
    inventoryRoomsForm.setFieldValue(
      'inventoryRoomsForm.selectedRoomIndex',
      currentRoomItemsForms.length,
    );
    if (roomType.primaryCategoryId) {
      setFilteredCategoryIds([_.toString(roomType.primaryCategoryId)]);
    } else {
      setFilteredCategoryIds([]);
    }
    return roomItemsForm;
  },
  gql`
    fragment InventoryRoomsForm_addRoomToInventoryRoomsForm on RoomType {
      id
      name
      primaryCategoryId
    }
  `,
);

const addItemToInventoryRoomsForm = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ inventoryRoomsForm, itemType,... Remove this comment to see the full error message
  ({inventoryRoomsForm, itemType, noRoomsErrorToast, setItemTypeKindFilters}) => {
    // If there are no rooms then show toast
    if (getCountOfActiveRooms(inventoryRoomsForm.values.inventoryRoomsForm) === 0) {
      noRoomsErrorToast.handleToast();
      return;
    }
    setItemTypeKindFilters([ItemTypeKind.ITEM, ItemTypeKind.CARTON]);
    const selectedRoomIndex = _.get(
      inventoryRoomsForm,
      'values.inventoryRoomsForm.selectedRoomIndex',
    );
    inventoryRoomsForm.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}.isDirty`,
      true,
    );
    const selectedRoomItems = _.get(
      inventoryRoomsForm,
      `values.inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}`,
    );
    const itemForm = ItemFormV2.newFromItemType(itemType, {
      collectionId: selectedRoomItems.collectionId,
      index: selectedRoomItems.itemForms.length,
    });
    const currentRoomItems = selectedRoomItems.itemForms;
    inventoryRoomsForm.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}.itemForms`,
      [...currentRoomItems, itemForm],
    );
    // Make sure to expand room section
    inventoryRoomsForm.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}.isCollapsed`,
      false,
    );
  },
  gql`
    ${ItemFormV2.newFromItemType.fragment}
    fragment InventoryRoomsForm_addItemToInventoryRoomsForm on ItemType {
      id
      ...ItemFormV2_newFromItemType
    }
  `,
);

const upsertItemToInventoryRoomsForm = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ inventoryRoomsForm, itemType,... Remove this comment to see the full error message
  ({inventoryRoomsForm, itemType, noRoomsErrorToast, setItemTypeKindFilters}) => {
    // If there are no rooms then show toast
    if (getCountOfActiveRooms(inventoryRoomsForm.values.inventoryRoomsForm) === 0) {
      noRoomsErrorToast.handleToast();
      return;
    }
    setItemTypeKindFilters([ItemTypeKind.ITEM, ItemTypeKind.CARTON]);
    const selectedRoomIndex = _.get(
      inventoryRoomsForm,
      'values.inventoryRoomsForm.selectedRoomIndex',
    );
    inventoryRoomsForm.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}.isDirty`,
      true,
    );
    const selectedRoomItems = _.get(
      inventoryRoomsForm,
      `values.inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}`,
    );
    const currentRoomItems = selectedRoomItems.itemForms;
    const itemToUpdate = _.findLast(currentRoomItems, (item) => {
      return item.name === itemType.name;
    });
    if (itemToUpdate && !itemToUpdate.isDeleted) {
      const updatedItemForm = _.cloneDeep(itemToUpdate);
      updatedItemForm.isDirty = true;
      const quantityField = itemToUpdate.take ? 'takeCount' : 'leaveCount';
      updatedItemForm[quantityField] = itemToUpdate[quantityField] + 1;
      inventoryRoomsForm.setFieldValue(
        `inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}.itemForms.${itemToUpdate.index}`,
        updatedItemForm,
      );
    } else {
      const itemForm = ItemFormV2.new({
        collectionId: selectedRoomItems.collectionId,
        name: itemType.name,
        weight: itemType.weight,
        volume: itemType.volume,
        price: itemType.price,
        packTime: itemType.packTime,
        unpackTime: itemType.unpackTime,
        isDerivedWeight: itemType.isDerivedWeight,
        itemTypeId: itemType.id,
        index: selectedRoomItems.itemForms.length,
        kind: itemType.kind,
      });
      inventoryRoomsForm.setFieldValue(
        `inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}.itemForms`,
        [...currentRoomItems, itemForm],
      );
    }
    // Make sure to expand room section
    inventoryRoomsForm.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}.isCollapsed`,
      false,
    );
  },
  gql`
    fragment InventoryRoomsForm_upsertItemToInventoryRoomsForm on ItemType {
      id
      name
      weight
      volume
      price
      packTime
      unpackTime
      isDerivedWeight
      kind
    }
  `,
);

const InventoryRoomsForm = {
  edit,
  toForm,
  toMutation,

  // Helpers
  getInfo,
  getFirstActiveRoomIndex,
  getCountOfActiveRooms,
  handleTakeToggle,
  handleQuantityChange,
  handleDeleteToggle,
  handleDuplicate,
  handleSelectRoom,
  handleDeleteRoom,
  setDirtyForms,
  addRoomToInventoryRoomsForm,
  addItemToInventoryRoomsForm,
  upsertItemToInventoryRoomsForm,
};

export default InventoryRoomsForm;
