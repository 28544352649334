// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView, RichTextInputV1, RichTextInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {
  DocumentItemImageType,
  DocumentItemInputGridType,
  DocumentItemGenericSignatureType,
  DocumentItemInputSignatureType,
  DocumentItemInputRadioButtonsType,
  DocumentItemInputTextAreasType,
  DocumentItemInputTextInputsType,
  DocumentItemType,
  DocumentTemplateModel,
  DocumentTemplateVersionModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import RadioButton from '@shared/design/components/RadioButton';
import VariableKind from '@shared/modules/Billing/enums/VariableKind';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import {
  SelectedDocumentItemType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import HardcodedSystemVariables from '@shared/modules/Variable/enums/HardcodedSystemVariables';
import ImageEditor from 'modules/Organization/Settings/Document/components/ImageEditor';
import InputGridEditor from 'modules/Organization/Settings/Document/components/InputGridEditor';
import InputIsRequiredCheckBox from 'modules/Organization/Settings/Document/components/InputIsRequiredCheckBox';
import InputRadioButtonsWithTextInputsEditor from 'modules/Organization/Settings/Document/components/InputRadioButtonsWithTextInputsEditor';
import InputTextAreasInputEditor from 'modules/Organization/Settings/Document/components/InputTextAreasInputEditor';
import InputTextInputEditor from 'modules/Organization/Settings/Document/components/InputTextInputEditor';
import SurveyDocumentCheckbox from 'modules/Organization/Settings/Document/components/SurveyDocumentCheckbox';
import VariableList from 'modules/Variable/components/VariableList';

const HeaderText = Styled.Text`
  ${Typography.Responsive.Subheading};
`;

const TextInput = Styled.TextInput`
  ${Typography.Responsive.Body}
  border-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
  padding-vertical: 8px;
`;

// RichTextInput has toolbar popovers and dropdowns that need to be above all other surrounding elements
const RichTextContainer = Styled.View`
  z-index: 99999;
`;

const BlankItemInputContainer = Styled.View`
`;

const BlankItemInputContainerText = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.tertiary};
`;

type InputEditorSelectedDocumentItemType = SelectedDocumentItemType & {
  documentItem: DocumentItemType;
};

const getIsClientInventory = ({documentItem}: {documentItem: DocumentItemType}) => {
  return (
    'input' in documentItem &&
    'isClientInventory' in documentItem.input &&
    documentItem.input.isClientInventory
  );
};

const GenerateTextInput = ({
  documentItem,
  setDraftDocumentContentJson,
  documentTemplate,
  documentItemField,
  isSmall,
  placeholder,
  organization,
  isRichText,
}: {
  documentItem: DocumentItemType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentTemplate: DocumentTemplateModel;
  documentItemField?: string;
  isSmall?: boolean;
  placeholder: string;
  organization: any;
  isRichText?: boolean;
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {isRichText ? (
        <RichTextContainer>
          {organization.features.isEnabledRichTextEditorV2 ? (
            <RichTextInput
              placeholder={placeholder}
              value={'text' in documentItem ? documentItem.text : ''}
              onChange={(value) => {
                setDraftDocumentContentJson((prevState) => {
                  if (documentItemField) {
                    _.set(prevState, `${documentItemField}.text`, value);
                  }
                  return {...prevState};
                });
              }}
              hasAdvancedFormatting
              // paddingBottom allows for the link popover to be clickable below the text input
              // it must be "contained" within RichTextInput to be clickable, regardless of z-index
              style={{flex: 1, minHeight: '360px', marginBottom: 24}}
            />
          ) : (
            <RichTextInputV1
              placeholder={placeholder}
              value={'text' in documentItem ? documentItem.text : ''}
              setFieldValue={(name, value) => {
                setDraftDocumentContentJson((prevState) => {
                  if (documentItemField) {
                    _.set(prevState, `${documentItemField}.text`, value);
                  }
                  return {...prevState};
                });
              }}
              hasCustomToolbar
              // paddingBottom allows for the link popover to be clickable below the text input
              // it must be "contained" within RichTextInput to be clickable, regardless of z-index
              style={{flex: 1, minHeight: '360px', paddingBottom: 24}}
            />
          )}
        </RichTextContainer>
      ) : (
        <React.Fragment>
          <TextInput
            autoFocus
            placeholder={placeholder}
            value={'text' in documentItem ? documentItem.text : ''}
            multiline
            onChangeText={(text: string) => {
              setDraftDocumentContentJson((prevState) => {
                _.set(prevState, `${documentItemField}.text`, text);
                return {...prevState};
              });
            }}
            style={{
              minHeight: isSmall ? '100px' : '300px',
            }}
            responsive={responsive}
          />
          <Space height={48} />
        </React.Fragment>
      )}
      <VariableList
        organization={organization}
        hardcodedSystemVariables={HardcodedSystemVariables.getProjectAndFilteredVariablesByKind(
          HardcodedSystemVariables.DOCUMENT_VARIABLES,
          [documentTemplate.category],
        )}
        variableKindsToExclude={
          documentTemplate.category === DocumentTemplateCategory.INVOICE ? [VariableKind.JOB] : []
        }
      />
    </React.Fragment>
  );
};

const NotEditablePlaceholder = () => {
  const responsive = useResponsive();
  return (
    <BlankItemInputContainer>
      <BlankItemInputContainerText responsive={responsive}>
        {'This document item is not editable.'}
      </BlankItemInputContainerText>
    </BlankItemInputContainer>
  );
};

const generateDefaultTemplateForItemKind = (
  selectedDocumentItem: InputEditorSelectedDocumentItemType,
  setDraftDocumentContentJson: SetDocumentContentJsonType,
  documentTemplateVersion: DocumentTemplateVersionModel,
) => {
  const {documentItem, index, sectionIndex, columnIndex} = selectedDocumentItem;

  const documentItemField = `${DocumentContentJson.getDocumentItemsField({
    sectionIndex,
    columnIndex,
  })}.${index}`;

  switch (documentItem.itemKind) {
    case DocumentItemKindV2.SECTION_TITLE:
      return (
        <GenerateTextInput
          organization={documentTemplateVersion.organization}
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentTemplate={documentTemplateVersion.documentTemplate}
          documentItemField={documentItemField}
          isSmall
          placeholder={'Enter section title'}
        />
      );
    case DocumentItemKindV2.TEXT_TITLE:
      return (
        <GenerateTextInput
          organization={documentTemplateVersion.organization}
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentTemplate={documentTemplateVersion.documentTemplate}
          documentItemField={documentItemField}
          isSmall
          placeholder={'Enter paragraph title'}
        />
      );
    case DocumentItemKindV2.TEXT_PARAGRAPH:
      return (
        <GenerateTextInput
          organization={documentTemplateVersion.organization}
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentTemplate={documentTemplateVersion.documentTemplate}
          documentItemField={documentItemField}
          placeholder={'Enter paragraph text'}
        />
      );
    case DocumentItemKindV2.TEXT:
      return (
        <GenerateTextInput
          key={documentItemField}
          isRichText
          organization={documentTemplateVersion.organization}
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentTemplate={documentTemplateVersion.documentTemplate}
          documentItemField={documentItemField}
          placeholder={'Enter paragraph text'}
        />
      );
    case DocumentItemKindV2.IMAGE:
      return (
        <ImageEditor
          documentItem={documentItem as DocumentItemImageType}
          documentItemField={documentItemField}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
        />
      );
    case DocumentItemKindV2.INPUT_RADIO_BUTTONS:
      return (
        <InputRadioButtonsWithTextInputsEditor
          documentItem={documentItem as DocumentItemInputRadioButtonsType}
          documentItemField={documentItemField}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
        />
      );
    case DocumentItemKindV2.INPUT_TEXT_INPUTS:
      return (
        <InputTextInputEditor
          documentItem={documentItem as DocumentItemInputTextInputsType}
          documentItemField={documentItemField}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
        />
      );
    case DocumentItemKindV2.INPUT_TEXT_AREAS:
      return (
        <InputTextAreasInputEditor
          documentItem={documentItem as DocumentItemInputTextAreasType}
          documentItemField={documentItemField}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
        />
      );
    case DocumentItemKindV2.INPUT_GRID:
      return (
        <InputGridEditor
          documentItem={documentItem as DocumentItemInputGridType}
          documentItemField={documentItemField}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
        />
      );
    case DocumentItemKindV2.INPUT_SIGNATURE:
      return (
        <React.Fragment>
          <InputIsRequiredCheckBox
            documentItem={documentItem as DocumentItemInputSignatureType}
            documentItemField={documentItemField}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
          />
        </React.Fragment>
      );
    case DocumentItemKindV2.GENERIC_SIGNATURE:
      return (
        <React.Fragment>
          <InputIsRequiredCheckBox
            documentItem={documentItem as DocumentItemGenericSignatureType}
            documentItemField={documentItemField}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
          />
        </React.Fragment>
      );
    case DocumentItemKindV2.INVENTORY_SUMMARY:
    case DocumentItemKindV2.SURVEY_CARTONS_SUMMARY:
    case DocumentItemKindV2.SURVEY_ITEMS_SUMMARY:
    case DocumentItemKindV2.SURVEY_ROOMS_SUMMARY:
      return (
        <React.Fragment>
          <SurveyDocumentCheckbox
            label={'Show Notes'}
            property={'showNotes'}
            documentItemField={documentItemField}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            isChecked={('input' in documentItem && documentItem.input.showNotes) || false}
          />
          <Space height={8} />
          <SurveyDocumentCheckbox
            label={'Show Volume'}
            property={'showVolume'}
            documentItemField={documentItemField}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            isChecked={('input' in documentItem && documentItem.input.showVolume) || false}
          />
          <Space height={8} />
          <SurveyDocumentCheckbox
            label={'Show Weight'}
            property={'showWeight'}
            documentItemField={documentItemField}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            isChecked={('input' in documentItem && documentItem.input.showWeight) || false}
          />
        </React.Fragment>
      );
    case DocumentItemKindV2.TIMESHEET_INFORMATION:
    case DocumentItemKindV2.INPUT_PER_MOVER_TIMESHEET:
      if (documentTemplateVersion.organization.isFullyEnabledTimesheetsV2) {
        return (
          <SurveyDocumentCheckbox
            label={'Show Mover Names'}
            property={'showMoverNames'}
            documentItemField={documentItemField}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            isChecked={('input' in documentItem && documentItem.input.showMoverNames) || false}
          />
        );
      } else {
        return <NotEditablePlaceholder />;
      }
    case DocumentItemKindV2.INVENTORY_ITEMS_SUMMARY:
    case DocumentItemKindV2.GENERAL_INVENTORY_SUMMARY:
      return (
        <React.Fragment>
          <RadioButton
            label={'Job Inventory'}
            isOn={!getIsClientInventory({documentItem})}
            onPress={() => {
              setDraftDocumentContentJson((prevState) => {
                _.set(prevState, `${documentItemField}.input.isClientInventory`, false);
                return {...prevState};
              });
            }}
          />
          <Space height={8} />
          <RadioButton
            label={'Client Inventory'}
            isOn={getIsClientInventory({documentItem})}
            onPress={() => {
              setDraftDocumentContentJson((prevState) => {
                _.set(prevState, `${documentItemField}.input.isClientInventory`, true);
                return {...prevState};
              });
            }}
          />
        </React.Fragment>
      );
    default:
      return <NotEditablePlaceholder />;
  }
};

const DocumentTemplateVersionItemInputEditor = ({
  selectedDocumentItem,
  setDraftDocumentContentJson,
  documentTemplateVersion,
}: {
  selectedDocumentItem: InputEditorSelectedDocumentItemType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentTemplateVersion: DocumentTemplateVersionModel;
}) => {
  const responsive = useResponsive();
  const documentItem = selectedDocumentItem?.documentItem;

  return (
    <ScrollView style={{flex: 1}} contentContainerStyle={{padding: 24}}>
      <HeaderText responsive={responsive}>
        {DocumentItemKindV2.getItemDisplayName({
          itemKind: documentItem.itemKind,
          isFullyEnabledTimesheetsV2:
            documentTemplateVersion.organization.isFullyEnabledTimesheetsV2,
        })}
      </HeaderText>
      <Space height={12} />
      {generateDefaultTemplateForItemKind(
        selectedDocumentItem,
        setDraftDocumentContentJson,
        documentTemplateVersion,
      )}
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentTemplateVersionItemInputEditor.fragment = gql`
  ${VariableList.fragment}
  fragment DocumentTemplateVersionItemInputEditor on DocumentTemplateVersion {
    id
    organization {
      id
      isFullyEnabledTimesheetsV2
      features {
        isEnabledRichTextEditorV2: isEnabled(feature: "RICH_TEXT_EDITOR_V2")
      }
      ...VariableList
    }
    documentTemplate {
      id
      category
    }
  }
`;

export default DocumentTemplateVersionItemInputEditor;
