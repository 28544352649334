// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {withFragment, Currency} from '@supermove/utils';

// App
import BillTipForm, {FormikForm, GraphQLForm, New} from '@shared/modules/Billing/forms/BillTipForm';
import JobUserForm, {
  JobUserFormType,
  JobUserFormTypeForm,
  JobUserFormTypeMutation,
} from '@shared/modules/Dispatch/forms/JobUserForm';

const getBillTipForm = ({
  job,
  billId,
  isDynamicJobTip,
}: {
  job: any;
  billId: string;
  isDynamicJobTip: boolean;
}): New => {
  if (job.billTips.length) {
    const jobUserTipsTotalAmount = _.sumBy(job.assignedJobUsersForTips, 'tipAmount');

    if (job.billTips.length > 1) {
      // Support the v1 bill tips system where jobs can have more than one tip
      const billTipsTotalAmount = _.sumBy(job.billTips, 'amount');
      return BillTipForm.new({
        jobId: job.id,
        billId,
        name: Job.getTipName(job),
        amount: isDynamicJobTip ? jobUserTipsTotalAmount : billTipsTotalAmount,
      });
    }
    // @ts-expect-error TS(2741): Property 'paymentId' is missing in type '{ billTip... Remove this comment to see the full error message
    return BillTipForm.edit({
      ...job.billTips[0],
      amount: isDynamicJobTip ? jobUserTipsTotalAmount : job.billTips[0].amount,
    });
  }
  return BillTipForm.new({jobId: job.id, billId, name: Job.getTipName(job), amount: 0});
};

const getJobUserTipTotal = (jobTipsForm: JobTipsFormTypeForm): number => {
  return _.sumBy(jobTipsForm.jobUserForms, (form) => Currency.convertToCents(form.tipAmount));
};

export interface JobTipsFormType {
  jobId: string;
  billTipForm: New;
  jobUserForms: JobUserFormType[];
  displayName: string;
  displayDate: string;
  jobUuid: string;
}

const edit = withFragment(
  (
    job: any,
    {billId, isDynamicJobTip}: {billId: string; isDynamicJobTip: boolean},
  ): JobTipsFormType => {
    return {
      jobId: job.id,
      billTipForm: getBillTipForm({job, billId, isDynamicJobTip}),
      jobUserForms: job.assignedJobUsersForTips.map(JobUserForm.edit),

      // Private
      displayName: job.fullName,
      displayDate: Job.getJobDisplayDate(job),
      jobUuid: job.uuid,
    };
  },
  gql`
    ${BillTipForm.edit.fragment}
    ${JobUserForm.edit.fragment}
    ${Job.getTipName.fragment}
    ${Job.getJobDisplayDate.fragment}

    fragment JobTipsForm_edit on Job {
      id
      uuid
      fullName
      billTips {
        id
        ...BillTipForm_edit
      }
      assignedJobUsersForTips {
        id
        ...JobUserForm_edit
      }
      ...Job_getTipName
      ...Job_getJobDisplayDate
    }
  `,
);

export interface JobTipsFormTypeForm {
  jobId: string;
  billTipForm: FormikForm;
  jobUserForms: JobUserFormTypeForm[];
  displayName: string;
  displayDate: string;
  jobUuid: string;
}

const toForm = ({
  jobId,
  billTipForm,
  jobUserForms,
  displayName,
  displayDate,
  jobUuid,
}: JobTipsFormType): JobTipsFormTypeForm => ({
  jobId,
  billTipForm: BillTipForm.toForm(billTipForm),
  jobUserForms: jobUserForms.map(JobUserForm.toForm),

  // Private
  displayName,
  displayDate,
  jobUuid,
});

export interface JobTipsFormTypeMutation {
  jobId: string;
  billTipForm: GraphQLForm;
  jobUserForms: JobUserFormTypeMutation[];
}

const toMutation = ({
  jobId,
  billTipForm,
  jobUserForms,
}: JobTipsFormTypeForm): JobTipsFormTypeMutation => ({
  jobId,
  billTipForm: BillTipForm.toMutation(billTipForm),
  jobUserForms: jobUserForms.map(JobUserForm.toMutation),
});

const JobTipsForm = {
  edit,
  toForm,
  toMutation,

  // Helpers
  getJobUserTipTotal,
};

export default JobTipsForm;
