// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Tooltip} from '@supermove/components';
import {useState, usePopover, useEffect, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';

const CollapsibleContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: ${({responsive}: any) => (responsive.desktop ? '1px' : '0px')};
`;

const CollapsedRow = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  height: 48px;
`;

const CollapseBodyContainer = Styled.View`
  border-color: ${colors.gray.border};
  border-top-width: ${({isCollapsed, responsive}: any) =>
    isCollapsed || !responsive.desktop ? '0px' : '1px'};
  z-index: -1;
`;

const SectionHeader = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const ToolTipText = Styled.Text`
  ${Typography.Body};
  color: ${colors.white};
`;

const MenuButton = Styled.ButtonV2`
  padding-right: 12px;
  padding-left: 12px;
  padding-vertical: 5px;
  border-radius: 4px;
  border-color: ${colors.blue.interactive};
  border-width: 1px;
`;

const PrimaryButton = ({
  handlePrimaryAction,
  primaryActionText,
  primaryActionLeftIcon,
  primaryActionRightIcon,
  primaryDropdownActions,
  isPrimaryActionDropdown,
  isDisabled,
  isSubmitting,
}: any) => {
  return (
    <React.Fragment>
      {primaryActionText &&
        (isPrimaryActionDropdown ? (
          <React.Fragment>
            <Space width={12} />
            <DropdownButton
              isSmall
              actions={primaryDropdownActions}
              text={primaryActionText}
              iconLeft={primaryActionLeftIcon}
              isDisabled={isDisabled}
              menuWidth={150}
              menuPosition={DropdownButton.MENU_POSITION.RIGHT}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Space width={12} />
            <Button
              onPress={handlePrimaryAction}
              iconLeft={primaryActionLeftIcon}
              iconRight={primaryActionRightIcon}
              isSmall
              text={primaryActionText}
              isDisabled={isDisabled}
              isSubmitting={isSubmitting}
            />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

const CollapsibleContent = ({
  title,
  TitleIconComponent,
  TitleRightComponent = null,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  primaryActionLeftIcon,
  primaryActionRightIcon,
  secondaryActionLeftIcon,
  secondaryActionRightIcon,
  isSubmittingPrimaryAction,
  isDisabledPrimaryAction,
  isDisabledSecondaryAction,
  primaryActionTooltipText,
  primaryDropdownActions,
  isPrimaryActionDropdown,
  children,
  defaultCollapsed,
  menuActions,
  style,
  handlePress,
  titleNumberOfLines,
  isCollapsedOverride = null,
}: {
  title: string;
  TitleIconComponent?: React.ReactNode;
  TitleRightComponent?: React.ReactNode;
  handlePrimaryAction?: () => void;
  handleSecondaryAction?: () => void;
  primaryActionText?: string | null;
  secondaryActionText?: string | null;
  defaultCollapsed?: boolean;
  primaryActionLeftIcon?: string;
  primaryActionRightIcon?: string;
  secondaryActionLeftIcon?: string;
  secondaryActionRightIcon?: string;
  primaryDropdownActions?: any[];
  isSubmittingPrimaryAction?: boolean;
  isPrimaryActionDropdown?: boolean;
  isDisabledPrimaryAction?: boolean;
  isDisabledSecondaryAction?: boolean;
  primaryActionTooltipText?: string | null;
  menuActions?: any[];
  style?: any;
  handlePress?: (collapsed: boolean) => void;
  titleNumberOfLines?: number;
  isCollapsedOverride?: boolean | null;
  children?: React.ReactNode;
}) => {
  const responsive = useResponsive();
  const [isCollapsed, setIsCollapsed] = useState(
    isCollapsedOverride !== null ? isCollapsedOverride : defaultCollapsed,
  );
  useEffect(() => {
    setIsCollapsed(isCollapsedOverride !== null ? isCollapsedOverride : defaultCollapsed);
  }, [isCollapsedOverride, defaultCollapsed]);

  const menuPopover = usePopover();

  return (
    <CollapsibleContainer style={style} responsive={responsive}>
      <CollapsedRow
        onPress={() => {
          if (isCollapsedOverride === null) {
            setIsCollapsed(!isCollapsed);
          }
          handlePress?.(!isCollapsed);
        }}
      >
        <Icon
          source={isCollapsed ? Icon.AngleDown : Icon.AngleUp}
          color={colors.gray.secondary}
          size={responsive.desktop ? 12 : 14}
          style={{width: 28}}
        />
        <Space width={8} />
        {TitleIconComponent && (
          <React.Fragment>
            {TitleIconComponent}
            <Space width={8} />
          </React.Fragment>
        )}
        <SectionHeader responsive={responsive} numberOfLines={titleNumberOfLines}>
          {title}
        </SectionHeader>
        <Space style={{flex: 1}} />
        {!isCollapsed ? (
          <React.Fragment>
            {handleSecondaryAction && secondaryActionText && (
              <SecondaryButton
                onPress={handleSecondaryAction}
                iconLeft={secondaryActionLeftIcon}
                iconRight={secondaryActionRightIcon}
                isSmall
                text={secondaryActionText}
                isDisabled={isDisabledSecondaryAction}
              />
            )}
            {primaryActionTooltipText ? (
              <Tooltip
                placement={'top'}
                overlay={() => {
                  return <ToolTipText>{primaryActionTooltipText}</ToolTipText>;
                }}
              >
                <SectionHeader>
                  <PrimaryButton
                    primaryActionText={primaryActionText}
                    primaryActionLeftIcon={primaryActionLeftIcon}
                    primaryActionRightIcon={primaryActionRightIcon}
                    primaryDropdownActions={primaryDropdownActions}
                    handlePrimaryAction={handlePrimaryAction}
                    isPrimaryActionDropdown={isPrimaryActionDropdown}
                    isDisabled={isDisabledPrimaryAction}
                  />
                </SectionHeader>
              </Tooltip>
            ) : (
              <PrimaryButton
                primaryActionText={primaryActionText}
                primaryActionLeftIcon={primaryActionLeftIcon}
                primaryActionRightIcon={primaryActionRightIcon}
                primaryDropdownActions={primaryDropdownActions}
                handlePrimaryAction={handlePrimaryAction}
                isPrimaryActionDropdown={isPrimaryActionDropdown}
                isDisabled={isDisabledPrimaryAction}
                isSubmitting={isSubmittingPrimaryAction}
              />
            )}
            {!!menuActions?.length && (
              <React.Fragment>
                <Space width={8} />
                <ActionMenuPopover
                  popover={menuPopover}
                  placement={ActionMenuPopover.Position.BottomEnd}
                  width={200}
                  actions={menuActions.map((action: any) => {
                    if (action.actions) {
                      return {
                        ...action,
                        actions: action.actions.map((subAction: any) => {
                          return {
                            ...subAction,
                            text: subAction.label,
                          };
                        }),
                      };
                    }
                    return {...action, text: action.label};
                  })}
                >
                  <MenuButton onPress={() => menuPopover.handleToggle()}>
                    <Icon source={Icon.EllipsisV} color={colors.blue.interactive} size={16} />
                  </MenuButton>
                </ActionMenuPopover>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          TitleRightComponent
        )}
      </CollapsedRow>
      <CollapseBodyContainer isCollapsed={isCollapsed} responsive={responsive}>
        {!isCollapsed && !_.isEmpty(children) && children}
      </CollapseBodyContainer>
    </CollapsibleContainer>
  );
};

CollapsibleContent.PrimaryButton = PrimaryButton;

export default CollapsibleContent;
