// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';

// App
import CancelProjectForm, {
  CancelProjectFormType,
} from '@shared/modules/Project/forms/CancelProjectForm';

const useCancelProjectMutation = ({
  cancelProjectForm,
  onSuccess,
  onError,
}: {
  cancelProjectForm: CancelProjectFormType;
  onSuccess: () => void;
  onError: (error: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      cancelProjectForm: CancelProjectForm.toForm(cancelProjectForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCancelProjectMutation.mutation,
    variables: {
      cancelProjectForm: CancelProjectForm.toMutation(form.values.cancelProjectForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCancelProjectMutation.mutation = gql`
  mutation CancelProjectMutation($cancelProjectForm: CancelProjectForm!) {
    response: cancelProject(cancelProjectForm: $cancelProjectForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useCancelProjectMutation;
