// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useCallback, useEffect, useMountEffect, useQuery, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

import {RoleArgument} from '@shared/modules/User/enums/UserRole';

export type AppContextProviderViewerType = {
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  fullName: string;
  role: RoleArgument;
  unreadNotificationsCount: number;
  isPrimaryOrganizationAdmin: boolean;
  isAuthorizedAccountingActions: boolean;
  organization: {
    id: string;
    name: string;
  };
  viewingOrganization: {
    id: string;
    name: string;
    slug: string;
    hasMultipleOrganizations: boolean;
    company: {
      id: string;
      isActive: boolean;
    };
    features: {
      isEnabledGlobalSearchV2: boolean;
      isEnabledMultibranchOrganizationSwitcher: boolean;
    };
  };
};

export type AppContextProviderActualViewerType = {
  id: string;
  isActive: boolean;
  role: RoleArgument;
};
export interface AppContextProps {
  viewer?: AppContextProviderViewerType;
  actualViewer?: AppContextProviderActualViewerType;
  isGlobalNavSidebarExpanded: boolean;
  toggleIsGlobalNavSidebarExpanded: () => void;
  globalNavigationTab: string;
  setGlobalNavigationTab: (tab: string) => void;
}

export const AppContext = React.createContext<AppContextProps>({
  viewer: undefined,
  isGlobalNavSidebarExpanded: false,
  toggleIsGlobalNavSidebarExpanded: () => {},
  globalNavigationTab: '',
  setGlobalNavigationTab: () => {},
});

const AppContextProvider = ({children}: React.PropsWithChildren<{}>) => {
  const [viewer, setViewer] = useState<AppContextProps['viewer']>();
  const [actualViewer, setActualViewer] = useState<AppContextProps['actualViewer']>();
  const [globalNavigationTab, setGlobalNavigationTab] = useState('');
  const [isGlobalNavSidebarExpanded, setIsGlobalNavSidebarExpanded] = useState(false);
  const {data} = useQuery(AppContextProvider.query, {fetchPolicy: 'cache-and-network'});

  useEffect(() => {
    if (data) {
      setViewer(data.viewer);
      setActualViewer(data.actualViewer);
    }
  }, [data]);

  const handleCheckSidebarIsExpanded = async () => {
    const value = await Storage.getItem('IS_GLOBAL_NAV_SIDEBAR_EXPANDED');
    return value === 'true';
  };
  useMountEffect(() => {
    handleCheckSidebarIsExpanded().then((value) => {
      setIsGlobalNavSidebarExpanded(value);
    });
  });

  const toggleIsGlobalNavSidebarExpanded = useCallback(async () => {
    const nextIsGlobalNavSidebarExpanded = !isGlobalNavSidebarExpanded;
    setIsGlobalNavSidebarExpanded(nextIsGlobalNavSidebarExpanded);
    await Storage.setItem('IS_GLOBAL_NAV_SIDEBAR_EXPANDED', nextIsGlobalNavSidebarExpanded);
  }, [isGlobalNavSidebarExpanded]);

  const store = {
    viewer,
    actualViewer,
    isGlobalNavSidebarExpanded,
    toggleIsGlobalNavSidebarExpanded,
    globalNavigationTab,
    setGlobalNavigationTab,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AppContextProvider.query = gql`
  query AppContextProvider {
    ${gql.query}
    actualViewer {
      id
      role
      isActive
    }
    viewer {
      id
      isActive
      isDeleted
      fullName
      role
      unreadNotificationsCount
      isPrimaryOrganizationAdmin
      isAuthorizedAccountingActions
      organization {
        id
        name
      }
      viewingOrganization {
        id
        name
        slug
        hasMultipleOrganizations
        company {
          id
          isActive
        }
        features {
          isEnabledGlobalSearchV2: isEnabled(feature: "GLOBAL_SEARCH_V2")
          isEnabledMultibranchOrganizationSwitcher: isEnabled(feature: "MULTIBRANCH_ORGANIZATION_SWITCHER")
        }
      }
    }
  }
`;

export default AppContextProvider;
