// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {Form} from '@supermove/hooks';
import {pluralize} from '@supermove/utils';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import {InventoryRoomsFormType} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import EditRoomAttachmentsDrawerContent from 'modules/Inventory/Edit/components/EditRoomAttachmentsDrawerContent';

const DrawerContentContainer = Styled.View`
  flex: 1;
`;

const EditRoomAttachmentsDrawer = ({
  isOpen,
  handleClose,
  inventoryRoomsForm,
}: {
  isOpen: boolean;
  handleClose: () => void;
  inventoryRoomsForm: Form<{inventoryRoomsForm: InventoryRoomsFormType}>;
}) => {
  const {selectedRoomIndex, roomItemsForms} = inventoryRoomsForm.values.inventoryRoomsForm;
  const selectedRoomForm = roomItemsForms[selectedRoomIndex];
  const videoCount = _.isEmpty(selectedRoomForm.latestVideoAttachment) ? 0 : 1;
  const photoCount = selectedRoomForm.photoAttachments.length;

  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={handleClose}
      width={DrawerWithClose.WIDTH.MEDIUM}
      isAnimated={false}
      headerText={`${selectedRoomForm.name} Media`}
      description={`${videoCount} ${pluralize('video', videoCount)}, ${photoCount} ${pluralize('photo', photoCount)}`}
      bodyProps={{
        bodyScrollStyle: {flex: 1, padding: 0},
      }}
    >
      <DrawerContentContainer>
        <EditRoomAttachmentsDrawerContent inventoryRoomsForm={inventoryRoomsForm} />
      </DrawerContentContainer>
    </DrawerWithClose>
  );
};

export default EditRoomAttachmentsDrawer;
