// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useQuery, useResponsive} from '@supermove/hooks';
import {CancellationReasonModel, ProjectModel} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import CancelProjectForm, {
  CancelProjectFormType,
} from '@shared/modules/Project/forms/CancelProjectForm';

const ProjectCancellationReasonModalContent = ({
  project,
  isOpen,
  form,
}: {
  project: ProjectModel;
  isOpen: boolean;
  form: Form<{cancelProjectForm: CancelProjectFormType}>;
}) => {
  const responsive = useResponsive();

  const {data, loading} = useQuery(ProjectCancellationReasonModalContent.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {slug: project.organization.slug},
  });

  const cancellationReasons = data ? data.organization.companySettings.cancellationReasons : [];
  const selectedCancellationReason = _.find(cancellationReasons, {
    id: form.values.cancelProjectForm.cancellationReasonId,
  });

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Cancellation Reason'}
        name={'cancelProjectForm.cancellationReasonId'}
        component={DropdownInput}
        isRequired
        isResponsive
        input={{
          isLoading: loading,
          isPortaled: true,
          placeholder: 'Select cancellation reason',
          setFieldValue: form.setFieldValue,
          options: cancellationReasons.map(({id, name}: CancellationReasonModel) => ({
            label: name,
            value: id,
          })),
          style: {flex: 1},
        }}
        style={{flex: 1}}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        label={'Details'}
        name={'cancelProjectForm.details'}
        isRequired={selectedCancellationReason?.name === 'Other'}
        isResponsive
        input={{
          multiline: true,
          placeholder: 'Enter details',
          style: {
            height: 76,
            paddingVertical: 8,
            paddingHorizontal: 12,
          },
        }}
      />
      {responsive.desktop && <Space height={16} />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectCancellationReasonModalContent.fragment = gql`
  ${CancelProjectForm.edit.fragment}
  fragment ProjectCancellationReasonModalContent on Project {
    id
    organization {
      id
      slug
    }
    ...CancelProjectForm_edit
  }
`;

ProjectCancellationReasonModalContent.query = gql`
  query ProjectCancellationReasonModalContent($slug: String!) {
    organization(slug: $slug) {
      id
      companySettings {
        cancellationReasons {
          id
          name
        }
      }
    }
  }
`;

export default ProjectCancellationReasonModalContent;
