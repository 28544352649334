// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInlineFormMutation, useToast} from '@supermove/hooks';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardLinkedToGlobalDashboardForm from '@shared/modules/Reports/forms/DashboardLinkedToGlobalDashboardForm';

interface DashboardsPageResetDashboardToDefaultModalProps {
  dashboardId: string;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const DashboardsPageResetDashboardToDefaultModal = ({
  dashboardId,
  isOpen,
  handleClose,
  refetch,
}: DashboardsPageResetDashboardToDefaultModalProps) => {
  const resetDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard has been reset!',
  });

  const {form, handleSubmit, submitting} = useInlineFormMutation({
    name: 'dashboardLinkedToGlobalDashboardForm',
    form: {
      dashboardId,
    },
    enableReinitialize: true,
    toForm: DashboardLinkedToGlobalDashboardForm.toForm,
    toMutation: DashboardLinkedToGlobalDashboardForm.toMutation,
    mutation: gql`
      mutation useResetDashboardToGlobalDashboardMutation($dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm!) {
        response: resetDashboardToGlobalDashboard(dashboardLinkedToGlobalDashboardForm: $dashboardLinkedToGlobalDashboardForm) {
          ${gql.errors}
          dashboard {
            id
          }
        }
      }
    `,
    onSuccess: () => {
      resetDashboardSuccessToast.handleToast();
      refetch();
      handleClose();
    },
  });

  return (
    <SuccessModal
      isOpen={isOpen}
      icon={Icon.ChartLineUp}
      title={`Reset filters?`}
      subtitle={
        'This will remove the saved filters and reset it back to the default for the entire company.'
      }
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

export default DashboardsPageResetDashboardToDefaultModal;
