// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useResponsive, useState} from '@supermove/hooks';
import {Job, JobUser} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionBadge from 'modules/App/components/ActionBadge';
import StatusBadge from 'modules/App/components/StatusBadge';
import CrewTrucksPopover from 'modules/Dispatch/Crew/components/CrewTrucksPopover';
import CrewUsersAndDriversPopover from 'modules/Dispatch/Crew/components/CrewUsersAndDriversPopover';
import JobJobUsersScheduleModal from 'modules/Job/V2/Move/components/JobJobUsersScheduleModal';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import JobCrewsListTruckJobUser from 'modules/Project/V2/Show/Blocks/Job/components/JobCrewsListTruckJobUser';
import JobMissingDateModal from 'modules/Project/V2/Show/Blocks/Job/components/JobMissingDateModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CrewContainer = Styled.View`
  padding: 12px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border}
  background-color: ${colors.gray.background}
`;

const CardTitle = Styled.Text`
  ${Typography.Responsive.Label}
`;

const TrucksAndMoversListContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const BadgeText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'MaybeR... Remove this comment to see the full error message
    disabled,
  }) => (disabled ? colors.gray.tertiary : colors.gray.secondary)};
  margin-left: 8px;
`;

const EmptyText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const getCrews = ({job, viewer}: any) => {
  // Only the primary organization shows all crews
  const crews = viewer.viewingOrganization.isPrimary
    ? job.crews
    : job.crews.filter((crew: any) => crew.organization.id === viewer.viewingOrganization.id);

  const sortedCrews = _.sortBy(
    crews,
    (crew) => !crew.isPrimary,
    (crew) => crew.organization.name,
  );

  return sortedCrews.map((crew) => ({
    ...crew,
    jobUsers: _.sortBy(crew.jobUsers, (jobUser) => jobUser.user.fullName),
  }));
};

const ActionBadgeWrapper = ({job, responsive, popover, children}: any) => {
  if (responsive.desktop) {
    return (
      <Popover.Content innerRef={popover.ref}>
        <JobActionDisabledTooltip job={job}>{children}</JobActionDisabledTooltip>
      </Popover.Content>
    );
  }
  return children;
};

const TrucksButton = ({
  crew,
  job,
  refetch,
  isDispatchCloseable,
  setIsDispatchCloseable,
  responsive,
}: any) => {
  const crewTrucksPopover = usePopover();
  const jobMissingDateModal = useModal({name: 'Job Missing Date Modal', enableTracking: true});
  const hasDate = !!job.startDate;
  const text = `${crew.numberOfAssignedTrucks}/${crew.numberOfRequiredTrucks}`;

  return (
    <React.Fragment>
      <ActionBadgeWrapper job={job} responsive={responsive} popover={crewTrucksPopover}>
        <ActionBadge
          icon={Icon.Truck}
          onPress={hasDate ? crewTrucksPopover.handleToggle : jobMissingDateModal.handleOpen}
          isActive={crewTrucksPopover.isOpen}
          isDisabled={!isDispatchCloseable || job.isFinal || job.isCancelled}
          {...(responsive.desktop
            ? {}
            : {
                isWidthOfContainer: true,
                containerStyle: {flex: 1},
                style: {flex: 1, height: 40},
              })}
        >
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <BadgeText responsive={responsive} disabled={job.isFinal || job.isCancelled}>
            {text}
          </BadgeText>
        </ActionBadge>
      </ActionBadgeWrapper>
      <CrewTrucksPopover
        key={crewTrucksPopover.key}
        crewId={crew.id}
        popoverRef={crewTrucksPopover.ref}
        isOpen={crewTrucksPopover.isOpen}
        handleOpen={crewTrucksPopover.handleOpen}
        handleClose={crewTrucksPopover.handleClose}
        placement={Popover.Positions.BottomEnd}
        offset={[0, 4]}
        refetch={refetch}
        isDispatchCloseable={isDispatchCloseable}
        setIsDispatchCloseable={setIsDispatchCloseable}
        shouldRunUpsertCostsAndBillingEngineAsync
      />
      <JobMissingDateModal
        key={jobMissingDateModal.key}
        job={job}
        projectTypeId={job.project.projectTypeId}
        isOpen={jobMissingDateModal.isOpen}
        handleClose={jobMissingDateModal.handleClose}
        onSuccess={() => {
          crewTrucksPopover.handleOpen();
          refetch();
        }}
        messageExtension={' before adding trucks'}
      />
    </React.Fragment>
  );
};

const MoversButton = ({
  crew,
  job,
  refetch,
  isDispatchCloseable,
  setIsDispatchCloseable,
  responsive,
}: any) => {
  const crewMoversPopover = usePopover();
  const jobJobUsersScheduleModal = useModal({name: 'Job JobUsers Schedule Modal'});
  const jobMissingDateModal = useModal({name: 'Job Missing Date Modal', enableTracking: true});
  const hasDate = !!job.startDate;
  const text = `${crew.jobUsers.length}/${crew.numberOfRequiredMovers}`;

  return (
    <React.Fragment>
      <ActionBadgeWrapper job={job} responsive={responsive} popover={crewMoversPopover}>
        <ActionBadge
          icon={Icon.User}
          onPress={hasDate ? crewMoversPopover.handleToggle : jobMissingDateModal.handleOpen}
          isActive={crewMoversPopover.isOpen}
          isDisabled={!isDispatchCloseable || job.isFinal || job.isCancelled}
          {...(responsive.desktop
            ? {}
            : {
                isWidthOfContainer: true,
                containerStyle: {flex: 1},
                style: {flex: 1, height: 40},
              })}
        >
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <BadgeText responsive={responsive} disabled={job.isFinal || job.isCancelled}>
            {text}
          </BadgeText>
        </ActionBadge>
      </ActionBadgeWrapper>
      <CrewUsersAndDriversPopover
        key={crewMoversPopover}
        crew={crew}
        popoverRef={crewMoversPopover.ref}
        isOpen={crewMoversPopover.isOpen}
        handleOpen={crewMoversPopover.handleOpen}
        handleClose={crewMoversPopover.handleClose}
        placement={Popover.Positions.BottomEnd}
        refetch={refetch}
        jobJobUsersScheduleModal={jobJobUsersScheduleModal}
        offset={[0, 4]}
        isDispatchCloseable={isDispatchCloseable}
        setIsDispatchCloseable={setIsDispatchCloseable}
        shouldRunUpsertCostsAndBillingEngineAsync
      />
      <JobJobUsersScheduleModal
        jobUuid={job.uuid}
        isOpen={jobJobUsersScheduleModal.isOpen}
        handleClose={() => {
          jobJobUsersScheduleModal.handleClose();
          refetch();
        }}
      />
      <JobMissingDateModal
        key={jobMissingDateModal.key}
        job={job}
        projectTypeId={job.project.projectTypeId}
        isOpen={jobMissingDateModal.isOpen}
        handleClose={jobMissingDateModal.handleClose}
        onSuccess={() => {
          crewMoversPopover.handleOpen();
          refetch();
        }}
        messageExtension={' before adding movers'}
      />
    </React.Fragment>
  );
};

const TrucksAndMoversButtons = ({
  crew,
  job,
  refetch,
  isDispatchCloseable,
  setIsDispatchCloseable,
  responsive,
}: any) => {
  const showTrucks = Job.getShowTrucks(job);
  const showMovers = Job.getShowMovers(job);

  return (
    <React.Fragment>
      {showTrucks && (
        <TrucksButton
          crew={crew}
          job={job}
          refetch={refetch}
          isDispatchCloseable={isDispatchCloseable}
          setIsDispatchCloseable={setIsDispatchCloseable}
          responsive={responsive}
        />
      )}
      {showMovers && showTrucks && <Space width={responsive.desktop ? 12 : 16} />}
      {showMovers && (
        <MoversButton
          crew={crew}
          job={job}
          refetch={refetch}
          isDispatchCloseable={isDispatchCloseable}
          setIsDispatchCloseable={setIsDispatchCloseable}
          responsive={responsive}
        />
      )}
    </React.Fragment>
  );
};

const JobCrewCardHeader = ({crew, job, refetch, responsive}: any) => {
  const [isDispatchCloseable, setIsDispatchCloseable] = useState(true);

  return (
    <React.Fragment>
      <Row>
        <CardTitle responsive={responsive}>{crew.organization.name}</CardTitle>
        {crew.isPrimary && (
          <React.Fragment>
            <Space style={responsive.desktop ? {width: 12} : {flex: 1}} />
            <StatusBadge
              label={'Primary'}
              textColor={colors.orange.status}
              backgroundColor={colors.orange.accent}
              size={StatusBadge.SIZE.LARGE}
            />
          </React.Fragment>
        )}
        {responsive.desktop && (
          <React.Fragment>
            <Space width={12} />
            <TrucksAndMoversButtons
              crew={crew}
              job={job}
              refetch={refetch}
              isDispatchCloseable={isDispatchCloseable}
              setIsDispatchCloseable={setIsDispatchCloseable}
              responsive={responsive}
            />
          </React.Fragment>
        )}
      </Row>
      {!responsive.desktop && (
        <React.Fragment>
          <Space height={16} />
          <Row>
            <TrucksAndMoversButtons
              crew={crew}
              job={job}
              refetch={refetch}
              isDispatchCloseable={isDispatchCloseable}
              setIsDispatchCloseable={setIsDispatchCloseable}
              responsive={responsive}
            />
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const TrucksAndMoversList = ({crew, job, refetch, responsive, viewer}: any) => {
  const trucksAndMovers = [
    ...(Job.getShowTrucks(job) ? crew.jobTrucks : []),
    ...(Job.getShowMovers(job) ? crew.jobUsers : []),
  ];

  if (_.isEmpty(trucksAndMovers)) {
    return (
      <React.Fragment>
        <Space height={16} />
        <EmptyText responsive={responsive}>There are no assigned trucks or movers.</EmptyText>
      </React.Fragment>
    );
  }

  return (
    <TrucksAndMoversListContainer>
      {trucksAndMovers.map((item, index) => {
        return (
          <JobCrewsListTruckJobUser key={item.id} index={index}>
            {item.truck ? (
              <JobCrewsListTruckJobUser.Truck
                job={job}
                truck={item.truck}
                responsive={responsive}
              />
            ) : (
              <JobCrewsListTruckJobUser.JobUser
                jobUser={item}
                job={job}
                refetch={refetch}
                responsive={responsive}
                viewerRole={viewer.role}
              />
            )}
          </JobCrewsListTruckJobUser>
        );
      })}
    </TrucksAndMoversListContainer>
  );
};

const JobCrewCard = ({crew, job, refetch, responsive, viewer}: any) => {
  return (
    <CrewContainer>
      <JobCrewCardHeader crew={crew} job={job} refetch={refetch} responsive={responsive} />
      {crew.jobTrucks.length || crew.jobUsers.length ? <Space height={16} /> : null}
      <TrucksAndMoversList
        crew={crew}
        job={job}
        refetch={refetch}
        responsive={responsive}
        viewer={viewer}
      />
    </CrewContainer>
  );
};

const JobCrewsList = ({job, viewer, refetch}: any) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {getCrews({job, viewer}).map((crew, index) => {
        return (
          <React.Fragment key={crew.id}>
            {index > 0 && <Space height={16} />}
            <JobCrewCard
              crew={crew}
              job={job}
              refetch={refetch}
              responsive={responsive}
              viewer={viewer}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCrewsList.fragment = gql`
  ${CrewUsersAndDriversPopover.fragment}
  ${Job.getShowMovers.fragment}
  ${Job.getShowTrucks.fragment}
  ${JobUser.getTooltipStatus.fragment}
  ${JobCrewsListTruckJobUser.fragment}
  ${(JobCrewsListTruckJobUser.Truck as any).fragment}
  ${(JobCrewsListTruckJobUser.JobUser as any).fragment}
  ${JobMissingDateModal.fragment}

  fragment JobCrewsList on Job {
    id
    uuid
    startDate
    isFinal
    isCancelled
    crews {
      id
      isPrimary
      numberOfAssignedTrucks
      numberOfRequiredTrucks
      numberOfRequiredMovers
      jobUsers {
        id
        ...JobCrewsListTruckJobUser_JobUser
      }
      jobTrucks {
        id
        truck {
          id
          ...JobCrewsListTruckJobUser_Truck
        }
      }
      organization {
        id
        name
      }
      ...CrewUsersAndDriversPopover
    }
    project {
      id
      projectTypeId
    }
    ...Job_getShowMovers
    ...Job_getShowTrucks
    ...JobCrewsListTruckJobUser
    ...JobMissingDateModal
  }

  fragment JobCrewsList_Viewer on User {
    id
    role
    viewingOrganization {
      id
      isPrimary
    }
  }
`;

export default JobCrewsList;
