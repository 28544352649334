// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useResponsive, useToast} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import Toast from '@shared/design/components/Toast';
import CancelProjectForm from '@shared/modules/Project/forms/CancelProjectForm';
import useUpdateProjectCancellationReasonMutation from '@shared/modules/Project/hooks/useUpdateProjectCancellationReasonMutation';
import ProjectCancellationReasonModalContent from 'modules/Project/V2/Show/components/ProjectCancellationReasonModalContent';

const EditProjectCancellationReasonModal = ({
  project,
  isOpen,
  handleClose,
  refetch,
}: {
  project: ProjectModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const projectCancelledToast = useToast({
    ToastComponent: Toast,
    message: 'Cancellation reason updated.',
  });
  const cancelProjectForm = CancelProjectForm.edit(project);
  const {form, handleSubmit, submitting} = useUpdateProjectCancellationReasonMutation({
    cancelProjectForm,
    onSuccess: () => {
      projectCancelledToast.handleToast();
      handleClose();
      refetch();
    },
    onError: (errors: MutationError[]) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Edit Cancellation Reason'}
      handleAction={handleSubmit}
      handleActionText={'Save'}
      handleActionColor={colors.red.warning}
      handleCloseText={'Go Back'}
      isSubmitting={submitting}
      isMobileSheet
      shouldCloseOnClickOutside={responsive.desktop}
    >
      <ProjectCancellationReasonModalContent
        project={project}
        isOpen={isOpen}
        // @ts-expect-error - using Form but expecting FormikFormType
        form={form}
      />
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectCancellationReasonModal.fragment = gql`
  ${CancelProjectForm.edit.fragment}
  fragment EditProjectCancellationReasonModal on Project {
    id
    organization {
      id
      slug
    }
    ...CancelProjectForm_edit
  }
`;

export default EditProjectCancellationReasonModal;
