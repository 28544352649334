// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Emoji, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation, useResponsive} from '@supermove/hooks';
import {PaymentMethod} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import PayEngineCreditCardInput from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import {
  transactionErrorMessage,
  transactionContactMessage,
} from '@shared/modules/Payment/hooks/payengineErrors';
import useChargePayEngineCreditCard from '@shared/modules/Payment/hooks/useChargePayEngineCreditCard';
import ConfirmationStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationStepHeader';

import UpdateConfirmationDepositMutation from './UpdateConfirmationDepositMutation';

const Content = Styled.View`
  align-self: stretch;
  padding-top: 60px;
  padding-bottom: 90px;
`;

const SubtitleBold = Styled.H6`
  ${fontWeight(700)}
  line-height: 24px;
`;

const Row = Styled.View`
  margin-top: 30px;
`;

const Section = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Name = Styled.H6`
  ${fontWeight(700)}
`;

const Footer = Styled.View`
  width: 100%;
  margin-top: 30px;
`;

const Button = Styled.StatefulButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ValidationError = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const getSubtitleText = (displayAmount: any) => {
  return [
    `We require a deposit to confirm your move. ` +
      `This deposit guarantees your move with us for your scheduled date. ` +
      `The deposit amount will be automatically applied to the total cost of your ` +
      `move when your move is complete. \n\n`,
    <SubtitleBold key={1}>
      {`Please use the secure payment form below to leave your deposit. ` +
        `You will be immediately charged for ${displayAmount}.`}
    </SubtitleBold>,
  ];
};

const ButtonContent = ({isLoading, isSuccess, confirmation}: any) => {
  if (isLoading) {
    return null;
  }
  if (isSuccess) {
    return <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Check} />;
  }
  const actionText = _.get(confirmation, 'nextStep.actionText', '');
  if (actionText) {
    return <Text>{actionText}</Text>;
  }
  return <Text>Continue</Text>;
};

const getBillId = (project: any) => {
  // TODO: Fix the types here... this is legacy logic held over from:
  //   https://github.com/supermove/supermove/blame/ad04133affdd872d574113f775611a094666bf9b/apps/manager/src/modules/Customer/Project/Confirmation/Deposit/components/DepositConfirmationProjectForm.js#L22-L27
  return Number(_.get(project, 'currentPrimaryBill.id')) as unknown as string;
};

const getDisplayAmount = ({subtotal, total, paymentMethod}: any) => {
  if (!paymentMethod?.feePercentage) {
    return Currency.display(total);
  }
  return `${Currency.display(total)} (${Currency.display(subtotal)} + ${PaymentMethod.getPaymentMethodDisplay(paymentMethod)})`;
};

type OwnDepositConfirmationProjectFormPayEngineProps = {
  project: any;
  onSuccess: (...args: any[]) => any;
  beforeSubmit?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'DepositConfirmationProjectFormPayEngin... Remove this comment to see the full error message
type DepositConfirmationProjectFormPayEngineProps =
  OwnDepositConfirmationProjectFormPayEngineProps &
    typeof DepositConfirmationProjectFormPayEngine.defaultProps;

// @ts-expect-error TS(7022): 'DepositConfirmationProjectFormPayEngine' implicit... Remove this comment to see the full error message
const DepositConfirmationProjectFormPayEngine = ({
  project,
  onSuccess,
  beforeSubmit,
}: DepositConfirmationProjectFormPayEngineProps) => {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isFormLoaded, setIsFormLoaded] = React.useState(false);
  const onLoad = React.useCallback(() => {
    setIsFormLoaded(true);
  }, [setIsFormLoaded]);

  const updateConfirmationDepositForm = useForm({
    initialValues: {
      paymentId: undefined,
    },
  });
  const updateConfirmationDepositMutation = useFormMutation({
    form: updateConfirmationDepositForm,
    mutation: UpdateConfirmationDepositMutation.mutation,
    variables: {
      confirmationId: project.confirmation.id,
      paymentId: updateConfirmationDepositForm.values.paymentId,
    },
    onSuccess: (response) => {},
    onError: () => {},
  });

  const client = project.billingClient;

  const paymentMethod = project.organization.payenginePaymentMethod;
  const depositAmount = Number(project.confirmation.nextStep.value);
  const paymentFeeAmount = paymentMethod
    ? PaymentMethod.computePaymentFeeAmount(paymentMethod, {amount: depositAmount})
    : 0;
  const amount = depositAmount + paymentFeeAmount;
  const chargePayEngineCreditCard = useChargePayEngineCreditCard({
    beginPaymentV3Form: BeginPaymentV3Form.new({
      billId: getBillId(project),
      customerId: client.primaryContact.id,
      name: 'Deposit',
      description: `Deposit for Project ${project.identifier}`,
      amount,
      method: 'PAYENGINE_CREDIT_CARD',
      paymentMethodId: paymentMethod?.id,
      paymentFeeAmount,
    }),
    saveToClientId: project.billingClient.id,
  });

  const onPress = async () => {
    try {
      await chargePayEngineCreditCard.handleTokenizeCreditCard();
    } catch (error) {
      console.error(`Error tokenizing credit card: `, error);
      // @ts-expect-error TS(2345): Argument of type '"Invalid credit card details."' ... Remove this comment to see the full error message
      setErrorMessage(`Invalid credit card details.`);
      return;
    }

    try {
      const response = await chargePayEngineCreditCard.handleChargeCreditCard();
      await updateConfirmationDepositForm.setFieldValue('paymentId', response.payment.id);
    } catch (error) {
      console.error(`Error charging credit card: `, error);
      setErrorMessage(
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message

        `${transactionErrorMessage(error)} ${transactionContactMessage(project.organization.name)}`,
      );
      return;
    }

    try {
      const response = await updateConfirmationDepositMutation.handleSubmit();
      const errors = response?.errors || response?.data?.response?.errors;
      if (!response || errors) {
        // @ts-expect-error TS(2345): Argument of type '"Something went wrong."' is not ... Remove this comment to see the full error message
        setErrorMessage('Something went wrong.');
        console.error(`Error executing UpdateConfirmationDepositMutation: `, errors);
        return;
      }

      setErrorMessage(null);
      setIsSuccess(true);
      onSuccess();
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type '"Something went wrong."' is not ... Remove this comment to see the full error message
      setErrorMessage('Something went wrong.');
      console.error('Unhandled error: ', error);
    }
  };

  const isLoading =
    chargePayEngineCreditCard.submitting || updateConfirmationDepositMutation.submitting;
  const responsive = useResponsive();
  return (
    <Content>
      <ConfirmationStepHeader
        title={'Leave your deposit'}
        subtitle={getSubtitleText(
          getDisplayAmount({
            subtotal: depositAmount,
            total: amount,
            paymentMethod,
          }),
        )}
        confirmation={project.confirmation}
      />
      <Row>
        <Section>
          <Emoji component={EmojiText} name={'credit_card'} />
          <Name>Credit card information</Name>
        </Section>
        <PayEngineCreditCardInput
          setCreditCardClient={chargePayEngineCreditCard.setCreditCardClient}
          mode={project.organization.mode}
          onLoad={onLoad}
        />
        {errorMessage && <ValidationError>{errorMessage}</ValidationError>}
      </Row>
      <Footer {...responsive}>
        <Button
          disabled={!isFormLoaded}
          loading={isLoading}
          success={isSuccess}
          onPress={() => beforeSubmit(onPress)}
        >
          <ButtonContent
            isLoading={isLoading}
            isSuccess={isSuccess}
            confirmation={project.confirmation}
          />
        </Button>
      </Footer>
    </Content>
  );
};

DepositConfirmationProjectFormPayEngine.defaultProps = {
  beforeSubmit: (handleSubmit: any) => handleSubmit(),
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DepositConfirmationProjectFormPayEngine.fragment = gql`
  ${PaymentMethod.computePaymentFeeAmount.fragment}
  ${PaymentMethod.getPaymentMethodDisplay.fragment}
  fragment DepositConfirmationProjectFormPayEngine on Project {
    id
    identifier
    number
    activeMoveJobs {
      id
      estimateBill {
        id
      }
    }
    confirmation {
      id
      nextStep {
        kind
        value
        actionText
      }
    }
    organization {
      id
      mode
      name
      payenginePaymentMethod {
        id
        feePercentage
        ...PaymentMethod_computePaymentFeeAmount
        ...PaymentMethod_getPaymentMethodDisplay
      }
    }
    currentPrimaryBill {
      id
    }
    billingClient {
      id
      primaryContact {
        id
      }
    }
  }
`;

export default DepositConfirmationProjectFormPayEngine;
