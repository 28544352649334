// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Project, ProjectModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const ProjectStatusContainer = Styled.View<{color: string}>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  border-radius: 4px;
  background-color: ${({color}) => colors.getBackgroundColor(color)};
`;

const ProjectStatusText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
`;

const ProjectStatusBadge = ({project}: {project: ProjectModel}) => {
  const responsive = useResponsive();
  const {isEnabledCancelProject} = project.organization.features;
  const status = Project.getProjectStatus(project);
  const color =
    project.isCancelled && isEnabledCancelProject
      ? colors.gray.secondary
      : Project.getProjectStatusColor(project);
  const icon = Project.getProjectStatusIcon(project);

  return (
    <TextTooltip
      text={
        project.isCancelled && isEnabledCancelProject && project.projectCancellationReason
          ? `Cancelled at ${Datetime.convertToDisplayDatetime(project.projectCancellationReason.createdAt)}`
          : undefined
      }
      placement={'top'}
    >
      <ProjectStatusContainer color={color}>
        <Icon source={icon} size={Icon.Sizes.Small} color={color} />
        <Space width={8} />
        <ProjectStatusText color={color} responsive={responsive}>
          {status}
        </ProjectStatusText>
      </ProjectStatusContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectStatusBadge.fragment = gql`
  ${Project.getProjectStatus.fragment}
  ${Project.getProjectStatusColor.fragment}
  ${Project.getProjectStatusIcon.fragment}
  fragment ProjectStatusBadge on Project {
    id
    isCancelled
    projectCancellationReason {
      id
      createdAt
    }
    organization {
      id
      features {
        isEnabledCancelProject: isEnabled(feature: "CANCEL_PROJECT")
      }
    }
    ...Project_getProjectStatus
    ...Project_getProjectStatusColor
    ...Project_getProjectStatusIcon
  }
`;

export default ProjectStatusBadge;
