import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import LocationForm, {LocationFormType} from '@shared/modules/Location/forms/LocationForm';

export interface ProjectLocationsFormType {
  projectId: string;
  originLocationId: string;
  destinationLocationId: string;
  locationForms: LocationFormType[];
}

const edit = withFragment(
  (project) => ({
    projectId: (project as any).id,
    originLocationId: (project as any).originLocationId,
    destinationLocationId: (project as any).destinationLocationId,
    locationForms: _.isEmpty((project as any).locations)
      ? [LocationForm.new({kind: LocationKind.INTERMEDIATE})]
      : (project as any).locations.map((location: any) => LocationForm.edit(location)),
  }),
  gql`
    ${LocationForm.edit.fragment}

    fragment ProjectLocationsForm_edit on Project {
      id
      originLocationId
      destinationLocationId
      locations {
        id
        ...LocationForm_edit
      }
    }
  `,
);

const _delete = ({projectId, originLocationId, destinationLocationId, locationIds}: any) => {
  return {
    projectId,
    originLocationId,
    destinationLocationId,
    locationForms: locationIds.map((locationId: any) => LocationForm.delete({locationId})),
  };
};

const toForm = ({projectId, originLocationId, destinationLocationId, locationForms}: any) => ({
  projectId,
  originLocationId: _.toString(originLocationId),
  destinationLocationId: _.toString(destinationLocationId),
  locationForms: locationForms.map((locationForm: any) => LocationForm.toForm(locationForm)),
});

const toMutation = ({projectId, originLocationId, destinationLocationId, locationForms}: any) => ({
  projectId,
  originLocationId: originLocationId ? _.toNumber(originLocationId) : null,
  destinationLocationId: destinationLocationId ? _.toNumber(destinationLocationId) : null,
  locationForms: locationForms.map((locationForm: any) => LocationForm.toMutation(locationForm)),
});

const ProjectLocationsForm = {
  edit,
  delete: _delete,
  toForm,
  toMutation,
};

export default ProjectLocationsForm;
