// Supermove
import {gql} from '@supermove/graphql';
import {OrganizationModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import {EmailSenderKindType} from '@shared/modules/Organization/enums/EmailSenderKind';

export interface OrganizationEmailSenderFormType {
  organizationId: string;
  emailSenderKind: EmailSenderKindType;
}

const edit = withFragment(
  (organization: OrganizationModel) => ({
    organizationId: organization.id,
    emailSenderKind: organization.settings.emailSenderKind,
  }),
  gql`
    fragment OrganizationEmailSenderForm_edit on Organization {
      id
      settings {
        id
        emailSenderKind
      }
    }
  `,
);

const toMutation = ({organizationId, emailSenderKind}: OrganizationEmailSenderFormType) => ({
  organizationId,
  defaultEmailSenderKind: emailSenderKind,
});

const OrganizationEmailSenderForm = {
  edit,
  toMutation,
};

export default OrganizationEmailSenderForm;
