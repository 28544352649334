// Libraries
import React from 'react';

// Supermove
import {PdfViewV2, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import PrintDocumentV2PdfWrapper from '@shared/modules/Document/components/PrintDocumentV2PdfWrapper';

const SkeletonLoaderContentContainer = Styled.View`
  background-color: ${colors.white};
  align-items: center;
  height: ${PdfViewV2.PAGE_HEIGHT}px;
  padding: 48px;
`;

const SkeletonLoaderBodyText = () => {
  return <SkeletonLoader height={SkeletonLoader.HEIGHT.Typography.Body} isFullWidth />;
};

const PrintDocumentSkeletonLoader = ({
  showPrint,
  style,
}: {
  showPrint?: boolean;
  style?: React.CSSProperties;
}) => {
  const Wrapper = showPrint ? PrintDocumentV2PdfWrapper : React.Fragment;

  return (
    <Wrapper>
      <SkeletonLoaderContentContainer style={style}>
        <Space height={48} />
        <SkeletonLoader height={120} width={200} />
        <Space height={32} />
        <SkeletonLoaderBodyText />
        <Space height={32} />
        <SkeletonLoaderBodyText />
        <Space height={32} />
        <SkeletonLoaderBodyText />
        <Space height={32} />
        <SkeletonLoaderBodyText />
      </SkeletonLoaderContentContainer>
    </Wrapper>
  );
};

export default PrintDocumentSkeletonLoader;
