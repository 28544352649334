// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {FileModel, EmailAttachmentStatus} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import {getIconFromExtension} from 'modules/Communication/Email/utils/getAttachmentIcon';

const containerHeight = 130;

const AttachmentImageContainer = Styled.ButtonV2<{isHovered: boolean; isSuccess: boolean}>`
  height: ${containerHeight}px;
  width: 170px;
  flex-direction: column;
  border: 1px solid ${({isHovered, isSuccess}) => (isHovered ? colors.blue.interactive : isSuccess ? colors.gray.border : colors.orange.hover)};
  background-color: ${({isHovered, isSuccess}) => (isSuccess ? (isHovered ? colors.blue.accent : colors.white) : colors.orange.accent)};
  border-radius: 4px;
  overflow: hidden;
  margin-right: 16px;
`;

const imageHeight = 96;

const AttachmentImage = Styled.View<{fileUrl: string}>`
  padding: 12px;
  height: ${imageHeight}px;
  border-bottom-color: ${colors.gray.border};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-image: ${({fileUrl}) => `url(${fileUrl})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-origin: content-box;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const ErrorAttachmentContainer = Styled.View<{hasFilename: boolean}>`
  background-color: ${colors.orange.accent}
  height: ${imageHeight}px;
  border-bottom-color: ${colors.orange.hover};
  border-bottom-style: solid;
  border-bottom-width: ${({hasFilename}) => (hasFilename ? '1px' : '0')};
  align-items: center;
  padding-top: ${({hasFilename}) => (hasFilename ? '0' : containerHeight - imageHeight)}px;
  justify-content: center;
`;

const FilenameContainer = Styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-horizontal: 8px;
`;

const FilenameText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.primary};
`;

const PreviewNonImageText = Styled.Text<{isSmall?: boolean}>`
  ${({isSmall}) => (isSmall ? Typography.Responsive.Label : Typography.Responsive.Heading1)}
  position: absolute;
`;

interface EmailAttachmentImageProps {
  file: FileModel;
  status?: EmailAttachmentStatus;
  responsive: ResponsiveType;
  handleSelectAttachment?: () => void;
  children?: React.ReactNode;
  imageRef?: React.RefObject<any>;
  isHovered?: boolean;
  showFilename?: boolean;
  containerStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  isSmall?: boolean;
}

const FileName = ({filename, responsive}: {filename: string; responsive: ResponsiveType}) => {
  return (
    <FilenameContainer>
      <FilenameText responsive={responsive} numberOfLines={1}>
        {filename}
      </FilenameText>
    </FilenameContainer>
  );
};

const ErrorAttachmentImage = ({
  imageStyle,
  hasFilename,
}: {
  imageStyle?: React.CSSProperties;
  hasFilename: boolean;
}) => {
  return (
    <ErrorAttachmentContainer style={imageStyle} hasFilename={hasFilename}>
      <Icon source={Icon.ExclamationTriangle} size={30} color={colors.orange.status} />
    </ErrorAttachmentContainer>
  );
};

const NonImageAttachment = ({
  responsive,
  isSmall,
  filetype,
}: {
  responsive: ResponsiveType;
  isSmall?: boolean;
  filetype: string;
}) => {
  const iconSource = getIconFromExtension(filetype);
  return (
    <PreviewNonImageText responsive={responsive} numberOfLines={1} isSmall={isSmall}>
      <Icon source={iconSource} size={isSmall ? 30 : 60} color={colors.gray.tertiary} />
    </PreviewNonImageText>
  );
};

const EmailAttachmentImage = ({
  file,
  status = 'SUCCESS',
  responsive,
  handleSelectAttachment,
  imageRef,
  isHovered,
  children,
  showFilename,
  containerStyle,
  imageStyle,
  isSmall = true,
}: EmailAttachmentImageProps) => {
  const isSuccess = status === 'SUCCESS';
  const hasFilename = !!(showFilename && file?.name);

  return (
    <TextTooltip
      text={
        isSuccess ? undefined : 'Attachment failed to send, please check the file and try again.'
      }
      isVisible={isHovered}
      placement={'top'}
    >
      <AttachmentImageContainer
        ref={imageRef}
        isHovered={isHovered}
        isSuccess={isSuccess}
        onPress={handleSelectAttachment}
        style={containerStyle}
        disabled={!isSuccess}
      >
        {isSuccess ? (
          <AttachmentImage fileUrl={file.downloadUrl} style={imageStyle}>
            {!file.isImage && (
              <NonImageAttachment
                responsive={responsive}
                isSmall={isSmall}
                filetype={file.filetype}
              />
            )}
            {children}
          </AttachmentImage>
        ) : (
          <ErrorAttachmentImage hasFilename={hasFilename} imageStyle={imageStyle} />
        )}
        {hasFilename ? <FileName filename={file.name} responsive={responsive} /> : null}
      </AttachmentImageContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachmentImage.fragment = gql`
  fragment EmailAttachmentImage on File {
    id
    name
    isImage
    filetype
    downloadUrl
  }
`;

export default EmailAttachmentImage;
