import React from 'react';

import {Browser} from '@supermove/app';

import AppWrapper from 'core/AppWrapper';

import {client} from './config';

export const Root = (props: {}) => {
  return (
    <Browser client={client}>
      <AppWrapper />
    </Browser>
  );
};
