// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInlineFormMutation, useToast} from '@supermove/hooks';
import {DashboardModel} from '@supermove/models';

// App

import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardForm, {
  DashboardFormEditFragment,
} from '@shared/modules/Reports/forms/DashboardForm';

export type DashboardDataType = {
  id: string;
  globalDashboardId?: string;
};

interface DashboardsPageRemoveDashboardModalProps {
  dashboard: DashboardModel;
  isOpen: boolean;
  handleClose: () => void;
  handleRemove: () => void;
}

const DashboardsPageRemoveDashboardModal = ({
  dashboard,
  isOpen,
  handleClose,
  handleRemove,
}: DashboardsPageRemoveDashboardModalProps) => {
  const removeDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard removed!',
  });

  const {form, handleSubmit, submitting} = useInlineFormMutation({
    name: 'dashboardForm',
    form: DashboardForm.edit(dashboard),
    enableReinitialize: true,
    toForm: DashboardForm.toForm,
    toMutation: DashboardForm.toMutation,
    mutation: gql`
      mutation useDeleteDashboardMutation($dashboardForm: DashboardForm!) {
        response: deleteDashboard(dashboardForm: $dashboardForm) {
          ${gql.errors}
          dashboard {
            id
          }
        }
      }
    `,
    onSuccess: () => {
      removeDashboardSuccessToast.handleToast();
      handleRemove();
      handleClose();
    },
  });

  const message = dashboard.globalDashboardId
    ? `You can add this report back via the library.`
    : `This cannot be undone.`;

  return (
    <WarningModal
      isSubmitting={submitting}
      isOpen={isOpen}
      icon={Icon.Trash}
      title={`Remove report?`}
      message={message}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

DashboardsPageRemoveDashboardModal.fragment = gql`
  ${DashboardFormEditFragment}
  fragment DashboardsPageRemoveDashboardModalFragment on Dashboard {
    id
    ...DashboardFormEdit
  }
`;

export default DashboardsPageRemoveDashboardModal;
