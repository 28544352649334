// Libraries
import React from 'react';

// Supermove
import {BrainfishWidget, useSupportTools} from '@supermove/analytics';
import {MaintenancePage, SupermoveApp} from '@supermove/app';
import {Styled, UpdateChecker, ToastContainer} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useNavigationDOM,
  useQuery,
  useResponsive,
  useState,
  useTouch,
} from '@supermove/hooks';
import {Environment, Storage} from '@supermove/sdk';

// App

import {getManagerSSEChannels} from '@shared/modules/SSE/components/SSEConfig';
import {SSEProvider} from '@shared/modules/SSE/context/SSEContext';
import UserRole from '@shared/modules/User/enums/UserRole';
import Router from 'core/Router';
import GlobalHeader from 'modules/App/Global/GlobalHeader';
import GlobalHeaderMobile from 'modules/App/Global/GlobalHeaderMobile';
import GlobalNavigationSidebar from 'modules/App/Global/GlobalNavigationSidebar';
import ErrorModal from 'modules/App/components/ErrorModal';
import UpdateAppBanner from 'modules/App/components/UpdateAppBanner';
import AppContextProvider from 'modules/App/context/AppContextProvider';
import useAppContext from 'modules/App/context/useAppContext';

import {AppInfo} from '../config';

const Row = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const Page = Styled.View`
  flex: 1;
`;

const getIsCustomerPage = ({navigator}: any) => {
  return navigator.location.pathname.includes('/0/');
};

const getIsMoverPage = ({navigator}: any) => {
  return navigator.location.pathname.includes('/1/');
};

const getIsSharePage = ({navigator}: any) => {
  return navigator.location.pathname.includes('/share/');
};

const logOut = async () => {
  await Storage.clear();
  window.location.href = '/';
};

const ManagerAppNavigation = ({children}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const {viewer, actualViewer} = useAppContext();
  const isCustomerPage = getIsCustomerPage({navigator});
  const isMoverPage = getIsMoverPage({navigator});
  const isSharePage = getIsSharePage({navigator});
  // NOTE: The viewer check is a safeguard to prevent downstream components from erroring if they rely on the viewer without a null check.
  // This is existing functionality that ideally can be removed in the future.
  const isGlobalNavVisible = viewer && !isCustomerPage && !isMoverPage && !isSharePage;

  useEffect(() => {
    // First check if impersonating. We want to allow impersonating inactive users for super admins.
    if (viewer && actualViewer && viewer.id !== actualViewer.id) {
      if (actualViewer.role !== UserRole.SUPER_ADMIN || !actualViewer.isActive) {
        logOut();
      }
    } else if (
      viewer &&
      (viewer.isDeleted || !viewer.isActive || !viewer.viewingOrganization.company.isActive)
    ) {
      logOut();
    }
  }, [viewer]);

  return (
    <React.Fragment>
      {isGlobalNavVisible && (!responsive.desktop ? <GlobalHeaderMobile /> : <GlobalHeader />)}
      <Row>
        {isGlobalNavVisible && responsive.desktop && <GlobalNavigationSidebar />}
        <Page>{children}</Page>
      </Row>
    </React.Fragment>
  );
};

const SSEWrapper = ({viewer, children}: any) => {
  const sseChannels = getManagerSSEChannels({
    viewerId: viewer?.id,
    organizationId: viewer?.viewingOrganization?.id,
  });

  return (
    <SSEProvider
      userId={viewer?.id}
      organizationId={viewer?.viewingOrganization?.id}
      channels={sseChannels}
    >
      {children}
    </SSEProvider>
  );
};

const ExternalWidgets = ({viewer, error}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const {isTouch} = useTouch();
  const [isZendeskChatVisible, setIsZendeskChatVisible] = useState(false);
  const [isBrainfishWidgetVisible, setIsBrainfishWidgetVisible] = useState(false);
  const {zendesk, handleOpen} = useSupportTools({isChatVisible: isZendeskChatVisible});

  useEffect(() => {
    // Dynamically show/hide the support widgets based on the current page and device type.
    const shouldHide =
      !responsive.desktop ||
      getIsCustomerPage({navigator}) ||
      getIsMoverPage({navigator}) ||
      getIsSharePage({navigator}) ||
      isTouch;

    const {isEnabledOfficeZendeskSupportWidget, isEnabledOfficeBrainfishSupportWidget} =
      viewer.viewingOrganization.features;

    setIsZendeskChatVisible(isEnabledOfficeZendeskSupportWidget && !shouldHide);
    setIsBrainfishWidgetVisible(isEnabledOfficeBrainfishSupportWidget && !shouldHide);
  }, [responsive, navigator, isTouch, viewer]);

  useEffect(() => {
    if (error) {
      zendesk.open();
    }
  }, [error, zendesk]);

  return <BrainfishWidget isVisible={isBrainfishWidgetVisible} onPress={handleOpen} />;
};

const ManagerAppContent = ({children}: any) => {
  const name = 'Manager';
  const version = AppInfo.getVersion();
  const buildNumber = AppInfo.getBuildNumber();
  const {data} = useQuery(ManagerApp.query, {fetchPolicy: 'cache-first'});
  const [error, setError] = useState(null);

  return (
    <ErrorModal
      trigger={({handleOpen}: any) => (
        <SupermoveApp
          name={name}
          version={version}
          buildNumber={buildNumber}
          showOfflineOverlay={false}
          onError={(error) => {
            handleOpen();
            // @ts-expect-error TS(2345): Argument of type 'Error' is not assignable to para... Remove this comment to see the full error message
            setError(error);
          }}
          isAuthenticated={data?.isAuthenticated}
          viewer={data?.viewer}
        >
          <UpdateChecker name={name} buildNumber={buildNumber} pollInterval={1000 * 30}>
            {({isVisible, isRequired}) => (isVisible ? <UpdateAppBanner /> : null)}
          </UpdateChecker>
          <AppContextProvider>
            <SSEWrapper viewer={data?.viewer}>{children}</SSEWrapper>
          </AppContextProvider>
          {data?.viewer ? <ExternalWidgets viewer={data.viewer} error={error} /> : null}
          <ToastContainer />
        </SupermoveApp>
      )}
    />
  );
};

const ManagerApp = () => {
  const showMaintenancePage = Environment.get('SHOW_MAINTENANCE_PAGE');

  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return (
    <ManagerAppContent>
      <ManagerAppNavigation>
        <Router pageContext={{appNamespace: 'office'}} />
      </ManagerAppNavigation>
    </ManagerAppContent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManagerApp.query = gql`
  ${SupermoveApp.fragment}

  query ManagerApp {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledOfficeZendeskSupportWidget: isEnabled(feature: "OFFICE_ZENDESK_SUPPORT_WIDGET")
          isEnabledOfficeBrainfishSupportWidget: isEnabled(feature: "OFFICE_BRAINFISH_SUPPORT_WIDGET")
        }
      }
      ...SupermoveApp
    }
  }
`;

export default ManagerApp;
