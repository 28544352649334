const SUPERMOVE = 'SUPERMOVE';
const USER = 'USER';
const ORGANIZATION = 'ORGANIZATION';

const EmailSenderKind = {
  SUPERMOVE,
  USER,
  ORGANIZATION,
} as const;

export type EmailSenderKindType = (typeof EmailSenderKind)[keyof typeof EmailSenderKind];

export const EmailSenderKindDisplay: Record<EmailSenderKindType, string> = {
  [EmailSenderKind.SUPERMOVE]: 'Supermove',
  [EmailSenderKind.USER]: 'User',
  // This is really on the organization level, but we display company for simplicity to the user
  [EmailSenderKind.ORGANIZATION]: 'Company',
};

export default EmailSenderKind;
