// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Linkify, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {TextMessage} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {downloadFromUrl} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FileImage from 'modules/App/components/FileImage';
import CustomerChatLink from 'modules/Chat/Customer/components/CustomerChatLink';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'MaybeResp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.primary};
`;

const TimeText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const DashedLine = Styled.View`
  flex: 1;
  border-bottom-width: 1px;
  border-color: ${colors.gray.secondary};
  border-style: dashed;
`;

const TextMessageContainer = Styled.View`
  width: 85%;
  align-self: ${({
    // @ts-expect-error TS(2339): Property 'isCustomer' does not exist on type 'Them... Remove this comment to see the full error message
    isCustomer,
  }) => (isCustomer ? 'flex-start' : 'flex-end')};
`;

const TextMessageBodyContainer = Styled.View`
  border-radius: 12px;
  padding: 12px;
`;

const FileButton = Styled.ButtonV2`
  border-radius: 4px;
  overflow: hidden;
  background-color: ${colors.gray.tertiary};
  width: 160px;
  height: 160px;
  justify-content: center;
  align-self: center;
`;

const FilePreview = ({file}: any) => {
  const handleDownload = () => downloadFromUrl(file.downloadUrl);
  return (
    <FileButton onPress={handleDownload}>
      <FileImage file={file} />
    </FileButton>
  );
};

const CustomerLink = (href: any, text: any, key: any) => {
  return (
    <CustomerChatLink url={href} color={colors.blue.accentDark} key={key}>
      {text}
    </CustomerChatLink>
  );
};

const CustomerMessageBody = ({body, file}: any) => {
  const responsive = useResponsive();
  return (
    <TextMessageBodyContainer
      style={{
        backgroundColor: colors.gray.border,
        borderBottomLeftRadius: 0,
      }}
    >
      <Linkify component={CustomerLink}>
        <Text responsive={responsive} color={colors.black}>
          {body}
        </Text>
      </Linkify>
      {file && <FilePreview file={file} />}
    </TextMessageBodyContainer>
  );
};

const OrganizationLink = (href: any, text: any, key: any) => {
  return (
    <CustomerChatLink url={href} color={colors.blue.interactive} key={key}>
      {text}
    </CustomerChatLink>
  );
};

const OrganizationMessageBody = ({body, file}: any) => {
  const responsive = useResponsive();
  return (
    <TextMessageBodyContainer
      style={{
        backgroundColor: colors.blue.accentDark,
        borderBottomRightRadius: 0,
      }}
    >
      <Linkify component={OrganizationLink}>
        <Text responsive={responsive} color={colors.white}>
          {body}
        </Text>
      </Linkify>
      {file && <FilePreview file={file} />}
    </TextMessageBodyContainer>
  );
};

// We wrap TextMessageItem in React.memo so that text messages don't update
// everytime data is polled. This is safe since messages cannot be edited.
const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.textMessage.id === nextProps.textMessage.id;
};
// @ts-expect-error TS(2339): Property 'textMessage' does not exist on type '{ c... Remove this comment to see the full error message
const TextMessageItem = React.memo(({textMessage, customerId}) => {
  const responsive = useResponsive();
  const isCustomer = String(textMessage.creatorId) === String(customerId);
  const name = textMessage.creator ? textMessage.creator.fullName : textMessage.organization.name;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <TextMessageContainer isCustomer={isCustomer} responsive={responsive}>
      <Space height={4} />
      {isCustomer ? (
        <CustomerMessageBody body={textMessage.body} file={textMessage.file} />
      ) : (
        <OrganizationMessageBody body={textMessage.body} file={textMessage.file} />
      )}
      <Space height={8} />
      <Row
        style={{
          justifyContent: 'space-between',
          flexDirection: isCustomer ? 'row' : 'row-reverse',
        }}
      >
        <NameText responsive={responsive}>{name}</NameText>
        <TimeText responsive={responsive}>{TextMessage.getDisplayTime(textMessage)}</TimeText>
      </Row>
      <Space height={4} />
    </TextMessageContainer>
  );
}, areEqual);

const DateHeader = ({displayDate}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Space height={8} />
      <Row>
        <DashedLine />
        <Space width={8} />
        <Text responsive={responsive} color={colors.gray.secondary}>
          {displayDate}
        </Text>
        <Space width={8} />
        <DashedLine />
      </Row>
      <Space height={8} />
    </React.Fragment>
  );
};

const INITIAL_ITEM_COUNT = 50;

const CustomerChatTextMessagesList = ({textMessages, customerId, textMessagesScrollView}: any) => {
  const [filteredMessageCount, setFilteredMessageCount] = useState(INITIAL_ITEM_COUNT);
  const orderedTextMessages = _.orderBy(textMessages, 'createdAt', 'desc').slice(
    0,
    filteredMessageCount,
  );
  const flatListStyle = {paddingHorizontal: 16, flexDirection: 'column-reverse'};
  const flatListContentStyle = {flexDirection: 'column-reverse'};

  return (
    <FlatList
      ref={textMessagesScrollView.ref}
      // This way the most recent message gets sorted as first and then gets rendered at the bottom
      data={orderedTextMessages}
      keyExtractor={(textMessage: any) => textMessage.id}
      // We use column-reverse so that the bottom of the list renders first. This way rather than having to scroll to the bottom, we automatically start at the bottom of the list.
      style={flatListStyle}
      contentContainerStyle={flatListContentStyle}
      initialNumToRender={filteredMessageCount}
      renderItem={({item: textMessage, index}: any) => {
        const previousTextMessage = _.get(orderedTextMessages, `${index - 1}`);
        const previousDisplayDate = previousTextMessage
          ? TextMessage.getDisplayDate(previousTextMessage)
          : null;
        const currentDisplayDate = TextMessage.getDisplayDate(textMessage);
        const showDate = currentDisplayDate !== previousDisplayDate;
        return (
          <React.Fragment>
            {showDate && <DateHeader displayDate={currentDisplayDate} />}
            {/* @ts-expect-error TS(2322): Type '{ textMessage: any; customerId: any; }' is n... Remove this comment to see the full error message */}
            <TextMessageItem textMessage={textMessage} customerId={customerId} />
          </React.Fragment>
        );
      }}
      ListFooterComponent={
        <React.Fragment>
          {filteredMessageCount < textMessages.length && (
            <Row style={{justifyContent: 'center'}}>
              <TertiaryButton
                text={'Load more messages'}
                isResponsive
                onPress={() => setFilteredMessageCount(filteredMessageCount + 25)}
              />
            </Row>
          )}
        </React.Fragment>
      }
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerChatTextMessagesList.fragment = gql`
  ${FileImage.fragment}
  ${TextMessage.getDisplayDate.fragment}
  ${TextMessage.getDisplayTime.fragment}
  ${TextMessage.getServerDate.fragment}

  fragment CustomerChatTextMessagesList on TextMessage {
    id
    createdAt
    body
    creatorId
    creator {
      id
      fullName
    }
    organization {
      id
      name
    }
    file {
      id
      downloadUrl
      ...FileImage
    }
    ...TextMessage_getDisplayDate
    ...TextMessage_getDisplayTime
    ...TextMessage_getServerDate
  }
`;

export default CustomerChatTextMessagesList;
