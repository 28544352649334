// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {useEffect, useRef, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import ClientForm from '@shared/modules/Client/forms/ClientForm';

const Container = Styled.View<{index: number; mobile: boolean}>`
  z-index: ${({index = 0}) => 100 - index};
  ${({mobile}) => (mobile ? '' : 'flex: 1')}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const OptionContainer = Styled.View<{color: string; isFirstOption: boolean}>`
  background-color: ${({color}) => color};
  padding-vertical: 5px;
  padding-horizontal: 12px;
  margin-top: ${({isFirstOption}) => (isFirstOption ? 4 : 2)};
`;

const OptionText = Styled.Text<{color: string}>`
  ${Typography.Body4}
  color: ${({color}) => color};
  padding-vertical: 1px;
`;

const filterConfig = {
  // This tells react-select what value to search options on
  stringify: (option: any) => {
    const contact = option.data.client.primaryContact;
    return contact
      ? `${option.label} ${contact.fullName} ${contact.phoneNumber} ${contact.email}`
      : option.label;
  },
};

const getClientOptionColor = ({isSelected, isFocused}: any) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (isFocused) {
    return colors.hover;
  }
  return 'transparent';
};

const EmptyMessage = ({children}: any) => {
  return (
    <OptionContainer isFirstOption color={'transparent'}>
      <OptionText numberOfLines={1} color={colors.gray.secondary}>
        {children}
      </OptionText>
    </OptionContainer>
  );
};

const ClientsSearchDropdownOption = ({
  data: {client},
  value,
  innerProps,
  isFocused,
  isSelected,
  options,
  searchQuery,
}: any) => {
  const noMatches = (searchQuery || '').length >= 3;

  // There will always be at least 1 option, the derived client from the form.
  // If there is only 1 option, then we've either skipped the empty search or
  // there are no matching items returned from the search.
  if (options.length === 1) {
    if (noMatches) {
      return <EmptyMessage>No matches found</EmptyMessage>;
    } else {
      return <EmptyMessage>Type to add or search</EmptyMessage>;
    }
  }

  // The derived client has no id and does not need to show up as an option.
  // This option will always be last, and so the Space serves as padding.
  if (!client.id) {
    return <Space height={4} />;
  }

  const isFirstOption = value === _.get(options, `0.client.id`);
  return (
    <OptionContainer
      {...innerProps}
      isFocused={isFocused}
      isFirstOption={isFirstOption}
      color={getClientOptionColor({isSelected, isFocused})}
    >
      <Row>
        <OptionText numberOfLines={1} color={isSelected ? colors.white : colors.gray.primary}>
          {client.primaryContact.fullName}
        </OptionText>
        <Space style={{flex: 1, minWidth: 4}} />
        <OptionText
          numberOfLines={1}
          color={isSelected ? colors.blue.accent : colors.gray.secondary}
        >
          {client.name}
        </OptionText>
      </Row>
      <Row>
        <OptionText color={isSelected ? colors.blue.accent : colors.gray.secondary}>
          {Phone.display(client.primaryContact.phoneNumber)}
        </OptionText>
        <Space style={{flex: 1, minWidth: 4}} />
        <OptionText
          numberOfLines={1}
          color={isSelected ? colors.blue.accent : colors.gray.secondary}
        >
          {client.primaryContact.email}
        </OptionText>
      </Row>
    </OptionContainer>
  );
};

const ClientSearchDropdownInput = ({
  index,
  options,
  form,
  field,
  component,
  name,
  label,
  placeholder,
  disabled,
  required,
  searchQuery,
  handleChangeSearch,
  handleValidation,
  canSearchClients,
  hasWarning,
  warningMessage,
  loading,
  style,
  isRequired,
}: any) => {
  const responsive = useResponsive();
  const dropdownRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const valueField = `${field}.${name}`;
  const value = _.get(form.values, valueField, '');
  const dropdownValueField = `${field}.clientId`;
  const dropdownValue = _.get(form.values, dropdownValueField);
  const error = _.get(form.errors, valueField);
  const sharedInputProps = {
    disabled,
    // required is legacy and sets the field background color to yellow
    // isRequired is the new pattern that adds the label decoration
    // if isRequired, always set required to false.
    required: required && !isRequired,
    placeholder,
    style: {...style, flex: 1, borderColor: error ? colors.red.warning : colors.gray.tertiary},
  };

  useEffect(() => {
    if (handleValidation) {
      handleValidation(isFocused);
    }
  }, [handleValidation, isFocused]);

  return canSearchClients && !disabled ? (
    <Container index={index} {...responsive}>
      <FieldInput
        {...form}
        name={dropdownValueField}
        label={label}
        component={DropdownInput}
        hasWarning={hasWarning}
        warningMessage={warningMessage}
        style={style}
        isRequired={isRequired}
        input={{
          ...sharedInputProps,
          innerRef: dropdownRef,
          inputValue: isFocused ? value : undefined,
          value: dropdownValue || '',
          // Placeholder will not render if Select.hasValue(). Additionally when value is an
          // empty string, Select.hasValue() also resolves to true. To show the placeholder,
          // we manually control if we want to show the value or not.
          controlShouldRenderValue: value !== '',
          options,
          isLoading: loading && isFocused,
          isSearchable: true,
          setFieldValue: form.setFieldValue,
          // v2 search backend performs fuzzy matching, so always show all results
          filterOption: (option: any, rawInput: string) => true,
          isVirtualized: false,
          onMenuOpen: () => setIsFocused(true),
          onInputChange: (text: any, {action}: any) => {
            if (action === 'input-change') {
              handleChangeSearch(text);
            }
            if (action === 'input-blur') {
              setIsFocused(false);
            }
          },
          onChangeValue: (clientId: any, data: any) => {
            setIsFocused(false);
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            dropdownRef.current.blur();
            // Pressing enter invokes onChangeValue and selects the first dropdown option. If the
            // user presses enter when there are no options available, we skip updating the form
            // as to not update it with empty data.
            if (clientId) {
              form.setFieldValue(field, ClientForm.toForm(ClientForm.edit(data.client)));
            }
          },
          onFocus: () => {
            setIsFocused(true);
            handleChangeSearch(value);
          },
          onBlur: () => {
            setIsFocused(false);
            handleChangeSearch(value.trim());
          },
          components: {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            Option: (optionData: any) => (
              <ClientsSearchDropdownOption {...optionData} searchQuery={searchQuery} />
            ),
          },
        }}
      />
      {error && (
        <React.Fragment>
          <Space height={4} />
          <FieldInput.CaptionText hasErrors>{error}</FieldInput.CaptionText>
        </React.Fragment>
      )}
    </Container>
  ) : (
    <Container index={index} {...responsive}>
      <FieldInput.Memoized
        {...form}
        index={index}
        name={`${field}.${name}`}
        label={label}
        component={component}
        style={style}
        input={
          component ? {...sharedInputProps, setFieldValue: form.setFieldValue} : sharedInputProps
        }
      />
    </Container>
  );
};

export default ClientSearchDropdownInput;
