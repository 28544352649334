// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import WorkflowTriggerIdentifiers from '@shared/modules/Workflow/enums/WorkflowTriggerIdentifier';
import WorkflowStepForm, {
  WorkflowStepFormType,
  WorkflowStepFormAsMutation,
} from '@shared/modules/Workflow/forms/WorkflowStepForm';

export interface WorkflowForm {
  workflowId: number | null;
  organizationId: number;
  name: string;
  description: string;
  isDeleted: boolean;
  isActive: boolean;
  triggerIdentifier: string;
  triggerVariableIdentifier: string;
  projectTypeIdList: (string | number)[];
  jobTypeIdList: number[];
  tagIdList: number[];
  userFlowId: string | undefined;
  triggerDaysBefore: number | null;
  triggerHour: number;
  triggerIdentifierCategory: string;
  workflowStepForms: WorkflowStepFormType[];
  isStepsEnabled: boolean;
  isEditingStep?: boolean;
}

export interface WorkflowFormAsMutation {
  workflowId: number | null;
  organizationId: number;
  name: string;
  description: string;
  isDeleted: boolean;
  isActive: boolean;
  triggerIdentifier: string;
  triggerVariableIdentifier: string;
  triggerDaysBefore: number | null;
  triggerHour: number;
  projectTypeIdList: (string | number)[];
  jobTypeIdList: number[];
  tagIdList: number[];
  userFlowId: number | undefined;
  workflowStepForms: WorkflowStepFormAsMutation[];
}

const _new = ({
  organizationId,
  workflowId,
}: {
  organizationId: number;
  workflowId?: number;
}): WorkflowForm => ({
  workflowId: workflowId || null,
  organizationId,
  name: '',
  description: '',
  isDeleted: false,
  isActive: true,
  triggerIdentifier: '',
  triggerVariableIdentifier: '',
  projectTypeIdList: [],
  tagIdList: [],
  userFlowId: undefined,
  jobTypeIdList: [],
  workflowStepForms: [],
  triggerDaysBefore: null,
  triggerHour: 7,

  // Private
  triggerIdentifierCategory: '',
  isStepsEnabled: false,
  isEditingStep: false,
});

const edit = withFragment(
  (workflow: any): WorkflowForm => ({
    workflowId: workflow.id,
    organizationId: workflow.organizationId,
    name: workflow.name,
    description: workflow.description,
    isDeleted: workflow.isDeleted,
    isActive: workflow.isActive,
    triggerIdentifier: workflow.triggerIdentifier,
    triggerVariableIdentifier: workflow.triggerVariableIdentifier,
    triggerDaysBefore: workflow.triggerDaysBefore,
    triggerHour: workflow.triggerHour,
    // If the project type list is empty, set it to -1 (the frontend will interpret this as "All Project Types")
    projectTypeIdList:
      workflow.projectTypeIdList.length === 0
        ? [-1]
        : workflow.projectTypeIdList.map((id: number) => _.toString(id)),
    jobTypeIdList: workflow.jobTypeIdList.map((id: number) => _.toString(id)),
    tagIdList:
      workflow.tagIdList.length === 0
        ? [-1]
        : workflow.tagIdList.map((id: number) => _.toString(id)),
    workflowStepForms: workflow.workflowSteps.map((workflowStep: any) => {
      return WorkflowStepForm.edit(workflowStep);
    }),
    userFlowId: _.toString(workflow.userFlowId),

    // Private
    triggerIdentifierCategory: workflow.triggerIdentifier
      ? WorkflowTriggerIdentifiers.getCategory(workflow.triggerIdentifier)
      : '',
    isStepsEnabled: !!workflow.triggerIdentifier,
    isEditingStep: false,
  }),
  gql`
    ${WorkflowStepForm.edit.fragment}
    fragment WorkflowForm_edit on Workflow {
      id
      organizationId
      name
      description
      isDeleted
      isActive
      triggerIdentifier
      triggerVariableIdentifier
      triggerDaysBefore
      triggerHour
      projectTypeIdList
      jobTypeIdList
      tagIdList
      userFlowId
      workflowSteps {
        id
        ...WorkflowStepForm_edit
      }
    }
  `,
);

const _delete = ({workflowId}: {workflowId: number}) => ({
  ..._new({} as {organizationId: number}),
  workflowId,
});

const duplicate = ({workflowId}: {workflowId: number}) => ({
  ..._new({} as {organizationId: number}),
  workflowId,
});

const toForm = (workflowForm: WorkflowForm): WorkflowForm => ({
  workflowId: workflowForm.workflowId,
  organizationId: workflowForm.organizationId,
  name: workflowForm.name,
  description: workflowForm.description,
  isDeleted: workflowForm.isDeleted,
  isActive: workflowForm.isActive,
  triggerIdentifier: workflowForm.triggerIdentifier,
  triggerVariableIdentifier: workflowForm.triggerVariableIdentifier,
  triggerDaysBefore: workflowForm.triggerDaysBefore,
  triggerHour: workflowForm.triggerHour,
  projectTypeIdList: workflowForm.projectTypeIdList,
  jobTypeIdList: workflowForm.jobTypeIdList,
  tagIdList: workflowForm.tagIdList,
  userFlowId: workflowForm.userFlowId,
  workflowStepForms: workflowForm.workflowStepForms.map(
    (workflowStepForm: WorkflowStepFormType) => {
      return WorkflowStepForm.toForm(workflowStepForm);
    },
  ),

  // Private
  triggerIdentifierCategory: workflowForm.triggerIdentifierCategory,
  isStepsEnabled: workflowForm.isStepsEnabled,
});

const toMutation = (workflowForm: WorkflowForm): WorkflowFormAsMutation => ({
  workflowId: workflowForm.workflowId,
  organizationId: workflowForm.organizationId,
  name: workflowForm.name,
  description: workflowForm.description,
  isDeleted: workflowForm.isDeleted,
  isActive: workflowForm.isActive,
  triggerIdentifier: workflowForm.triggerIdentifier,
  triggerVariableIdentifier: workflowForm.triggerVariableIdentifier,
  triggerDaysBefore:
    workflowForm.triggerDaysBefore === 0 || workflowForm.triggerDaysBefore
      ? _.toNumber(workflowForm.triggerDaysBefore)
      : null,
  triggerHour: workflowForm.triggerHour,

  // -1 is the UI value for the dropdown to recognize the 'ALL Project Types' option.
  // The api is setup to recognize an empty array as applying to all project types.
  projectTypeIdList: workflowForm.projectTypeIdList.includes(-1)
    ? []
    : workflowForm.projectTypeIdList,
  userFlowId: workflowForm.userFlowId ? _.toNumber(workflowForm.userFlowId) : undefined,
  jobTypeIdList: workflowForm.jobTypeIdList,
  tagIdList: workflowForm.tagIdList.includes(-1) ? [] : workflowForm.tagIdList,
  workflowStepForms: workflowForm.workflowStepForms.map(
    (workflowStepForm: WorkflowStepFormType) => {
      return WorkflowStepForm.toMutation(workflowStepForm);
    },
  ),
});

const WorkflowForm = {
  new: _new,
  edit,
  delete: _delete,
  duplicate,
  toForm,
  toMutation,
};

export default WorkflowForm;
