// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {ThreadModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import CustomerEmailThreadItemAttachments from 'modules/Communication/Email/components/CustomerEmailThreadItemAttachments';

const DetailsText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const NameContainer = Styled.View`
  flex: 1;
`;

const DateContainer = Styled.View`
  flex-shrink: 0;
  margin-left: 4px;
  align-items: flex-end;
`;

const EmailThreadItem = Styled.ButtonV2<{hasError: boolean}>`
  background-color: ${({hasError}) => (hasError ? colors.orange.accent : colors.white)};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmailThreadName = Styled.Text`
  ${Typography.Responsive.Label}
`;

const CustomerEmailThreadItemV2 = ({
  emailThread,
  setSelectedThread,
}: {
  emailThread: ThreadModel;
  setSelectedThread: (thread: ThreadModel) => void;
}) => {
  const responsive = useResponsive();
  const firstEmail = emailThread.allEmails[0];
  const hasFromName = !!firstEmail?.fromName;
  const hasDate = !!emailThread.mostRecentCommunicationDate;
  const hasError = firstEmail?.status === 'ERROR';

  return (
    <EmailThreadItem onPress={() => setSelectedThread(emailThread)} hasError={hasError}>
      <Row>
        {hasError ? (
          <React.Fragment>
            <Icon source={Icon.ExclamationTriangle} color={colors.orange.status} size={14} />
            <Space width={8} />
          </React.Fragment>
        ) : null}
        <EmailThreadName responsive={responsive} numberOfLines={2}>
          {emailThread.name}
        </EmailThreadName>
      </Row>
      <Space height={8} />
      <Row>
        <NameContainer>
          <DetailsText responsive={responsive} numberOfLines={1}>
            {hasFromName ? firstEmail.fromName : null}
          </DetailsText>
        </NameContainer>
        <DateContainer>
          <DetailsText responsive={responsive} numberOfLines={1}>
            {hasDate
              ? Datetime.convertToDisplayDatetime(emailThread.mostRecentCommunicationDate)
              : null}
          </DetailsText>
        </DateContainer>
      </Row>
      {firstEmail?.emailAttachments?.length ? (
        <Row>
          <CustomerEmailThreadItemAttachments
            emailAttachments={firstEmail.emailAttachments}
            emailStatus={firstEmail.status}
          />
        </Row>
      ) : null}
    </EmailThreadItem>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerEmailThreadItemV2.fragment = gql`
  ${CustomerEmailThreadItemAttachments.fragment}

  fragment CustomerEmailThreadItemV2 on Thread {
    id
    name
    mostRecentCommunicationDate
    allEmails {
      id
      fromName
      status
      ...CustomerEmailThreadItemAttachments
    }
  }
`;

export default CustomerEmailThreadItemV2;
