// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {PageLoader, Icon, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useNavigationDOM,
  useState,
  useDrawer,
  ModalType,
  DrawerType,
} from '@supermove/hooks';
import {OrganizationModel, ProjectTypeModel, UserModel} from '@supermove/models';

// App
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useCreateProjectTypeMutation from '@shared/modules/Project/hooks/useCreateProjectTypeMutation';
import ProjectSettingsPage from 'modules/Organization/Settings/Project/ProjectSettingsPage';
import CreateProjectTypeDrawer from 'modules/Organization/Settings/ProjectTypes/components/CreateProjectTypeDrawer';
import CreateProjectTypeModal from 'modules/Organization/Settings/ProjectTypes/components/CreateProjectTypeModal';
import CreateProjectTypeSuccessModal from 'modules/Organization/Settings/ProjectTypes/components/CreateProjectTypeSuccessModal';
import ProjectTypesTable from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypesTable';

const ProjectSettingsProjectTypesContent = ({
  organization,
  refetch,
  isReordering,
  setIsReordering,
  createProjectTypeDrawer,
  createProjectTypeModal,
  createProjectTypeSuccessModal,
  isStorageSettingsPage,
}: {
  organization: OrganizationModel;
  refetch: () => void;
  isReordering: boolean;
  setIsReordering: (isReordering: boolean) => void;
  createProjectTypeDrawer: DrawerType;
  createProjectTypeModal: ModalType;
  createProjectTypeSuccessModal: ModalType;
  isStorageSettingsPage: boolean;
}) => {
  const {navigator} = useNavigationDOM();
  const projectTypeForm = ProjectTypeForm.new({
    organization,
    category: ProjectTypeCategory.MOVE,
  });

  const {form, handleSubmit, submitting} = useCreateProjectTypeMutation({
    projectTypeForm,
    onSuccess: ({projectType}: {projectType: ProjectTypeModel}) => {
      refetch();
      form.setFieldValue(`projectTypeForm.projectTypeUuid`, projectType.uuid);
      createProjectTypeModal.handleClose();
      createProjectTypeSuccessModal.handleOpen();
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  const projectTypeUuid = _.get(form.values, `projectTypeForm.projectTypeUuid`);
  const handleViewProjectType = () => {
    navigator.push(`/settings/projects/project-types/${projectTypeUuid}/job-types`);
  };

  const handleCloseCreateProjectTypeSuccessModal = () => {
    createProjectTypeSuccessModal.handleClose();
    form.resetForm();
  };

  return (
    <React.Fragment>
      <ScrollView horizontal style={{paddingHorizontal: 24}}>
        <ProjectTypesTable
          organization={organization}
          isReordering={isReordering}
          setIsReordering={setIsReordering}
          isEnabledOfficeUserFlowSettings={organization.features.isEnabledOfficeUserFlowSettings}
          refetch={refetch}
        />
      </ScrollView>
      <CreateProjectTypeModal
        key={createProjectTypeModal.key}
        isOpen={createProjectTypeModal.isOpen}
        handleClose={() => {
          createProjectTypeModal.handleClose();
          form.resetForm();
        }}
        organizationId={organization.id}
        form={form}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
      <CreateProjectTypeDrawer
        isOpen={createProjectTypeDrawer.isOpen}
        organization={organization}
        handleClose={createProjectTypeDrawer.handleClose}
        refetch={refetch}
        isStorageSettingsPage={isStorageSettingsPage}
      />
      <CreateProjectTypeSuccessModal
        key={createProjectTypeSuccessModal.key}
        form={form}
        field={'projectTypeForm'}
        isOpen={createProjectTypeSuccessModal.isOpen}
        handleClose={handleCloseCreateProjectTypeSuccessModal}
        handlePrimaryAction={handleViewProjectType}
        primaryActionText={'View Project Type'}
        secondaryActionText={'Back to List'}
      />
    </React.Fragment>
  );
};

// TODO(dan) Later we'll change this page to have it's own query, so for now we simulate the query
// with the viewer being optional.
const ProjectSettingsPageProjectTypes = ({
  viewer,
  refetch,
  isStorageSettingsPage,
}: {
  viewer?: UserModel;
  refetch: () => void;
  isStorageSettingsPage: boolean;
}) => {
  const [isReordering, setIsReordering] = useState(false);
  const createProjectTypeModal = useModal({name: 'Create Project Type Modal'});
  const createProjectTypeSuccessModal = useModal({name: 'Create Project Type Success Modal'});
  const createProjectTypeDrawer = useDrawer({name: 'Create Project Type Drawer'});

  return (
    <ProjectSettingsPage
      title='Project Types'
      description='Select the name of your project type to add, view or configure: Job Types, Billing, Variables and Valuation Coverage.'
      actionText={'Create Project Type'}
      actionIcon={Icon.Plus}
      handleAction={() => {
        if (viewer?.viewingOrganization.features.isEnabledOfficeUserFlowSettings) {
          createProjectTypeDrawer.handleOpen();
        } else {
          createProjectTypeModal.handleOpen();
        }
      }}
      isLoadingAction={!viewer}
    >
      <PageLoader loading={!viewer}>
        {() => {
          const organization = viewer!.viewingOrganization;
          return (
            <ProjectSettingsProjectTypesContent
              organization={organization}
              refetch={refetch}
              isReordering={isReordering}
              setIsReordering={setIsReordering}
              createProjectTypeDrawer={createProjectTypeDrawer}
              createProjectTypeModal={createProjectTypeModal}
              createProjectTypeSuccessModal={createProjectTypeSuccessModal}
              isStorageSettingsPage={isStorageSettingsPage}
            />
          );
        }}
      </PageLoader>
    </ProjectSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
// TODO(dan) Turn this into a top level page with query when fully enabled
ProjectSettingsPageProjectTypes.fragment = gql`
  ${ProjectTypesTable.fragment}
  ${ProjectTypeForm.new.fragment}

  fragment ProjectSettingsPageProjectTypes on Query {
    viewer {
      id
      viewingOrganization {
        id
        isOwnerOfSettings
        features {
          isEnabledOfficeUserFlowSettings: isEnabled(feature: "OFFICE_USER_FLOW_SETTINGS")
        }
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        ...ProjectTypesTable
        ...ProjectTypeForm_new
      }
    }
  }
`;

export default ProjectSettingsPageProjectTypes;
