// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import OrganizationEmailSenderForm, {
  OrganizationEmailSenderFormType,
} from '@shared/modules/Organization/forms/OrganizationEmailSenderForm';

const useUpdateOrganizationEmailSenderMutation = ({
  organizationEmailSenderForm,
  onSuccess,
  onError,
}: {
  organizationEmailSenderForm: OrganizationEmailSenderFormType;
  onSuccess: ({organization}: {organization: OrganizationModel}) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: organizationEmailSenderForm,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationEmailSenderMutation.mutation,
    variables: {
      organizationEmailSenderForm: OrganizationEmailSenderForm.toMutation(form.values),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationEmailSenderMutation.mutation = gql`
  mutation useUpdateOrganizationEmailSenderMutation($organizationEmailSenderForm: OrganizationEmailSenderForm!) {
    response: updateOrganizationEmailSender(organizationEmailSenderForm: $organizationEmailSenderForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateOrganizationEmailSenderMutation;
