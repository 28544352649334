// Libraries

// Supermove

export interface WorkflowRunForm {
  workflowRunId?: number;
}

const _new = ({workflowRunId}: {workflowRunId: number}): WorkflowRunForm => ({
  workflowRunId,
});

const WorkflowRunForm = {
  new: _new,
};

export default WorkflowRunForm;
