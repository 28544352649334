// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {
  Inventory,
  ItemModel,
  ItemTypeModel,
  ItemTagItemModel,
  AttachmentModel,
} from '@supermove/models';
import {withFragment, uuid} from '@supermove/utils';

// App
import ItemTypeKind, {ItemTypeKindType} from '@shared/modules/Inventory/enums/ItemTypeKind';
import ItemAttachmentForm, {
  ItemAttachmentFormType,
} from '@shared/modules/Inventory/forms/ItemAttachmentForm';
import ItemTagItemForm, {
  ItemTagItemFormType,
} from '@shared/modules/Inventory/forms/ItemTagItemForm';

export interface ItemFormV2Type {
  itemId: string;
  collectionId: number;
  name: string;
  takeCount: number;
  leaveCount: number;
  weight: number;
  volume: number;
  price: number;
  packTime: number;
  unpackTime: number;
  isDerivedWeight: boolean;
  itemTypeId?: number | string | null;
  isVoid: boolean;
  isDeleted: boolean;
  notes: string;
  kind: ItemTypeKindType;
  uuid: string;
  // Only used for driver inventory
  lotNumber: string | null;
  color: string | null;
  itemNumber: string | null;
  itemTagItemForms: ItemTagItemFormType[];
  itemAttachmentForms: ItemAttachmentFormType[];
  // private
  isDirty: boolean;
  take: boolean;
  index?: number;
}

type ItemFormV2ToFormType = ItemFormV2Type;

// HACK(Hammad): This type exists to handle edge cases where we do not follow the standard toForm pattern.
// ItemFormV2.toForm is not called anywhere, so we are either performing calculations directly with number fields
// or converting to string values elsewhere, such as for price.
// WARNING: This is technical debt that should not be replicated. New code should always use toForm for proper type handling.
export type ItemFormV2PartialToFormType = Omit<ItemFormV2Type, 'price'> & {price: string};

export interface ItemFormV2ToMutationType {
  itemId?: string;
  collectionId: number;
  name: string;
  takeCount: number;
  leaveCount: number;
  weight: number;
  volume: number;
  price: number;
  packTime: number;
  unpackTime: number;
  isDerivedWeight: boolean;
  itemTypeId?: number | string | null;
  isVoid: boolean;
  isDeleted: boolean;
  notes: string;
  uuid: string;
  // Only used for driver inventory
  lotNumber: string | null;
  color: string | null;
  itemNumber: string | null;
  itemTagItemForms: ItemTagItemFormType[];
  itemAttachmentForms: ItemAttachmentFormType[];
}

const edit = withFragment(
  (item: ItemModel, {index}: {index: number}): ItemFormV2Type => ({
    itemId: item.id,
    collectionId: item.collectionId,
    name: item.name,
    takeCount: item.takeCount,
    leaveCount: item.leaveCount,
    weight: item.weight,
    volume: item.volume,
    price: item.price,
    packTime: item.packTime,
    unpackTime: item.unpackTime,
    isDerivedWeight: item.isDerivedWeight,
    itemTypeId: item.itemTypeId,
    isVoid: item.isVoid,
    isDeleted: item.isDeleted,
    notes: (item as any).notes,
    kind: item?.itemType?.kind || ItemTypeKind.ITEM,
    uuid: item.uuid,
    // Only used for driver inventory
    lotNumber: item.lotNumber,
    color: item.color,
    itemNumber: item.itemNumber,
    itemTagItemForms: item.itemTagItems.map((itemTagItem: ItemTagItemModel) =>
      ItemTagItemForm.edit(itemTagItem),
    ),
    itemAttachmentForms: item.attachments.map((itemAttachment: AttachmentModel) =>
      ItemAttachmentForm.edit(itemAttachment),
    ),
    // private
    isDirty: false,
    // This makes sure we default the take property to true
    // We only consider the item as not take if the leave count is greater than 0
    take: !(item.leaveCount > 0),
    // Index is passed in here so we have a unique identifier for each item
    // itemId cannot be used as a unique id because new items do not have an id
    index,
  }),
  gql`
    ${ItemTagItemForm.edit.fragment}
    ${ItemAttachmentForm.edit.fragment}

    fragment ItemFormV2_edit on Item {
      id
      uuid
      collectionId
      name
      notes
      takeCount
      leaveCount
      weight
      volume
      price
      packTime
      unpackTime
      lotNumber
      color
      itemNumber
      isDerivedWeight
      itemTypeId
      isVoid
      isDeleted
      itemType {
        id
        kind
      }
      itemTagItems {
        id
        ...ItemTagItemForm_edit
      }
      attachments {
        id
        ...ItemAttachmentForm_edit
      }
    }
  `,
);

const _new = ({
  collectionId,
  name = '',
  weight = 0,
  volume = 0,
  price = 0,
  packTime = 0,
  unpackTime = 0,
  notes = '',
  isDerivedWeight = false,
  itemTypeId,
  index,
  kind,
  isVoid = false,
  lotNumber = null,
  color = null,
  itemNumber = null,
  itemTagItems = [],
  itemAttachments = [],
}: {
  collectionId: number;
  name: string;
  weight: number;
  volume: number;
  price: number;
  packTime?: number;
  unpackTime?: number;
  notes?: string;
  isDerivedWeight?: boolean;
  itemTypeId?: number | string | null;
  index?: number;
  kind: ItemTypeKindType;
  isVoid?: boolean;
  lotNumber?: string | null;
  color?: string | null;
  itemNumber?: string | null;
  itemTagItems?: ItemTagItemModel[];
  itemAttachments?: ItemAttachmentFormType[];
}): ItemFormV2Type => ({
  // Temp item ID used so that we have a unique identifier to delete the correct row in the virtualized table
  // Without a unique identifier we can't identify which row to rerender in the table
  itemId: `NEW_${uuid()}`,
  collectionId,
  name,
  takeCount: 1,
  leaveCount: 0,
  weight,
  volume,
  price,
  packTime,
  unpackTime,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted: false,
  notes,
  kind,
  uuid: uuid(),
  // Only used for driver inventory
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms: itemTagItems.map((itemTagItem) => ItemTagItemForm.new(itemTagItem)),
  itemAttachmentForms: itemAttachments.map((itemAttachment) =>
    ItemAttachmentForm.new(itemAttachment),
  ),

  // private
  isDirty: true,
  take: true,
  index,
});

const newFromItemType = withFragment(
  (
    itemType: ItemTypeModel,
    {collectionId, index}: {collectionId: number; index: number},
  ): ItemFormV2Type => {
    return _new({
      collectionId,
      name: itemType.name,
      weight: itemType.weight,
      volume: itemType.volume,
      price: itemType.price,
      packTime: itemType.packTime,
      unpackTime: itemType.unpackTime,
      isDerivedWeight: itemType.isDerivedWeight,
      itemTypeId: itemType.id,
      index,
      kind: itemType.kind,
    });
  },
  gql`
    fragment ItemFormV2_newFromItemType on ItemType {
      id
      name
      weight
      volume
      price
      packTime
      unpackTime
      isDerivedWeight
      kind
    }
  `,
);

const toForm = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  packTime,
  unpackTime,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  kind,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms,
  itemAttachmentForms,

  // private
  isDirty,

  take,
  index,
}: ItemFormV2Type): ItemFormV2ToFormType => ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  packTime,
  unpackTime,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  kind,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms: itemTagItemForms.map((itemTagItemForm: any) =>
    ItemTagItemForm.toForm(itemTagItemForm),
  ),
  itemAttachmentForms: itemAttachmentForms.map((itemAttachmentForm: any) =>
    ItemAttachmentForm.toForm(itemAttachmentForm),
  ),

  // private
  isDirty,
  take,
  index,
});

const toMutation = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  packTime,
  unpackTime,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms,
  itemAttachmentForms,
}: ItemFormV2Type): ItemFormV2ToMutationType => ({
  // Do not send itemId if it's a newly created item
  itemId: itemId.includes('NEW') ? undefined : itemId,
  collectionId,
  name,
  takeCount: Inventory.getFloatValue(takeCount),
  leaveCount: Inventory.getFloatValue(leaveCount),
  weight: Inventory.getFloatValue(weight),
  volume: Inventory.getFloatValue(volume),
  price,
  packTime: Inventory.getFloatValue(packTime),
  unpackTime: Inventory.getFloatValue(unpackTime),
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  uuid,
  lotNumber: !lotNumber ? null : lotNumber,
  color: !color ? null : color,
  itemNumber,
  itemTagItemForms: itemTagItemForms.map((itemTagItemForm: any) =>
    ItemTagItemForm.toMutation({...itemTagItemForm, itemUuid: uuid}),
  ),
  itemAttachmentForms: itemAttachmentForms.map((itemAttachmentForm: any) =>
    ItemAttachmentForm.toMutation({...itemAttachmentForm, itemUuid: uuid}),
  ),
});

const getItemTypeMatchesMinItemNumber = ({minItemNumber, itemForm}: any) => {
  const min = _.toNumber(minItemNumber);
  if (min) {
    return itemForm.itemNumber >= min;
  }
  return true;
};

const getItemTypeMatchesMaxItemNumber = ({maxItemNumber, itemForm}: any) => {
  const max = _.toNumber(maxItemNumber);
  if (max) {
    return itemForm.itemNumber <= max;
  }
  return true;
};

const getItemTypeMatchesRoom = ({roomIds, allRooms, itemForm}: any) => {
  const itemRoom = allRooms.find((room: any) =>
    room.itemForms.some((filteredItem: any) => _.isEqual(filteredItem, itemForm)),
  );
  if (roomIds) {
    return _.includes(roomIds, itemRoom.roomId);
  }
  return true;
};

const getItemTypeMatchesLot = ({lotNumbers, itemForm}: any) => {
  if (lotNumbers) {
    return _.includes(lotNumbers, `${itemForm.lotNumber}-${itemForm.color}`);
  }
  return true;
};

const getItemTypeMatchesTagOrException = ({tagsOrExceptions, itemTagItemKind, itemForm}: any) => {
  // This is for both item tag or exception types
  if (tagsOrExceptions) {
    return itemForm.itemTagItemForms.some((itemTagItem: any) => {
      return (
        itemTagItem.kind === itemTagItemKind &&
        itemTagItem.itemTagIds.some((itemTagId: any) =>
          tagsOrExceptions.includes(itemTagId.toString()),
        )
      );
    });
  }
  return true;
};

const getItemTypeShowVoidedLabel = ({showVoidedLabels, itemForm}: any) => {
  return showVoidedLabels !== 'false' || itemForm.isVoid === false;
};

const ItemFormV2 = {
  edit,
  new: _new,
  newFromItemType,
  toForm,
  toMutation,

  // Helpers
  getItemTypeMatchesMinItemNumber,
  getItemTypeMatchesMaxItemNumber,
  getItemTypeMatchesRoom,
  getItemTypeMatchesLot,
  getItemTypeMatchesTagOrException,
  getItemTypeShowVoidedLabel,
};

export default ItemFormV2;
