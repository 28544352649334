// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const MobileSubheadingText = Styled.Text`
  ${Typography.Mobile.Subheading};
  padding-vertical: 3px;
`;

const EllipsisIcon = () => {
  return <Icon source={Icon.EllipsisV} color={colors.blue.interactive} size={16} />;
};

const EllipsisButton = ({onPress, isDisabled}: {onPress: () => void; isDisabled: boolean}) => {
  // NOTE(dan): The setTimeout was added to the onPress because of buggy behavior
  // on desktop browsers when testing on mobile sizes. Whereas on mobile devices
  // the behavior is as expected, on desktop browsers the onPress is called twice
  // and immediately closes the sheet.
  return (
    <TertiaryButton
      onPress={() => setTimeout(onPress, 0)}
      style={{height: 32, width: 32}}
      isDisabled={isDisabled}
    >
      <Icon
        source={Icon.EllipsisV}
        color={isDisabled ? colors.gray.disabled : colors.blue.interactive}
        size={16}
      />
    </TertiaryButton>
  );
};

const TextButton = ({
  onPress,
  text,
  isDisabled,
}: {
  onPress: () => void;
  text: string;
  isDisabled: boolean;
}) => {
  return (
    <TertiaryButton
      text={text}
      onPress={onPress}
      isResponsive
      style={{height: 32}}
      isDisabled={isDisabled}
    />
  );
};

interface MobileProjectBlockHeaderProps {
  title: string;
  ActionsComponent?: React.FC<any>;
  actionsComponentProps?: object;
  children?: React.ReactNode;
  style?: object;
}

const MobileProjectBlockHeader = ({
  title,
  ActionsComponent,
  actionsComponentProps,
  children,
  style,
}: MobileProjectBlockHeaderProps) => {
  return (
    <React.Fragment>
      <Row style={style}>
        <MobileSubheadingText>{title}</MobileSubheadingText>
        <Space style={{flex: 1, minWidth: 8}} />
        {ActionsComponent && <ActionsComponent {...actionsComponentProps} />}
      </Row>
      {children}
      <Space height={16} />
    </React.Fragment>
  );
};

MobileProjectBlockHeader.EllipsisIcon = EllipsisIcon;
MobileProjectBlockHeader.EllipsisButton = EllipsisButton;
MobileProjectBlockHeader.TextButton = TextButton;

export default MobileProjectBlockHeader;
