// Libraries
import React from 'react';

// App

import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';

const QuitEditingModal = ({handleClose, isOpen, navigator, params, projectUuid}: any) => {
  return (
    <MessageModal
      isOpen={isOpen}
      title={'Quit editing?'}
      message={`Your changes will not be saved.`}
      handlePressOutside={handleClose}
      handlePrimaryAction={handleClose}
      handleSecondaryAction={() => {
        handleClose();
        navigator.goBack();
      }}
      primaryActionText={'Keep Editing'}
      secondaryActionText={'Quit Without Saving'}
    />
  );
};

export default QuitEditingModal;
