// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';

const useUpdateInventoryRoomsWithBillingMutation = ({
  inventoryRoomsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      inventoryRoomsForm: InventoryRoomsForm.toForm(inventoryRoomsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateInventoryRoomsWithBillingMutation.mutation,
    variables: {
      inventoryRoomsForm: InventoryRoomsForm.toMutation(form.values.inventoryRoomsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateInventoryRoomsWithBillingMutation.mutation = gql`
  mutation useUpdateInventoryRoomsWithBillingMutation($inventoryRoomsForm: InventoryRoomsForm!) {
    response: updateInventoryRoomsWithBilling(inventoryRoomsForm: $inventoryRoomsForm) {
      ${gql.errors}
      inventory {
        id
      }
    }
  }
`;

export default useUpdateInventoryRoomsWithBillingMutation;
