// Supermove
import {gql} from '@supermove/graphql';
import {JobTypeStepDocumentTemplateModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface JobTypeStepDocumentTemplateFormType {
  jobTypeStepDocumentTemplateId?: string;
  jobTypeStepId: number;
  organizationId: number;
  documentTemplateId: number;
  readonlySectionUuids: string[];
  index: number;
}

export interface JobTypeStepDocumentTemplateNewFormType {
  documentTemplateId: string;
  jobTypeStepId: string;
  organizationId: number;
  index: number;
}

export interface JobTypeStepDocumentTemplateFormMutationType {
  jobTypeStepDocumentTemplateId?: string;
  jobTypeStepId: number;
  organizationId: number;
  documentTemplateId: number;
  readonlySectionUuids: string[];
  index: number;
}

const sort = (a: JobTypeStepDocumentTemplateFormType, b: JobTypeStepDocumentTemplateFormType) => {
  return a.index - b.index;
};

const _new = ({
  jobTypeStepId,
  organizationId,
  documentTemplateId,
  index,
}: JobTypeStepDocumentTemplateNewFormType): JobTypeStepDocumentTemplateFormType => ({
  jobTypeStepId: parseInt(jobTypeStepId, 10),
  organizationId,
  documentTemplateId: parseInt(documentTemplateId, 10),
  readonlySectionUuids: [],
  index,
});

const edit = withFragment(
  ({
    id,
    jobTypeStepId,
    organizationId,
    documentTemplateId,
    readonlySectionUuids,
    index,
  }: JobTypeStepDocumentTemplateModel): JobTypeStepDocumentTemplateFormType => ({
    jobTypeStepDocumentTemplateId: id,
    jobTypeStepId: parseInt(jobTypeStepId, 10),
    organizationId,
    documentTemplateId,
    readonlySectionUuids,
    index,
  }),
  gql`
    fragment JobTypeStepDocumentTemplateForm on JobTypeStep {
      id
      jobTypeStepId
      organizationId
      updatedById
      createdById
      documentTemplateId
      readonlySectionUuids
      index
    }
  `,
);

const toMutation = ({
  jobTypeStepDocumentTemplateId,
  jobTypeStepId,
  organizationId,
  documentTemplateId,
  readonlySectionUuids,
  index,
}: JobTypeStepDocumentTemplateFormType): JobTypeStepDocumentTemplateFormMutationType => ({
  jobTypeStepDocumentTemplateId,
  jobTypeStepId,
  organizationId,
  documentTemplateId,
  readonlySectionUuids,
  index,
});

const JobTypeStepDocumentTemplateForm = {
  new: _new,
  edit,
  toMutation,

  // Helpers
  sort,
};

export default JobTypeStepDocumentTemplateForm;
