// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, MapType, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  Form,
  useDrawer,
  useModal,
  useNavigationDOM,
  usePopover,
  useResponsive,
  useToast,
  useTrackButton,
} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import TextTooltip from '@shared/design/components/TextTooltip';
import Toast from '@shared/design/components/Toast';
import ViewLocationButtons from '@shared/modules/Location/components/ViewLocationButtons';
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import ToggleBadge from 'modules/App/components/ToggleBadge';
import EditProjectLocationFields from 'modules/Project/V2/Edit/components/EditProjectLocationFields';
import EditProjectLocationDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectLocationDrawer';
import DeleteProjectLocationModal from 'modules/Project/V2/Show/components/DeleteProjectLocationModal';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LocationItemContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.white}
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
`;

const LocationItemTitle = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const ActionsDivider = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const handleSetStopKind = ({form, field, index, kind}: any) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;
  const locationFormField = `${field}.locationForms.${index}`;
  setFieldValue(`${locationFormField}.kind`, kind);
};

const handlePressStartLocation = ({form, field, index, locationForm, isStops, isOn}: any) => {
  const originField = `${field}.originLocationId`;
  if (isStops) {
    return handleSetStopKind({form, field, index, kind: LocationKind.PICK_UP});
  }
  if (isOn) {
    return form.setFieldValue(originField, null);
  }
  return form.setFieldValue(originField, locationForm.locationId);
};

const handlePressEndLocation = ({form, field, index, locationForm, isStops, isOn}: any) => {
  const destinationField = `${field}.destinationLocationId`;
  if (isStops) {
    return handleSetStopKind({form, field, index, kind: LocationKind.DROP_OFF});
  }
  if (isOn) {
    return form.setFieldValue(destinationField, null);
  }
  return form.setFieldValue(destinationField, locationForm.locationId);
};

const handleUnlink = ({form, field}: any) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;
  setFieldValue(`${field}.locationId`, null);
  setFieldValue(`${field}.copyOfLocationId`, null);
};

const handleUpdatedLocation = ({updatedLocationForm, form, jobsField, locationForm}: any) => {
  const jobForms = _.get(form.values, jobsField);
  const updatedJobForms = jobForms.map((jobForm: any) => {
    const updatedLocationForms = jobForm.locationForms.map((form: any) => {
      if (LocationForm.getIsSameLocations({locationForm1: locationForm, locationForm2: form})) {
        return {...updatedLocationForm, locationId: form.locationId};
      }
      return form;
    });
    return {...jobForm, locationForms: updatedLocationForms};
  });
  form.setFieldValue(jobsField, updatedJobForms);
};

const StartLocationBadge = ({form, field, index, locationForm, isStops, isDisabled}: any) => {
  const isOn = isStops
    ? locationForm.kind === LocationKind.PICK_UP
    : _.get(form.values, `${field}.originLocationId`) === locationForm.locationId;

  return (
    <ToggleBadge
      label={isStops ? 'Pick-up' : 'Origin'}
      color={ToggleBadge.COLORS.BLUE}
      isOn={isOn}
      onPress={() =>
        !isDisabled && handlePressStartLocation({form, field, index, locationForm, isStops, isOn})
      }
    />
  );
};

const EndLocationBadge = ({form, field, index, locationForm, isStops, isDisabled}: any) => {
  const isOn = isStops
    ? locationForm.kind === LocationKind.DROP_OFF
    : _.get(form.values, `${field}.destinationLocationId`) === locationForm.locationId;

  return (
    <ToggleBadge
      label={isStops ? 'Drop-off' : 'Destination'}
      color={ToggleBadge.COLORS.GREEN}
      isOn={isOn}
      onPress={() =>
        !isDisabled && handlePressEndLocation({form, field, index, locationForm, isStops, isOn})
      }
    />
  );
};

const StartEndLocationBadges = ({form, field, index, locationForm, isStops, isDisabled}: any) => {
  return (
    <Row>
      <StartLocationBadge
        form={form}
        field={field}
        index={index}
        locationForm={locationForm}
        isStops={isStops}
        isDisabled={isDisabled}
      />
      <Space width={8} />
      <EndLocationBadge
        form={form}
        field={field}
        index={index}
        locationForm={locationForm}
        isStops={isStops}
        isDisabled={isDisabled}
      />
    </Row>
  );
};

const LocationActionButtons = ({
  form,
  locationFormField,
  isExisting,
  isCopy,
  isStops,
  isOneStop,
  isDisabled,
  handlePressDelete,
  editLocationDrawer,
  map,
  location,
}: any) => {
  return (
    <React.Fragment>
      <ViewLocationButtons location={location} map={map} />
      <Space width={8} />
      <ActionsDivider />
      <Space width={8} />
      {(isExisting || isCopy) && isStops && (
        <React.Fragment>
          <TextTooltip isEnabledMobileToast={false} text={'Unlink location'} placement={'top'}>
            <Column>
              <TertiaryButton
                onPress={() => handleUnlink({form, field: locationFormField})}
                style={{width: 24, height: 24}}
                isDisabled={isDisabled}
              >
                <Icon
                  source={Icon.LinkSlash}
                  size={16}
                  color={isDisabled ? colors.gray.disabled : colors.blue.interactive}
                />
              </TertiaryButton>
            </Column>
          </TextTooltip>
          <Space width={8} />
          <TertiaryButton
            style={{width: 24, height: 24}}
            onPress={editLocationDrawer.handleOpen}
            isDisabled={isDisabled}
          >
            <Icon
              source={Icon.Pen}
              size={16}
              color={isDisabled ? colors.gray.disabled : colors.blue.interactive}
            />
          </TertiaryButton>
          <Space width={8} />
        </React.Fragment>
      )}
      {isOneStop ? (
        <TextTooltip
          isEnabledMobileToast={false}
          text={'At least one stop is required on a job'}
          placement={'top'}
        >
          <Column style={{width: 24, height: 24, alignItems: 'center', justifyContent: 'center'}}>
            <Icon source={Icon.Trash} size={16} color={colors.gray.disabled} />
          </Column>
        </TextTooltip>
      ) : (
        <TertiaryButton
          onPress={handlePressDelete}
          style={{width: 24, height: 24}}
          isDisabled={isDisabled}
        >
          <Icon
            source={Icon.Trash}
            size={16}
            color={isDisabled ? colors.gray.disabled : colors.red.warning}
          />
        </TertiaryButton>
      )}
    </React.Fragment>
  );
};

const LocationMobileActionMenu = ({
  form,
  locationFormField,
  isExisting,
  isCopy,
  isStops,
  isOneStop,
  handlePressDelete,
  editLocationDrawer,
  location,
}: any) => {
  const {navigator} = useNavigationDOM();
  const locationActionsPopover = usePopover();
  const cannotRemoveToast = useToast({
    ToastComponent: Toast,
    message: 'Job must have at least 1 stop.',
  });
  const trackGoogleMapsButton = useTrackButton({name: 'Google Maps Button'});
  const trackZillowButton = useTrackButton({name: 'Zillow Button'});
  const trackStreetViewButton = useTrackButton({name: 'Street View Button'});

  return (
    <ActionMenuPopover
      popover={locationActionsPopover}
      actions={[
        {
          label: 'Open',
          actions: ViewLocationButtons.getMenuActions({
            location,
            navigator,
            trackGoogleMapsButton,
            trackZillowButton,
            trackStreetViewButton,
          }),
        },
        {
          label: 'Edit',
          actions: [
            ...((isExisting || isCopy) && isStops
              ? [
                  {
                    text: 'Unlink location',
                    onPress: () => handleUnlink({form, field: locationFormField}),
                  },
                  {
                    text: 'Edit location',
                    onPress: editLocationDrawer.handleOpen,
                  },
                ]
              : []),
            {
              text: 'Remove stop',
              color: isOneStop ? colors.gray.tertiary : colors.red.warning,
              onPress: isOneStop ? cannotRemoveToast.handleToast : handlePressDelete,
            },
          ],
        },
      ]}
    >
      <TertiaryButton
        style={{height: 16, width: 16}}
        onPress={locationActionsPopover.handleOpen}
        isHitSlop
      >
        <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
      </TertiaryButton>
    </ActionMenuPopover>
  );
};

const LocationItemHeader = ({
  form,
  field,
  jobsField,
  index,
  project,
  locationForm,
  handleRemoveLocation,
  isStops,
  isDisabled,
  map,
}: {
  form: Form;
  field: string;
  jobsField: string;
  index: number;
  project: ProjectModel;
  locationForm: any; // TODO: need to add types to LocationForm
  handleRemoveLocation: ({form, field, index}: {form: Form; field: string; index: number}) => void;
  isStops: boolean;
  isDisabled: boolean;
  map: MapType;
}) => {
  const responsive = useResponsive();
  const position = index + 1;
  const totalLocations = _.get(form.values, `${field}.locationForms`).length;

  // TODO(dan) Update to also watch location.jobs
  const isExisting = !!locationForm.locationId;
  const isCopy = !!locationForm.copyOfLocationId;
  const isOneStop = totalLocations === 1;
  const deleteLocationModal = useModal({
    name: 'Delete Project Location Modal',
    enableTracking: true,
  });
  const editLocationDrawer = useDrawer({
    name: 'Edit Project Location Drawer',
    enableTracking: true,
  });
  const locationFormField = `${field}.locationForms.${index}`;
  const handlePressDelete = () =>
    isExisting && !isStops
      ? deleteLocationModal.handleOpen()
      : handleRemoveLocation({form, field, index});

  const actionComponentProps = {
    form,
    locationFormField,
    isExisting,
    isCopy,
    isStops,
    isOneStop,
    handlePressDelete,
    editLocationDrawer,
    isDisabled,
    map,
    location: ViewLocationButtons.getLocationFromLocationForm(locationForm),
  };

  return (
    <Row style={{alignItems: 'center'}}>
      {(isStops || !project.organization.features.isEnabledJobStopsDrawer) && (
        <React.Fragment>
          <LocationItemTitle responsive={responsive}>{`${
            isStops ? 'Stop' : 'Location'
          } ${position} of ${totalLocations}`}</LocationItemTitle>
          <Space width={12} />
        </React.Fragment>
      )}
      {responsive.desktop && (
        <StartEndLocationBadges
          form={form}
          field={field}
          index={index}
          locationForm={locationForm}
          isStops={isStops}
          isDisabled={isDisabled}
        />
      )}
      <Space style={{flex: 1}} />
      {responsive.desktop ? (
        <LocationActionButtons {...actionComponentProps} />
      ) : (
        <LocationMobileActionMenu {...actionComponentProps} />
      )}
      {isExisting && (
        <DeleteProjectLocationModal
          projectId={project.id}
          locationId={locationForm.locationId}
          isOpen={deleteLocationModal.isOpen}
          handleClose={deleteLocationModal.handleClose}
          handleDelete={() => {
            deleteLocationModal.handleClose();
            handleRemoveLocation({form, field, index});
          }}
        />
      )}
      <EditProjectLocationDrawer
        key={editLocationDrawer.key}
        isOpen={editLocationDrawer.isOpen}
        handleClose={editLocationDrawer.handleClose}
        locationForm={locationForm}
        onSuccess={(updatedLocationForm: any) =>
          handleUpdatedLocation({updatedLocationForm, form, jobsField, locationForm})
        }
      />
    </Row>
  );
};

const WarehouseLocationItem = ({
  form,
  field,
  project,
}: {
  form: Form;
  field: string;
  project: ProjectModel;
}) => {
  const responsive = useResponsive();
  const locationFormField = `${field}.warehouseLocationForm`;

  return (
    <LocationItemContainer>
      <Row style={{alignItems: 'center'}}>
        <LocationItemTitle responsive={responsive}>{`Warehouse`}</LocationItemTitle>
      </Row>
      <Space height={16} />
      <Callout text={'This stop will not be shown to the customer.'} />
      <Space height={16} />
      <EditProjectLocationFields
        form={form}
        field={locationFormField}
        project={project}
        isWarehouse
      />
    </LocationItemContainer>
  );
};

const EditLocationsListItem = ({
  form,
  field,
  jobsField,
  index,
  project,
  handleRemoveLocation,
  autoFocus,
  isStops,
  showJobsCallout,
  isDisabled,
  map,
}: {
  form: Form;
  field: string;
  jobsField: string;
  index: number;
  project: ProjectModel;
  handleRemoveLocation: ({form, field, index}: {form: Form; field: string; index: number}) => void;
  autoFocus: boolean;
  isStops: boolean;
  showJobsCallout: boolean;
  isDisabled: boolean;
  map: MapType;
}) => {
  const responsive = useResponsive();
  const locationFormField = `${field}.locationForms.${index}`;
  const locationForm = _.get(form.values, locationFormField);

  return (
    <LocationItemContainer>
      <LocationItemHeader
        form={form}
        field={field}
        jobsField={jobsField}
        index={index}
        project={project}
        locationForm={locationForm}
        handleRemoveLocation={handleRemoveLocation}
        isStops={isStops}
        isDisabled={isDisabled}
        map={map}
      />
      {!responsive.desktop && (
        <React.Fragment>
          <Space height={12} />
          <StartEndLocationBadges
            form={form}
            field={field}
            index={index}
            locationForm={locationForm}
            isStops={isStops}
            isDisabled={isDisabled}
          />
        </React.Fragment>
      )}
      <Space height={16} />
      <EditProjectLocationFields
        form={form}
        field={locationFormField}
        project={project}
        autoFocus={autoFocus}
        isStops={isStops}
        showJobsCallout={showJobsCallout}
        isDisabled={isDisabled}
      />
    </LocationItemContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditLocationsListItem.fragment = gql`
  ${EditProjectLocationFields.fragment}

  fragment EditLocationsListItem on Project {
    id
    organization {
      id
      features {
        isEnabledJobStopsDrawer: isEnabled(feature: "JOB_STOPS_DRAWER")
      }
    }
    ...EditProjectLocationFields
  }
`;

EditLocationsListItem.WarehouseLocationItem = WarehouseLocationItem;

export default EditLocationsListItem;
