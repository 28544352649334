// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {FileModel, AttachmentModel, EmailAttachmentStatus} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {downloadFromUrl} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import Sheet from '@shared/design/components/Sheet';
import SheetWithFooter, {
  SheetWithFooterProps,
} from '@shared/design/components/Sheet/SheetWithFooter';
import FileViewer from '@shared/modules/File/components/FileViewer';
import EmailAttachmentImage from 'modules/Communication/Email/components/EmailAttachmentImage';

const HeaderRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const DownloadCloseContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ModalBody = Styled.View`
  align-items: center;
`;

const IndexControlRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const FileContainer = Styled.View<{height: number; width: number}>`
  height: ${({height}) => height}px;
  width: ${({width}) => width}px;
`;

const IndexText = Styled.Text`
`;

const PlaceholderView = Styled.View`
`;

const MobileSheetBody = Styled.View`
  align-items: center;
`;

interface AttachmentDetailsModalProps {
  attachments: AttachmentModel[];
  responsive: ResponsiveType;
  selectedAttachmentId?: string;
  setSelectedAttachmentId: (id?: string) => void;
  selectedAttachmentStatus?: EmailAttachmentStatus;
  sheetWithFooterProps?: Partial<SheetWithFooterProps>;
}

const Header = ({
  file,
  responsive,
  handleClose,
}: {
  file?: FileModel;
  responsive: ResponsiveType;
  handleClose: () => void;
}) => {
  return (
    <HeaderRow>
      {file ? <HeaderText responsive={responsive}>{file.name}</HeaderText> : <PlaceholderView />}
      <DownloadCloseContainer>
        {file ? (
          <Button
            iconLeft={Icon.FileArrowDown}
            text={'Download'}
            onPress={() => downloadFromUrl(file.downloadUrl)}
          />
        ) : null}
        <Space width={16} />
        <TertiaryButton onPress={handleClose}>
          <Icon source={Icon.Xmark} />
        </TertiaryButton>
      </DownloadCloseContainer>
    </HeaderRow>
  );
};

const IndexControl = ({
  setSelectedAttachmentId,
  selectedIndex,
  attachments,
}: {
  setSelectedAttachmentId: (id?: string) => void;
  selectedIndex: number;
  attachments: AttachmentModel[];
}) => {
  const firstDisabled = selectedIndex === 0;
  const lastDisabled = selectedIndex === attachments.length - 1;
  return (
    <IndexControlRow>
      <Space height={16} />
      <TertiaryButton
        isDisabled={firstDisabled}
        onPress={() => setSelectedAttachmentId(attachments[selectedIndex - 1].id)}
      >
        <Icon
          source={Icon.ChevronLeft}
          color={firstDisabled ? colors.gray.disabled : colors.blue.interactive}
          size={16}
        />
      </TertiaryButton>
      <Space width={16} />
      <IndexText>{`${selectedIndex + 1} of ${attachments.length}`}</IndexText>
      <Space width={16} />
      <TertiaryButton
        isDisabled={lastDisabled}
        onPress={() => setSelectedAttachmentId(attachments[selectedIndex + 1].id)}
      >
        <Icon
          source={Icon.ChevronRight}
          color={lastDisabled ? colors.gray.disabled : colors.blue.interactive}
          size={16}
        />
      </TertiaryButton>
    </IndexControlRow>
  );
};

const MobileCloseButton = ({handleClose}: {handleClose: () => void}) => {
  return (
    <TertiaryButton onPress={handleClose}>
      <Icon source={Icon.Xmark} color={colors.gray.secondary} />
    </TertiaryButton>
  );
};

const AttachmentDetailsModal = ({
  attachments,
  responsive,
  selectedAttachmentId,
  setSelectedAttachmentId,
  selectedAttachmentStatus = 'SUCCESS',
  sheetWithFooterProps,
}: AttachmentDetailsModalProps) => {
  const selectedAttachment = attachments.find(
    (attachment) => attachment.id === selectedAttachmentId,
  );

  if (!selectedAttachment) {
    return null;
  }

  const selectedAttachmentIndex = attachments.findIndex(
    (attachment) => attachment.id === selectedAttachmentId,
  );

  const handleClose = () => {
    setSelectedAttachmentId(undefined);
  };

  const imageHeight = responsive.mobile ? 300 : 500;
  const selectedFile = selectedAttachment.file;

  if (responsive.mobile) {
    return (
      <Sheet.PreventPropagationContainer>
        <SheetWithFooter
          isOpen={!!selectedAttachment}
          handleClose={handleClose}
          headerText={selectedFile?.name}
          isFixedHeight={false}
          // Facing a weird mobile bug where the sheet is automatically closing itself if this isn't set,
          // will remove if this is fixed
          shouldCloseOnClickOutside={false}
          HeaderRightComponent={() => <MobileCloseButton handleClose={handleClose} />}
          primaryActionText={'Download'}
          primaryActionIcon={Icon.FileArrowDown}
          handlePrimaryAction={() => downloadFromUrl(selectedFile.downloadUrl)}
          style={{backgroundColor: colors.white}}
          {...sheetWithFooterProps}
        >
          <MobileSheetBody>
            {/* We aren't using FileViewer on mobile because the documents don't render properly, they get cut off
            This renders images but not PDFs  */}
            <EmailAttachmentImage
              file={selectedFile}
              status={selectedAttachmentStatus}
              responsive={responsive}
              containerStyle={{height: imageHeight, width: 300, borderWidth: '0px'}}
              imageStyle={{height: imageHeight, width: 300, borderBottomWidth: 0}}
              isSmall={false}
            />
            <Space height={16} />
            <IndexControl
              selectedIndex={selectedAttachmentIndex}
              attachments={attachments}
              setSelectedAttachmentId={setSelectedAttachmentId}
            />
          </MobileSheetBody>
        </SheetWithFooter>
      </Sheet.PreventPropagationContainer>
    );
  }

  return (
    <LargeModal
      isResponsive
      isScrollable
      isOpen={!!selectedAttachment}
      title={selectedFile?.name}
      handlePressOutside={handleClose}
      HeaderComponent={() => (
        <Header file={selectedFile} responsive={responsive} handleClose={handleClose} />
      )}
      style={{backgroundColor: colors.white, padding: 24}}
      bodyStyle={{backgroundColor: colors.white}}
      FooterComponent={() => (
        <IndexControl
          selectedIndex={selectedAttachmentIndex}
          attachments={attachments}
          setSelectedAttachmentId={setSelectedAttachmentId}
        />
      )}
    >
      <ModalBody>
        <FileContainer height={600} width={700}>
          <FileViewer file={selectedFile} />
        </FileContainer>
        <Space height={16} />
      </ModalBody>
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AttachmentDetailsModal.fragment = gql`
  ${FileViewer.fragment}
  ${EmailAttachmentImage.fragment}

  fragment AttachmentDetailsModal on File {
    id
    name
    downloadUrl
    ...FileViewer
    ...EmailAttachmentImage
  }
`;

export default AttachmentDetailsModal;
