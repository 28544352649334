// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {AttachmentModel} from '@supermove/models';

// App
import {UpdateAttachmentFormType} from '@shared/modules/File/forms/UpdateAttachmentForm';
import UpdateAttachmentsForm, {
  UpdateAttachmentsFormType,
} from '@shared/modules/File/forms/UpdateAttachmentsForm';

export interface UpdateAttachmentMutationWrapper {
  updateAttachmentsForm: {
    updateAttachmentForms: UpdateAttachmentFormType[];
  };
}

const useUpdateAttachmentsMutation = ({
  updateAttachmentsForm,
  onSuccess,
  onError,
}: {
  updateAttachmentsForm: UpdateAttachmentsFormType;
  onSuccess: (response: {attachments: AttachmentModel[]}) => void;
  onError: (e: any) => void;
}) => {
  const form = useForm<UpdateAttachmentMutationWrapper>({
    initialValues: {
      // @ts-expect-error TS(2322): Type '{ updateAttachmentForms: { attachmentId: str... Remove this comment to see the full error message
      updateAttachmentsForm: UpdateAttachmentsForm.toForm(updateAttachmentsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateAttachmentsMutation.mutation,
    variables: {
      updateAttachmentsForm: UpdateAttachmentsForm.toMutation(form.values.updateAttachmentsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateAttachmentsMutation.mutation = gql`
  mutation useUpdateAttachmentsMutation($updateAttachmentsForm: UpdateAttachmentsForm!) {
    response: updateAttachments(updateAttachmentsForm: $updateAttachmentsForm) {
      ${gql.errors}

      attachments {
        id
      }
    }
  }
`;

export default useUpdateAttachmentsMutation;
