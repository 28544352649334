// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, PreventPropagation, FileDragInput, Popover} from '@supermove/components';
import {
  useModal,
  ModalType,
  usePopover,
  PopoverType,
  useResponsive,
  ResponsiveType,
  useInternet,
  ToastType,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, downloadFromUrl} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import DeleteInventoryItemPhotoModal from 'modules/Inventory/Edit/components/DeleteInventoryItemPhotoModal';
import {
  FormFileType,
  ItemFilesFormType,
} from 'modules/Inventory/Edit/components/EditInventoryItemPhotosDrawer';
import InventoryPhotosFileDropzone from 'modules/Inventory/Edit/components/InventoryPhotosFileDropzone';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const DropzoneSectionContainer = Styled.View`
  height: 120px;
`;

const FilesListContainer = Styled.View`
  flex: 1;
`;

const FilesListContentContainer = Styled.View`
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${colors.white};
`;

const FilesListFileContainer = Styled.View`
  padding: 12px;
`;

const FileDetailsRow = Styled.View`
  flex-direction: row;
`;

const LinkContainer = Styled.View`
  flex: 1;
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;
const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const LoadingIndicator = Styled.Loading`
`;

const Image = Styled.Image`
  height: 80px;
  width: 80px;
  background-color: ${colors.gray.border};
`;

const FileName = ({
  formFile,
  formFileIndex,
  handleOpenPopoutDrawer,
  responsive,
}: {
  formFile: FormFileType;
  formFileIndex: number;
  handleOpenPopoutDrawer: (index: number) => void;
  responsive: ResponsiveType;
}) => {
  return (
    <LinkContainer>
      <LinkText
        responsive={responsive}
        numberOfLines={1}
        onClick={() => handleOpenPopoutDrawer(formFileIndex)}
      >
        {formFile.filename}
      </LinkText>
    </LinkContainer>
  );
};

const FileActionsButton = ({
  formFile,
  formFileIndex,
  handleOpenPopoutDrawer,
  fileActionsPopover,
  deleteInventoryItemPhotoModal,
  responsive,
}: {
  formFile: FormFileType;
  formFileIndex: number;
  handleOpenPopoutDrawer: (index: number) => void;
  fileActionsPopover: PopoverType;
  deleteInventoryItemPhotoModal: ModalType;
  responsive: ResponsiveType;
}) => {
  return (
    <ActionMenuPopover
      popover={fileActionsPopover}
      width={200}
      sheetLabel={'Actions'}
      shouldMobileSheetCloseOnClickOutside={false}
      placement={Popover.Positions.AutoStart}
      actions={[
        {
          text: 'View',
          onPress: () => {
            handleOpenPopoutDrawer(formFileIndex);
          },
        },
        {
          text: 'Download',
          onPress: () => {
            downloadFromUrl(formFile.downloadUrl);
          },
        },
        {
          text: 'Delete',
          color: colors.red.warning,
          onPress: deleteInventoryItemPhotoModal.handleOpen,
        },
      ]}
    >
      <PreventPropagation>
        <IconButton
          source={'ellipsis-v'}
          isSecondary
          size={responsive.desktop ? 16 : 18}
          onPress={fileActionsPopover.handleOpen}
        />
      </PreventPropagation>
    </ActionMenuPopover>
  );
};

const FileActions = ({
  formFile,
  formFileIndex,
  itemFilesForm,
  handleOpenPopoutDrawer,
  handleClosePopoutDrawer,
  handleUpdateInventoryRoomsForm,
  photoDeletedToast,
  responsive,
}: {
  formFile: FormFileType;
  formFileIndex: number;
  itemFilesForm: ItemFilesFormType;
  handleOpenPopoutDrawer: (index: number) => void;
  handleClosePopoutDrawer: () => void;
  handleUpdateInventoryRoomsForm: (updatedFormFiles: FormFileType[]) => void;
  photoDeletedToast: ToastType;
  responsive: ResponsiveType;
}) => {
  const fileActionsPopover = usePopover();
  const deleteInventoryItemPhotoModal = useModal({name: 'Delete Inventory Item Photo Modal'});
  return (
    <React.Fragment>
      <FileActionsButton
        key={`ATTACHMENT_ACTIONS_POPOVER-${fileActionsPopover.isOpen}`}
        formFile={formFile}
        formFileIndex={formFileIndex}
        handleOpenPopoutDrawer={handleOpenPopoutDrawer}
        fileActionsPopover={fileActionsPopover}
        deleteInventoryItemPhotoModal={deleteInventoryItemPhotoModal}
        responsive={responsive}
      />
      {/* Note(Hammad): This PreventPropagation is to prevent a bug where clicking in the modal opens the photo details popout drawer */}
      <PreventPropagation>
        <DeleteInventoryItemPhotoModal
          deleteInventoryItemPhotoModal={deleteInventoryItemPhotoModal}
          formFile={formFile}
          itemFilesForm={itemFilesForm}
          handleClosePopoutDrawer={handleClosePopoutDrawer}
          handleUpdateInventoryRoomsForm={handleUpdateInventoryRoomsForm}
          photoDeletedToast={photoDeletedToast}
        />
      </PreventPropagation>
    </React.Fragment>
  );
};

const FileItem = ({
  formFile,
  formFileIndex,
  itemFilesForm,
  handleOpenPopoutDrawer,
  handleClosePopoutDrawer,
  handleUpdateInventoryRoomsForm,
  photoDeletedToast,
  responsive,
}: {
  formFile: FormFileType;
  formFileIndex: number;
  itemFilesForm: ItemFilesFormType;
  handleOpenPopoutDrawer: (index: number) => void;
  handleClosePopoutDrawer: () => void;
  handleUpdateInventoryRoomsForm: (updatedFormFiles: FormFileType[]) => void;
  photoDeletedToast: ToastType;
  responsive: ResponsiveType;
}) => {
  const uploadedText = `Uploaded by ${formFile.uploadedBy} on ${Datetime.convertToDisplayDatetime(formFile.uploadedAt, Datetime.DISPLAY_SHORT_DATE)}`;

  return (
    <FilesListFileContainer>
      <FileDetailsRow>
        <TertiaryButton onPress={() => handleOpenPopoutDrawer(formFileIndex)}>
          <Image
            resizeMode={'contain'}
            source={{uri: formFile.downloadUrl}}
            accessibilityLabel={formFile.filename}
          />
        </TertiaryButton>
        <Space width={16} />
        <FileName
          formFile={formFile}
          formFileIndex={formFileIndex}
          handleOpenPopoutDrawer={handleOpenPopoutDrawer}
          responsive={responsive}
        />
        <Space width={16} />
        <FileActions
          formFile={formFile}
          formFileIndex={formFileIndex}
          itemFilesForm={itemFilesForm}
          handleOpenPopoutDrawer={handleOpenPopoutDrawer}
          handleClosePopoutDrawer={handleClosePopoutDrawer}
          handleUpdateInventoryRoomsForm={handleUpdateInventoryRoomsForm}
          photoDeletedToast={photoDeletedToast}
          responsive={responsive}
        />
      </FileDetailsRow>
      <Space height={12} />
      <Row>
        <MicroText responsive={responsive}>{uploadedText}</MicroText>
      </Row>
    </FilesListFileContainer>
  );
};

const FileItemLoading = () => {
  return (
    <FilesListFileContainer>
      <Row>
        <LoadingIndicator size={16} />
      </Row>
    </FilesListFileContainer>
  );
};

const FilesList = ({
  formFiles,
  itemFilesForm,
  handleOpenPopoutDrawer,
  handleClosePopoutDrawer,
  handleUpdateInventoryRoomsForm,
  handleAddFiles,
  photoDeletedToast,
}: {
  formFiles: FormFileType[];
  itemFilesForm: ItemFilesFormType;
  handleOpenPopoutDrawer: (index: number) => void;
  handleClosePopoutDrawer: () => void;
  handleUpdateInventoryRoomsForm: (updatedFormFiles: FormFileType[]) => void;
  handleAddFiles: ({
    itemFilesForm,
    rawFiles,
  }: {
    itemFilesForm: ItemFilesFormType;
    rawFiles: File[];
  }) => void;
  photoDeletedToast: ToastType;
}) => {
  const responsive = useResponsive();
  const {isConnected} = useInternet();
  return (
    <FilesListContainer>
      <DropzoneSectionContainer>
        <FileDragInput
          style={{flex: 1}}
          accept={'image/*'}
          disableDrag
          disableClick={!isConnected}
          onFilesChange={(rawFiles) => {
            handleClosePopoutDrawer();
            handleAddFiles({itemFilesForm, rawFiles});
          }}
        >
          {({isDragActive}) => {
            return <InventoryPhotosFileDropzone formFiles={formFiles} />;
          }}
        </FileDragInput>
      </DropzoneSectionContainer>
      <Space height={16} />
      <FilesListContentContainer>
        {formFiles.map((formFile: FormFileType, formFileIndex: number) => {
          const isUploadingFile = !formFile.fileId;
          return (
            <React.Fragment key={formFile.uuid}>
              {formFileIndex > 0 && <Line />}
              {isUploadingFile ? (
                <FileItemLoading />
              ) : (
                <FileItem
                  formFile={formFile}
                  formFileIndex={formFileIndex}
                  itemFilesForm={itemFilesForm}
                  handleOpenPopoutDrawer={handleOpenPopoutDrawer}
                  handleClosePopoutDrawer={handleClosePopoutDrawer}
                  handleUpdateInventoryRoomsForm={handleUpdateInventoryRoomsForm}
                  photoDeletedToast={photoDeletedToast}
                  responsive={responsive}
                />
              )}
            </React.Fragment>
          );
        })}
      </FilesListContentContainer>
      {/* This spacing corresponds to the Drawer.Body Scrollview padding */}
      <Space height={responsive.desktop ? 24 : 16} />
    </FilesListContainer>
  );
};

const EditInventoryItemPhotosListView = ({
  isDragActive,
  formFiles,
  itemFilesForm,
  handleOpenPopoutDrawer,
  handleClosePopoutDrawer,
  handleUpdateInventoryRoomsForm,
  handleAddFiles,
  photoDeletedToast,
}: {
  isDragActive: boolean;
  formFiles: FormFileType[];
  itemFilesForm: ItemFilesFormType;
  handleOpenPopoutDrawer: (index: number) => void;
  handleClosePopoutDrawer: () => void;
  handleUpdateInventoryRoomsForm: (updatedFormFiles: FormFileType[]) => void;
  handleAddFiles: ({
    itemFilesForm,
    rawFiles,
  }: {
    itemFilesForm: ItemFilesFormType;
    rawFiles: File[];
  }) => void;
  photoDeletedToast: ToastType;
}) => {
  if (isDragActive || _.isEmpty(formFiles)) {
    return <InventoryPhotosFileDropzone formFiles={formFiles} isDragActive={isDragActive} />;
  }

  return (
    <FilesList
      formFiles={formFiles}
      itemFilesForm={itemFilesForm}
      handleOpenPopoutDrawer={handleOpenPopoutDrawer}
      handleClosePopoutDrawer={handleClosePopoutDrawer}
      handleUpdateInventoryRoomsForm={handleUpdateInventoryRoomsForm}
      handleAddFiles={handleAddFiles}
      photoDeletedToast={photoDeletedToast}
    />
  );
};

export default EditInventoryItemPhotosListView;
