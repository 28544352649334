// Supermove
import _ from 'lodash';

import {colors} from '@supermove/styles';

const PENDING = 'PENDING';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';
const FAILED = 'FAILED';
const SKIPPED = 'SKIPPED';

const STATUS_OPTIONS = {
  PENDING: {
    value: 'PENDING',
    label: 'Pending',
    backgroundColor: colors.orange.accent,
    textColor: colors.orange.status,
  },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'In Progress',
    backgroundColor: colors.blue.accent,
    textColor: colors.blue.interactive,
  },
  COMPLETED: {
    value: 'COMPLETED',
    label: 'Completed',
    backgroundColor: colors.green.accent,
    textColor: colors.green.status,
  },
  FAILED: {
    value: 'FAILED',
    label: 'Failed',
    backgroundColor: colors.red.accent,
    textColor: colors.red.warning,
  },
  CANCELLED: {
    value: 'CANCELLED',
    label: 'Cancelled',
  },
};

const getStatusOption = (status: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return STATUS_OPTIONS[status];
};

const WorkflowRunStatus = {
  // Enum Options
  PENDING,
  IN_PROGRESS,
  COMPLETED,
  CANCELLED,
  FAILED,
  SKIPPED,

  DROPDOWN_OPTIONS: _.values(STATUS_OPTIONS),

  getStatusOption,
};

export default WorkflowRunStatus;
