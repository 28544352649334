// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import DocumentV2Pdf from '@shared/modules/Document/components/DocumentV2Pdf';
import PrintDocumentV2PdfWrapper from '@shared/modules/Document/components/PrintDocumentV2PdfWrapper';

const PageContainer = Styled.View`
  flex: 1;
  align-items: center;
`;

const ErrorHeading = Styled.Text`
  ${Typography.PageHeading}
  text-align: center;
`;

const ErrorText = Styled.Text`
  ${Typography.Body}
  text-align: center;
`;

const ErrorMessage = () => {
  return (
    <PageContainer>
      <Space height={64} />
      <ErrorHeading>Failed to load PDF</ErrorHeading>
      <Space height={16} />
      <ErrorText>
        The PDF for this document was not generated. Please contact support and include a link to
        this page.
      </ErrorText>
    </PageContainer>
  );
};

const CustomerDocumentV2Pdf = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(CustomerDocumentV2Pdf.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <Loading loading={loading}>
      {() =>
        data.document.isUploaded ? (
          <PrintDocumentV2PdfWrapper documentUuid={data.document.uuid}>
            <DocumentV2Pdf document={data.document} />
          </PrintDocumentV2PdfWrapper>
        ) : (
          <ErrorMessage />
        )
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerDocumentV2Pdf.query = gql`
  ${DocumentV2Pdf.fragment}
  query CustomerDocumentV2Pdf($uuid: String!) {
    ${gql.query}
    document(uuid: $uuid) {
      id
      uuid
      isUploaded
      ...DocumentV2Pdf
    }
  }
`;

export default CustomerDocumentV2Pdf;
