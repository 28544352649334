// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {useResponsive, ResponsiveType, Form} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import {InventoryRoomsFormType} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import EditRoomAttachmentsDrawerContent from 'modules/Inventory/Edit/components/EditRoomAttachmentsDrawerContent';

const Container = Styled.View`
  width: 400px;
  background-color: ${colors.gray.background};
`;

const HeaderContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.white};
`;

const HeaderTextContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.primary};
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const ContentContainer = Styled.View`
  flex: 1;
`;

const Header = ({
  headerText,
  description,
  handleClose,
  responsive,
}: {
  headerText: string;
  description: string;
  handleClose: () => void;
  responsive: ResponsiveType;
}) => {
  return (
    <HeaderContainer>
      <HeaderTextContainer>
        <HeaderText responsive={responsive}>{headerText}</HeaderText>
        <Space width={16} />
        <IconButton
          source={Icon.Xmark}
          isSecondary
          size={responsive.desktop ? 16 : 18}
          onPress={handleClose}
        />
      </HeaderTextContainer>
      <Space height={8} />
      <DescriptionText>{description}</DescriptionText>
    </HeaderContainer>
  );
};

const EditRoomAttachmentsSidebar = ({
  inventoryRoomsForm,
  handleClose,
}: {
  inventoryRoomsForm: Form<{inventoryRoomsForm: InventoryRoomsFormType}>;
  handleClose: () => void;
}) => {
  const {selectedRoomIndex, roomItemsForms} = inventoryRoomsForm.values.inventoryRoomsForm;
  const selectedRoomForm = roomItemsForms[selectedRoomIndex];
  const videoCount = _.isEmpty(selectedRoomForm.latestVideoAttachment) ? 0 : 1;
  const photoCount = selectedRoomForm.photoAttachments.length;
  const responsive = useResponsive();

  return (
    <Container>
      <Header
        headerText={`${selectedRoomForm.name} Media`}
        description={`${videoCount} ${pluralize('video', videoCount)}, ${photoCount} ${pluralize('photo', photoCount)}`}
        handleClose={handleClose}
        responsive={responsive}
      />
      <Line />
      <ContentContainer>
        <EditRoomAttachmentsDrawerContent inventoryRoomsForm={inventoryRoomsForm} />
      </ContentContainer>
    </Container>
  );
};

export default EditRoomAttachmentsSidebar;
