// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, Form, useEffect, useRef, useState, ResponsiveType} from '@supermove/hooks';
import {AttachmentModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import Drawer from '@shared/design/components/Drawer';
import Sheet from '@shared/design/components/Sheet';
import {SheetFooterProps} from '@shared/design/components/Sheet/SheetWithFooter';
import {WrappedEmailFormType} from '@shared/modules/Email/hooks/useSendEmailForProjectMutation';
import BulkAttachmentsForm from '@shared/modules/File/forms/BulkAttachmentsForm';
import useUpdateAttachmentsMutation, {
  UpdateAttachmentMutationWrapper,
} from '@shared/modules/File/hooks/useUpdateAttachmentsMutation';
import AttachmentDetailsModal from 'modules/Communication/Email/components/AttachmentDetailsModal';
import EmailAttachmentImage from 'modules/Communication/Email/components/EmailAttachmentImage';
import EmailAttachmentDrawerUploadFiles from 'modules/File/Attachment/components/EmailAttachmentDrawerUploadFiles';

const DrawerContainer = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const DrawerMainContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  width: ${({responsive}) => (responsive.desktop ? '400' : undefined)};
`;

const MobileContainer = Styled.View`
  padding-horizontal: 16px;
`;

// On mobile a line needs to go to the edge of the screen,
// so add a negative margin to undo the default padding
const UploadFileContainer = Styled.View<{responsive: ResponsiveType}>`
    margin-horizontal: ${({responsive}) => (responsive.mobile ? '-16px' : '0px')};
`;

const DrawerPreviewContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  width: ${({responsive}) => (responsive.desktop ? '400' : undefined)};
  border-right-width: ${({responsive}) => (responsive.mobile ? '0' : '1')}px;
  border-right-color: ${colors.gray.border};
`;

const AttachmentSubtitleText = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.secondary};
`;

const AttachmentName = Styled.Text`
  ${Typography.Responsive.Link}
  flex: 1;
`;

const AttachmentRow = Styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const AddButton = Styled.Button<{isSelected: boolean}>`
  height: 32px;
  width: 32px;
  margin: 5px;
  padding-horizontal: 12px;
  background-color: ${({isSelected}) => (isSelected ? colors.red.warning : colors.blue.interactive)};
`;

const AvailableAttachments = ({
  attachments,
  attachedAttachments,
  toggleAddRemoveAttachment,
  responsive,
  handleAttachmentPress,
}: {
  attachments: AttachmentModel[];
  attachedAttachments: AttachmentModel[];
  toggleAddRemoveAttachment: (attachment: AttachmentModel) => void;
  responsive: ResponsiveType;
  handleAttachmentPress: (attachment: AttachmentModel) => void;
}) => {
  return (
    <React.Fragment>
      {attachments.map((attachment) => {
        const isAttached = attachedAttachments.some(
          (attachedAttachment) => attachedAttachment.id === attachment.id,
        );
        return (
          <AttachmentRow key={attachment.id}>
            <AddButton
              onPress={() => toggleAddRemoveAttachment(attachment)}
              isSelected={isAttached}
            >
              <Icon
                color={colors.white}
                size={Icon.Sizes.Small}
                source={isAttached ? Icon.Trash : Icon.Plus}
              />
            </AddButton>
            <Space width={8} />
            <AttachmentName
              onPress={() => handleAttachmentPress(attachment)}
              responsive={responsive}
              numberOfLines={1}
            >
              {attachment.file.name}
            </AttachmentName>
          </AttachmentRow>
        );
      })}
    </React.Fragment>
  );
};

const UploadFiles = ({
  responsive,
  updateAttachmentForm,
  projectUuid,
}: {
  responsive: ResponsiveType;
  updateAttachmentForm: Form<UpdateAttachmentMutationWrapper>;
  projectUuid: string;
}) => {
  return (
    <React.Fragment>
      <AttachmentSubtitleText responsive={responsive}>Upload Files</AttachmentSubtitleText>
      <Space height={8} />
      <UploadFileContainer responsive={responsive}>
        <EmailAttachmentDrawerUploadFiles
          form={updateAttachmentForm}
          projectUuid={projectUuid}
          responsive={responsive}
        />
      </UploadFileContainer>
    </React.Fragment>
  );
};

const ExistingAttachmentItems = ({
  drawerAttachments,
  projectAttachments,
  libraryAttachments,
  responsive,
  toggleAddRemoveAttachment,
  handleAttachmentPress,
}: {
  drawerAttachments: AttachmentModel[];
  projectAttachments: AttachmentModel[];
  libraryAttachments: AttachmentModel[];
  responsive: ResponsiveType;
  toggleAddRemoveAttachment: (attachment: AttachmentModel) => void;
  handleAttachmentPress: (attachment: AttachmentModel) => void;
}) => {
  return (
    <React.Fragment>
      <Space height={5} />
      <AttachmentSubtitleText responsive={responsive}>From Library</AttachmentSubtitleText>
      <Space height={8} />
      <AvailableAttachments
        attachments={libraryAttachments}
        toggleAddRemoveAttachment={toggleAddRemoveAttachment}
        attachedAttachments={drawerAttachments}
        responsive={responsive}
        handleAttachmentPress={handleAttachmentPress}
      />
      <Space height={16} />
      <AttachmentSubtitleText responsive={responsive}>From Project</AttachmentSubtitleText>
      <Space height={8} />
      <AvailableAttachments
        attachments={projectAttachments}
        toggleAddRemoveAttachment={toggleAddRemoveAttachment}
        attachedAttachments={drawerAttachments}
        responsive={responsive}
        handleAttachmentPress={handleAttachmentPress}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const DrawerContent = ({
  previewAttachment,
  setPreviewAttachment,
  handleClose,
  drawerAttachments,
  projectAttachments,
  libraryAttachments,
  updateAttachmentsForm,
  projectUuid,
  isAllAttachmentsUploaded,
  submitting,
  toggleAddRemoveAttachment,
  handleSubmit,
  responsive,
}: {
  previewAttachment?: AttachmentModel;
  setPreviewAttachment: (attachment?: AttachmentModel) => void;
  handleClose: () => void;
  drawerAttachments: AttachmentModel[];
  projectAttachments: AttachmentModel[];
  libraryAttachments: AttachmentModel[];
  updateAttachmentsForm: Form<UpdateAttachmentMutationWrapper>;
  projectUuid: string;
  isAllAttachmentsUploaded: boolean;
  submitting: boolean;
  toggleAddRemoveAttachment: (attachment: AttachmentModel) => void;
  handleSubmit: () => void;
  responsive: ResponsiveType;
}) => {
  return (
    <DrawerContainer>
      {previewAttachment ? (
        <DrawerPreviewContainer responsive={responsive}>
          <Drawer.Header
            headerText={previewAttachment.file.name}
            handleClose={() => setPreviewAttachment(undefined)}
            closeIcon={Icon.ArrowRightToLine}
            titleNumberOfLines={responsive.mobile ? undefined : 1}
            isClosable
            isResponsive
          />
          <Drawer.Body bodyScrollStyle={{flex: 1}}>
            <EmailAttachmentImage
              file={previewAttachment.file}
              responsive={responsive}
              isSmall={false}
              containerStyle={{
                backgroundColor: colors.gray.background,
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: 500,
                width: 350,
                borderWidth: '0px',
              }}
              imageStyle={{height: 500, width: 350, borderBottomWidth: 0}}
            />
          </Drawer.Body>
        </DrawerPreviewContainer>
      ) : null}
      <DrawerMainContainer responsive={responsive}>
        <Drawer.Header
          headerText={'Add Attachments'}
          isResponsive
          handleBack={handleClose}
          isClosable={!responsive.desktop}
        />
        <Drawer.Body>
          <ExistingAttachmentItems
            drawerAttachments={drawerAttachments}
            projectAttachments={projectAttachments}
            libraryAttachments={libraryAttachments}
            responsive={responsive}
            toggleAddRemoveAttachment={toggleAddRemoveAttachment}
            handleAttachmentPress={setPreviewAttachment}
          />
          <UploadFiles
            updateAttachmentForm={updateAttachmentsForm}
            projectUuid={projectUuid}
            responsive={responsive}
          />
        </Drawer.Body>
        {responsive.desktop ? (
          <Drawer.Footer
            isDisabled={!isAllAttachmentsUploaded}
            isSubmitting={submitting}
            primaryAction={handleSubmit}
            secondaryAction={handleClose}
            primaryActionText={'Attach'}
            secondaryActionText={'Cancel'}
            primaryActionIcon={Icon.Check}
          />
        ) : (
          // Tablet uses this
          <Drawer.FooterMobile
            isDisabled={!isAllAttachmentsUploaded}
            isSubmitting={submitting}
            primaryAction={handleSubmit}
            primaryActionText={'Attach'}
          />
        )}
      </DrawerMainContainer>
    </DrawerContainer>
  );
};

const getMobileSheetProps = (
  isAttached: boolean,
  toggleAddRemove: () => void,
): Partial<SheetFooterProps> => {
  if (isAttached) {
    return {
      primaryActionColor: colors.red.warning,
      handlePrimaryAction: toggleAddRemove,
      primaryActionText: 'Remove',
      primaryActionIcon: Icon.Trash,
    };
  }
  return {
    handlePrimaryAction: toggleAddRemove,
    primaryActionText: 'Add',
    primaryActionIcon: Icon.Plus,
  };
};

const MobilePreviewAttachment = ({
  previewAttachment,
  addedAttachments,
  allAttachments,
  responsive,
  setPreviewAttachment,
  toggleAddRemoveAttachment,
}: {
  previewAttachment: AttachmentModel;
  addedAttachments: AttachmentModel[];
  allAttachments: AttachmentModel[];
  responsive: ResponsiveType;
  setPreviewAttachment: (attachment?: AttachmentModel) => void;
  toggleAddRemoveAttachment: (attachment: AttachmentModel) => void;
}) => {
  const isPreviewAttachmentSelected = addedAttachments.some(
    (attachment) => attachment.id === previewAttachment.id,
  );
  return (
    <AttachmentDetailsModal
      attachments={allAttachments}
      responsive={responsive}
      selectedAttachmentId={previewAttachment.id}
      setSelectedAttachmentId={(previewAttachmentId) =>
        setPreviewAttachment(
          allAttachments.find((attachment) => attachment.id === previewAttachmentId),
        )
      }
      sheetWithFooterProps={getMobileSheetProps(isPreviewAttachmentSelected, () =>
        toggleAddRemoveAttachment(previewAttachment),
      )}
    />
  );
};

const DrawerMobileContent = ({
  previewAttachment,
  setPreviewAttachment,
  drawerAttachments,
  projectAttachments,
  libraryAttachments,
  updateAttachmentsForm,
  projectUuid,
  toggleAddRemoveAttachment,
  responsive,
}: {
  previewAttachment?: AttachmentModel;
  setPreviewAttachment: (attachment?: AttachmentModel) => void;
  drawerAttachments: AttachmentModel[];
  projectAttachments: AttachmentModel[];
  libraryAttachments: AttachmentModel[];
  updateAttachmentsForm: Form<UpdateAttachmentMutationWrapper>;
  projectUuid: string;
  toggleAddRemoveAttachment: (attachment: AttachmentModel) => void;
  responsive: ResponsiveType;
}) => {
  return (
    <MobileContainer>
      <Space height={8} />
      {previewAttachment ? (
        <MobilePreviewAttachment
          previewAttachment={previewAttachment}
          addedAttachments={drawerAttachments}
          allAttachments={[...libraryAttachments, ...projectAttachments]}
          responsive={responsive}
          setPreviewAttachment={setPreviewAttachment}
          toggleAddRemoveAttachment={toggleAddRemoveAttachment}
        />
      ) : (
        <React.Fragment>
          <ExistingAttachmentItems
            drawerAttachments={drawerAttachments}
            projectAttachments={projectAttachments}
            libraryAttachments={libraryAttachments}
            responsive={responsive}
            toggleAddRemoveAttachment={toggleAddRemoveAttachment}
            handleAttachmentPress={setPreviewAttachment}
          />
          <UploadFiles
            updateAttachmentForm={updateAttachmentsForm}
            projectUuid={projectUuid}
            responsive={responsive}
          />
        </React.Fragment>
      )}
      <Space height={16} />
    </MobileContainer>
  );
};

const EmailAttachmentDrawer = ({
  isOpen,
  handleClose,
  projectUuid,
  form,
  attachedAttachments,
  attachmentsField,
  projectAttachments,
  libraryAttachments,
  responsive,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  projectUuid: string;
  form: Form<WrappedEmailFormType>;
  attachmentsField: string;
  attachedAttachments: AttachmentModel[];
  projectAttachments: AttachmentModel[];
  libraryAttachments: AttachmentModel[];
  responsive: ResponsiveType;
  refetch: () => void;
}) => {
  const [previewAttachment, setPreviewAttachment] = useState<AttachmentModel>();
  const previewRecentlyUnset = useRef(false);
  const setAttachmentPreviewWithTimeout = (attachment?: AttachmentModel) => {
    // There is an issue where the preview is set to no attachments, then a text box fires itself and re-sets the preview
    if (!attachment) {
      previewRecentlyUnset.current = true;
      setTimeout(() => {
        previewRecentlyUnset.current = false;
      }, 100);
      // Can always close the modal
      setPreviewAttachment(attachment);
    } else if (!previewRecentlyUnset.current) {
      // But only re-open if it hasn't been closed in the past 100ms
      setPreviewAttachment(attachment);
    }
  };
  // We don't update the actual form until we submit, so we set the initial attachments here
  // and work off that instead of attachedAttachments
  const bulkAttachmentsForm = useForm({
    initialValues: {
      bulkAttachmentsForm: BulkAttachmentsForm.toForm({attachmentForms: []}),
    },
  });

  const {
    form: updateAttachmentsForm,
    handleSubmit: submitUpdateAttachments,
    submitting,
  } = useUpdateAttachmentsMutation({
    updateAttachmentsForm: BulkAttachmentsForm.toUpdateAttachmentsForm(
      bulkAttachmentsForm.values.bulkAttachmentsForm,
    ),
    onSuccess: (response) => {
      const newAttachments = response.attachments.reduce((acc, attachment) => {
        if (!drawerAttachments.some((drawerAttachment) => drawerAttachment.id === attachment.id)) {
          acc.push(attachment);
        }
        return acc;
      }, drawerAttachments);
      form.setFieldValue(
        attachmentsField,
        newAttachments.map((attachment) => attachment.id),
      );
      refetch();
      updateAttachmentsForm.handleReset();
      handleClose();
    },
    onError: (errors) => console.log(errors),
  });
  const isAllAttachmentsUploaded = _.isEmpty(
    _.filter(
      _.get(updateAttachmentsForm.values, 'updateAttachmentsForm.updateAttachmentForms', []),
      (updateAttachmentForm) => updateAttachmentForm.attachmentId === undefined,
    ),
  );
  const [drawerAttachments, setDrawerAttachments] = useState(attachedAttachments);

  useEffect(() => {
    // When the drawer is closed, sync the drawer attachments with the attached attachments
    // So when it opens again it's in the correct state
    if (!isOpen && drawerAttachments !== attachedAttachments) {
      setDrawerAttachments(attachedAttachments);
    }
  }, [isOpen, drawerAttachments, attachedAttachments]);

  const toggleAddRemoveAttachment = (attachment: AttachmentModel) => {
    const attachmentAttached = drawerAttachments.some(
      (attachedAttachment) => attachedAttachment.id === attachment.id,
    );
    if (attachmentAttached) {
      setDrawerAttachments(
        drawerAttachments.filter((attachedAttachment) => attachedAttachment.id !== attachment.id),
      );
    } else {
      setDrawerAttachments([...drawerAttachments, attachment]);
    }
  };

  const handleSubmit = () => {
    if (updateAttachmentsForm.values.updateAttachmentsForm?.updateAttachmentForms?.length) {
      submitUpdateAttachments();
    } else {
      form.setFieldValue(
        attachmentsField,
        drawerAttachments.map((attachment) => attachment.id),
      );
      handleClose();
    }
  };

  return (
    <React.Fragment>
      {responsive.mobile ? (
        <Sheet.PreventPropagationContainer>
          <Sheet
            isOpen={isOpen}
            handleClose={
              previewAttachment ? () => setAttachmentPreviewWithTimeout(undefined) : handleClose
            }
            handleGoBack={previewAttachment ? undefined : handleClose}
            HeaderRightComponent={
              previewAttachment
                ? () => (
                    <Sheet.HeaderActionIcon
                      icon={Icon.Xmark}
                      handleAction={() => setAttachmentPreviewWithTimeout(undefined)}
                    />
                  )
                : () => null
            }
            headerText={'Add Attachments'}
            headerContainerStyle={{backgroundColor: colors.white}}
            style={{backgroundColor: colors.white}}
          >
            <ScrollView>
              <DrawerMobileContent
                previewAttachment={previewAttachment}
                setPreviewAttachment={setAttachmentPreviewWithTimeout}
                drawerAttachments={drawerAttachments}
                projectAttachments={projectAttachments}
                libraryAttachments={libraryAttachments}
                updateAttachmentsForm={updateAttachmentsForm}
                projectUuid={projectUuid}
                toggleAddRemoveAttachment={(attachment: AttachmentModel) => {
                  toggleAddRemoveAttachment(attachment);
                  setAttachmentPreviewWithTimeout(undefined);
                }}
                responsive={responsive}
              />
            </ScrollView>
            <Drawer.FooterMobile
              isDisabled={!isAllAttachmentsUploaded}
              isSubmitting={submitting}
              primaryAction={handleSubmit}
              primaryActionText={'Attach'}
            />
          </Sheet>
        </Sheet.PreventPropagationContainer>
      ) : (
        <Drawer isOpen={isOpen}>
          <DrawerContent
            previewAttachment={previewAttachment}
            setPreviewAttachment={setAttachmentPreviewWithTimeout}
            handleClose={handleClose}
            drawerAttachments={drawerAttachments}
            projectAttachments={projectAttachments}
            libraryAttachments={libraryAttachments}
            updateAttachmentsForm={updateAttachmentsForm}
            projectUuid={projectUuid}
            isAllAttachmentsUploaded={isAllAttachmentsUploaded}
            submitting={submitting}
            toggleAddRemoveAttachment={toggleAddRemoveAttachment}
            handleSubmit={handleSubmit}
            responsive={responsive}
          />
        </Drawer>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachmentDrawer.fragment = gql`
  ${EmailAttachmentImage.fragment}

  fragment EmailAttachmentDrawer_File on File {
    id
    name
    ...EmailAttachmentImage
  }
`;

export default EmailAttachmentDrawer;
