// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useQuery, useResponsive, useState, ResponsiveType, useHover} from '@supermove/hooks';
import {AttachmentModel, ProjectModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App

import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import {SheetWithFooterProps} from '@shared/design/components/Sheet/SheetWithFooter';
import {WrappedEmailFormType} from '@shared/modules/Email/hooks/useSendEmailForProjectMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import AttachmentDetailsModal from 'modules/Communication/Email/components/AttachmentDetailsModal';
import EmailAttachmentImage from 'modules/Communication/Email/components/EmailAttachmentImage';
import EmailAttachmentDrawer from 'modules/File/Attachment/components/EmailAttachmentDrawer';

const Container = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const AttachmentTitleAndPreviewContainer = Styled.View`
  flex: 1;
`;

const AddAttachmentContainer = Styled.View<{responsive: ResponsiveType}>`
  align-items: ${({responsive}) => (responsive.mobile ? 'stretch' : 'flex-end')};
  minWidth: ${({responsive}) => (responsive.mobile ? undefined : '190px')};
`;

const AttachmentTitleText = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const AttachedAttachmentsContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  align-items: center;
  justify-content: ${({responsive}) => (responsive.mobile ? 'center' : 'flex-start')};
  flex-wrap: wrap;
`;

const AddButton = Styled.Button<{isSelected: boolean}>`
  height: 32px;
  width: 32px;
  margin: 5px;
  padding-horizontal: 12px;
  background-color: ${({isSelected}) => (isSelected ? colors.red.warning : colors.blue.interactive)};
`;

interface EmailAttachmentsSelectorWithDrawerProps {
  form: Form<WrappedEmailFormType>;
  project: ProjectModel;
  name?: string;
}

const AttachedAttachment = ({
  form,
  attachment,
  attachmentsFieldName,
  responsive,
  handleSelectAttachment,
}: {
  form: Form<WrappedEmailFormType>;
  attachment: AttachmentModel;
  attachmentsFieldName: string;
  responsive: ResponsiveType;
  handleSelectAttachment: (attachment: AttachmentModel) => void;
}) => {
  const {ref, isHovered} = useHover({hoverCheckType: 'enterLeave'});
  const handleRemove = () => {
    const attachments: string[] = _.get(form.values, attachmentsFieldName, []);
    form.setFieldValue(
      attachmentsFieldName,
      attachments.filter((id) => id !== attachment.id),
    );
  };

  return (
    <EmailAttachmentImage
      imageRef={ref}
      isHovered={isHovered}
      file={attachment.file}
      handleSelectAttachment={() => handleSelectAttachment(attachment)}
      responsive={responsive}
      showFilename
      isSmall
      containerStyle={{marginBottom: 16}}
    >
      {isHovered && (
        <TertiaryButton
          style={{backgroundColor: colors.red.warning, width: 27, height: 27}}
          onPress={handleRemove}
        >
          <Icon source={Icon.Trash} color={colors.white} size={10} />
        </TertiaryButton>
      )}
    </EmailAttachmentImage>
  );
};

const AddAttachmentsButton = ({
  handleAddAttachments,
  responsive,
}: {
  handleAddAttachments: () => void;
  responsive: ResponsiveType;
}) => {
  return (
    <AddAttachmentContainer responsive={responsive}>
      <SecondaryButton
        text={'Add Attachments'}
        iconLeft={Icon.Plus}
        isWidthOfContainer={responsive.mobile}
        isLarge={responsive.mobile}
        onPress={handleAddAttachments}
      />
    </AddAttachmentContainer>
  );
};

const AttachmentsPreview = ({
  form,
  name,
  project,
  responsive,
  setAddAttachmentsDrawerOpen,
  setSelectedAttachmentId,
}: {
  form: Form<WrappedEmailFormType>;
  name?: string;
  project: ProjectModel;
  responsive: ResponsiveType;
  setAddAttachmentsDrawerOpen: (isOpen: boolean) => void;
  setSelectedAttachmentId: (attachmentId: string | undefined) => void;
}) => {
  const attachmentsFieldName = name ? `${name}.attachmentIds` : 'attachmentIds';
  const projectAttachments = project.filteredAttachments;
  const libraryAttachments = project.organization.emailTemplateAttachments;
  const allAttachments = _.union(projectAttachments, libraryAttachments);
  const attachedAttachmentIds = _.get(form.values, attachmentsFieldName);
  const attachedAttachments = allAttachments.filter((attachment) =>
    attachedAttachmentIds.includes(attachment.id),
  );

  return (
    <Container>
      <AttachmentTitleAndPreviewContainer>
        <AttachmentTitleText
          responsive={responsive}
        >{`Attachments (${attachedAttachmentIds.length})`}</AttachmentTitleText>
        <Space height={16} />
        <AttachedAttachmentsContainer responsive={responsive}>
          {attachedAttachments.map((attachment, index) => {
            return (
              <AttachedAttachment
                key={attachment.id}
                form={form}
                attachment={attachment}
                attachmentsFieldName={attachmentsFieldName}
                handleSelectAttachment={(attachment) => setSelectedAttachmentId(attachment.id)}
                responsive={responsive}
              />
            );
          })}
        </AttachedAttachmentsContainer>
        {responsive.mobile ? (
          <AddAttachmentsButton
            handleAddAttachments={() => setAddAttachmentsDrawerOpen(true)}
            responsive={responsive}
          />
        ) : null}
      </AttachmentTitleAndPreviewContainer>
      {responsive.mobile ? null : (
        <AddAttachmentContainer responsive={responsive}>
          <AddAttachmentsButton
            handleAddAttachments={() => setAddAttachmentsDrawerOpen(true)}
            responsive={responsive}
          />
        </AddAttachmentContainer>
      )}
    </Container>
  );
};

const getMobileProps = (handleRemove: () => void): Partial<SheetWithFooterProps> => {
  // Because this is attached to the email, on mobile this turns into a "remove" button
  return {
    primaryActionText: 'Remove',
    primaryActionIcon: Icon.Trash,
    primaryActionColor: colors.red.warning,
    handlePrimaryAction: handleRemove,
  };
};

const EmailAttachmentsSelectorContent = ({
  form,
  name,
  project,
  refetch,
}: {
  form: Form<WrappedEmailFormType>;
  name?: string;
  project: ProjectModel;
  refetch: () => void;
}) => {
  const [addAttachmentsDrawerOpen, setAddAttachmentsDrawerOpen] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState<string | undefined>(undefined);
  const responsive = useResponsive();

  const attachmentsFieldName = name ? `${name}.attachmentIds` : 'attachmentIds';
  const projectAttachments = project.filteredAttachments;
  const libraryAttachments = project.organization.emailTemplateAttachments;
  const allAttachments = _.union(projectAttachments, libraryAttachments);
  const attachedAttachmentIds = _.get(form.values, attachmentsFieldName);

  const attachedAttachments = allAttachments.filter((attachment) =>
    attachedAttachmentIds.includes(attachment.id),
  );

  const handleRemove = () => {
    const attachments: string[] = _.get(form.values, attachmentsFieldName, []);
    form.setFieldValue(
      attachmentsFieldName,
      attachments.filter((id) => id !== selectedAttachmentId),
    );
    setSelectedAttachmentId(undefined);
  };

  return (
    <React.Fragment>
      <AttachmentsPreview
        form={form}
        name={name}
        project={project}
        responsive={responsive}
        setAddAttachmentsDrawerOpen={setAddAttachmentsDrawerOpen}
        setSelectedAttachmentId={setSelectedAttachmentId}
      />
      <AttachmentDetailsModal
        attachments={attachedAttachments}
        responsive={responsive}
        selectedAttachmentId={selectedAttachmentId}
        setSelectedAttachmentId={setSelectedAttachmentId}
        sheetWithFooterProps={responsive.mobile ? getMobileProps(handleRemove) : undefined}
      />
      <EmailAttachmentDrawer
        isOpen={addAttachmentsDrawerOpen}
        handleClose={() => setAddAttachmentsDrawerOpen(false)}
        projectUuid={project.uuid}
        form={form}
        attachmentsField={attachmentsFieldName}
        attachedAttachments={attachedAttachments}
        projectAttachments={projectAttachments}
        libraryAttachments={libraryAttachments}
        responsive={responsive}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const EmailAttachmentsSelectorWithDrawer = ({
  form,
  project,
  name,
}: EmailAttachmentsSelectorWithDrawerProps) => {
  const {loading, data, refetch} = useQuery(EmailAttachmentsSelectorWithDrawer.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: project.uuid,
    },
  });

  return (
    <Loading alwaysUpdate loading={loading} as={PageLoadingIndicator}>
      {() => (
        <EmailAttachmentsSelectorContent
          form={form}
          name={name}
          project={data.project}
          refetch={refetch}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachmentsSelectorWithDrawer.query = gql`
  ${EmailAttachmentImage.fragment}
  ${EmailAttachmentDrawer.fragment}
  ${AttachmentDetailsModal.fragment}

  query EmailAttachmentsSelectorWithDrawer($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      organization {
        id
        emailTemplateAttachments {
          id
          file {
            id
            name
            ...EmailAttachmentImage
            ...EmailAttachmentDrawer_File
            ...AttachmentDetailsModal
          }
        }
      }
      filteredAttachments: filteredAttachments(attachmentCategoryKinds: []) {
        id
        file {
          id
          name
          ...EmailAttachmentImage
          ...EmailAttachmentDrawer_File
          ...AttachmentDetailsModal
        }
      }
    }
  }
`;

EmailAttachmentsSelectorWithDrawer.fragment = gql`
  fragment EmailAttachmentsSelectorWithDrawer on Project {
    id
    uuid
  }
`;

export default EmailAttachmentsSelectorWithDrawer;
