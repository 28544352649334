// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePopover, useQuery, useResponsive, useRef} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import ButtonGroup from '@shared/design/components/Button/ButtonGroup';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import CustomSurveyTypes from '@shared/modules/Inventory/enums/CustomSurveyTypes';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import SurveyMethodKind from '@shared/modules/Inventory/enums/SurveyMethodKind';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import EditRoomSection from 'modules/Inventory/Edit/components/EditRoomSection';
import MobileInventoryRooms from 'modules/Inventory/Edit/components/MobileInventoryRooms';

const IS_ALL_COLLAPSED = 'IS_ALL_COLLAPSED';
const IS_ALL_EXPANDED = 'IS_ALL_EXPANDED';
const IS_EXPANDED_AND_COLLAPSED = 'IS_EXPANDED_AND_COLLAPSED';

const SectionHeader = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-vertical: 24px;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'isDense' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isDense,
  }) => (isDense ? '16px' : '24px')};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
`;

const HeaderRightContainer = Styled.View`
  flex-direction: row;
`;

const MobileContainer = Styled.View`
  flex-direction: column;
  flex: 1;
`;

const Container = Styled.View`
  flex-direction: column;
  flex: 1;
  flex-grow: 3;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const RoomPopoverContainer = Styled.View`
  max-height: 300px;
  background-color: ${colors.white};
  width: 200px;
  border-radius: 4px;
  padding: 12px 0px;
  border: 1px solid ${colors.gray.border};
  overflow-y: auto;
`;

const RoomOptionContainer = Styled.ButtonV2`
  height: 44px;
  padding: 12px;
`;

const RoomOptionText = Styled.Text`
  ${Typography.Body}
`;

const getFilterOptions = ({inventoryRoomsInfo}: any) => {
  return [
    {
      value: ItemTypeKind.ITEM,
      label: 'Items',
      count: _.toString(inventoryRoomsInfo.itemCount),
    },
    {
      value: ItemTypeKind.CARTON,
      label: 'Cartons',
      count: _.toString(inventoryRoomsInfo.cartonCount),
    },
  ];
};

const isAllRoomsCollapsedOrExpanded = ({inventoryRoomsForm}: any) => {
  let numCollapsed = 0;
  let numExpanded = 0;
  inventoryRoomsForm.values.inventoryRoomsForm.roomItemsForms.forEach((roomItemsForm: any) => {
    if (roomItemsForm.isCollapsed) {
      numCollapsed += 1;
    } else {
      numExpanded += 1;
    }
  });
  if (numCollapsed === 0) {
    return IS_ALL_EXPANDED;
  }
  if (numExpanded === 0) {
    return IS_ALL_COLLAPSED;
  }
  return IS_EXPANDED_AND_COLLAPSED;
};

const handleExpandCollapseAll = ({inventoryRoomsForm, expandCollapsedState}: any) => {
  const collapseAll = expandCollapsedState === IS_ALL_EXPANDED;
  inventoryRoomsForm.values.inventoryRoomsForm.roomItemsForms.forEach(
    (roomItemsForm: any, index: any) => {
      inventoryRoomsForm.setFieldValue(
        `inventoryRoomsForm.roomItemsForms.${index}.isCollapsed`,
        collapseAll,
      );
    },
  );
};

const RoomOption = ({roomType, onPress}: any) => {
  return (
    <RoomOptionContainer onPress={onPress}>
      <RoomOptionText>{roomType.name}</RoomOptionText>
    </RoomOptionContainer>
  );
};

const RoomPopover = ({
  popover,
  inventoryRoomsForm,
  roomTypes,
  inventoryId,
  scrollToBottom,
  setFilteredCategoryIds,
}: any) => {
  return (
    <Popover
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
    >
      <RoomPopoverContainer>
        {roomTypes.map((roomType: any) => {
          return (
            <RoomOption
              key={roomType.id}
              roomType={roomType}
              onPress={() => {
                InventoryRoomsForm.addRoomToInventoryRoomsForm({
                  inventoryId,
                  inventoryRoomsForm,
                  roomType,
                  setFilteredCategoryIds,
                });
                popover.handleClose();
                // setTimeout needed wait until after the new room is added to the view
                setTimeout(scrollToBottom, 0);
              }}
            />
          );
        })}
        <RoomOption
          roomType={CustomSurveyTypes.ROOM}
          onPress={() => {
            InventoryRoomsForm.addRoomToInventoryRoomsForm({
              inventoryId,
              inventoryRoomsForm,
              roomType: CustomSurveyTypes.ROOM,
              setFilteredCategoryIds,
            });
            popover.handleClose();
            // setTimeout needed wait until after the new room is added to the view
            setTimeout(scrollToBottom, 0);
          }}
        />
      </RoomPopoverContainer>
    </Popover>
  );
};

const EditInventoryRoomsSection = ({
  inventoryRoomsForm,
  setFilteredCategoryIds,
  setSearchTerm,
  setActiveRoomRef,
  itemTypeKindFilters,
  setItemTypeKindFilters,
  isResponsiveSurveysVisible,
  handleScrollRoomToBottom,
  mobileInventoryLibrarySheet,
  editRoomAttachmentsSidebar,
  isEnabledSurveysVirtualWalkthroughSupport,
}: any) => {
  const addRoomPopover = usePopover();
  const emptyStateAddRoomPopover = usePopover();
  const roomsSectionRef = useRef(null);
  const responsive = useResponsive();
  const isDense = responsive.mobile || responsive.tablet;
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(EditInventoryRoomsSection.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryUuid: params.inventoryUuid,
    },
  });
  const expandCollapsedState = isAllRoomsCollapsedOrExpanded({inventoryRoomsForm});
  const inventoryRoomsInfo = InventoryRoomsForm.getInfo(
    inventoryRoomsForm.values.inventoryRoomsForm,
  );
  const {selectedRoomIndex, roomItemsForms} = inventoryRoomsForm.values.inventoryRoomsForm;

  return (
    <Loading loading={loading}>
      {() =>
        isResponsiveSurveysVisible ? (
          <MobileContainer>
            <MobileInventoryRooms
              inventoryRoomsForm={inventoryRoomsForm}
              setFilteredCategoryIds={setFilteredCategoryIds}
              setSearchTerm={setSearchTerm}
              itemTypeKindFilters={itemTypeKindFilters}
              setItemTypeKindFilters={setItemTypeKindFilters}
              inventory={data.inventory}
              setActiveRoomRef={setActiveRoomRef}
              handleScrollRoomToBottom={handleScrollRoomToBottom}
              mobileInventoryLibrarySheet={mobileInventoryLibrarySheet}
              viewer={data.viewer}
              editRoomAttachmentsSidebar={editRoomAttachmentsSidebar}
              isEnabledSurveysVirtualWalkthroughSupport={isEnabledSurveysVirtualWalkthroughSupport}
            />
          </MobileContainer>
        ) : (
          <Container>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <SectionHeader isDense={isDense}>
              <HeaderText>Inventory</HeaderText>
              <HeaderRightContainer>
                <ButtonGroup
                  containerStyle={{width: 'fit-content'}}
                  options={getFilterOptions({inventoryRoomsInfo})}
                  selectedOptionValues={itemTypeKindFilters}
                  handleOptionPress={(option) => {
                    setItemTypeKindFilters(_.xor(itemTypeKindFilters, [option]));
                  }}
                  showCounts
                />
                <Space width={isDense ? 8 : 16} />
                <SecondaryButton
                  // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                  text={
                    responsive.large
                      ? expandCollapsedState === IS_ALL_EXPANDED
                        ? 'Collapse All'
                        : 'Expand All'
                      : null
                  }
                  iconLeft={
                    expandCollapsedState === IS_ALL_EXPANDED
                      ? Icon.ArrowsToLine
                      : Icon.ArrowsFromLine
                  }
                  iconSize={12}
                  onPress={() => {
                    handleExpandCollapseAll({inventoryRoomsForm, expandCollapsedState});
                  }}
                />
                <Space width={isDense ? 8 : 16} />
                <Popover.Content innerRef={addRoomPopover.ref}>
                  <SecondaryButton
                    onPress={addRoomPopover.handleToggle}
                    text={'Add Room'}
                    iconLeft={Icon.Plus}
                    iconSize={12}
                    iconRight={Icon.ChevronDown}
                  />
                </Popover.Content>
              </HeaderRightContainer>
            </SectionHeader>
            {_.isEmpty(roomItemsForms.filter((roomItemsForm: any) => !roomItemsForm.isDeleted)) ? (
              <EmptyState.ContentContainer>
                <Popover.Content innerRef={emptyStateAddRoomPopover.ref}>
                  <EmptyState
                    title={'No rooms.'}
                    message={`Add a room and it'll appear here.`}
                    primaryActionIcon={Icon.Plus}
                    primaryActionText={'Add Room'}
                    handlePrimaryAction={emptyStateAddRoomPopover.handleToggle}
                  />
                </Popover.Content>
                <RoomPopover
                  refetch={refetch}
                  popover={emptyStateAddRoomPopover}
                  inventoryRoomsForm={inventoryRoomsForm}
                  roomTypes={data.inventory.project.projectType.defaultInventoryLibrary.roomTypes}
                  inventoryId={data.inventory.id}
                  // @ts-expect-error TS(2531): Object is possibly 'null'.
                  scrollToBottom={() => roomsSectionRef.current.scrollToEnd()}
                  setFilteredCategoryIds={setFilteredCategoryIds}
                />
              </EmptyState.ContentContainer>
            ) : (
              <ScrollView horizontal contentContainerStyle={{flex: 1}}>
                <ScrollView
                  style={{
                    flex: 1,
                    paddingTop: 16,
                    paddingHorizontal: isDense ? 16 : 24,
                    minWidth: 1024,
                  }}
                  ref={roomsSectionRef}
                >
                  {roomItemsForms.map((roomItemsForm: any, index: any) => {
                    if (roomItemsForm.isDeleted) {
                      return null;
                    }
                    return (
                      <React.Fragment key={index}>
                        <EditRoomSection
                          isSelected={selectedRoomIndex === index}
                          onPress={() => {
                            InventoryRoomsForm.handleSelectRoom({
                              inventoryRoomsForm,
                              index,
                              setSearchTerm,
                              setFilteredCategoryIds,
                            });
                          }}
                          index={index}
                          inventoryRoomsForm={inventoryRoomsForm}
                          defaultDensityFactor={
                            data.inventory.project.organization.settings.defaultDensityFactor
                          }
                          itemTypeKindFilters={itemTypeKindFilters}
                          setActiveRoomRef={setActiveRoomRef}
                          isEnabledResponsiveSurveysForDesktop={
                            data.inventory.project.organization.features
                              .isEnabledResponsiveSurveysForDesktop
                          }
                          handleScrollRoomToBottom={handleScrollRoomToBottom}
                          isEnabledSurveysTimeAdditives={
                            data.inventory.project.organization.features
                              .isEnabledSurveysTimeAdditives
                          }
                          isEnabledSurveysItemPhotos={
                            data.inventory.project.organization.features.isEnabledSurveysItemPhotos
                          }
                          organizationId={data.inventory.project.organization.id}
                          viewer={data.viewer}
                          editRoomAttachmentsSidebar={editRoomAttachmentsSidebar}
                          isEnabledSurveysVirtualWalkthroughSupport={
                            isEnabledSurveysVirtualWalkthroughSupport
                          }
                          isVirtualWalkthrough={
                            data.inventory.surveyMethod.kind ===
                            SurveyMethodKind.VIRTUAL_WALKTHROUGH
                          }
                        />
                        <Space height={24} />
                      </React.Fragment>
                    );
                  })}
                </ScrollView>
              </ScrollView>
            )}
            <RoomPopover
              refetch={refetch}
              popover={addRoomPopover}
              inventoryRoomsForm={inventoryRoomsForm}
              roomTypes={data.inventory.project.projectType.defaultInventoryLibrary.roomTypes}
              inventoryId={data.inventory.id}
              // @ts-expect-error TS(2531): Object is possibly 'null'.
              scrollToBottom={() => roomsSectionRef.current.scrollToEnd()}
              setFilteredCategoryIds={setFilteredCategoryIds}
            />
          </Container>
        )
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditInventoryRoomsSection.query = gql`
  ${InventoryRoomsForm.addRoomToInventoryRoomsForm.fragment}
  ${MobileInventoryRooms.fragment}
  ${EditRoomSection.fragment}
  query EditInventoryRoomsSection($inventoryUuid: String!) {
    inventory(uuid: $inventoryUuid) {
      id
      project {
        id
        projectType {
          id
          defaultInventoryLibrary {
            id
            roomTypes {
              id
              name
              ...InventoryRoomsForm_addRoomToInventoryRoomsForm
            }
          }
        }
        organization {
          id
          settings {
            id
            defaultDensityFactor
          }
          features {
            isEnabledResponsiveSurveysForDesktop: isEnabled(
              feature: "RESPONSIVE_SURVEYS_FOR_DESKTOP"
            )
            isEnabledSurveysVirtualWalkthroughSupport: isEnabled(
              feature: "SURVEYS_VIRTUAL_WALKTHROUGH_SUPPORT"
            )
            isEnabledSurveysTimeAdditives: isEnabled(feature: "SURVEYS_TIME_ADDITIVES")
            isEnabledSurveysItemPhotos: isEnabled(feature: "SURVEYS_ITEM_PHOTOS")
          }
        }
      }
      ...MobileInventoryRooms
      ...EditRoomSection_Inventory
    }
    viewer {
      id
      ...EditRoomSection
      ...MobileInventoryRooms_viewer
    }
  }
`;

export default EditInventoryRoomsSection;
