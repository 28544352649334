// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useRef,
  useResponsive,
  useSheet,
  useState,
  Form,
  DrawerType,
  SheetProps,
} from '@supermove/hooks';
import {InventoryModel, UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import Tabs from '@shared/design/components/Tabs';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import SurveyMethodKind from '@shared/modules/Inventory/enums/SurveyMethodKind';
import InventoryRoomsForm, {
  InventoryRoomsFormType,
} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import {RoomItemsFormType} from '@shared/modules/Inventory/forms/RoomItemsForm';
import {GetRefType} from '@shared/utilities/typescript';
import AddRoomSheet from 'modules/Inventory/Edit/components/AddRoomSheet';
import MobileInventoryItem from 'modules/Inventory/Edit/components/MobileInventoryItem';
import MobileInventoryRoomHeader from 'modules/Inventory/Edit/components/MobileInventoryRoomHeader';
import MobileInventoryRoomsSummary from 'modules/Inventory/Edit/components/MobileInventoryRoomsSummary';

const ResponsiveContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const AddRoomButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  border-left-width: 1;
  border-color: ${colors.gray.border};
`;

const ITEM_HEIGHT_SHORT = 140;
const ITEM_HEIGHT_TALL = 180;

const RoomTabs = ({
  roomItemsForms,
  selectedTabIndex,
  inventoryRoomsForm,
  setSearchTerm,
  setFilteredCategoryIds,
  addRoomSheet,
  responsive,
}: any) => {
  return (
    <Row style={{width: '100%', borderBottomWidth: 1, borderColor: colors.gray.border}}>
      <Tabs
        isSpacedTabs={false}
        tabs={[
          {
            icon: Icon.ListUl,
            label: 'Summary',
            roomIndex: 'SUMMARY',
          },
          ...roomItemsForms
            .map((roomItemsForm: any, index: any) =>
              roomItemsForm.isDeleted
                ? null
                : {
                    label: roomItemsForm.name,
                    roomIndex: index,
                  },
            )
            .filter(Boolean),
        ]}
        activeTabIndex={selectedTabIndex}
        handlePressTab={({roomIndex}) => {
          if (roomIndex === 'SUMMARY') {
            inventoryRoomsForm.setFieldValue(`inventoryRoomsForm.selectedRoomIndex`, null);
          } else {
            InventoryRoomsForm.handleSelectRoom({
              inventoryRoomsForm,
              index: roomIndex,
              setSearchTerm,
              setFilteredCategoryIds,
            });
          }
        }}
        tabStyle={{paddingTop: 16, paddingLeft: 24, paddingRight: 24}}
        style={{flex: 1}}
      />
      {responsive.mobile ? null : (
        <AddRoomButton onPress={() => addRoomSheet.handleOpen()}>
          <Icon source={Icon.Plus} size={16} color={colors.blue.interactive} />
        </AddRoomButton>
      )}
    </Row>
  );
};

const EmptyRoom = ({
  selectedTabIndex,
  selectedRoomForm,
  inventoryRoomsForm,
  selectedRoomIndex,
  itemTypeKindFilters,
  setItemTypeKindFilters,
  defaultItemTypeKindFilters,
  editRoomAttachmentsSidebar,
  isEnabledSurveysVirtualWalkthroughSupport,
  isVirtualWalkthrough,
}: {
  selectedTabIndex: number;
  selectedRoomForm: RoomItemsFormType;
  inventoryRoomsForm: Form<{inventoryRoomsForm: InventoryRoomsFormType}>;
  selectedRoomIndex: number;
  itemTypeKindFilters: string[];
  setItemTypeKindFilters: (filters: string[]) => void;
  defaultItemTypeKindFilters: string[];
  editRoomAttachmentsSidebar: DrawerType;
  isEnabledSurveysVirtualWalkthroughSupport: boolean;
  isVirtualWalkthrough: boolean;
}) => {
  return (
    <React.Fragment>
      <MobileInventoryRoomHeader
        key={selectedTabIndex}
        roomItemsForm={selectedRoomForm}
        inventoryRoomsForm={inventoryRoomsForm}
        selectedRoomIndex={selectedRoomIndex}
        itemTypeKindFilters={itemTypeKindFilters}
        setItemTypeKindFilters={setItemTypeKindFilters}
        editRoomAttachmentsSidebar={editRoomAttachmentsSidebar}
        isEnabledSurveysVirtualWalkthroughSupport={isEnabledSurveysVirtualWalkthroughSupport}
        isVirtualWalkthrough={isVirtualWalkthrough}
      />
      <EmptyState.ContentContainer>
        {_.isEqual(itemTypeKindFilters, defaultItemTypeKindFilters) ? (
          <EmptyState title={'No items.'} message={'Tap on an item to add it to this room.'} />
        ) : (
          <EmptyState
            title={'No results.'}
            message={'No results match your selected filters.\nReset all filters and try again.'}
            primaryActionIcon={Icon.Trash}
            primaryActionText={'Reset Filters'}
            handlePrimaryAction={() =>
              setItemTypeKindFilters([ItemTypeKind.ITEM, ItemTypeKind.CARTON])
            }
          />
        )}
      </EmptyState.ContentContainer>
    </React.Fragment>
  );
};

const MobileFooterButtons = ({
  addRoomSheet,
  mobileInventoryLibrarySheet,
  isAddRoom = true,
  isAddItem = true,
}: any) => {
  return (
    <Row style={{padding: 8, borderTopWidth: 1, borderColor: colors.gray.border}}>
      {isAddRoom && (
        <SecondaryButton
          isResponsive
          text={'Add Room'}
          onPress={() => addRoomSheet.handleOpen()}
          isWidthOfContainer
          style={{flex: 1}}
        />
      )}
      {isAddRoom && isAddItem && <Space width={8} />}
      {isAddItem && (
        <Button
          isResponsive
          text={'Add Item'}
          onPress={() => mobileInventoryLibrarySheet.handleOpen()}
          isWidthOfContainer
          style={{flex: 1}}
        />
      )}
    </Row>
  );
};

const MobileInventoryRooms = ({
  inventoryRoomsForm,
  setFilteredCategoryIds,
  setSearchTerm,
  itemTypeKindFilters,
  setItemTypeKindFilters,
  inventory,
  setActiveRoomRef,
  handleScrollRoomToBottom,
  mobileInventoryLibrarySheet,
  viewer,
  editRoomAttachmentsSidebar,
  isEnabledSurveysVirtualWalkthroughSupport,
}: {
  inventoryRoomsForm: Form<{inventoryRoomsForm: InventoryRoomsFormType}>;
  setFilteredCategoryIds: () => void;
  setSearchTerm: () => void;
  itemTypeKindFilters: string[];
  setItemTypeKindFilters: (filters: string[]) => void;
  inventory: InventoryModel;
  setActiveRoomRef: (ref: GetRefType<React.ComponentType>) => void;
  handleScrollRoomToBottom: () => void;
  mobileInventoryLibrarySheet: SheetProps;
  viewer: UserModel;
  editRoomAttachmentsSidebar: DrawerType;
  isEnabledSurveysVirtualWalkthroughSupport: boolean;
}) => {
  const responsive = useResponsive();
  const addRoomSheet = useSheet({name: 'Add Room Sheet'});
  const flatListRef = useRef(null);
  const {selectedRoomIndex, roomItemsForms} = inventoryRoomsForm.values.inventoryRoomsForm;
  const selectedRoomForm = roomItemsForms[selectedRoomIndex];
  const visibleRoomItemsForms = roomItemsForms.filter(
    (roomItemsForm: any) => !roomItemsForm.isDeleted,
  );
  const selectedTabIndex =
    _.findIndex(
      visibleRoomItemsForms || [],
      (roomItemsForm) => (roomItemsForm as any).uuid === selectedRoomForm?.uuid,
    ) + 1; // +1 to account for the summary tab
  // +1 to account for the summary tab
  const filteredItemForms = selectedRoomForm?.itemForms.filter(
    (itemForm: any) => !itemForm.isDeleted && _.includes(itemTypeKindFilters, itemForm.kind),
  );
  const [itemHeight, setItemHeight] = useState(180);
  const [itemContainerWidth, setItemContainerWidth] = useState(0);
  const isTwoColumns = itemContainerWidth > 800;
  const defaultItemTypeKindFilters = [ItemTypeKind.ITEM, ItemTypeKind.CARTON];
  const isVirtualWalkthrough = inventory.surveyMethod.kind === SurveyMethodKind.VIRTUAL_WALKTHROUGH;

  useEffect(() => {
    if (flatListRef) {
      setActiveRoomRef(flatListRef);
    }
  }, [flatListRef, setActiveRoomRef]);

  useEffect(() => {
    if (flatListRef?.current) {
      (flatListRef.current as any).scrollToOffset({animated: false, offset: 0});
    }
  }, [selectedTabIndex]); // eslint-disable-line react-hooks/exhaustive-deps
  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ResponsiveContainer
      onLayout={({nativeEvent}) => {
        const containerWidth = nativeEvent.layout.width;
        setItemContainerWidth(containerWidth);
        if (containerWidth < 552) {
          setItemHeight(ITEM_HEIGHT_TALL);
        } else if (containerWidth < 800) {
          setItemHeight(ITEM_HEIGHT_SHORT);
        } else {
          setItemHeight(ITEM_HEIGHT_TALL);
        }
      }}
    >
      <RoomTabs
        roomItemsForms={roomItemsForms}
        selectedTabIndex={selectedTabIndex}
        inventoryRoomsForm={inventoryRoomsForm}
        setSearchTerm={setSearchTerm}
        setFilteredCategoryIds={setFilteredCategoryIds}
        addRoomSheet={addRoomSheet}
        responsive={responsive}
      />
      {selectedTabIndex === 0 && (
        <React.Fragment>
          <MobileInventoryRoomsSummary
            roomItemsForms={visibleRoomItemsForms}
            itemContainerWidth={itemContainerWidth}
            isTwoColumns={isTwoColumns}
            inventoryRoomsForm={inventoryRoomsForm}
            setFilteredCategoryIds={setFilteredCategoryIds}
            setSearchTerm={setSearchTerm}
            addRoomSheet={addRoomSheet}
            editRoomAttachmentsSidebar={editRoomAttachmentsSidebar}
            isEnabledSurveysVirtualWalkthroughSupport={isEnabledSurveysVirtualWalkthroughSupport}
            isVirtualWalkthrough={isVirtualWalkthrough}
          />
          {responsive.mobile && (
            <MobileFooterButtons
              addRoomSheet={addRoomSheet}
              mobileInventoryLibrarySheet={mobileInventoryLibrarySheet}
              isAddItem={false}
            />
          )}
        </React.Fragment>
      )}
      {selectedRoomForm && (
        <React.Fragment>
          {_.isEmpty(filteredItemForms) ? (
            <EmptyRoom
              selectedTabIndex={selectedTabIndex}
              selectedRoomForm={selectedRoomForm}
              inventoryRoomsForm={inventoryRoomsForm}
              selectedRoomIndex={selectedRoomIndex}
              itemTypeKindFilters={itemTypeKindFilters}
              setItemTypeKindFilters={setItemTypeKindFilters}
              defaultItemTypeKindFilters={defaultItemTypeKindFilters}
              editRoomAttachmentsSidebar={editRoomAttachmentsSidebar}
              isEnabledSurveysVirtualWalkthroughSupport={isEnabledSurveysVirtualWalkthroughSupport}
              isVirtualWalkthrough={isVirtualWalkthrough}
            />
          ) : (
            <FlatList
              // Changing numberOfColumns on the fly is not supported by FlatList.
              // We need to set the key to force a re-render when the number of columns changes.
              key={isTwoColumns}
              ref={flatListRef}
              data={filteredItemForms}
              initialNumToRender={16}
              keyExtractor={(item: any) => item.uuid}
              // @ts-expect-error TS(7031): Binding element 'itemForm' implicitly has an 'any'... Remove this comment to see the full error message
              renderItem={({item: itemForm}) => {
                return (
                  <MobileInventoryItem
                    key={itemForm.uuid}
                    itemForm={itemForm}
                    inventoryRoomsForm={inventoryRoomsForm}
                    roomItemsFormIndex={selectedRoomIndex}
                    defaultDensityFactor={
                      inventory.project.organization.settings.defaultDensityFactor
                    }
                    isEnabledSurveysTimeAdditives={
                      inventory.project.organization.features.isEnabledSurveysTimeAdditives
                    }
                    isEnabledSurveysItemPhotos={
                      inventory.project.organization.features.isEnabledSurveysItemPhotos
                    }
                    style={{
                      width: isTwoColumns ? '50%' : '100%',
                      borderColor: colors.gray.border,
                      borderRightWidth: isTwoColumns ? 1 : 0,
                      borderBottomWidth: 1,
                      height: itemHeight,
                    }}
                    handleScrollRoomToBottom={handleScrollRoomToBottom}
                    isCompact={itemHeight === ITEM_HEIGHT_TALL}
                    viewer={viewer}
                    organizationId={inventory.project.organization.id}
                  />
                );
              }}
              numColumns={isTwoColumns ? 2 : 1}
              ListHeaderComponent={() => (
                <MobileInventoryRoomHeader
                  key={selectedTabIndex}
                  roomItemsForm={selectedRoomForm}
                  inventoryRoomsForm={inventoryRoomsForm}
                  selectedRoomIndex={selectedRoomIndex}
                  itemTypeKindFilters={itemTypeKindFilters}
                  setItemTypeKindFilters={setItemTypeKindFilters}
                  editRoomAttachmentsSidebar={editRoomAttachmentsSidebar}
                  isEnabledSurveysVirtualWalkthroughSupport={
                    isEnabledSurveysVirtualWalkthroughSupport
                  }
                  isVirtualWalkthrough={isVirtualWalkthrough}
                />
              )}
              // These props prevent the keyboard from hiding while scrolling.
              // This issue was occurring primarily on Sheets with nested scroll views.
              keyboardShouldPersistTaps={'always'}
              keyboardDismissMode={'none'}
              // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
              getItemLayout={(data, index) => ({
                length: itemHeight,
                offset: itemHeight * index,
                index,
              })}
            />
          )}
          {responsive.mobile && (
            <MobileFooterButtons
              addRoomSheet={addRoomSheet}
              mobileInventoryLibrarySheet={mobileInventoryLibrarySheet}
            />
          )}
        </React.Fragment>
      )}
      <AddRoomSheet
        sheet={addRoomSheet}
        inventoryRoomsForm={inventoryRoomsForm}
        roomTypes={inventory.project.projectType.defaultInventoryLibrary.roomTypes}
        inventoryId={inventory.id}
        setFilteredCategoryIds={setFilteredCategoryIds}
      />
    </ResponsiveContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileInventoryRooms.fragment = gql`
  ${AddRoomSheet.fragment}
  fragment MobileInventoryRooms on Inventory {
    id
    surveyMethod {
      id
      kind
    }
    project {
      id
      projectType {
        id
        defaultInventoryLibrary {
          id
          roomTypes {
            id
            ...AddRoomSheet
          }
        }
      }
      organization {
        id
        settings {
          id
          defaultDensityFactor
        }
        features {
          isEnabledSurveysVirtualWalkthroughSupport: isEnabled(
            feature: "SURVEYS_VIRTUAL_WALKTHROUGH_SUPPORT"
          )
          isEnabledSurveysTimeAdditives: isEnabled(feature: "SURVEYS_TIME_ADDITIVES")
          isEnabledSurveysItemPhotos: isEnabled(feature: "SURVEYS_ITEM_PHOTOS")
        }
      }
    }
  }

  ${MobileInventoryItem.fragment}
  fragment MobileInventoryRooms_viewer on User {
    id
    ...MobileInventoryItem
  }
`;

export default MobileInventoryRooms;
