// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Inventory, JobModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Table from '@shared/design/components/TableV2Deprecated';
import ItemTagItemKind from '@shared/modules/Inventory/enums/ItemTagItemKind';
import ItemTagKind from '@shared/modules/Inventory/enums/ItemTagKind';

const Container = Styled.View`
`;

const ItemTagsExceptionsContainer = Styled.View`
  flex: 1;
`;

const LotNumberAndColorRow = Styled.View`
  flex-direction: row;
  padding-vertical: 8px;
  padding-horizontal: 12px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: ${colors.gray.border}
`;

const BlackBoldText = Styled.Text`
  ${Typography.Label}
`;

const GrayLabelText = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary};
`;

const Heading = Styled.Text`
  ${Typography.Heading2}
`;

const getItemSummaryTableColumnDefinitions = () => {
  return [
    {
      width: 70,
      headerContent: () => {
        return null;
      },
      cellContent: ({item}: any) => {
        return <Table.CellText numberOfLines={2}>{item.itemNumber}</Table.CellText>;
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return <Table.HeaderText>Item</Table.HeaderText>;
      },
      cellContent: ({item}: any) => {
        return (
          <React.Fragment>
            {item.isVoid && (
              <React.Fragment>
                <Badge
                  textColor={colors.red700}
                  backgroundColor={colors.red50}
                  label={'Void'}
                  size={Badge.SIZE.SMALL}
                  style={{alignSelf: 'center'}}
                />
                <Space width={8} />
              </React.Fragment>
            )}
            <Table.CellText numberOfLines={7}>{item.name}</Table.CellText>
          </React.Fragment>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return <Table.HeaderText>Tags</Table.HeaderText>;
      },
      cellContent: ({item}: any) => {
        if (
          item.itemTagItems.length === 0 ||
          item.itemTagItems.filter(
            (itemTagItem: any) => itemTagItem.kind === ItemTagItemKind.ITEM_TAG,
          ).length === 0
        ) {
          return <Table.CellText>{'-'}</Table.CellText>;
        }
        const itemTagItems = item.itemTagItems.filter(
          (itemTagItem: any) => itemTagItem.kind === ItemTagItemKind.ITEM_TAG,
        );
        return (
          <ItemTagsExceptionsContainer>
            {_.map(itemTagItems, (itemTagItem) => {
              // itemTagItem.itemTags always has length 1 for item tags
              return (
                <Table.CellText numberOfLines={2}>{itemTagItem.itemTags[0].name}</Table.CellText>
              );
            })}
          </ItemTagsExceptionsContainer>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return <Table.HeaderText>Exceptions</Table.HeaderText>;
      },
      cellContent: ({item}: any) => {
        if (
          item.itemTagItems.length === 0 ||
          item.itemTagItems.filter(
            (itemTagItem: any) => itemTagItem.kind === ItemTagItemKind.EXCEPTION,
          ).length === 0
        ) {
          return <Table.CellText>{'-'}</Table.CellText>;
        }
        const itemTagItems = item.itemTagItems.filter(
          (itemTagItem: any) => itemTagItem.kind === ItemTagItemKind.EXCEPTION,
        );
        return (
          <ItemTagsExceptionsContainer>
            {_.map(itemTagItems, (itemTagItem) => {
              const itemTagExceptionLocationNames = _.map(
                itemTagItem.itemTags.filter(
                  (itemTag: any) => itemTag.kind === ItemTagKind.EXCEPTION_LOCATION,
                ),
                (itemTag) => itemTag.name,
              );
              const itemTagExceptionTypeNames = _.map(
                itemTagItem.itemTags.filter(
                  (itemTag: any) => itemTag.kind === ItemTagKind.EXCEPTION_TYPE,
                ),
                (itemTag) => itemTag.name,
              );
              const combinedTags = `${itemTagExceptionLocationNames.join(', ')}${
                itemTagExceptionTypeNames.length > 0 && itemTagExceptionLocationNames.length > 0
                  ? ' - '
                  : ''
              }${itemTagExceptionTypeNames.join(', ')}`;
              return <Table.CellText numberOfLines={10}>{combinedTags}</Table.CellText>;
            })}
          </ItemTagsExceptionsContainer>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return <Table.HeaderText>Notes</Table.HeaderText>;
      },
      cellContent: ({item}: any) => {
        return <Table.CellText>{item.notes ? item.notes : '-'}</Table.CellText>;
      },
    },
  ];
};

const getGroupedItems = (items: any) => {
  const orderedItems = _.orderBy(
    items,
    [
      (item) => !item.lotNumber && !item.color,
      'lotNumber',
      'color',
      (item) => _.toNumber(item.itemNumber),
    ],
    ['asc', 'asc', 'asc', 'asc'],
  );
  return _.groupBy(orderedItems, (item) => {
    // NOTE(cassie): This replaces null with tilde, which is after all alphanumeric characters. This will put null groups last.
    const lotNumber = item.lotNumber ?? '~';
    const color = item.color ?? '~';
    return `${lotNumber}-${color}`;
  });
};

const getItemsWithExceptionsCount = (items: any) => {
  return _.filter(items, (item) => {
    return _.some(item.itemTagItems, (itemTagItem) => {
      return itemTagItem.kind === ItemTagItemKind.EXCEPTION;
    });
  }).length;
};

const NoInventoryTable = () => {
  return (
    <Table
      columnDefinitions={getItemSummaryTableColumnDefinitions()}
      emptyStateText={'No inventory captured for this job.'}
      items={[]}
      itemKey={'id'}
      isDense
      containerStyle={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
      headerStyle={{backgroundColor: colors.gray.background}}
    />
  );
};

const LotAndColorTableHeader = ({items}: any) => {
  const {color, lotNumber} = items[0];
  const numberOfItemsWithExceptions = getItemsWithExceptionsCount(items);
  const colorName = color === null ? '' : Inventory.getColorNameFromHex(color);
  const exceptionsLabel = _.replace(
    `${pluralize('items', numberOfItemsWithExceptions, true)} with exceptions`,
    /\d/g,
    '',
  );
  return (
    <LotNumberAndColorRow>
      <BlackBoldText>{`${lotNumber === null ? '' : `Lot: ${lotNumber}`}${
        lotNumber === null ? `${colorName}` : `, ${colorName}`
      }`}</BlackBoldText>
      <Space style={{flex: 1}} />
      <GrayLabelText>{pluralize('total item', items.length, true)}</GrayLabelText>
      <Space width={24} />
      <BlackBoldText>{numberOfItemsWithExceptions}</BlackBoldText>
      <GrayLabelText>{exceptionsLabel}</GrayLabelText>
    </LotNumberAndColorRow>
  );
};

const LotNumberAndColorTable = ({inventory, isClientInventory}: any) => {
  const groupedItems = isClientInventory
    ? getGroupedItems(inventory.collection.clientInventoryItems)
    : getGroupedItems(inventory.collection.jobInventoryItems);
  return (
    <React.Fragment>
      {_.isEmpty(groupedItems) && <NoInventoryTable />}
      {_.map(groupedItems, (items, key) => {
        return (
          <React.Fragment key={key}>
            <LotAndColorTableHeader items={items} />
            <Table
              key={key}
              columnDefinitions={getItemSummaryTableColumnDefinitions()}
              emptyStateText={'No items'}
              items={items}
              itemKey={'id'}
              isDense
              containerStyle={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              headerStyle={{backgroundColor: colors.gray.background}}
            />
            <Space height={12} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const DocumentV2GeneralInventoryItemsContent = ({inventory, isClientInventory}: any) => {
  return (
    <Container>
      <Heading>Inventory Item Summary</Heading>
      <Space height={12} />
      {!inventory ? (
        <NoInventoryTable />
      ) : (
        <LotNumberAndColorTable inventory={inventory} isClientInventory={isClientInventory} />
      )}
    </Container>
  );
};

const ProjectDocumentV2GeneralInventoryItemsPreview = () => {
  const inventory = MOCK_INVENTORY_DATA;
  return <DocumentV2GeneralInventoryItemsContent inventory={inventory} />;
};

const ProjectDocumentV2GeneralInventoryItemsNonPreview = ({job, isClientInventory}: any) => {
  return (
    <DocumentV2GeneralInventoryItemsContent
      inventory={job.driverInventory}
      isClientInventory={isClientInventory}
    />
  );
};

type BaseProps = {
  isClientInventory?: boolean;
};
type PreviewProps = BaseProps & {
  job?: never;
  isPreview: true;
};
type DocumentProps = BaseProps & {
  job?: JobModel;
  isPreview?: boolean;
};
const ProjectDocumentV2GeneralInventoryItems = ({
  job,
  isPreview = false,
  isClientInventory = false,
}: PreviewProps | DocumentProps) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <ProjectDocumentV2GeneralInventoryItemsPreview />
      ) : (
        <ProjectDocumentV2GeneralInventoryItemsNonPreview
          job={job}
          isClientInventory={isClientInventory}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const ItemFragment = gql`
  fragment ItemFragment on Item {
    id
    name
    itemNumber
    lotNumber
    color
    notes
    isVoid
    itemTagItems {
      id
      kind
      itemTags {
        id
        name
        kind
      }
    }
  }
`;

ProjectDocumentV2GeneralInventoryItems.fragment = gql`
  ${ItemFragment}
  fragment ProjectDocumentV2GeneralInventoryItems on Job {
    id
    driverInventory {
      id
      collection {
        id
        jobInventoryItems: filteredItemsForJobUuids(jobUuids: [$jobUuid]) {
          id
          ...ItemFragment
        }
        clientInventoryItems: filteredItemsForJobUuids(jobUuids: []) {
          id
          ...ItemFragment
        }
      }
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_INVENTORY_DATA = {
  id: 1,
  collection: {
    id: 1,
    jobInventoryItems: [
      {
        id: 1,
        itemNumber: '001',
        name: 'Couch',
        isVoid: false,
        lotNumber: 'ABC123',
        color: Inventory.getColorHexFromName('Purple'),
        notes: '',
        itemTagItems: [
          {
            id: 1,
            kind: ItemTagItemKind.ITEM_TAG,
            itemTags: [
              {
                id: 1,
                name: 'Fragile',
                kind: ItemTagKind.ITEM_TAG,
              },
            ],
          },
          {
            id: 2,
            kind: ItemTagItemKind.ITEM_TAG,
            itemTags: [
              {
                id: 2,
                name: 'Valuable',
                kind: ItemTagKind.ITEM_TAG,
              },
            ],
          },
          {
            id: 3,
            kind: ItemTagItemKind.EXCEPTION,
            itemTags: [
              {
                id: 5,
                name: 'Top',
                kind: ItemTagKind.EXCEPTION_LOCATION,
              },
              {
                id: 6,
                name: 'Left',
                kind: ItemTagKind.EXCEPTION_LOCATION,
              },
              {
                id: 3,
                name: 'Damaged',
                kind: ItemTagKind.EXCEPTION_TYPE,
              },
              {
                id: 4,
                name: 'Cracked',
                kind: ItemTagKind.EXCEPTION_TYPE,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        itemNumber: '001',
        name: 'Table',
        isVoid: false,
        lotNumber: 'XYZ321',
        color: Inventory.getColorHexFromName('Green'),
        notes: '',
        itemTagItems: [],
      },
      {
        id: 3,
        itemNumber: '002',
        name: 'Void Item',
        isVoid: true,
        lotNumber: 'XYZ321',
        color: Inventory.getColorHexFromName('Green'),
        notes: '',
        itemTagItems: [],
      },
    ],
  },
};

export default ProjectDocumentV2GeneralInventoryItems;
