// Libraries

// Explo variable can be any _thing
type ExploVariable = any;

/**
 * When a date or date range is set, Explo returns an object but requires an ISO string as input.
 * Here, we look for variables with timestamps and replace them with ISO strings.
 */
const updateTimestampsToISOStrings = (newVar: ExploVariable): ExploVariable => {
  const updateValueIfTimestamp = (value: ExploVariable): ExploVariable => {
    if (value && typeof value === 'object' && typeof value.ts === 'number') {
      // Found a `ts` field of type number
      return new Date(value.ts).toISOString();
    } else {
      return traverseAndUpdate(value);
    }
  };

  const traverseAndUpdate = (node: ExploVariable): ExploVariable => {
    if (typeof node !== 'object' || node === null || node === undefined) {
      return node; // Base case: Not an object or is null or undefined
    }

    if (Array.isArray(node)) {
      return node.map(traverseAndUpdate);
    }

    const updatedNode = {...node};

    for (const key in updatedNode) {
      if (Object.prototype.hasOwnProperty.call(updatedNode, key)) {
        const value = updatedNode[key];
        updatedNode[key] = updateValueIfTimestamp(value);
      }
    }

    return updatedNode;
  };

  // In case if it is a date (not other object), we update it right away.
  const checkedVar = updateValueIfTimestamp(newVar);
  // Traverse the fields of the object
  return traverseAndUpdate(checkedVar);
};

/**
 * When a multi-select variable is cleared, Explo returns undefined for it.
 * However, if undefined is used for multi-select (array), the dashboard breaks.
 * Instead, we need to check if the cleared variable was an array and set it as empty array instead of undefined.
 */
const updateArrayToEmptyArrayIfNeeded = (prevVar: ExploVariable, newVar: ExploVariable) => {
  return Array.isArray(prevVar) && !newVar ? [] : newVar;
};

const Explo = {
  formatVariable: (prevVar: ExploVariable, newVar: ExploVariable) => {
    const cleanedVar = updateArrayToEmptyArrayIfNeeded(prevVar, newVar);
    return updateTimestampsToISOStrings(cleanedVar);
  },
} as const;

export default Explo;
