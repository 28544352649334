// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  EmailAttachmentModel,
  EmailAttachmentStatus,
  EmailStatus,
  FileModel,
} from '@supermove/models';

// App
import Tag from '@shared/design/components/Tag';
import {getIconFromExtension} from 'modules/Communication/Email/utils/getAttachmentIcon';

const AttachmentsContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

interface CustomerEmailThreadItemAttachmentsProps {
  emailAttachments: EmailAttachmentModel[];
  emailStatus: EmailStatus;
}

// We only have space for 2 attachments, the rest get shown as "+{number}"
const DISPLAY_ATTACHMENT_COUNT = 2;

const AttachmentTag = ({
  file,
  status,
  showErrors,
}: {
  file?: FileModel;
  status: EmailAttachmentStatus;
  showErrors: boolean;
}) => {
  const extension = file?.filename?.split('.').pop();
  const displayError = status === 'ERROR' && showErrors;
  const icon = displayError
    ? Icon.FileCircleExclamation
    : getIconFromExtension(extension?.toLowerCase());
  const displayName =
    file?.name && file.name.length > 10 ? `${file.name.slice(0, 10)}...` : (file?.name ?? '');

  return <Tag label={displayName} iconLeft={icon} hasWarning={displayError} />;
};

const MoreAttachmentsTag = ({
  extraAttachments,
  showError,
}: {
  extraAttachments: EmailAttachmentModel[];
  showError: boolean;
}) => {
  const hasError =
    showError && extraAttachments.some((attachment) => attachment.status === 'ERROR');
  return <Tag label={`+${extraAttachments.length}`} hasWarning={hasError} />;
};

const CustomerEmailThreadItemAttachments = ({
  emailAttachments,
  emailStatus,
}: CustomerEmailThreadItemAttachmentsProps) => {
  const displayAttachments = emailAttachments.slice(0, DISPLAY_ATTACHMENT_COUNT);
  const extraAttachments = emailAttachments.slice(DISPLAY_ATTACHMENT_COUNT);

  return (
    <AttachmentsContainer>
      {displayAttachments.map((emailAttachment) => (
        <React.Fragment key={emailAttachment.id}>
          <AttachmentTag
            file={emailAttachment.attachment?.file}
            status={emailAttachment.status}
            showErrors={emailStatus !== 'ERROR'}
          />
          <Space width={8} />
        </React.Fragment>
      ))}
      {extraAttachments.length > 0 && (
        <MoreAttachmentsTag
          extraAttachments={extraAttachments}
          showError={emailStatus !== 'ERROR'}
        />
      )}
    </AttachmentsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerEmailThreadItemAttachments.fragment = gql`
  fragment CustomerEmailThreadItemAttachments on Email {
    id
    emailAttachments {
      id
      status
      attachment {
        id
        file {
          id
          filename
          name
        }
      }
    }
  }
`;

export default CustomerEmailThreadItemAttachments;
