// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useToast} from '@supermove/hooks';
import {DashboardCategoryModel, DashboardTagModel} from '@supermove/models';
import {existenceFilter} from '@supermove/utils';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardForm from '@shared/modules/Reports/forms/DashboardForm';
import useCreateDashboardMutation from '@shared/modules/Reports/hooks/useCreateDashboardMutation';
import GlobalDashboardsLibraryPageDashboardFields, {
  GlobalDashboardsLibraryPageDashboardFieldsFragment,
} from 'modules/Report/Library/components/GlobalDashboardsLibraryPageDashboardFields';

const GlobalDashboardsLibraryPageCreateDashboardDrawer = ({
  isOpen,
  organizationId,
  handleClose,
}: {
  isOpen: boolean;
  organizationId: string;
  handleClose: () => void;
}) => {
  const {navigator} = useNavigationDOM();
  const {loading, data} = useQuery<{
    dashboardCategories: DashboardCategoryModel[];
    dashboardTags: DashboardTagModel[];
  }>(GlobalDashboardsLibraryPageCreateDashboardDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });
  const createDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard created!',
  });
  const dashboardForm = DashboardForm.new({
    organizationId,
    name: '',
    exploDashboardEmbedId: '',
    exploVariables: '',
  });
  const {form, submitting, handleSubmit} = useCreateDashboardMutation({
    dashboardForm,
    onSuccess: ({dashboard}) => {
      createDashboardSuccessToast.handleToast({
        message: `'${dashboard.name}' created!`,
      });
      handleClose();
      navigator.replace(`/reports/${dashboard.uuid}/view`);
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={!form.dirty}
      headerText={'Add Custom Report'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      isDisabled={loading}
    >
      <Loading loading={!data || loading}>
        {() =>
          data && (
            <GlobalDashboardsLibraryPageDashboardFields
              form={form}
              dashboardCategories={data.dashboardCategories?.filter(existenceFilter) || []}
              dashboardTags={data.dashboardTags?.filter(existenceFilter) || []}
            />
          )
        }
      </Loading>
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalDashboardsLibraryPageCreateDashboardDrawer.query = gql`
  ${GlobalDashboardsLibraryPageDashboardFieldsFragment}
  query GlobalDashboardsLibraryPageCreateDashboardDrawer {
    ${gql.query}
    ...GlobalDashboardsLibraryPageDashboardFields
  }
`;

export default GlobalDashboardsLibraryPageCreateDashboardDrawer;
