// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Tag} from '@supermove/models';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import TagPill from 'modules/Tag/components/TagPill';
import UpsertJobTagModal from 'modules/Tag/components/UpsertJobTagModal';

const Row = Styled.View`
  flex-direction: row;
`;

const JobTagContainer = Styled.View`
`;

const JobTagPill = ({tag, job, refetch, isLast}: any) => {
  return (
    <React.Fragment>
      <TagPill
        tag={tag}
        tagAssignmentId={tag.tagAssignmentId}
        jobId={_.toNumber(job.id)}
        refetch={refetch}
        isSmall
        hasDeleteButton={!job.isFinal}
      />
      <Space width={isLast ? 8 : 4} />
    </React.Fragment>
  );
};

const JobTags = ({job, refetch, responsive}: any) => {
  const upsertJobTagModal = useModal({name: 'Upsert Job Tag Modal', enableTracking: true});
  const tags = Tag.getJobTagAndTagAssignment(job);
  const tagIds = tags.map((tag: any) => tag.id);
  return (
    <JobTagContainer>
      <Row style={{flexWrap: 'wrap', alignItems: 'center'}}>
        {tags.map((tag: any, index: any) => {
          return (
            <JobTagPill
              job={job}
              key={index}
              tag={tag}
              index={index}
              refetch={refetch}
              isLast={index === tags.length - 1}
            />
          );
        })}
        <JobActionDisabledTooltip job={job}>
          <TertiaryButton
            style={{
              marginBottom: responsive.desktop ? 6 : 8,
              marginLeft: responsive.desktop ? 4 : 0,
            }}
            iconLeft={Icon.Plus}
            // @ts-expect-error TS(2322): Type 'false | "Add Job Tag"' is not assignable to ... Remove this comment to see the full error message
            text={tags.length === 0 && 'Add Job Tag'}
            iconSize={responsive.desktop ? 16 : 12}
            isDisabled={job.isFinal || job.isCancelled}
            isHitSlop
            isResponsive
            onPress={upsertJobTagModal.handleOpen}
          />
        </JobActionDisabledTooltip>
        <UpsertJobTagModal
          key={upsertJobTagModal.key}
          job={job}
          tagIds={tagIds}
          refetch={refetch}
          isOpen={upsertJobTagModal.isOpen}
          handleClose={upsertJobTagModal.handleClose}
        />
      </Row>
    </JobTagContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTags.fragment = gql`
  ${Tag.getJobTagAndTagAssignment.fragment}
  ${TagPill.fragment}
  ${UpsertJobTagModal.fragment}
  ${JobActionDisabledTooltip.fragment}

  fragment JobTags on Job {
    id
    isFinal
    isCancelled
    tagAssignmentsByJob {
      id
      tag {
        id
        ...TagPill
      }
    }
    ...Tag_getJobTagAndTagAssignment
    ...UpsertJobTagModal
    ...JobActionDisabledTooltip
  }
`;

export default JobTags;
