// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, PreventPropagation, Space, Styled} from '@supermove/components';
import {PopoverType, Form, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import IconButton from '@shared/design/components/IconButton';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';

const Row = Styled.View`
  flex-direction: row;
`;

const PopoverContainer = Styled.View`
  padding: 12px
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
`;

const EditingPopover = ({
  form,
  field,
  handleSubmit,
  submitting,
  placeholder,
  popover,
  buttonLeftOffset,
}: {
  form: Form;
  field: string;
  handleSubmit: () => void;
  submitting: boolean;
  placeholder: string;
  popover: PopoverType;
  buttonLeftOffset: number;
}) => {
  return (
    <Popover
      reference={popover.ref}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      placement={Popover.Positions.BottomStart}
      offset={[-(4 + buttonLeftOffset), 6]}
    >
      <PopoverContainer>
        <FieldInput
          {...form}
          name={field}
          isResponsive
          input={{
            placeholder,
            autoFocus: true,
            multiline: true,
            style: {height: 160, width: 344, paddingTop: 8, paddingBottom: 8},
          }}
        />
        <Space height={12} />
        <Row>
          <SecondaryButton
            text={'Cancel'}
            onPress={popover.handleClose}
            isDisabled={submitting}
            style={{flex: 1}}
            isWidthOfContainer
          />
          <Space width={8} />
          <Button
            text={'Save'}
            onPress={handleSubmit}
            isSubmitting={submitting}
            style={{flex: 1}}
            isWidthOfContainer
          />
        </Row>
      </PopoverContainer>
    </Popover>
  );
};

const EditingSheet = ({
  form,
  field,
  handleSubmit,
  submitting,
  placeholder,
  sheetTitle,
  popover,
}: {
  form: Form;
  field: string;
  handleSubmit: () => void;
  submitting: boolean;
  placeholder: string;
  sheetTitle: string;
  popover: PopoverType;
}) => {
  return (
    <SheetWithFooter
      isOpen={popover.isOpen}
      headerText={sheetTitle}
      handleClose={popover.handleClose}
      closeText={'Cancel'}
      handlePrimaryAction={handleSubmit}
      primaryActionText={'Save'}
      isSubmitting={submitting}
      shouldCloseOnClickOutside={false}
      isScrollable={false}
      isFixedHeight
      isFixedFooter
      bodyContainerStyle={{flex: 1}}
    >
      <FieldInput
        {...form}
        name={field}
        isResponsive
        input={{
          placeholder,
          autoFocus: true,
          multiline: true,
          style: {width: '100%', paddingTop: 8, paddingBottom: 8, flex: 1},
        }}
        style={{flex: 1}}
      />
    </SheetWithFooter>
  );
};

const EditFieldTextAreaButton = ({
  form,
  field,
  handleSubmit,
  submitting,
  placeholder,
  sheetTitle,
  popover,
  buttonLeftOffset,
  isDisabled,
  style,
}: {
  form: Form;
  field: string;
  handleSubmit: () => void;
  submitting: boolean;
  placeholder: string;
  sheetTitle: string;
  popover: PopoverType;
  buttonLeftOffset: number;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}) => {
  const responsive = useResponsive();
  return (
    <PreventPropagation
      style={
        style ||
        (responsive.desktop
          ? {
              position: 'absolute',
              top: -3,
              left: buttonLeftOffset,
              zIndex: 1000,
            }
          : {
              paddingRight: 2,
            })
      }
    >
      <Popover.Content innerRef={popover.ref}>
        <PreventPropagation>
          <IconButton
            source={Icon.Pen}
            onPress={popover.handleToggle}
            size={responsive.desktop ? 12 : 14}
            isDisabled={isDisabled}
          />
        </PreventPropagation>
      </Popover.Content>
      {responsive.desktop ? (
        <EditingPopover
          form={form}
          field={field}
          handleSubmit={handleSubmit}
          submitting={submitting}
          placeholder={placeholder}
          popover={popover}
          buttonLeftOffset={buttonLeftOffset}
        />
      ) : (
        <EditingSheet
          form={form}
          field={field}
          handleSubmit={handleSubmit}
          submitting={submitting}
          placeholder={placeholder}
          sheetTitle={sheetTitle}
          popover={popover}
        />
      )}
    </PreventPropagation>
  );
};

export default EditFieldTextAreaButton;
