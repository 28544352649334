// Libraries
import _ from 'lodash';

// Supermove
import {useTrackEvent} from '@supermove/hooks';

const useTrackButton = ({name}: {name: string}) => {
  const buttonName = _.startCase(name);

  const {trackEvent} = useTrackEvent({
    eventName: `Pressed ${buttonName}`,
    eventType: 'Button Press',
    // TODO(sam): Add pageName when it is available in navigator
    pageName: '',
  });

  return trackEvent;
};

export default useTrackButton;
