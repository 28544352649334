// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled, SanitizedHTML} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useMountEffect} from '@supermove/hooks';
import {EmailTemplate, EmailTemplateModel, ProjectModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EmailTemplateBodyKind from '@shared/modules/Email/enums/EmailTemplateBodyKind';
import EmailForm from '@shared/modules/Email/forms/EmailForm';
import EmailPreviewForm from '@shared/modules/Email/forms/EmailPreviewForm';
import useEmailPreviewMutation from '@shared/modules/Email/hooks/useEmailPreviewMutation';
import EmailContentSection from 'modules/Email/components/EmailContentSection';
import EmailRecipientsSection from 'modules/Email/components/EmailRecipientsSection';

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const getEmailTemplates = ({form, field, organization, defaultEmailTemplateForm}: any) => {
  const defaultEmailTemplateFormKind = _.get(defaultEmailTemplateForm, 'emailForm.kind');
  const emailTemplates = _.sortBy(organization.allEmailTemplatesSorted, (emailTemplate) =>
    defaultEmailTemplateForm ? emailTemplate.kind !== defaultEmailTemplateFormKind : null,
  );

  // TODO(dan) Remove this conditional section once all email templates come from the backend
  const isDefaultEmailTemplateIncludedInEmailTemplates = !!_.find(
    emailTemplates,
    (emailTemplate) => emailTemplate.kind === defaultEmailTemplateFormKind,
  );
  if (defaultEmailTemplateForm && !isDefaultEmailTemplateIncludedInEmailTemplates) {
    const isCurrentEmailTemplateIdEmpty = _.isNil(_.get(form.values, `${field}.emailTemplateId`));
    if (isCurrentEmailTemplateIdEmpty) {
      // If there is no selected email template, we set it to the custom email template.
      form.setFieldValue(`${field}.emailTemplateId`, -1);
    }
    return [
      EmailTemplate.CUSTOM_EMAIL_TEMPLATE,
      {
        id: -1,
        name: EmailTemplate.getDisplayFromKind(defaultEmailTemplateFormKind),
        ...defaultEmailTemplateForm.emailForm,
      },
      ...emailTemplates,
    ];
  }

  return [EmailTemplate.CUSTOM_EMAIL_TEMPLATE, ...emailTemplates];
};

const getEmailTemplateOptions = ({emailTemplates}: any) => {
  const emailTemplateOptions = emailTemplates.map(({id, name}: any) => ({
    label: name,
    value: id,
  }));
  return emailTemplateOptions;
};

const submitEmailTemplateForPreview = ({
  emailTemplates,
  emailTemplateId,
  emailPreviewForm,
  handleSubmit,
  setIsGeneratingPreview,
}: any) => {
  const emailTemplate = emailTemplates.find(({id}: any) => id === emailTemplateId);
  emailPreviewForm.setFieldValue('emailPreviewForm.emailTemplate', emailTemplate);
  setIsGeneratingPreview && setIsGeneratingPreview(true);
  setTimeout(() => handleSubmit(), 0);
};

const EmailTemplateSection = ({
  form,
  field,
  emailTemplates,
  emailPreviewForm,
  handleEmailPreviewSubmit,
  isSubmitting,
  setIsGeneratingPreview,
}: any) => {
  const options = getEmailTemplateOptions({emailTemplates});

  return (
    <React.Fragment>
      <Label>Email Template</Label>
      <Space height={2} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={1}>
        <FieldInput
          {...form}
          component={DropdownInput}
          isResponsive
          name={`${field}.emailTemplateId`}
          input={{
            options,
            isPortaled: true,
            placeholder: 'Select an email template',
            setFieldValue: (name: any, value: any) => {
              form.setFieldValue(`${field}.emailTemplateId`, value);
              submitEmailTemplateForPreview({
                emailTemplates,
                emailTemplateId: value,
                emailPreviewForm,
                handleSubmit: handleEmailPreviewSubmit,
                setIsGeneratingPreview,
              });
            },
            style: {
              width: '100%',
            },
            isLoading: isSubmitting,
          }}
          style={{
            width: '100%',
          }}
        />
      </Section>
      <Space height={12} />
    </React.Fragment>
  );
};

const ProjectEmailFieldsContent = ({
  form: parentForm,
  field,
  project,
  emailTemplates,
  viewerId,
  setIsGeneratingPreview,
  metadata,
}: {
  form: Form;
  field: string;
  project: ProjectModel;
  emailTemplates: EmailTemplateModel[];
  viewerId: string;
  setIsGeneratingPreview: (isGeneratingPreview: boolean) => void;
  metadata?: string;
}) => {
  const emailPreviewForm = EmailPreviewForm.new({
    projectId: project.id as unknown as number,
    metadata,
  });
  const {form, handleSubmit, submitting} = useEmailPreviewMutation({
    emailPreviewForm,
    onSuccess: ({emailPreview}: any) => {
      const emailTemplate = _.get(form.values, 'emailPreviewForm.emailTemplate');
      const emailForm = EmailForm.toForm(
        EmailForm.newFromProjectWithEmailTemplate({
          viewerId,
          project,
          emailTemplate: {
            ...emailTemplate,
            subject: emailPreview.subject,
            body: emailPreview.body,
          },
          kind: emailTemplate.kind,
        }),
      );
      parentForm.setFieldValue(`${field}`, emailForm);
      setIsGeneratingPreview && setIsGeneratingPreview(false);
    },
    onError: (errors: any) => {
      console.log(errors);
    },
  });

  const parentEmailTemplateId = _.get(parentForm.values, `${field}.emailTemplateId`);

  // on mount, we ensure the selected email template have been evaluated for preview
  useMountEffect(() => {
    const emailTemplateId = parentEmailTemplateId;
    submitEmailTemplateForPreview({
      emailTemplates,
      emailTemplateId,
      emailPreviewForm: form,
      handleSubmit,
      setIsGeneratingPreview,
    });
  });
  const bodyKind = _.get(form.values, 'emailPreviewForm.emailTemplate.bodyKind');

  const displayTemplates = emailTemplates.filter(
    (template) => !template.isDeleted || template.id === parentEmailTemplateId,
  );

  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={1}>
        <EmailTemplateSection
          form={parentForm}
          field={field}
          emailTemplates={displayTemplates}
          emailPreviewForm={form}
          handleEmailPreviewSubmit={handleSubmit}
          isSubmitting={submitting}
          setIsGeneratingPreview={setIsGeneratingPreview}
        />
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={2}>
        <EmailRecipientsSection form={parentForm} field={field} />
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={3}>
        {bodyKind === EmailTemplateBodyKind.HTML ? (
          <React.Fragment>
            <EmailContentSection.Subject field={field} form={parentForm} />
            <Space height={16} />
            <SanitizedHTML rawHTML={_.get(parentForm.values, `${field}.body`)} />
          </React.Fragment>
        ) : (
          <EmailContentSection
            form={parentForm}
            field={field}
            isEnabledEmailRichTextEditorV2={
              project.organization.features.isEnabledEmailRichTextEditorV2
            }
          />
        )}
      </Section>
    </Container>
  );
};

const ProjectEmailFields = ({
  field,
  form,
  viewerId,
  project,
  defaultEmailTemplateForm,
  setIsGeneratingPreview,
  metadata,
}: any) => {
  const {organization} = project.projectType;
  const emailTemplates = getEmailTemplates({
    form,
    field,
    organization,
    defaultEmailTemplateForm,
  });

  return (
    <ProjectEmailFieldsContent
      form={form}
      field={field}
      project={project}
      emailTemplates={emailTemplates}
      viewerId={viewerId}
      setIsGeneratingPreview={setIsGeneratingPreview}
      metadata={metadata}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectEmailFields.fragment = gql`
  ${EmailForm.newFromProjectWithEmailTemplate.fragment}

  fragment ProjectEmailFields on Project {
    id
    projectType {
      id
      organization {
        id
        allEmailTemplatesSorted {
          id
          kind
          name
          isDeleted
          ...EmailForm_newFromProjectWithEmailTemplate_EmailTemplate
        }
      }
    }
    organization {
      id
      features {
        isEnabledEmailRichTextEditorV2: isEnabled(feature: "EMAIL_RICH_TEXT_EDITOR_V2")
      }
    }
    ...EmailForm_newFromProjectWithEmailTemplate_Project
  }
`;

export default ProjectEmailFields;
