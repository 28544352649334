// Libraries
import _ from 'lodash';
import React from 'react';
import {Redirect} from 'react-router-dom';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useNavigationDOM} from '@supermove/hooks';

// Components
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import {Page} from 'modules/App/components';
import JobNotFoundPage from 'modules/Job/components/JobNotFoundPage';

const ShowJobPageContent = ({uuid, job, params, responsive}: any) => {
  const {navigator} = useNavigationDOM();
  const isOverrideV1 = _.includes(Object.keys(params), 'overrideV1');

  // Possibly navigate to the project page v2
  useMountEffect(() => {
    if (job && !isOverrideV1) {
      const {isEnabledProjectPageV2, isEnabledProjectPageMobile} = job.organization.features;

      if (
        (responsive.desktop && isEnabledProjectPageV2) ||
        (responsive.mobile && isEnabledProjectPageMobile) ||
        responsive.tablet
      ) {
        navigator.replace(
          `/projects/${job.project.uuid}/view?block=${ProjectBlockKind.Job.JOB_DETAILS}&jobUuid=${job.uuid}`,
        );
      }
    }
  });

  if (!job) {
    return <JobNotFoundPage />;
  }

  const urlParamString = isOverrideV1 ? '?overrideV1' : '';

  switch (job.primaryStatus) {
    case 'CANCELLED':
    case 'LEAD':
    case 'HOLD':
    case 'NOT_READY':
    case 'READY':
      return <Redirect to={`/jobs/${uuid}/prepare${urlParamString}`} />;
    case 'INCOMPLETE':
    case 'IN_PROGRESS':
      return <Redirect to={`/jobs/${uuid}/in-progress${urlParamString}`} />;
    case 'COMPLETE':
    case 'NOT_FINAL':
    case 'FINAL':
      return <Redirect to={`/jobs/${uuid}/complete${urlParamString}`} />;
    default:
      console.log(`Unknown job primary status: ${job.primaryStatus}.`);
      return null;
  }
};

const ShowJobPage = ({
  match: {
    params: {uuid},
  },
}: any) => (
  <Page variables={{uuid}} query={ShowJobPage.query}>
    {({loading, data, params, responsive}: any) => (
      <Loading loading={loading}>
        {() => (
          <ShowJobPageContent uuid={uuid} job={data.job} params={params} responsive={responsive} />
        )}
      </Loading>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowJobPage.query = gql`
  query ShowJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      primaryStatus: calendarPrimaryStatus
      project {
        id
        uuid
      }
      organization {
        id
        name
        features {
          isEnabledProjectPageV2: isEnabled(feature: "PROJECT_PAGE_V2")
          isEnabledProjectPageMobile: isEnabled(feature: "PROJECT_PAGE_MOBILE")
        }
      }
    }
  }
`;

export default ShowJobPage;
