// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, DragAndDropList, Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {Form, ResponsiveType, useDragAndDrop, useState, useMemo} from '@supermove/hooks';
import {DocumentTemplateModel, JobTypeStepModel, OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import JobTypeStepDocumentTemplateForm, {
  JobTypeStepDocumentTemplateFormType,
} from '@shared/modules/Job/forms/JobTypeStepDocumentTemplateForm';

import JobTypeStepDocumentsSectionItem from './JobTypeStepDocumentsSectionItem';

const SearchDocumentInputContainer = Styled.View`
`;

const DocumentsContainer = Styled.View`
  flex: 1;
`;

const DocumentSectionItemContainer = Styled.View`
  z-index: -1;
`;

const DocumentSectionListEmpty = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const LoadingIndicator = Styled.Loading`
    flex: 1;
    margin-vertical: 20px;
    justify-content: center;
`;

interface DocumentListItemType {
  label: string;
  value: string;
  id: string;
}

const getDocumentListItems = (
  organizationDocumentTemplates: DocumentTemplateModel[],
  documentTemplateKinds: string[],
) => {
  return organizationDocumentTemplates.reduce<DocumentListItemType[]>(
    (filteredDocumentTemplateOptions, documentTemplateOption) => {
      if (
        !documentTemplateKinds.includes(documentTemplateOption.identifier) &&
        documentTemplateOption.category !== DocumentTemplateCategory.INVOICE
      ) {
        return [
          ...filteredDocumentTemplateOptions,
          {
            label: documentTemplateOption.name,
            value: documentTemplateOption.identifier,
            id: documentTemplateOption.id,
          },
        ];
      }
      return filteredDocumentTemplateOptions;
    },
    [],
  );
};

const swapDocumentTemplateKinds = ({
  form,
  documentTemplateKindsField,
  fromIndex,
  toIndex,
  handleSubmit,
}: {
  form: Form<JobTypeStepDocumentTemplateFormType>;
  documentTemplateKindsField: string;
  fromIndex: number;
  toIndex: number;
  handleSubmit: () => void;
}) => {
  const jobTypeStepDocumentTemplateKinds = _.get(form.values, documentTemplateKindsField);
  const reorderedDocumentTemplateKinds = List.move({
    list: jobTypeStepDocumentTemplateKinds,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(documentTemplateKindsField, reorderedDocumentTemplateKinds);
  const jobTypeStepDocumentTemplateForms: JobTypeStepDocumentTemplateFormType[] = _.get(
    form.values,
    'jobTypeStepForm.jobTypeStepDocumentTemplateForms',
  );
  const reorderedDocumentTemplateForms = List.move({
    list: jobTypeStepDocumentTemplateForms,
    fromIndex,
    toIndex,
  });
  reorderedDocumentTemplateForms.forEach((documentTemplateForm, index) => {
    documentTemplateForm.index = index;
  });
  form.setFieldValue(
    'jobTypeStepForm.jobTypeStepDocumentTemplateForms',
    reorderedDocumentTemplateForms,
  );
  setImmediate(handleSubmit);
};

const JobTypeStepDocumentSearch = ({
  form,
  handleSubmit,
  organization,
  documentTemplateKindsField,
  documentTemplateKinds,
  jobTypeStepDocumentTemplateKindsField,
  handleMenuClose,
}: {
  form: Form<any>;
  handleSubmit: () => void;
  organization: any;
  documentTemplateKindsField: string;
  documentTemplateKinds: string[];
  jobTypeStepDocumentTemplateKindsField: string;
  handleMenuClose: () => void;
}) => {
  const jobTypeStepDocumentTemplateForms =
    _.get(form.values, jobTypeStepDocumentTemplateKindsField) || [];

  return (
    <SearchDocumentInputContainer>
      <FieldInput
        {...form}
        component={DropdownInput}
        input={{
          placeholder: 'Search',
          autoFocus: true,
          defaultMenuIsOpen: true,
          isSearchable: true,
          onMenuClose: handleMenuClose,
          name: documentTemplateKindsField,
          options: getDocumentListItems(
            organization.documentTemplatesWithActiveDocumentTemplateVersion,
            documentTemplateKinds,
          ),
          onChangeValue: (value: string, documentOption: {id: string}) => {
            form.setFieldValue(documentTemplateKindsField, [...documentTemplateKinds, value]);
            form.setFieldValue(jobTypeStepDocumentTemplateKindsField, [
              ...jobTypeStepDocumentTemplateForms,
              JobTypeStepDocumentTemplateForm.new({
                jobTypeStepId: form.values.jobTypeStepForm.jobTypeStepId,
                organizationId: organization.id,
                documentTemplateId: documentOption.id,
                index: jobTypeStepDocumentTemplateForms?.length || 0,
              }),
            ]);
            setImmediate(handleSubmit);
            handleMenuClose();
          },
          setFieldValue: form.setFieldValue,
          style: {
            width: '100%',
            borderRadius: 8,
            borderColor: colors.gray.border,
            paddingLeft: 20,
          },
          components: {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          },
        }}
        style={{width: '100%'}}
      />
      <Icon
        source={Icon.Search}
        size={12}
        color={colors.gray.tertiary}
        style={{position: 'absolute', top: 11, left: 12, zIndex: 100}}
      />
    </SearchDocumentInputContainer>
  );
};

const JobTypeStepDocumentSectionContent = ({
  form,
  handleSubmit,
  responsive,
  handleSelectDocumentSection,
  organization,
  jobTypeStep,
}: {
  form: Form<any>;
  handleSubmit: () => void;
  responsive: ResponsiveType;
  handleSelectDocumentSection: (documentTemplate: DocumentTemplateModel) => void;
  organization: OrganizationModel;
  jobTypeStep?: JobTypeStepModel;
}) => {
  const [isAddDocumentsOpen, setIsAddDocumentsOpen] = useState(false);
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const documentTemplateKindsField = 'jobTypeStepForm.documentTemplateKinds';
  const documentTemplateKinds: string[] = _.get(form.values, documentTemplateKindsField) || [];
  const jobTypeStepDocumentTemplateFormsField = 'jobTypeStepForm.jobTypeStepDocumentTemplateForms';

  // Allows us to know the document names even if child API call is loading
  // Ideally the documentTemplateForm would store this info, but it's a string right now which makes it harder to refactor to add data
  const documentTemplateKindToTemplate = useMemo(() => {
    return documentTemplateKinds.reduce<{[identifier: string]: DocumentTemplateModel}>(
      (kindToTemplate, kind) => {
        const documentTemplate =
          organization.documentTemplatesWithActiveDocumentTemplateVersion.find(
            (documentTemplate: DocumentTemplateModel) => documentTemplate.identifier === kind,
          );
        if (documentTemplate) {
          return {
            ...kindToTemplate,
            [kind]: documentTemplate,
          };
        }
        return kindToTemplate;
      },
      {},
    );
  }, [documentTemplateKinds]);

  return (
    <DocumentsContainer>
      <Space height={16} />
      <DocumentSectionItemContainer>
        {documentTemplateKinds.length === 0 && (
          <DocumentSectionListEmpty>
            This step will be enabled once documents are added.
          </DocumentSectionListEmpty>
        )}
        <DragAndDropList
          isReordering={isReordering}
          spaceBetweenItems={10}
          onReorder={({fromIndex, toIndex}) => {
            handleReorderStart();
            swapDocumentTemplateKinds({
              form,
              documentTemplateKindsField,
              fromIndex,
              toIndex,
              handleSubmit,
            });
            handleReorderEnd();
          }}
        >
          {documentTemplateKinds.map((documentTemplateKind: string, index: number) => (
            <JobTypeStepDocumentsSectionItem
              key={index}
              form={form}
              documentTemplate={documentTemplateKindToTemplate[documentTemplateKind]}
              documentTemplateKindsField={documentTemplateKindsField}
              jobTypeStepDocumentTemplateFormsField={jobTypeStepDocumentTemplateFormsField}
              handleSelectDocumentSection={handleSelectDocumentSection}
              handleSubmit={handleSubmit}
              responsive={responsive}
              jobTypeStep={jobTypeStep}
            />
          ))}
        </DragAndDropList>
      </DocumentSectionItemContainer>
      <Space height={8} />
      {isAddDocumentsOpen ? (
        <JobTypeStepDocumentSearch
          form={form}
          handleSubmit={handleSubmit}
          organization={organization}
          documentTemplateKindsField={documentTemplateKindsField}
          documentTemplateKinds={documentTemplateKinds}
          jobTypeStepDocumentTemplateKindsField={jobTypeStepDocumentTemplateFormsField}
          handleMenuClose={() => setIsAddDocumentsOpen(false)}
        />
      ) : (
        <TertiaryButton
          iconLeft={Icon.Plus}
          iconRight={Icon.AngleDown}
          text='Add Document from Library'
          onPress={() => setIsAddDocumentsOpen(true)}
        />
      )}
    </DocumentsContainer>
  );
};

const JobTypeStepDocumentsSection = ({
  form,
  handleSubmit,
  responsive,
  handleSelectDocumentSection,
  jobTypeStepIdField,
  organization,
}: {
  form: Form<any>;
  handleSubmit: () => void;
  responsive: ResponsiveType;
  handleSelectDocumentSection: (documentTemplate: DocumentTemplateModel) => void;
  jobTypeStepIdField: string;
  organization: OrganizationModel;
}) => {
  const jobTypeStepId = _.get(form.values, jobTypeStepIdField);
  const isDataNeeded = jobTypeStepId;
  const {loading, data, refetch} = useQuery(JobTypeStepDocumentsSection.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobTypeStepId,
    },
    // If a step has never had a document added, step won't exist in the DB
    // But it also won't have documents, so we can skip the query
    skip: !isDataNeeded,
  });

  return (
    <Loading loading={loading} as={() => <LoadingIndicator size='small' />}>
      {() => (
        <JobTypeStepDocumentSectionContent
          form={form}
          handleSubmit={async () => {
            await handleSubmit();
            if (isDataNeeded) {
              refetch();
            }
          }}
          responsive={responsive}
          handleSelectDocumentSection={handleSelectDocumentSection}
          organization={organization}
          jobTypeStep={data?.jobTypeStepById}
        />
      )}
    </Loading>
  );
};

JobTypeStepDocumentsSection.query = gql`
${JobTypeStepDocumentsSectionItem.fragment}

query JobTypeStepDocumentsSection($jobTypeStepId: String!) {
  ${gql.query}
  jobTypeStepById(jobTypeStepId: $jobTypeStepId) {
    id
    ...JobTypeStepDocumentsSectionItem
  }
}
`;

// This component uses this fragment because it's the same between all job type steps, and can be found on the JobType
// With this fragment, we can avoid making duplicate calls to get the same document templates
JobTypeStepDocumentsSection.fragment = gql`
  fragment JobTypeStepDocumentsSection on Organization {
    id
    documentTemplatesWithActiveDocumentTemplateVersion {
      id
      name
      identifier
      category
      uuid
    }
  }
`;

export default JobTypeStepDocumentsSection;
