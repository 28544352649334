// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useResponsive, useState, useScrollView, useToast} from '@supermove/hooks';
import {JobModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import Switch from '@shared/design/components/Switch';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectJobsForm from '@shared/modules/Project/forms/ProjectJobsForm';
import useUpdateProjectJobsMutation from '@shared/modules/Project/hooks/useUpdateProjectJobsMutation';
import EditLocationsList from 'modules/Project/V2/Edit/components/EditLocationsList';

type LocationIndexToPositionY = {
  [key: number]: number;
};

const EditJobStopsDrawer = ({
  job,
  isOpen,
  handleClose,
  scrollToIndex,
  refetch,
}: {
  job: JobModel;
  isOpen: boolean;
  handleClose: () => void;
  scrollToIndex?: number;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Job stops updated!',
  });

  const [isReordering, setIsReordering] = useState(false);
  const [locationIndexToPositionY, setLocationIndexToPositionY] =
    useState<LocationIndexToPositionY>({});
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollView = useScrollView();

  const projectJobsForm = ProjectJobsForm.edit(job.project);
  const {form, handleSubmit, submitting} = useUpdateProjectJobsMutation({
    projectJobsForm,
    onSuccess: () => {
      refetch();
      successToast.handleToast();
      handleClose();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const field = `projectJobsForm`;
  const jobIndex = _.findIndex(
    _.get(form.values, `${field}.jobForms`),
    ({jobId: formJobId}: {jobId: string}) => formJobId === job.id,
  );
  const jobFormField = `${field}.jobForms.${jobIndex}`;

  // When viewing a removed job, there will be no jobForm
  const jobForm = _.get(form.values, jobFormField);
  const isFullyRendered =
    jobForm?.locationForms.length === Object.keys(locationIndexToPositionY).length;

  // Possibly scroll to a specific location
  useEffect(() => {
    if (!_.isNil(scrollToIndex) && isFullyRendered && !isScrolled) {
      const y = locationIndexToPositionY[scrollToIndex] - 16;
      scrollView.handleScrollTo({y, animated: true});
      setIsScrolled(true);
    }
  }, [isFullyRendered, isScrolled, locationIndexToPositionY, scrollToIndex, scrollView]);

  // Handle the case where the job is removed
  if (!jobForm) {
    return null;
  }

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      width={520}
      isDisabled={!form.dirty}
      isSubmitting={submitting}
      handleSubmit={() => {
        form.setFieldValue(`${jobFormField}.isUpdated`, form.dirty);
        setImmediate(() => handleSubmit());
      }}
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      shouldCloseOnClickOutside={!form.dirty}
      scrollViewRef={scrollView.ref}
      isClosable={!responsive.desktop}
      headerText={'Edit Job Stops'}
      HeaderRightComponent={() =>
        responsive.desktop ? (
          <Switch
            isOn={isReordering}
            onChange={(value) => setIsReordering(value)}
            labelRight={'Reorder'}
          />
        ) : null
      }
    >
      {!responsive.desktop && (
        <React.Fragment>
          <Switch
            isOn={isReordering}
            onChange={(value) => setIsReordering(value)}
            labelRight={'Reorder'}
            isMobile
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <EditLocationsList
        form={form}
        field={jobFormField}
        jobsField={`${field}.jobForms`}
        project={job.project}
        isReordering={isReordering}
        setLocationIndexToPositionY={setLocationIndexToPositionY}
        scrollToIndex={scrollToIndex}
        isStops
        scrollView={scrollView}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobStopsDrawer.fragment = gql`
  ${EditLocationsList.fragment}
  ${ProjectJobsForm.edit.fragment}

  fragment EditJobStopsDrawer on Job {
    id
    project {
      id
      ...EditLocationsList
      ...ProjectJobsForm_edit
    }
  }
`;

export default EditJobStopsDrawer;
