// Libraries
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

// App

import ShipmentStatus from '@shared/modules/Dispatch/enums/ShipmentStatus';
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import {
  compose,
  employeeLogin,
  page,
  redirectIfAuthenticated,
  redirectIfNotAuthenticated,
  view,
} from 'core/Gates';
import Meta from 'core/Meta';
import ListCompensationReportAccountingPage from 'modules/Accounting/CompensationReport/List/ListCompensationReportAccountingPage';
import PrintCompensationReportAccountingPage from 'modules/Accounting/CompensationReport/Print/PrintCompensationReportAccountingPage';
import ListExportsAccountingPage from 'modules/Accounting/Exports/ListExportsAccountingPage';
import ShowPayrollReportAccountingPage from 'modules/Accounting/PayrollReport/Show/ShowPayrollReportAccountingPage';
import ListContractorPayrollPage from 'modules/Accounting/components/ListContractorPayrollPage';
import ListCrewPayrollPage from 'modules/Accounting/components/ListCrewPayrollPage';
import ListEmployeePayrollPage from 'modules/Accounting/components/ListEmployeePayrollPage';
import ListInvoicesPage from 'modules/Accounting/components/ListInvoicesPage';
import ListSalesPayrollPage from 'modules/Accounting/components/ListSalesPayrollPage';
import LoginAuthentication from 'modules/Authentication/Login';
import PasswordResetAuthentication from 'modules/Authentication/Password/Reset';
import PasswordSetAuthentication from 'modules/Authentication/Password/Set';
import RegisterAuthentication from 'modules/Authentication/Register';
import InviteOrganizationUserSignUpPage from 'modules/Authentication/Signup/InviteOrganizationUserSignUpPage';
import ReportsCalendar from 'modules/Calendar/Reports';
import ListClaimsPage from 'modules/Claim/ListClaimsPage';
import ListClientsPage from 'modules/Client/ListClientsPage';
import ShowClientPage from 'modules/Client/Show/ShowClientPage';
import EmailThreadPage from 'modules/Communication/Email/EmailThreadPage';
import CustomerDocumentShow from 'modules/Customer/Document/Show';
import ReviewInvoicePage from 'modules/Customer/Invoice/Review/ReviewInvoicePage';
import InvoiceSuccessPage from 'modules/Customer/Invoice/Success/InvoiceSuccessPage';
import InvoiceViewPage from 'modules/Customer/Invoice/components/InvoiceViewPage';
import CustomerBillJob from 'modules/Customer/Job/Bill';
import CustomerJobChangeOrder from 'modules/Customer/Job/ChangeOrder';
import CustomerConfirmJob from 'modules/Customer/Job/Confirm';
import CustomerQuoteJob from 'modules/Customer/Job/Quote';
import CustomerReportMoveJob from 'modules/Customer/Job/ReportMove';
import CustomerInvoiceReportMoveJob from 'modules/Customer/Job/ReportMove/Invoice';
import CustomerJobRiskyMove from 'modules/Customer/Job/RiskyMove';
import CustomerTrackJob from 'modules/Customer/Job/Track';
import CustomerNewJobRequest from 'modules/Customer/JobRequest/New';
import CustomerSuccessJobRequest from 'modules/Customer/JobRequest/Success';
import CustomerProjectConfirmation from 'modules/Customer/Project/Confirmation';
import CustomerProjectCardConfirmation from 'modules/Customer/Project/Confirmation/Card';
import CustomerProjectConfirmationDocumentV2 from 'modules/Customer/Project/Confirmation/CustomerProjectConfirmationDocumentV2';
import CustomerProjectDepositConfirmation from 'modules/Customer/Project/Confirmation/Deposit';
import CustomerProjectConfirmationDocument from 'modules/Customer/Project/Confirmation/Document';
import CustomerProjectConfirmationDocumentShow from 'modules/Customer/Project/Confirmation/Document/Show';
import CustomerProjectConfirmationExpired from 'modules/Customer/Project/Confirmation/Expired';
import CustomerProjectConfirmationInventory from 'modules/Customer/Project/Confirmation/Inventory';
import CustomerProjectConfirmationInventoryPrint from 'modules/Customer/Project/Confirmation/Inventory/Print';
import CustomerProjectConfirmationPrint from 'modules/Customer/Project/Confirmation/Print';
import CustomerProjectQuoteConfirmation from 'modules/Customer/Project/Confirmation/Quote';
import CustomerProjectConfirmationSuccess from 'modules/Customer/Project/Confirmation/Success';
import CustomerProjectInventoryPrint from 'modules/Customer/Project/Inventory/Print';
import CustomerProjectQuote from 'modules/Customer/Project/Quote';
import CustomerProjectQuoteDocumentV2 from 'modules/Customer/Project/Quote/CustomerProjectQuoteDocumentV2';
import CustomerProjectQuoteExpired from 'modules/Customer/Project/Quote/Expired';
import CustomerProjectQuoteInfo from 'modules/Customer/Project/Quote/Info';
import CustomerProjectQuoteInventory from 'modules/Customer/Project/Quote/Inventory';
import CustomerProjectQuoteSuccess from 'modules/Customer/Project/Quote/Success';
import ShowDay from 'modules/Day/Show';
import Dispatch from 'modules/Dispatch';
import DayCalendarDispatch from 'modules/Dispatch/Calendar/Day/DayCalendarDispatch';
import DispatchLive from 'modules/Dispatch/Live';
import LongDistanceDispatchPage from 'modules/Dispatch/LongDistance/LongDistanceDispatchPage';
import LongDistanceDispatchTripsListPage from 'modules/Dispatch/LongDistance/LongDistanceDispatchTripsListPage';
import LongDistanceDispatchViewTripPage from 'modules/Dispatch/LongDistance/LongDistanceDispatchViewTripPage';
import TripLongDistanceDispatchPage from 'modules/Dispatch/LongDistance/Trip/TripLongDistanceDispatchPage';
import CustomerDocumentV2Pdf from 'modules/Document/DocumentV2/CustomerDocumentV2Pdf';
import CustomerDocumentV2Show from 'modules/Document/DocumentV2/CustomerDocumentV2Show';
import SharedDocumentPage from 'modules/Document/DocumentV2/SharedDocumentPage';
import SharedLiveDocumentPage from 'modules/Document/DocumentV2/SharedLiveDocumentPage';
import SharedPdfDocumentPage from 'modules/Document/DocumentV2/SharedPdfDocumentPage';
import ComposeEmailPage from 'modules/Email/Project/ComposeEmailPage';
import ListCompensationReportAccountingEmployeePage from 'modules/Employee/Accounting/CompensationReport/List/ListCompensationReportAccountingEmployeePage';
import EmployeeCrewJob from 'modules/Employee/Job/Crew';
import EmployeeDetailsJob from 'modules/Employee/Job/Details';
import EmployeeShowJob from 'modules/Employee/Job/Show';
import EmployeeViewJob from 'modules/Employee/Job/View';
import EmployeeScheduleUser from 'modules/Employee/User/Schedule';
import EmployeeShowUserDay from 'modules/Employee/UserDay/Show';
import Home from 'modules/Home';
import EditInventoryPage from 'modules/Inventory/Edit/EditInventoryPage';
import EditInventoryPageV2 from 'modules/Inventory/Edit/EditInventoryPageV2';
import EditRoomInventoryPage from 'modules/Inventory/Room/Edit/EditRoomInventoryPage';
import CompleteJob from 'modules/Job/Complete';
import SendEmailForJobPage from 'modules/Job/Email/components/SendEmailForJobPage';
import JobEstimateCalendar from 'modules/Job/Estimate/Calendar';
import JobEstimateDay from 'modules/Job/Estimate/Day';
import InProgressJob from 'modules/Job/InProgress';
import EditRoomInventoryJob from 'modules/Job/Inventory/Room/Edit';
import SummaryInventoryJobPage from 'modules/Job/Inventory/Summary/SummaryInventoryJobPage';
import PrepareJob from 'modules/Job/Prepare';
import PrintJob from 'modules/Job/Print';
import CardPrintJob from 'modules/Job/Print/Card';
import InvoiceReportMoveJob from 'modules/Job/ReportMove/Invoice';
import SendReportMoveJob from 'modules/Job/ReportMove/Send';
import ShowJob from 'modules/Job/Show';
import AuditTimesheetJobPage from 'modules/Job/Timesheet/Audit/AuditTimesheetJobPage';
import ConvertJobRequest from 'modules/JobRequest/Convert';
import ListJobRequests from 'modules/JobRequest/List';
import CancelledListJobRequests from 'modules/JobRequest/List/Cancelled';
import NewListJobRequests from 'modules/JobRequest/List/New';
import PendingListJobRequests from 'modules/JobRequest/List/Pending';
import ShowJobRequest from 'modules/JobRequest/Show';
import NotFound from 'modules/NotFound';
import OrganizationSettings from 'modules/Organization/Settings';
import CreateHourlyRateTablePage from 'modules/Organization/Settings/Billing/CreateHourlyRateTablePage';
import EditHourlyRateTablePage from 'modules/Organization/Settings/Billing/EditHourlyRateTablePage';
import OrganizationSettingsBillingFormulasPage from 'modules/Organization/Settings/Billing/Formulas/OrganizationSettingsBillingFormulasPage';
import OrganizationSettingsBillingHourlyRatesPage from 'modules/Organization/Settings/Billing/OrganizationSettingsBillingHourlyRatesPage';
import OrganizationSettingsBillingTablesPage from 'modules/Organization/Settings/Billing/Tables/OrganizationSettingsBillingTablesPage';
import OrganizationSettingsBillingVariablesPage from 'modules/Organization/Settings/Billing/Variables/OrganizationSettingsBillingVariablesPage';
import CreateBillTypePage from 'modules/Organization/Settings/BillingLibraries/BillTypes/CreateBillTypePage';
import UpdateBillTypePage from 'modules/Organization/Settings/BillingLibraries/BillTypes/UpdateBillTypePage';
import BillingLibraryBillItemTypesPage from 'modules/Organization/Settings/BillingLibraries/BillingLibraryBillItemTypesPage';
import BillingLibraryBillRulesPage from 'modules/Organization/Settings/BillingLibraries/BillingLibraryBillRulesPage';
import BillingLibraryBillTypesPage from 'modules/Organization/Settings/BillingLibraries/BillingLibraryBillTypesPage';
import BillingLibraryCompensationItemTypesPage from 'modules/Organization/Settings/BillingLibraries/BillingLibraryCompensationItemTypesPage';
import BillingLibraryCostItemTypesPage from 'modules/Organization/Settings/BillingLibraries/BillingLibraryCostItemTypesPage';
import OrganizationSettingsBillingLibrariesPage from 'modules/Organization/Settings/BillingLibraries/OrganizationSettingsBillingLibrariesPage';
import CreateSmsTemplatesPage from 'modules/Organization/Settings/Communication/CreateSmsTemplatesPage';
import EditSmsTemplatesPage from 'modules/Organization/Settings/Communication/EditSmsTemplatesPage';
import OrganizationSettingsCommunicationPage from 'modules/Organization/Settings/Communication/OrganizationSettingsCommunicationPage';
import OrganizationSettingsCompanyAccountingPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyAccountingPage';
import OrganizationSettingsCompanyBranchesPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyBranchesPage';
import OrganizationSettingsCompanyBrandingPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyBrandingPage';
import OrganizationSettingsCompanyBusinessInfoPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyBusinessInfoPage';
import OrganizationSettingsCompanyClaimsPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyClaimsPage';
import OrganizationSettingsCompanyCrewAppPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyCrewAppPage';
import OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage';
import OrganizationSettingsCompanyIntegrationsAccountingExportsPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyIntegrationsAccountingExportsPage';
import OrganizationSettingsCompanyIntegrationsAccountingGeneralPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyIntegrationsAccountingGeneralPage';
import OrganizationSettingsCompanyIntegrationsAccountingHistoryPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyIntegrationsAccountingHistoryPage';
import OrganizationSettingsCompanyIntegrationsAccountingImportsPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyIntegrationsAccountingImportsPage';
import OrganizationSettingsCompanyIntegrationsAccountingItemsPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyIntegrationsAccountingItemsPage';
import OrganizationSettingsCompanyIntegrationsPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyIntegrationsPage';
import OrganizationSettingsCompanyPaymentsOnboardingPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyPaymentsOnboardingPage';
import OrganizationSettingsCompanyPaymentsPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyPaymentsPage';
import OrganizationSettingsCompanyReferralsPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyReferralsPage';
import OrganizationSettingsCompanyWarehousesPage from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyWarehousesPage';
import DocumentTemplateVersionEditorPage from 'modules/Organization/Settings/Document/DocumentTemplateVersionEditorPage';
import DocumentsSettingsDocumentFlowRunsPage from 'modules/Organization/Settings/Document/DocumentsSettingsDocumentFlowRunsPage';
import DocumentsSettingsPageDocumentFlows from 'modules/Organization/Settings/Document/DocumentsSettingsDocumentFlowsPage';
import OrganizationSettingsDocumentTemplateSettingsPage from 'modules/Organization/Settings/Document/OrganizationSettingsDocumentTemplateSettingsPage';
import CreateDocumentFlowPage from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/CreateDocumentFlowPage';
import EditDocumentFlowPage from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/EditDocumentFlowPage';
import OrganizationSettingsEmail from 'modules/Organization/Settings/Email';
import CreateEmailTemplatePage from 'modules/Organization/Settings/Email/CreateEmailTemplatePage';
import EditEmailTemplatePage from 'modules/Organization/Settings/Email/EditEmailTemplatePage';
import OrganizationSettingsExternal from 'modules/Organization/Settings/External';
import OrganizationSettingsIntegrationsWebhooksPage from 'modules/Organization/Settings/Integrations/OrganizationSettingsIntegrationsWebhooksPage';
import InventoryLibraryCartonsPage from 'modules/Organization/Settings/Inventory/InventoryLibraryCartonsPage';
import InventoryLibraryCategoriesPage from 'modules/Organization/Settings/Inventory/InventoryLibraryCategoriesPage';
import InventoryLibraryExceptionLocationsPage from 'modules/Organization/Settings/Inventory/InventoryLibraryExceptionLocationsPage';
import InventoryLibraryExceptionTypesPage from 'modules/Organization/Settings/Inventory/InventoryLibraryExceptionTypesPage';
import InventoryLibraryItemTagsPage from 'modules/Organization/Settings/Inventory/InventoryLibraryItemTagsPage';
import InventoryLibraryItemsPage from 'modules/Organization/Settings/Inventory/InventoryLibraryItemsPage';
import InventoryLibraryRoomsPage from 'modules/Organization/Settings/Inventory/InventoryLibraryRoomsPage';
import OrganizationSettingsInventoryGeneralSettings from 'modules/Organization/Settings/Inventory/OrganizationSettingsInventoryGeneralSettings';
import OrganizationSettingsInventoryLibrariesSettings from 'modules/Organization/Settings/Inventory/OrganizationSettingsInventoryLibrariesSettings';
import JobTypeGeneralSettingsPage from 'modules/Organization/Settings/JobTypes/JobTypeGeneralSettingsPage';
import JobTypeStepsSettingsPage from 'modules/Organization/Settings/JobTypes/JobTypeStepsSettingsPage';
import JobTypeVariableSettingsPage from 'modules/Organization/Settings/JobTypes/JobTypeVariableSettingsPage';
import OrganizationSettingsJobs from 'modules/Organization/Settings/Jobs';
import MoveSettingsPage from 'modules/Organization/Settings/Moves/components/MoveSettingsPage';
import ProjectSettingsPageCancellations from 'modules/Organization/Settings/Project/ProjectSettingsPageCancellations';
import OrganizationSettingsProjectTypes from 'modules/Organization/Settings/ProjectTypes/OrganizationSettingsProjectTypes';
import ProjectTypeAccountingSettingsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypeAccountingSettingsPage';
import ProjectTypeDocumentFlowsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypeDocumentFlowsPage';
import ProjectTypeDriverInventorySettingsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypeDriverInventorySettingsPage';
import ProjectTypeJobTypesSettingsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypeJobTypesSettingsPage';
import ProjectTypePaymentSettingsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypePaymentSettingsPage';
import ProjectTypeQuotesAndConfirmationsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypeQuotesAndConfirmationsSettingsPage';
import ProjectTypeSurveySettingsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypeSurveySettingsPage';
import ProjectTypeValuationCoverageSettingsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypeValuationCoverageSettingsPage';
import ProjectTypeVariableSettingsPage from 'modules/Organization/Settings/ProjectTypes/ProjectTypeVariableSettingsPage';
import StaffSettingsPage from 'modules/Organization/Settings/Staff/components/StaffSettingsPage';
import ContainerTypeSettingsPage from 'modules/Organization/Settings/Storage/Containers/ContainerTypeSettingsPage';
import StorageSettingsPage from 'modules/Organization/Settings/Storage/StorageSettingsPage';
import WarehouseSettingsPage from 'modules/Organization/Settings/Storage/Warehouses/WarehouseSettingsPage';
import OrganizationSettingsSupplies from 'modules/Organization/Settings/Supplies';
import OrganizationSettingsTasksPage from 'modules/Organization/Settings/Tasks/OrganizationSettingsTasksPage';
import WorkflowBuilderPage from 'modules/Organization/Settings/Workflow/Builder/WorkflowBuilderPage';
import OrganizationSettingsWorkflowRunsPage from 'modules/Organization/Settings/Workflow/OrganizationSettingsWorkflowRunsPage';
import OrganizationSettingsWorkflowSettingsPage from 'modules/Organization/Settings/Workflow/OrganizationSettingsWorkflowSettingsPage';
import PaymentsPage from 'modules/Payment/PaymentsPage';
import ShowPayableUserCrewPayroll from 'modules/Payroll/Crew/PayableUser/Show';
import SalesPayroll from 'modules/Payroll/Sales';
import ShowPayableUserSalesPayroll from 'modules/Payroll/Sales/PayableUser/Show';
import ProjectAttachmentPage from 'modules/Project/Attachments/ProjectAttachmentPage';
import EditBillBillingProjectPage from 'modules/Project/Billing/Bill/Edit/EditBillBillingProjectPage';
import ConfirmationProject from 'modules/Project/Confirmation';
import CreateDocumentFlowRunPage from 'modules/Project/DocumentFlow/CreateDocumentFlowRunPage';
import EditProjectJob from 'modules/Project/Job/Edit';
import MovesPage from 'modules/Project/List/MovesPage';
import NewProject from 'modules/Project/New';
import QuoteProject from 'modules/Project/Quote';
import ShowProject from 'modules/Project/Show';
import EditProjectAndJobTypesForProjectPage from 'modules/Project/V2/Edit/EditProjectAndJobTypesForProjectPage';
import EditProjectJobsPage from 'modules/Project/V2/Edit/EditProjectJobsPage';
import ShowProjectV2Page from 'modules/Project/V2/Show/ShowProjectV2Page';
import DashboardsPage from 'modules/Report/DashboardsPage';
import GlobalDashboardsLibraryPage from 'modules/Report/Library/GlobalDashboardsLibraryPage';
import MetabaseReport from 'modules/Report/Metabase';
import EditBillsReportMove from 'modules/ReportMove/Bills/Edit';
import EditMoveUsersReportMove from 'modules/ReportMove/MoveUsers/Edit';
import ShowReportMove from 'modules/ReportMove/Show';
import EditTimeRangesReportMove from 'modules/ReportMove/TimeRanges/Edit';
import ShowEmployeeSchedules from 'modules/Schedule';
import InvoicePage from 'modules/Storage/InvoicePage';
import ListStorageProjectsPage from 'modules/Storage/ListStorageProjectsPage';
import StorageAssignContainersToProjectPage from 'modules/Storage/StorageAssignContainersToProjectPage';
import StorageContainersPage from 'modules/Storage/StorageContainersPage';
import StorageProjectPage from 'modules/Storage/StorageProjectPage';
import StorageShipmentsPage from 'modules/Storage/StorageShipmentsPage';
import TasksListPage from 'modules/TaskManagement/Tasks/List/TasksListPage';
import UserSettingsCommunicationEmailPage from 'modules/User/Settings/Communication/Email/UserSettingsCommunicationEmailPage';
import UserSettingsProfileNotificationsPage from 'modules/User/Settings/Profile/Notifications/UserSettingsProfileNotificationsPage';
import UserSettingsProfilePersonalPage from 'modules/User/Settings/Profile/Personal/UserSettingsProfilePersonalPage';

const ManagerRouter = ({context}: any) => (
  <Switch>
    <Route exact path={'/calendar'} render={compose(context, page('Calendar'), view(MovesPage))} />
    <Route
      exact
      path={'/jobs/estimates/calendar'}
      render={compose(context, page('Job Estimate Calendar'), view(JobEstimateCalendar))}
    />
    <Route
      exact
      path={'/jobs/estimates/calendar/:date'}
      render={compose(context, page('Job Estimate Day'), view(JobEstimateDay))}
    />

    {/* Capacity Calendar */}
    <Redirect exact from={'/moves/capacity/calendar'} to={`/moves/capacity/calendar/month`} />
    <Route
      exact
      path={'/moves/capacity/calendar/month'}
      render={compose(context, page('Capacity Calendar Month View'), view(MovesPage))}
    />
    <Route
      exact
      path={'/moves/capacity/calendar/day'}
      render={compose(context, page('Capacity Calendar Day View'), view(MovesPage))}
    />

    {/* Tasks */}
    <Redirect exact from={'/tasks'} to={'/tasks/me?isCompleted=false'} />
    <Route
      exact
      path={'/tasks/:tab'}
      render={compose(context, page('List Tasks'), view(TasksListPage))}
    />

    {/* Clients */}
    <Route
      exact
      path={'/clients/customers'}
      render={compose(context, page('List Clients'), view(ListClientsPage))}
    />
    <Redirect exact from={'/clients'} to={'/clients/customers'} />
    <Route
      exact
      path={'/clients/:clientUuid'}
      render={compose(context, page('Show Client'), view(ShowClientPage))}
    />

    {/* Booking */}
    <Route
      exact
      path={'/booking/calendar'}
      render={compose(context, page('Booking Calendar'), view(MovesPage))}
    />

    {/* Live */}
    <Route exact path={'/live'} render={compose(context, page('Live'), view(DispatchLive))} />

    {/* Dispatch */}
    <Route exact path={'/dispatch'} render={compose(context, page('Dispatch'), view(Dispatch))} />
    <Route
      exact
      path={'/dispatch/calendar/day/:date'}
      render={compose(context, page('Dispatch Calendar Day'), view(DayCalendarDispatch))}
    />
    <Redirect
      exact
      from={'/dispatch/long-distance/shipments'}
      to={`/dispatch/long-distance/shipments/${ShipmentStatus.UNPLANNED.toLowerCase()}?page=1`}
    />
    <Redirect
      exact
      from={'/dispatch/long-distance/trips'}
      to={`/dispatch/long-distance/trips/${TripStatus.ACTIVE.toLowerCase()}?page=1`}
    />
    <Route
      exact
      path={'/dispatch/long-distance/shipments/:subTab'}
      render={compose(
        context,
        page('Dispatch Long Distance Shipments'),
        view(LongDistanceDispatchPage),
      )}
    />
    <Route
      exact
      path={'/dispatch/long-distance/trips/:subTab'}
      render={compose(
        context,
        page('Dispatch Long Distance Trips'),
        view(LongDistanceDispatchPage),
      )}
    />
    <Route
      exact
      path={'/dispatch/long-distance/trips/:tripUuid/:tab'}
      render={compose(context, page('Show Trip'), view(TripLongDistanceDispatchPage))}
    />

    {/* Reports */}
    <Route
      exact
      path={'/reports'}
      render={compose(context, page('Reports'), view(DashboardsPage))}
    />
    <Route
      exact
      path={'/reports/:dashboardUuid/view'}
      render={compose(context, page('Reports View'), view(DashboardsPage))}
    />
    <Route
      exact
      path={'/reports/library'}
      render={compose(context, page('Reports Library'), view(GlobalDashboardsLibraryPage))}
    />
    <Route
      exact
      path={'/reports/calendar'}
      render={compose(context, page('Reports Calendar'), view(ReportsCalendar))}
    />
    <Route
      exact
      path={'/reports/:slug'}
      render={compose(context, page('Metabase Report'), view(MetabaseReport))}
    />
    <Redirect exact from='/reports/dashboards/:uuid' to='/reports/:uuid/view' />

    {/* Payroll */}
    <Route
      exact
      path={'/payroll/crew/:uuid'}
      render={compose(
        context,
        page('Show Payable User Crew Payroll'),
        view(ShowPayableUserCrewPayroll),
      )}
    />
    <Route
      exact
      path={'/payroll/sales'}
      render={compose(context, page('Sales Payroll'), view(SalesPayroll))}
    />
    <Route
      exact
      path={'/payroll/sales/:uuid'}
      render={compose(
        context,
        page('Show Payable User Sales Payroll'),
        view(ShowPayableUserSalesPayroll),
      )}
    />

    {/* Payroll Reports */}
    <Route
      exact
      path={'/accounting/payroll-reports/:payrollReportUuid'}
      render={compose(context, page('Show Payroll Report'), view(ShowPayrollReportAccountingPage))}
    />
    <Route
      exact
      path={'/accounting/compensation-reports'}
      render={compose(
        context,
        page('List Compensation Reports'),
        view(ListCompensationReportAccountingPage),
      )}
    />
    <Redirect exact from='/accounting/exports' to='/accounting/invoices' />
    <Route
      exact
      path={'/accounting/:uuid/exports'}
      render={compose(context, page('Accounting Exports'), view(ListExportsAccountingPage))}
    />

    {/* Days */}
    <Route
      exact
      path={'/manage/:date'}
      render={compose(context, page('Show Day'), view(ShowDay))}
    />

    {/* Job Requests - List */}
    <Route
      exact
      path={'/jobs/requests'}
      render={compose(context, page('List Job Requests'), view(ListJobRequests))}
    />
    <Route
      exact
      path={'/jobs/requests/cancelled'}
      render={compose(context, page('Cancelled List Job Requests'), view(CancelledListJobRequests))}
    />
    <Route
      exact
      path={'/jobs/requests/new'}
      render={compose(context, page('New List Job Requests'), view(NewListJobRequests))}
    />
    <Route
      exact
      path={'/jobs/requests/pending'}
      render={compose(context, page('Pending List Job Requests'), view(PendingListJobRequests))}
    />

    {/* Job Requests - Show */}
    <Route
      exact
      path={'/jobs/requests/:uuid'}
      render={compose(context, page('Show Job Request'), view(ShowJobRequest))}
    />
    <Route
      exact
      path={'/jobs/requests/:uuid/convert'}
      render={compose(context, page('Convert Job Request'), view(ConvertJobRequest))}
    />

    {/* Jobs */}
    <Route exact path={'/jobs/:uuid'} render={compose(context, page('Show Job'), view(ShowJob))} />
    <Route
      exact
      path={'/jobs/:uuid/prepare'}
      render={compose(context, page('Prepare Job'), view(PrepareJob))}
    />
    <Route
      exact
      path={'/jobs/:uuid/in-progress'}
      render={compose(context, page('In Progress Job'), view(InProgressJob))}
    />
    <Route
      exact
      path={'/jobs/:uuid/complete'}
      render={compose(context, page('Complete Job'), view(CompleteJob))}
    />
    <Route
      exact
      path={'/jobs/:uuid/move-report/send'}
      render={compose(context, page('Send Report Move Job'), view(SendReportMoveJob))}
    />
    <Route
      exact
      path={'/jobs/:uuid/invoice/send'}
      render={compose(context, page('Send Invoice Job'), view(InvoiceReportMoveJob))}
    />
    <Route
      exact
      path={'/jobs/:jobUuid/timesheet/audit'}
      render={compose(context, page('Audit Timesheet Job'), view(AuditTimesheetJobPage))}
    />
    <Route
      exact
      path={'/jobs/:jobUuid/email/send'}
      render={compose(context, page('Send Email Job'), view(SendEmailForJobPage))}
    />
    {/* Accounting */}
    <Route
      exact
      path={'/:projectKind/projects/:projectUuid/invoices/new'}
      render={compose(context, page('Create Invoice Page'), view(InvoicePage))}
    />
    <Route
      exact
      path={'/:projectKind/projects/:projectUuid/invoices/:invoiceUuid'}
      render={compose(context, page('Edit Invoice Page'), view(InvoicePage))}
    />

    {/* Storage */}
    <Redirect exact from={'/storage'} to={'/storage/list'} />
    <Route
      exact
      path={'/storage/list'}
      render={compose(context, page('Storage Projects List Page'), view(ListStorageProjectsPage))}
    />
    <Route
      exact
      path={'/storage/shipments'}
      render={compose(context, page('Storage Shipment Page'), view(StorageShipmentsPage))}
    />
    <Route
      exact
      path={'/storage/containers'}
      render={compose(context, page('Storage Container Page'), view(StorageContainersPage))}
    />
    <Route
      exact
      path={'/storage/projects/:projectUuid'}
      render={compose(context, page('Storage Project Page'), view(StorageProjectPage))}
    />
    <Route
      exact
      path={'/storage/containers/assign-to-project/:projectUuid'}
      render={compose(
        context,
        page('Storage Assign Containers to Project Page'),
        view(StorageAssignContainersToProjectPage),
      )}
    />
    <Route
      exact
      path={'/invoices/:invoiceUuid/view'}
      render={compose(context, page('Customer - Project Invoice View'), view(InvoiceViewPage))}
    />

    {/* Accounting Tab */}
    <Redirect exact from={'/accounting/invoices'} to={'/accounting/invoices/move'} />
    <Route
      exact
      path={'/accounting/invoices/move'}
      render={compose(context, page('Accounting Move Invoices'), view(ListInvoicesPage))}
    />
    <Route
      exact
      path={'/accounting/invoices/storage'}
      render={compose(context, page('Accounting Storage Invoices'), view(ListInvoicesPage))}
    />
    <Route
      exact
      path={'/accounting/employee-payroll'}
      render={compose(context, page('Accounting Employee Payroll'), view(ListEmployeePayrollPage))}
    />
    <Route
      exact
      path={'/accounting/contractor-payroll'}
      render={compose(
        context,
        page('Accounting Contractor Payroll'),
        view(ListContractorPayrollPage),
      )}
    />
    <Route
      exact
      path={'/accounting/sales-payroll'}
      render={compose(context, page('Accounting Sales Payroll'), view(ListSalesPayrollPage))}
    />
    <Route
      exact
      path={'/accounting/crew-payroll'}
      render={compose(context, page('Accounting Crew Payroll'), view(ListCrewPayrollPage))}
    />
    <Redirect exact from='/accounting/reports' to='/reports' />

    {/* Payments Tab */}
    <Redirect exact from={'/payments'} to={'/payments/dashboard'} />
    <Route
      exact
      path={'/payments/dashboard'}
      render={compose(context, page('Payment Dashboard'), view(PaymentsPage))}
    />
    <Route
      exact
      path={'/payments/transactions'}
      render={compose(context, page('Payment Transactions'), view(PaymentsPage))}
    />
    <Route
      exact
      path={'/payments/batches'}
      render={compose(context, page('Payment Batches'), view(PaymentsPage))}
    />
    <Route
      exact
      path={'/payments/payouts'}
      render={compose(context, page('Payment Payouts'), view(PaymentsPage))}
    />
    <Route
      exact
      path={'/payments/statements'}
      render={compose(context, page('Payment Statements'), view(PaymentsPage))}
    />
    <Route
      exact
      path={'/payments/disputes'}
      render={compose(context, page('Payment Disputes'), view(PaymentsPage))}
    />

    {/* Claims Tab */}
    <Route exact path={'/claims'} render={compose(context, page('Claims'), view(ListClaimsPage))} />

    {/* Inventory */}
    <Route
      exact
      path={'/inventories/:uuid/edit'}
      render={compose(context, page('Edit Inventory'), view(EditInventoryPage))}
    />
    <Route
      exact
      path={'/projects/:projectUuid/inventory/:inventoryUuid'}
      render={compose(context, page('Edit Inventory Page V2'), view(EditInventoryPageV2))}
    />
    <Route
      exact
      path={'/inventories/:inventoryUuid/rooms/:uuid/edit'}
      render={compose(context, page('Edit Room Inventory'), view(EditRoomInventoryPage))}
    />

    {/* Jobs / Inventory */}
    <Route
      exact
      path={'/jobs/:jobUuid/inventories/:uuid/rooms/:roomUuid/edit'}
      render={compose(context, page('Edit Room Inventory Job'), view(EditRoomInventoryJob))}
    />
    <Route
      exact
      path={'/jobs/:jobUuid/inventories/:uuid/summary'}
      render={compose(context, page('Summary Inventory Job'), view(SummaryInventoryJobPage))}
    />

    {/* Projects */}
    <Route
      exact
      path={'/projects/new'}
      render={compose(context, page('New Project'), view(NewProject))}
    />
    <Route
      exact
      path={'/jobs/:uuid/edit'}
      render={compose(context, page('Edit Job'), view(EditProjectJob))}
    />
    <Route
      exact
      path={'/projects/:uuid/quote'}
      render={compose(context, page('Quote Project'), view(QuoteProject))}
    />
    <Route
      exact
      path={'/projects/:uuid/confirmation'}
      render={compose(context, page('Confirmation Project'), view(ConfirmationProject))}
    />
    <Route
      exact
      path={'/projects/:projectUuid/document-flows/:userFlowUuid/new'}
      render={compose(context, page('New Document Flow'), view(CreateDocumentFlowRunPage))}
    />
    <Route
      exact
      path={'/projects/billing/bills/:billUuid/edit'}
      render={compose(context, page('Edit Bill Billing Project'), view(EditBillBillingProjectPage))}
    />
    <Route
      exact
      path={'/projects/:projectUuid/attachments/:attachmentUuid'}
      render={compose(context, page('Project Attachment'), view(ProjectAttachmentPage))}
    />

    {/* List Moves Projects */}
    <Route
      exact
      path={'/moves/projects'}
      render={compose(context, page('Move Projects List'), view(MovesPage))}
    />

    {/* TODO(dan) Update this route and all redirects once isEnabledResponsiveProjectsList is fully rolled out */}
    <Route
      exact
      path={'/moves/list'}
      render={compose(context, page('List Moves'), view(MovesPage))}
    />
    <Redirect exact from={'/projects/leads'} to={'/moves/list'} />
    <Redirect exact from={'/projects/holds'} to={'/moves/list'} />
    <Redirect exact from={'/projects/booked'} to={'/moves/list'} />
    <Redirect exact from={'/projects/cancelled'} to={'/moves/list'} />
    <Redirect exact from={'/projects/completed'} to={'/moves/list'} />

    {/* Project Page */}
    <Route
      exact
      path={'/projects/:uuid'}
      render={compose(context, page('Show Project'), view(ShowProject))}
    />
    <Route
      exact
      path={'/projects/:projectUuid/view'}
      render={compose(context, page('View Project V2'), view(ShowProjectV2Page))}
    />
    <Route
      exact
      path={'/projects/:projectUuid/edit/jobs'}
      render={compose(context, page('Edit Project Jobs'), view(EditProjectJobsPage))}
    />
    <Route
      exact
      path={'/projects/:projectUuid/edit/types'}
      render={compose(
        context,
        page('Edit Types for Project'),
        view(EditProjectAndJobTypesForProjectPage),
      )}
    />
    <Route
      exact
      path={'/projects/:projectUuid/emails/:threadUuid'}
      render={compose(context, page('View Thread'), view(EmailThreadPage))}
    />
    <Route
      exact
      path={'/projects/:projectUuid/email/compose'}
      render={compose(context, page('Compose Email'), view(ComposeEmailPage))}
    />

    {/* Reports Route */}
    <Route
      exact
      path={'/move-reports/:uuid/show'}
      render={compose(context, page('Show Move Report'), view(ShowReportMove))}
    />
    <Route
      exact
      path={'/move-reports/:uuid/bills/edit'}
      render={compose(context, page('Edit Bills Move Report'), view(EditBillsReportMove))}
    />
    <Route
      exact
      path={'/move-reports/:uuid/hours/edit'}
      render={compose(context, page('Edit Hours Move Report'), view(EditMoveUsersReportMove))}
    />
    <Route
      exact
      path={'/move-reports/:uuid/timesheet/edit'}
      render={compose(context, page('Edit Timesheet Move Report'), view(EditTimeRangesReportMove))}
    />

    {/* User Settings */}
    <Route
      exact
      path={'/account/profile/personal-information'}
      render={compose(
        context,
        page('User Settings Profile Personal'),
        view(UserSettingsProfilePersonalPage),
      )}
    />
    <Redirect exact from={'/account/notifications'} to={'/account/profile/notifications'} />
    <Route
      exact
      path={'/account/profile/notifications'}
      render={compose(
        context,
        page('User Settings Profile Notifications'),
        view(UserSettingsProfileNotificationsPage),
      )}
    />
    <Route
      exact
      path={'/account/communication/email'}
      render={compose(
        context,
        page('User Settings Communication Email'),
        view(UserSettingsCommunicationEmailPage),
      )}
    />

    {/* Settings Route */}
    <Route
      exact
      path={'/settings'}
      render={compose(context, page('Organization Settings'), view(OrganizationSettings))}
    />
    <Route
      exact
      path={'/settings/company/branches'}
      render={compose(
        context,
        page('Organization Settings Branches'),
        view(OrganizationSettingsCompanyBranchesPage),
      )}
    />
    <Redirect exact from={'/settings/company/contractors'} to={'/settings/company/branches'} />
    <Route
      exact
      path={'/settings/company/business-info'}
      render={compose(
        context,
        page('Company Settings Business Info'),
        view(OrganizationSettingsCompanyBusinessInfoPage),
      )}
    />
    <Route
      exact
      path={'/settings/email'}
      render={compose(
        context,
        page('Organization Settings Email'),
        view(OrganizationSettingsEmail),
      )}
    />
    <Route
      exact
      path={'/settings/email/email-templates/new'}
      render={compose(
        context,
        page('Organization Settings Email Create Email Template'),
        view(CreateEmailTemplatePage),
      )}
    />
    <Route
      exact
      path={'/settings/email/email-templates/:emailTemplateUuid/edit'}
      render={compose(
        context,
        page('Organization Settings Email Edit Email Template'),
        view(EditEmailTemplatePage),
      )}
    />
    <Route
      exact
      path={'/settings/communication/:tab'}
      render={compose(
        context,
        page('Organization Settings Communication'),
        view(OrganizationSettingsCommunicationPage),
      )}
    />
    <Route
      exact
      path={'/settings/communication/:tab/create'}
      render={compose(
        context,
        page('Organization Settings Email Create SMS Template'),
        view(CreateSmsTemplatesPage),
      )}
    />
    <Route
      exact
      path={'/settings/communication/:tab/:textMessageTemplateUuid'}
      render={compose(
        context,
        page('Organization Settings Email Edit SMS Template'),
        view(EditSmsTemplatesPage),
      )}
    />
    <Route
      exact
      path={'/settings/external'}
      render={compose(
        context,
        page('Organization Settings External'),
        view(OrganizationSettingsExternal),
      )}
    />
    {/* Inventory Settings */}
    <Redirect exact from={'/settings/inventory'} to={'/settings/inventory/general'} />
    <Route
      exact
      path={'/settings/inventory/general'}
      render={compose(
        context,
        page('Inventory General Settings Page'),
        view(OrganizationSettingsInventoryGeneralSettings),
      )}
    />
    <Route
      exact
      path={'/settings/inventory/libraries'}
      render={compose(
        context,
        page('Inventory Libraries Settings Page'),
        view(OrganizationSettingsInventoryLibrariesSettings),
      )}
    />
    <Redirect
      exact
      from={'/settings/inventory/libraries/:libraryUuid'}
      to={'/settings/inventory/libraries/:libraryUuid/categories'}
    />
    <Route
      exact
      path={'/settings/inventory/libraries/:libraryUuid/categories'}
      render={compose(
        context,
        page('Inventory Library Categories Page'),
        view(InventoryLibraryCategoriesPage),
      )}
    />
    <Route
      exact
      path={'/settings/inventory/libraries/:libraryUuid/rooms'}
      render={compose(
        context,
        page('Inventory Library Rooms Page'),
        view(InventoryLibraryRoomsPage),
      )}
    />
    <Route
      exact
      path={'/settings/inventory/libraries/:libraryUuid/cartons'}
      render={compose(
        context,
        page('Inventory Library Cartons Page'),
        view(InventoryLibraryCartonsPage),
      )}
    />
    <Route
      exact
      path={'/settings/inventory/libraries/:libraryUuid/items'}
      render={compose(
        context,
        page('Inventory Library Items Page'),
        view(InventoryLibraryItemsPage),
      )}
    />
    <Route
      exact
      path={'/settings/inventory/libraries/:libraryUuid/item-tags'}
      render={compose(
        context,
        page('Inventory Library Item Tags Page'),
        view(InventoryLibraryItemTagsPage),
      )}
    />
    <Route
      exact
      path={'/settings/inventory/libraries/:libraryUuid/exception-types'}
      render={compose(
        context,
        page('Inventory Library Exception Types Page'),
        view(InventoryLibraryExceptionTypesPage),
      )}
    />
    <Route
      exact
      path={'/settings/inventory/libraries/:libraryUuid/exception-locations'}
      render={compose(
        context,
        page('Inventory Library Exception Locations Page'),
        view(InventoryLibraryExceptionLocationsPage),
      )}
    />
    <Route
      exact
      path={'/settings/moves/equipment-and-materials'}
      render={compose(
        context,
        page('Company Settings Equipment and Materials'),
        view(MoveSettingsPage),
      )}
    />
    <Route
      exact
      path={'/settings/jobs'}
      render={compose(context, page('Organization Settings Jobs'), view(OrganizationSettingsJobs))}
    />
    {/* redirect from old staff pages to new */}
    <Redirect exact from={'/settings/staff'} to={'/settings/staff/office-staff'} />
    <Redirect exact from={'/settings/users/staff'} to={'/settings/staff/office-staff'} />
    <Redirect
      exact
      from={'/settings/staff/office-staff'}
      to={'/settings/staff/office-staff/office-members'}
    />
    <Route
      exact
      path={'/settings/staff/office-staff/:subTab'}
      render={compose(
        context,
        page('Organization Settings Admins and Office Staff'),
        view(StaffSettingsPage),
      )}
    />
    <Redirect exact from={'/settings/users/movers'} to={'/settings/staff/movers'} />
    <Redirect exact from={'/settings/staff/movers'} to={'/settings/staff/movers/crew'} />
    <Route
      exact
      path={'/settings/staff/movers'}
      render={compose(context, page('Organization Settings Crew Members'), view(StaffSettingsPage))}
    />
    <Route
      exact
      path={'/settings/staff/movers/:subTab'}
      render={compose(context, page('Organization Settings Crew Members'), view(StaffSettingsPage))}
    />
    <Route
      exact
      path={'/settings/staff/positions'}
      render={compose(context, page('Organization Settings Positions'), view(StaffSettingsPage))}
    />
    <Route
      exact
      path={'/settings/staff/permissions'}
      render={compose(context, page('Organization Settings Permissions'), view(StaffSettingsPage))}
    />
    <Redirect exact from={'/settings/staff/tablets'} to={`/settings/company/crew-app`} />
    <Redirect exact from={'/settings/company/tablets'} to={`/settings/company/crew-app`} />
    <Redirect exact from={'/settings/staff/crew-app'} to={`/settings/company/crew-app`} />
    <Route
      exact
      path={'/settings/company/referrals'}
      render={compose(
        context,
        page('Company Settings Referrals'),
        view(OrganizationSettingsCompanyReferralsPage),
      )}
    />
    <Route
      exact
      path={'/settings/staff/schedules'}
      render={compose(context, page('Show Employee Schedules'), view(ShowEmployeeSchedules))}
    />
    <Route
      exact
      path={'/settings/moves/calendars'}
      render={compose(context, page('Organization Settings Calendars'), view(MoveSettingsPage))}
    />
    <Route
      exact
      path={'/settings/moves/trucks'}
      render={compose(context, page('Organization Settings Trucks'), view(MoveSettingsPage))}
    />
    <Redirect exact from={'/settings/dispatch/trucks'} to={'/settings/moves/trucks'} />
    <Route
      exact
      path={'/settings/moves/slots'}
      render={compose(context, page('Organization Settings Slots'), view(MoveSettingsPage))}
    />
    <Redirect exact from={'/settings/dispatch/slots'} to={'/settings/moves/slots'} />
    <Route
      exact
      path={'/settings/moves/location-types'}
      render={compose(
        context,
        page('Organization Settings Location Types'),
        view(MoveSettingsPage),
      )}
    />
    <Redirect
      exact
      from={'/settings/moves/capacity-calendar/:tab'}
      to={'/settings/moves/calendars'}
    />
    <Redirect exact from={'/settings/moves/capacity-calendar'} to={'/settings/moves/calendars'} />
    <Route
      exact
      path={'/settings/supplies'}
      render={compose(
        context,
        page('Organization Settings Supplies'),
        view(OrganizationSettingsSupplies),
      )}
    />
    <Route
      exact
      path={'/settings/company/branding'}
      render={compose(
        context,
        page('Company Settings Branding'),
        view(OrganizationSettingsCompanyBrandingPage),
      )}
    />
    <Route
      exact
      path={'/settings/storage'}
      render={compose(context, page('Organization Settings Storage'), view(StorageSettingsPage))}
    />
    <Route
      exact
      path={'/settings/storage/project-types'}
      render={compose(
        context,
        page('Organization Settings Storage Project Types'),
        view(OrganizationSettingsProjectTypes),
      )}
    />
    <Route
      exact
      path={'/settings/storage/warehouses'}
      render={compose(
        context,
        page('Organization Settings Storage Warehouses'),
        view(WarehouseSettingsPage),
      )}
    />
    <Route
      exact
      path={'/settings/company/warehouses'}
      render={compose(
        context,
        page('Organization Settings Warehouses'),
        view(OrganizationSettingsCompanyWarehousesPage),
      )}
    />
    <Route
      exact
      path={'/settings/storage/container-types'}
      render={compose(
        context,
        page('Organization Settings Storage Containers'),
        view(ContainerTypeSettingsPage),
      )}
    />
    <Redirect exact from='/settings/reports' to='/reports' />

    {/* Project Type Page */}
    {/* Redirect old routes to new project type settings page */}
    <Redirect exact from={'/settings/projects'} to={'/settings/projects/project-types'} />
    <Redirect exact from={'/settings/project-types'} to={'/settings/projects/project-types'} />
    <Redirect exact from={'/settings/project-types/tags'} to={'/settings/projects/tags'} />
    <Route
      exact
      path={'/settings/projects/project-types'}
      render={compose(
        context,
        page('Organization Settings Project Types'),
        view(OrganizationSettingsProjectTypes),
      )}
    />
    <Route
      exact
      path={'/settings/projects/tags'}
      render={compose(
        context,
        page('Organization Settings Tags'),
        view(OrganizationSettingsProjectTypes),
      )}
    />
    <Route
      exact
      path={'/settings/projects/cancellations'}
      render={compose(
        context,
        page('Organization Settings Project Cancellations'),
        view(ProjectSettingsPageCancellations),
      )}
    />
    <Route
      exact
      path={'/settings/projects/project-types/:projectTypeUuid/variables'}
      render={compose(
        context,
        page('Project Type Variables Page'),
        view(ProjectTypeVariableSettingsPage),
      )}
    />
    <Route
      exact
      path={'/settings/projects/project-types/:projectTypeUuid/payments'}
      render={compose(
        context,
        page('Project Type Payment Settings Page'),
        view(ProjectTypePaymentSettingsPage),
      )}
    />
    <Route
      exact
      path={'/settings/projects/project-types/:projectTypeUuid/accounting'}
      render={compose(
        context,
        page('Project Type Accounting Settings Page'),
        view(ProjectTypeAccountingSettingsPage),
      )}
    />
    <Route
      exact
      path={`/settings/projects/project-types/:projectTypeUuid/job-types`}
      render={compose(
        context,
        page('Organization Settings Job Types'),
        view(ProjectTypeJobTypesSettingsPage),
      )}
    />
    <Route
      exact
      path={`/settings/projects/project-types/:projectTypeUuid/valuation-coverage`}
      render={compose(
        context,
        page('Project Type Valuation Coverage'),
        view(ProjectTypeValuationCoverageSettingsPage),
      )}
    />
    <Route
      exact
      path={`/settings/projects/project-types/:projectTypeUuid/quotes-and-confirmations`}
      render={compose(
        context,
        page('Project Type Quotes and Confirmations'),
        view(ProjectTypeQuotesAndConfirmationsPage),
      )}
    />
    <Route
      exact
      path={`/settings/projects/project-types/:projectTypeUuid/document-flows`}
      render={compose(
        context,
        page('Project Type Document Flows'),
        view(ProjectTypeDocumentFlowsPage),
      )}
    />
    <Route
      exact
      path={`/settings/projects/project-types/:projectTypeUuid/surveys`}
      render={compose(context, page('Project Type Surveys'), view(ProjectTypeSurveySettingsPage))}
    />
    <Route
      exact
      path={`/settings/projects/project-types/:projectTypeUuid/driver-inventory`}
      render={compose(
        context,
        page('Project Type Driver Inventory'),
        view(ProjectTypeDriverInventorySettingsPage),
      )}
    />

    {/* Job Type Page */}
    <Route
      exact
      path={'/settings/projects/project-types/:projectTypeUuid/job-types/:jobTypeUuid/variables'}
      render={compose(context, page('Job Type Variables Page'), view(JobTypeVariableSettingsPage))}
    />
    <Route
      exact
      path={'/settings/projects/project-types/:projectTypeUuid/job-types/:jobTypeUuid/steps'}
      render={compose(context, page('Job Type Steps Page'), view(JobTypeStepsSettingsPage))}
    />
    <Route
      exact
      path={'/settings/projects/project-types/:projectTypeUuid/job-types/:jobTypeUuid/general'}
      render={compose(context, page('Job Type General Page'), view(JobTypeGeneralSettingsPage))}
    />

    {/* Document Template Page */}
    <Redirect exact from={'/settings/documents/'} to={'/settings/documents/document-templates/'} />
    <Route
      exact
      path={'/settings/documents/document-templates/:documentTemplateUuid'}
      render={compose(
        context,
        page('Show Document Template'),
        view(OrganizationSettingsDocumentTemplateSettingsPage),
      )}
    />
    <Route
      exact
      path={'/settings/documents/document-templates/'}
      render={compose(
        context,
        page('List Document Templates'),
        view(OrganizationSettingsDocumentTemplateSettingsPage),
      )}
    />
    <Redirect
      exact
      from={
        '/settings/documents/document-templates/:documentTemplateUuid/version/:documentTemplateVersionUuid'
      }
      to={
        '/settings/documents/document-templates/:documentTemplateUuid/version/:documentTemplateVersionUuid/edit'
      }
    />
    <Route
      exact
      path={
        '/settings/documents/document-templates/:documentTemplateUuid/version/:documentTemplateVersionUuid/edit'
      }
      render={compose(
        context,
        page('Document Template Version Edit'),
        view(DocumentTemplateVersionEditorPage),
      )}
    />
    <Route
      exact
      path={'/settings/documents/document-flows'}
      render={compose(
        context,
        page('Documents Settings Document Flows'),
        view(DocumentsSettingsPageDocumentFlows),
      )}
    />
    <Route
      exact
      path={'/settings/documents/document-flows/create'}
      render={compose(context, page('Create Document Flow Page'), view(CreateDocumentFlowPage))}
    />
    <Route
      exact
      path={'/settings/documents/document-flows/:userFlowUuid'}
      render={compose(
        context,
        page('Document Settings Show Document Flow'),
        view(DocumentsSettingsPageDocumentFlows),
      )}
    />
    <Route
      exact
      path={'/settings/documents/document-flows/:userFlowUuid'}
      render={compose(
        context,
        page('Document Settings Show Document Flow'),
        view(DocumentsSettingsPageDocumentFlows),
      )}
    />
    <Route
      exact
      path={'/settings/documents/document-flows/:userFlowUuid/edit'}
      render={compose(context, page('Edit Document Flow Page'), view(EditDocumentFlowPage))}
    />
    <Route
      exact
      path={'/settings/documents/document-flows/:userFlowUuid/runs'}
      render={compose(
        context,
        page('Document Flow Runs Page'),
        view(DocumentsSettingsDocumentFlowRunsPage),
      )}
    />

    {/* Billing Libraries */}
    <Route
      exact
      path={'/settings/billing/billing-libraries'}
      render={compose(
        context,
        page('Organization Settings Billing Libraries Page'),
        view(OrganizationSettingsBillingLibrariesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/billing-libraries/:billingLibraryUuid/items'}
      render={compose(
        context,
        page('Settings Billing Library Items'),
        view(BillingLibraryBillItemTypesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/billing-libraries/:billingLibraryUuid/items/:category'}
      render={compose(
        context,
        page('Settings Billing Library Category Items'),
        view(BillingLibraryBillItemTypesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/billing-libraries/:billingLibraryUuid/rules'}
      render={compose(
        context,
        page('Settings Billing Library Rules'),
        view(BillingLibraryBillRulesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/billing-libraries/:billingLibraryUuid/bill-templates'}
      render={compose(
        context,
        page('Settings Billing Library Bill Templates'),
        view(BillingLibraryBillTypesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/billing-libraries/:billingLibraryUuid/bill-templates/new'}
      render={compose(
        context,
        page('Settings Billing Library Create Bill Template'),
        view(CreateBillTypePage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/billing-libraries/:billingLibraryUuid/bill-templates/:billTypeUuid'}
      render={compose(
        context,
        page('Settings Billing Library Update Bill Template'),
        view(UpdateBillTypePage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/billing-libraries/:billingLibraryUuid/cost-items'}
      render={compose(
        context,
        page('Settings Billing Library Cost Items'),
        view(BillingLibraryCostItemTypesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/billing-libraries/:billingLibraryUuid/compensation-items'}
      render={compose(
        context,
        page('Settings Billing Library Compensation Items'),
        view(BillingLibraryCompensationItemTypesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/hourly-rates'}
      render={compose(
        context,
        page('Settings Billing Hourly Rates Page'),
        view(OrganizationSettingsBillingHourlyRatesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/hourly-rates/create'}
      render={compose(
        context,
        page('Create Hourly Rate Table Page'),
        view(CreateHourlyRateTablePage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/hourly-rates/:rateTableUuid/edit'}
      render={compose(context, page('Edit Hourly Rate Table Page'), view(EditHourlyRateTablePage))}
    />
    <Route
      exact
      path={'/settings/billing/formulas'}
      render={compose(
        context,
        page('Settings Billing Formulas Page'),
        view(OrganizationSettingsBillingFormulasPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/variables'}
      render={compose(
        context,
        page('Settings Billing Variables Page'),
        view(OrganizationSettingsBillingVariablesPage),
      )}
    />
    <Route
      exact
      path={'/settings/billing/tables'}
      render={compose(
        context,
        page('Settings Billing Tables Page'),
        view(OrganizationSettingsBillingTablesPage),
      )}
    />
    {/* Redirect old routes to new billing settings page */}
    <Redirect
      exact
      from={'/settings/billing-libraries'}
      to={'/settings/billing/billing-libraries'}
    />
    <Redirect exact from={'/settings/billing'} to={'/settings/billing/billing-libraries'} />
    <Route
      exact
      path={'/settings/company/accounting'}
      render={compose(
        context,
        page('Company Settings Accounting'),
        view(OrganizationSettingsCompanyAccountingPage),
      )}
    />
    <Route
      exact
      path={'/settings/company/claims'}
      render={compose(
        context,
        page('Company Settings Claims'),
        view(OrganizationSettingsCompanyClaimsPage),
      )}
    />
    <Route
      exact
      path={'/settings/company/tablets'}
      render={compose(
        context,
        page('Organization Settings Company Tablets'),
        view(OrganizationSettingsCompanyCrewAppPage),
      )}
    />
    <Route
      exact
      path={'/settings/company/crew-app'}
      render={compose(
        context,
        page('Organization Settings Crew App'),
        view(OrganizationSettingsCompanyCrewAppPage),
      )}
    />
    <Route
      exact
      path={'/settings/company/integrations'}
      render={compose(
        context,
        page('Company Settings Integrations'),
        view(OrganizationSettingsCompanyIntegrationsPage),
      )}
    />
    <Redirect
      exact
      from={'/settings/company/accounting/general'}
      to={'/settings/company/accounting'}
    />
    <Route
      exact
      path={'/settings/company/accounting/:uuid/general'}
      render={compose(
        context,
        redirectIfNotAuthenticated({roles: ['super', 'admin']}),
        page('Company Settings Integrations Accounting General'),
        view(OrganizationSettingsCompanyIntegrationsAccountingGeneralPage),
      )}
    />
    <Redirect
      exact
      from={'/settings/company/accounting/items'}
      to={'/settings/company/accounting'}
    />
    <Route
      exact
      path={'/settings/company/accounting/:uuid/items'}
      render={compose(
        context,
        redirectIfNotAuthenticated({roles: ['super', 'admin']}),
        page('Company Settings Integrations Accounting Items'),
        view(OrganizationSettingsCompanyIntegrationsAccountingItemsPage),
      )}
    />
    <Route
      exact
      path={'/settings/company/accounting/:uuid/imports'}
      render={compose(
        context,
        redirectIfNotAuthenticated({roles: ['super', 'admin']}),
        page('Company Settings Integrations Accounting Imports'),
        view(OrganizationSettingsCompanyIntegrationsAccountingImportsPage),
      )}
    />
    <Route
      exact
      path={'/settings/company/accounting/:uuid/imports/:category'}
      render={compose(
        context,
        redirectIfNotAuthenticated({roles: ['super', 'admin']}),
        page('Company Settings Integrations Accounting Import Categories'),
        view(OrganizationSettingsCompanyIntegrationsAccountingImportsPage),
      )}
    />
    <Redirect
      exact
      from={'/settings/company/accounting/logs'}
      to={'/settings/company/accounting'}
    />
    <Route
      exact
      path={'/settings/company/accounting/:uuid/logs'}
      render={compose(
        context,
        redirectIfNotAuthenticated({roles: ['super', 'admin']}),
        page('Company Settings Integrations Accounting Logs'),
        view(OrganizationSettingsCompanyIntegrationsAccountingHistoryPage),
      )}
    />
    <Redirect
      exact
      from={'/settings/company/accounting/exports'}
      to={'/settings/company/accounting'}
    />
    <Route
      exact
      path={'/settings/company/accounting/:uuid/exports'}
      render={compose(
        context,
        redirectIfNotAuthenticated({roles: ['super', 'admin']}),
        page('Company Settings Integrations Accounting Exports'),
        view(OrganizationSettingsCompanyIntegrationsAccountingExportsPage),
      )}
    />
    <Redirect
      exact
      from={'/settings/company/accounting/exports/:batchUuid'}
      to={'/settings/company/accounting'}
    />
    <Route
      exact
      path={'/settings/company/accounting/:uuid/exports/:batchUuid'}
      render={compose(
        context,
        redirectIfNotAuthenticated({roles: ['super', 'admin']}),
        page('Company Settings Integrations Accounting Exports Batch'),
        view(OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage),
      )}
    />

    <Route
      exact
      path={'/settings/company/payments'}
      render={compose(
        context,
        page('Company Settings Payments'),
        view(OrganizationSettingsCompanyPaymentsPage),
      )}
    />
    <Route
      exact
      path={'/settings/company/payments/onboarding'}
      render={compose(
        context,
        page('Company Settings Payments Onboarding'),
        view(OrganizationSettingsCompanyPaymentsOnboardingPage),
      )}
    />
    {/* TODO(jholston): [org-settings] remove this route, setup a redirect */}
    <Route
      exact
      path={'/settings/integrations/webhooks'}
      render={compose(
        context,
        page('Organization Settings Integrations Webhooks'),
        view(OrganizationSettingsIntegrationsWebhooksPage),
      )}
    />
    {/* Automation (Workflow) Settings */}
    <Route
      exact
      path={'/settings/automations'}
      render={compose(
        context,
        page('Workflow Settings'),
        view(OrganizationSettingsWorkflowSettingsPage),
      )}
    />
    <Route
      exact
      path={'/settings/automations/:workflowUuid'}
      render={compose(
        context,
        page('Workflow Settings Preview Workflow'),
        view(OrganizationSettingsWorkflowSettingsPage),
      )}
    />
    <Route
      exact
      path={'/settings/automations/:workflowUuid/runs'}
      render={compose(context, page('Workflow Runs'), view(OrganizationSettingsWorkflowRunsPage))}
    />
    <Route
      exact
      path={'/settings/automations/:workflowUuid/build'}
      render={compose(context, page('Workflow Builder'), view(WorkflowBuilderPage))}
    />
    {/* Tasks Settings */}
    <Redirect exact from={'/settings/tasks'} to={'/settings/tasks/task-templates'} />
    <Route
      exact
      path={'/settings/tasks/:tab'}
      render={compose(context, page('Settings Tasks'), view(OrganizationSettingsTasksPage))}
    />

    {/* Default Route */}
    <Route path={'*'} render={compose(context, page('Not Found'), view(NotFound))} />
  </Switch>
);

const CustomerRouter = ({context}: any) => (
  <Switch>
    {/* Render Document V1 - from DocumentTemplate in config files */}
    <Route
      exact
      path={'/0/:slug/documents/:uuid'}
      render={compose(context, page('Customer - Show Document'), view(CustomerDocumentShow))}
    />
    {/* Render Document V2 - from document_content_json */}
    <Route
      exact
      path={'/0/:slug/documents/v2/:uuid'}
      render={compose(context, page('Customer - Show Document V2'), view(CustomerDocumentV2Show))}
    />
    <Route
      exact
      path={'/0/:slug/documents/v2/:uuid/pdf'}
      render={compose(context, page('Customer - Document V2 PDF'), view(CustomerDocumentV2Pdf))}
    />
    <Route
      exact
      path={'/0/:slug/jobs/:uuid/print'}
      render={compose(context, page('Print - Job'), view(PrintJob))}
    />
    <Route
      exact
      path={'/0/:slug/jobs/:uuid/print/card'}
      render={compose(context, page('Print - Card Job'), view(CardPrintJob))}
    />
    <Route
      exact
      path={'/0/:slug/jobs/:uuid/bill'}
      render={compose(context, page('Customer - Bill Job'), view(CustomerBillJob))}
    />
    <Route
      path={'/0/:slug/jobs/:uuid/confirm'}
      render={compose(
        context,
        page('Customer - Redirect Job Confirmation'),
        view(CustomerConfirmJob),
      )}
    />
    <Route
      path={'/0/:slug/jobs/:uuid/confirmation'}
      render={compose(
        context,
        page('Customer - Redirect Job Confirmation'),
        view(CustomerConfirmJob),
      )}
    />
    <Route
      path={'/0/:slug/jobs/:uuid/quote'}
      render={compose(context, page('Customer - Redirect Job Quote'), view(CustomerQuoteJob))}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation'}
      render={compose(
        context,
        page('Customer - Project Confirmation'),
        view(CustomerProjectConfirmation),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/document/print'}
      render={compose(
        context,
        page('Customer - Project Confirmation Print'),
        view(CustomerProjectConfirmationPrint),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/inventory'}
      render={compose(
        context,
        page('Customer - Project Confirmation Inventory'),
        view(CustomerProjectConfirmationInventory),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/inventory/print'}
      render={compose(
        context,
        page('Customer - Project Confirmation Print'),
        view(CustomerProjectConfirmationInventoryPrint),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/inventory/print'}
      render={compose(
        context,
        page('Project Inventory Print'),
        view(CustomerProjectInventoryPrint),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/card'}
      render={compose(
        context,
        page('Customer - Project Confirmation Card'),
        view(CustomerProjectCardConfirmation),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/document'}
      render={compose(
        context,
        page('Customer - Project Confirmation Document'),
        view(CustomerProjectConfirmationDocument),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/documents/:documentUuid'}
      render={compose(
        context,
        page('Customer - Project Confirmation Document Show'),
        view(CustomerProjectConfirmationDocumentShow),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/documents/v2/:documentUuid'}
      render={compose(
        context,
        page('Customer - Project Confirmation Document V2'),
        view(CustomerProjectConfirmationDocumentV2),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/success'}
      render={compose(
        context,
        page('Customer - Project Confirmation Success'),
        view(CustomerProjectConfirmationSuccess),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/expired'}
      render={compose(
        context,
        page('Customer - Project Confirmation Expired'),
        view(CustomerProjectConfirmationExpired),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/quote'}
      render={compose(
        context,
        page('Customer - Project Quote Confirmation'),
        view(CustomerProjectQuoteConfirmation),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/confirmation/deposit'}
      render={compose(
        context,
        page('Customer - Project Deposit Confirmation'),
        view(CustomerProjectDepositConfirmation),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/quote'}
      render={compose(context, page('Customer - Project Quote'), view(CustomerProjectQuote))}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/quote/info'}
      render={compose(
        context,
        page('Customer - Project Quote Info'),
        view(CustomerProjectQuoteInfo),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/quote/inventory'}
      render={compose(
        context,
        page('Customer - Project Quote Inventory'),
        view(CustomerProjectQuoteInventory),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/quote/success'}
      render={compose(
        context,
        page('Customer - Project Quote Success'),
        view(CustomerProjectQuoteSuccess),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/quote/expired'}
      render={compose(
        context,
        page('Customer - Project Quote Expired'),
        view(CustomerProjectQuoteExpired),
      )}
    />
    <Route
      exact
      path={'/0/:slug/projects/:uuid/quote/documents/v2/:documentUuid'}
      render={compose(
        context,
        page('Customer - Project Quote Document V2'),
        view(CustomerProjectQuoteDocumentV2),
      )}
    />
    <Route
      exact
      path={'/0/:slug/jobs/:uuid/move-report'}
      render={compose(context, view(CustomerReportMoveJob))}
    />
    <Route
      exact
      path={'/0/:slug/jobs/:uuid/move-report/invoice'}
      render={compose(context, view(CustomerInvoiceReportMoveJob))}
    />
    <Route
      exact
      path={'/0/:slug/jobs/:uuid/change-order'}
      render={compose(context, page('Customer - Change Order'), view(CustomerJobChangeOrder))}
    />
    <Route
      exact
      path={'/0/:slug/jobs/:uuid/risky-move'}
      render={compose(context, page('Customer - Risky Move Job'), view(CustomerJobRiskyMove))}
    />
    <Route
      exact
      path={'/0/:slug/jobs/:uuid/track'}
      render={compose(context, page('Customer - Track Job'), view(CustomerTrackJob))}
    />
    <Route
      exact
      path={'/0/:slug/request'}
      render={compose(context, page('Customer - New Job Request'), view(CustomerNewJobRequest))}
    />
    <Route
      exact
      path={'/0/:slug/requests/:uuid/success'}
      render={compose(
        context,
        page('Customer - Success Job Request'),
        view(CustomerSuccessJobRequest),
      )}
    />
    <Route
      exact
      path={'/0/:slug/compensation-reports/:compensationReportUuid/print'}
      render={compose(
        context,
        page('Print Compensation Report'),
        view(PrintCompensationReportAccountingPage),
      )}
    />
    <Route
      exact
      path={'/0/:slug/invoices/:invoiceUuid/review'}
      render={compose(context, page('Customer - Review Project Invoice'), view(ReviewInvoicePage))}
    />
    <Route
      exact
      path={'/0/:slug/invoices/:invoiceUuid/success'}
      render={compose(
        context,
        page('Customer - Project Invoice Success'),
        view(InvoiceSuccessPage),
      )}
    />
  </Switch>
);

const EmployeeRouter = ({context}: any) => (
  <Switch>
    <Route
      exact
      path={'/1/schedule'}
      render={compose(context, page('Employee - Schedule User'), view(EmployeeScheduleUser))}
    />
    <Route
      exact
      path={'/1/schedule/:date'}
      render={compose(context, page('Employee - Show UserDay'), view(EmployeeShowUserDay))}
    />
    <Route
      exact
      path={'/1/accounting/compensation-reports'}
      render={compose(
        context,
        page('Employee - List Compensation Reports'),
        view(ListCompensationReportAccountingEmployeePage),
      )}
    />

    {/* Job */}
    <Route
      exact
      path={'/1/jobs/:uuid'}
      render={compose(context, page('Employee - Show Job'), view(EmployeeShowJob))}
    />
    <Route
      exact
      path={'/1/jobs/:uuid/details'}
      render={compose(context, page('Employee - Details Job'), view(EmployeeDetailsJob))}
    />
    <Route
      exact
      path={'/1/jobs/:uuid/crew'}
      render={compose(context, page('Employee - Crew Job'), view(EmployeeCrewJob))}
    />
    <Route
      exact
      path={'/1/:slug/compensation-reports/:compensationReportUuid/print'}
      render={compose(
        context,
        page('Print Compensation Report'),
        view(PrintCompensationReportAccountingPage),
      )}
    />

    {/* Long Distance Dispatch */}
    <Route
      exact
      path={'/1/dispatch/trips/:subTab'}
      render={compose(
        context,
        page('Long Distance Dispatch Trips List'),
        view(LongDistanceDispatchTripsListPage),
      )}
    />
    <Route
      exact
      path={'/1/dispatch/trips/:subTab/:tripUuid'}
      render={compose(
        context,
        page('Long Distance Dispatch View Trips and Shipment'),
        view(LongDistanceDispatchViewTripPage),
      )}
    />
  </Switch>
);

// @ts-expect-error TS(7031): Binding element 'context' implicitly has an 'any' ... Remove this comment to see the full error message
const Router = ({pageContext: context}) => (
  <Switch>
    {/* Public Routes */}
    <Route exact path={'/404'} render={compose(context, page('Not Found'), view(NotFound))} />
    <Route
      exact
      path={'/login'}
      render={compose(
        context,
        redirectIfAuthenticated({roles: ['manager', 'salesperson']}),
        page('Login'),
        view(LoginAuthentication, Meta.LOGIN),
      )}
    />
    <Route
      exact
      path={'/register'}
      render={compose(
        context,
        redirectIfAuthenticated({roles: ['manager', 'salesperson']}),
        page('Login'),
        page('Register'),
        view(RegisterAuthentication, Meta.REGISTER),
      )}
    />
    <Route
      exact
      path={'/password/reset'}
      render={compose(context, page('Reset Password'), view(PasswordResetAuthentication))}
    />
    <Route
      exact
      path={'/password/set'}
      render={compose(context, page('Set Password'), view(PasswordSetAuthentication))}
    />
    <Route
      exact
      path={'/invites/accept'}
      render={compose(context, page('Accept Invite'), view(InviteOrganizationUserSignUpPage))}
    />

    {/* Share Routes */}
    <Route
      exact
      path={'/share/documents/:shareUuid/'}
      render={compose(context, page('Shared Document'), view(SharedDocumentPage))}
    />
    <Route
      exact
      path={'/share/documents/:shareUuid/live'}
      render={compose(context, page('Shared Live Document'), view(SharedLiveDocumentPage))}
    />
    <Route
      exact
      path={'/share/documents/:shareUuid/pdf'}
      render={compose(context, page('Shared Pdf Document'), view(SharedPdfDocumentPage))}
    />

    {/* Mover Routes */}
    <Route
      exact
      path={'/1/jobs/:uuid/view'}
      render={compose(
        {...context, appNamespace: 'mover'},
        page('Employee - View Job'),
        view(EmployeeViewJob),
      )}
    />

    {/* Authenticated Routes */}
    <Route exact path={'/'} render={compose(context, page('Home'), view(Home))} />

    {/* Customer Routes */}
    <Route
      path={'/0'}
      render={compose({...context, appNamespace: 'customer'}, view(CustomerRouter))}
    />

    {/* Employee Routes */}
    <Route
      path={'/1'}
      render={compose(
        {...context, appNamespace: 'mover'},
        employeeLogin(),
        redirectIfNotAuthenticated({roles: ['employee']}),
        view(EmployeeRouter),
      )}
    />

    {/* Manager Routes */}
    <Route
      path={'*'}
      render={compose(
        context,
        redirectIfNotAuthenticated({roles: ['manager', 'salesperson']}),
        view(ManagerRouter),
      )}
    />
  </Switch>
);

export default Router;
