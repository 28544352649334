// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {EmailModel} from '@supermove/models';

// App
import AttachmentDetailsModal from 'modules/Communication/Email/components/AttachmentDetailsModal';

interface EmailAttachmentDetailsModalProps {
  email: EmailModel;
  responsive: ResponsiveType;
  selectedEmailAttachmentId?: string;
  setSelectedEmailAttachmentId: (id?: string) => void;
}

const EmailAttachmentDetailsModal = ({
  email,
  responsive,
  selectedEmailAttachmentId,
  setSelectedEmailAttachmentId,
}: EmailAttachmentDetailsModalProps) => {
  const selectedEmailAttachment = email.emailAttachments.find(
    (emailAttachment) => emailAttachment.id === selectedEmailAttachmentId,
  );

  if (!selectedEmailAttachment) {
    return null;
  }

  const successfulEmailAttachments = email.emailAttachments.filter(
    (emailAttachment) => emailAttachment.status === 'SUCCESS',
  );

  return (
    <AttachmentDetailsModal
      attachments={successfulEmailAttachments.map((emailAttachment) => emailAttachment.attachment)}
      responsive={responsive}
      selectedAttachmentId={selectedEmailAttachment.attachment.id}
      setSelectedAttachmentId={(attachmentId) => {
        const emailAttachment = successfulEmailAttachments.find(
          (emailAttachment) => emailAttachment.attachment.id === attachmentId,
        );
        setSelectedEmailAttachmentId(emailAttachment?.id);
      }}
      selectedAttachmentStatus={selectedEmailAttachment.status}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachmentDetailsModal.fragment = gql`
  ${AttachmentDetailsModal.fragment}

  fragment EmailAttachmentDetailsModal on Email {
    id
    emailAttachments {
      id
      status
      attachment {
        id
        file {
          id
          name
          downloadUrl
          ...AttachmentDetailsModal
        }
      }
    }
  }
`;

export default EmailAttachmentDetailsModal;
