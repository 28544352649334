// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {ProjectModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type CancelProjectFormType = {
  projectId: string;
  cancellationReasonId: string | undefined;
  details: string;
};

const _new = ({projectId}: {projectId: string}) => ({
  projectId,
  cancellationReasonId: undefined,
  details: '',
});

const edit = withFragment(
  (project: ProjectModel): CancelProjectFormType => ({
    projectId: project.id,
    cancellationReasonId: project.projectCancellationReason?.cancellationReasonId,
    details: project.projectCancellationReason?.details || '',
  }),
  gql`
    fragment CancelProjectForm_edit on Project {
      id
      projectCancellationReason {
        id
        cancellationReasonId
        details
      }
    }
  `,
);

const toForm = ({projectId, cancellationReasonId, details}: CancelProjectFormType) => ({
  projectId,
  cancellationReasonId: _.toString(cancellationReasonId),
  details,
});

const toMutation = ({projectId, cancellationReasonId, details}: CancelProjectFormType) => ({
  projectId,
  cancellationReasonId: _.toNumber(cancellationReasonId),
  details,
});

const CancelProjectForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default CancelProjectForm;
