// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {JobTypeStepModel} from '@supermove/models';

// App

import JobTypeStepForm, {JobTypeStepFormType} from '@shared/modules/Job/forms/JobTypeStepForm';

const useUpsertJobTypeStepMutation = ({
  jobTypeStepForm,
  onSuccess,
  onError,
}: {
  jobTypeStepForm: JobTypeStepFormType;
  onSuccess?: ({jobTypeStep}: {jobTypeStep: JobTypeStepModel}) => void;
  onError?: (error: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      jobTypeStepForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertJobTypeStepMutation.mutation,
    variables: {
      jobTypeStepForm: JobTypeStepForm.toMutation(form.values.jobTypeStepForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpsertJobTypeStepMutation.mutation = gql`
  mutation useUpsertJobTypeStepMutation($jobTypeStepForm: JobTypeStepForm!){
    response: upsertJobTypeStep(jobTypeStepForm: $jobTypeStepForm) {
      ${gql.errors}
      # These properties are used to set these values on success of the upsert. We can't refetch there because it makes the whole page flicker
      # So these set the values that come from the API, like the id's
      # documentTemplateKinds are also set from here, though they should not be
      # But we're going to remove it in the near future anyway in favor of jobTypeStepDocumentTemplates
      jobTypeStep{
        id
        isEnabled
        documentTemplateKinds
        jobTypeStepDocumentTemplates {
          id
          index
          documentTemplateId
        }
      }
    }
  }
`;

export default useUpsertJobTypeStepMutation;
