// Libraries

// Supermove
import {gql} from '@supermove/graphql';
import {PaginationInput, useDebouncedCallback, useQuery, useState} from '@supermove/hooks';
import {ClientModel} from '@supermove/models';

const getQuery = ({
  useClientFieldsClientSearchFragment,
}: {
  useClientFieldsClientSearchFragment: string;
}) => {
  return gql`
      ${useClientFieldsClientSearchFragment}
      query useClientFieldsClientSearchV2($searchQuery: String!) {
        ${gql.query}
        quickSearchClients(query: $searchQuery, options: {limit: 50}) {
          results {
            client {
              id
              ...useClientFieldsClientSearchFragment
            }
            distance
            terms
          }
        }
      }
    `;
};

// This hook is setup to separate some logic out of `ClientFields`. It is only used from
// that component and therefore can utilize a fragment passed through as an argument.
// This facilitates some separation of concerns and helps the readability of the component.
const useClientFieldsClientSearch = ({
  useClientFieldsClientSearchFragment,
  pagination,
}: {
  useClientFieldsClientSearchFragment: string;
  pagination: PaginationInput;
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeSearch = useDebouncedCallback((text) => setSearchQuery(text), 250);

  // useQuery can't be placed in an `if (useV2Search) { ... }` block, so we work around this by
  // parameterizing the query & response fields
  const {data, loading} = useQuery(getQuery({useClientFieldsClientSearchFragment}), {
    fetchPolicy: 'cache-and-network',
    skip: searchQuery.length < 3,
    variables: {
      searchQuery,
      slugs: ['ALL_ORGANIZATIONS'],
      pagination,
    },
  });

  // TODO: Fix ClientModel usage once GraphQL types are generated. ClientModel is an incorrect
  //   representation of the response type
  const clients =
    data?.quickSearchClients.results.map(({client}: {client: ClientModel}) => client) || [];

  return {
    clients,
    isSearching: loading,
    searchQuery,
    handleChangeSearch,
  };
};

export default useClientFieldsClientSearch;
