// Libraries
import _ from 'lodash';
import React from 'react';

// Supremove
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {WorkflowModel} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const SendDocumentFlowSlide = ({
  form,
  workflow,
  workflowStepsField,
  workflowStepIndex,
  handleSubmitValidateWorkflow,
}: {
  form: Form<{workflowForm: typeof WorkflowForm}>;
  workflow: WorkflowModel;
  workflowStepsField: string;
  workflowStepIndex: number;
  handleSubmitValidateWorkflow: () => void;
}) => {
  const {label, description} =
    WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.SEND_DOCUMENT_FLOW];
  const field = `${workflowStepsField}.${workflowStepIndex}.createAndSendUserFlowRunActionForm`;

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.userFlowId`}
        label={'Document Flow'}
        isRequired
        input={{
          options: workflow.organization.userFlows
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((userFlow) => ({
              value: _.toString(userFlow.id),
              label: userFlow.name,
            })),
          placeholder: 'Select a document flow',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
    </WorkflowStepSlide>
  );
};

const SendDocumentFlowDisplaySlide = ({
  form,
  workflow,
  workflowStepsField,
  workflowStepIndex,
  isPreview,
}: {
  form: Form<{workflowForm: typeof WorkflowForm}>;
  workflow: WorkflowModel;
  workflowStepsField: string;
  workflowStepIndex: number;
  isPreview: boolean;
}) => {
  const userFlow = _.find(workflow.organization.userFlows, {
    id: _.get(
      form.values,
      `${workflowStepsField}.${workflowStepIndex}.createAndSendUserFlowRunActionForm.userFlowId`,
    ),
  });
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={`Send email from document flow: ${userFlow?.name ?? ''}`}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepSendDocumentFlow = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }: {
    form: Form<{workflowForm: typeof WorkflowForm}>;
    workflowStepsField: string;
    workflowStepIndex: number;
    workflow: WorkflowModel;
    handleSubmitValidateWorkflow: () => void;
    isPreview: boolean;
  }) => [
    <SendDocumentFlowSlide
      key={'CREATE'}
      form={form}
      workflow={workflow}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />,
    <SendDocumentFlowDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflow={workflow}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      isPreview={isPreview}
    />,
  ],
  fragment: gql`
    fragment WorkflowStepSendDocumentFlow on Workflow {
      id
      organization {
        id
        sortedEmailTemplates {
          id
          name
        }
        userFlows {
          id
          name
        }
      }
    }
  `,
};

export default WorkflowStepSendDocumentFlow;
