// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import {RoomItemsFormType} from '@shared/modules/Inventory/forms/RoomItemsForm';

const EditRoomAttachmentsButton = ({
  roomItemsForm,
  handleOnPress,
  style,
}: {
  roomItemsForm: RoomItemsFormType;
  handleOnPress: () => void;
  style?: object;
}) => {
  const videoCount = _.isEmpty(roomItemsForm.latestVideoAttachment) ? 0 : 1;
  const photoCount = roomItemsForm.photoAttachments.length;

  return (
    <SecondaryButton
      onPress={handleOnPress}
      style={style || {paddingHorizontal: 8, paddingVertical: 4, height: 'auto'}}
    >
      <Icon source={Icon.Image} size={12} color={colors.blue.interactive} />
      <Space width={4} />
      <SecondaryButton.Text>{photoCount}</SecondaryButton.Text>
      <Space width={8} />
      <Icon source={Icon.Play} size={12} color={colors.blue.interactive} />
      <Space width={4} />
      <SecondaryButton.Text>{videoCount}</SecondaryButton.Text>
    </SecondaryButton>
  );
};

export default EditRoomAttachmentsButton;
