// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';

// App
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import Switch from '@shared/design/components/Switch';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import EditJobRouteBlock from 'modules/Project/V2/Edit/components/EditJobRouteBlock';
import EditLocationsList from 'modules/Project/V2/Edit/components/EditLocationsList';
import EditProjectBlockWrapper from 'modules/Project/V2/Edit/components/EditProjectBlockWrapper';
import MobileEditJobBlock from 'modules/Project/V2/Edit/components/MobileEditJobBlock';

const MOBILE_CONTEXT = {
  STOPS: 'STOPS',
  MAP: 'MAP',
};

const ReorderToggle = ({isReordering, setIsReordering, isDisabled}: any) => {
  return (
    <Switch
      isOn={isReordering}
      onChange={(value) => setIsReordering(value)}
      labelRight={'Reorder'}
      isResponsive
      disabled={isDisabled}
    />
  );
};

const EditJobStops = ({form, field, jobsField, project, isReordering, isDisabled, map}: any) => {
  return (
    <EditLocationsList
      form={form}
      field={field}
      jobsField={jobsField}
      project={project}
      isReordering={isReordering}
      // TODO(dan) Add set location position and focus index
      // when working on scrolling directly to job stop
      isDisabled={isDisabled}
      setLocationIndexToPositionY={() => {}}
      scrollToIndex={0}
      isStops
      map={map}
    />
  );
};

const MobileEditJobStopsHeader = ({
  mobileContext,
  setMobileContext,
  isReordering,
  setIsReordering,
  isDisabled,
}: any) => {
  return (
    <React.Fragment>
      <ContextSwitcher
        isFullWidth
        contextDefinitions={[
          {
            label: `Stops`,
            isSelected: mobileContext === MOBILE_CONTEXT.STOPS,
            onPress: () => setMobileContext(MOBILE_CONTEXT.STOPS),
          },
          {
            label: `Map`,
            isSelected: mobileContext === MOBILE_CONTEXT.MAP,
            onPress: () => setMobileContext(MOBILE_CONTEXT.MAP),
          },
        ]}
      />
      {mobileContext === MOBILE_CONTEXT.STOPS && (
        <React.Fragment>
          <Space height={16} />
          <ReorderToggle
            isReordering={isReordering}
            setIsReordering={setIsReordering}
            isDisabled={isDisabled}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const EditJobStopsBlockContent = ({
  form,
  field,
  jobsField,
  project,
  mobileContext,
  setMobileContext,
  isDisabled,
  map,
}: any) => {
  const responsive = useResponsive();
  const [isReordering, setIsReordering] = useState(false);
  const title = ProjectBlockKind.getDisplay(ProjectBlockKind.Job.STOPS);

  const jobStopsContentProps = {
    form,
    field,
    jobsField,
    project,
    isReordering,
    isDisabled,
    map,
  };

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={EditJobStops}
          bodyComponentProps={jobStopsContentProps}
          ActionButtonComponent={ReorderToggle}
          actionButtonComponentProps={{isReordering, setIsReordering, isDisabled}}
          title={ProjectBlockKind.getDisplay(ProjectBlockKind.Job.STOPS)}
          style={{width: '100%'}}
        />
      ) : (
        <MobileEditJobBlock headerText={title} responsive={responsive}>
          <MobileEditJobStopsHeader
            mobileContext={mobileContext}
            setMobileContext={setMobileContext}
            isReordering={isReordering}
            setIsReordering={setIsReordering}
            isDisabled={isDisabled}
          />
          <Space height={16} />
          {mobileContext === MOBILE_CONTEXT.STOPS && <EditJobStops {...jobStopsContentProps} />}
          {mobileContext === MOBILE_CONTEXT.MAP && (
            <EditJobRouteBlock.Map {...jobStopsContentProps} />
          )}
        </MobileEditJobBlock>
      )}
    </React.Fragment>
  );
};

const EditJobStopsBlock = ({
  form,
  field,
  jobsField,
  index,
  handleSetPositionY,
  project,
  isDisabled,
  map,
}: any) => {
  const [mobileContext, setMobileContext] = useState(MOBILE_CONTEXT.STOPS);

  return (
    <EditProjectBlockWrapper index={index} handleSetPositionY={handleSetPositionY}>
      <EditJobStopsBlockContent
        form={form}
        field={field}
        jobsField={jobsField}
        project={project}
        mobileContext={mobileContext}
        setMobileContext={setMobileContext}
        isDisabled={isDisabled}
        map={map}
      />
    </EditProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobStopsBlock.fragment = gql`
  ${EditLocationsList.fragment}
  ${EditJobRouteBlock.fragment}

  fragment EditJobStopsBlock on Project {
    id
    ...EditLocationsList
    ...EditJobRouteBlock
  }
`;

export default EditJobStopsBlock;
