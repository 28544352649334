// Libraries
import React from 'react';

// Supermove
import {Emoji, Loading, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {Document} from '@supermove/utils';

// App
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import DocumentV2Pdf from '@shared/modules/Document/components/DocumentV2Pdf';
import CustomerDocumentV2 from 'modules/Document/DocumentV2/CustomerDocumentV2';

const PaddingContainer = Styled.View`
`;

const openPrintDialog = async ({
  url,
  setIsLoading,
}: {
  url: string;
  setIsLoading: (loading: boolean) => void;
}) => {
  setIsLoading(true);
  const filename = 'document.pdf';
  url = `${window.location.origin}${url}`;
  const pdf = await Document.generatePDFFromUrl({filename, url});
  Document.printFile({file: pdf});
  setIsLoading(false);
};

const openPrintPdfDialog = async ({
  setIsLoading,
  documentUuid,
}: {
  setIsLoading: (loading: boolean) => void;
  documentUuid: string;
}) => {
  const filename = 'document.pdf';
  setIsLoading(true);
  try {
    const pdf = await Document.fetchPDFFromS3({filename, documentUuid});
    Document.printFile({file: pdf});
  } catch (error) {
    console.error({error});
  } finally {
    setIsLoading(false);
  }
};

const DocumentSuccessStep = ({documentUuid, defaultCollapsed}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const responsive = useResponsive();
  const {loading, data} = useQuery(DocumentSuccessStep.query, {
    variables: {
      documentUuid,
    },
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Loading loading={loading}>
      {() => {
        const {document} = data;
        return (
          <CollapsibleContent
            TitleIconComponent={<Emoji style={{paddingVertical: 6}} name={'pencil'} />}
            title={document.displayName}
            // Disabling the primaryActionText on mobile will hide the button
            // TODO(jholston): We can turn this on when mobile printing is supported
            // @ts-expect-error TS(2322): Type 'false | "Print"' is not assignable to type '... Remove this comment to see the full error message
            primaryActionText={!responsive.mobile && 'Print'}
            handlePrimaryAction={() => {
              if (data.document.organization.features.isEnabledOfficeChangeDocumentViewToPdfView) {
                openPrintPdfDialog({
                  documentUuid: data.document.uuid,
                  setIsLoading,
                });
              } else {
                openPrintDialog({
                  url: `/0/${document.organization.slug}/documents/v2/${document.uuid}`,
                  setIsLoading,
                });
              }
            }}
            defaultCollapsed={defaultCollapsed}
            isSubmittingPrimaryAction={isLoading}
          >
            <PaddingContainer {...responsive}>
              {data.document.organization.features.isEnabledOfficeChangeDocumentViewToPdfView ? (
                <DocumentV2Pdf
                  document={data.document}
                  viewerWidth={responsive.mobile ? 336 : 792}
                />
              ) : (
                <CustomerDocumentV2 document={data.document} />
              )}
            </PaddingContainer>
          </CollapsibleContent>
        );
      }}
    </Loading>
  );
};

DocumentSuccessStep.query = gql`
  ${CustomerDocumentV2.fragment}
  ${DocumentV2Pdf.fragment}
  query DocumentSuccessStep($documentUuid: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      uuid
      displayName
      organization {
        id
        slug
        features {
          isEnabledOfficeChangeDocumentViewToPdfView: isEnabled(
            feature: "OFFICE_CHANGE_DOCUMENT_VIEW_TO_PDF_VIEW"
          )
        }
      }
      ...CustomerDocumentV2
      ...DocumentV2Pdf
    }
  }
`;

export default DocumentSuccessStep;
