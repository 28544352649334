// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useState,
  usePopover,
  useToast,
  useRef,
  useEffect,
  Form,
  DrawerType,
} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import InventoryRoomsForm, {
  InventoryRoomsFormType,
} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import RoomItemsForm from '@shared/modules/Inventory/forms/RoomItemsForm';
import {GetRefType} from '@shared/utilities/typescript';
import EditRoomAttachmentsButton from 'modules/Inventory/Edit/components/EditRoomAttachmentsButton';
import EditRoomSectionTable from 'modules/Inventory/Edit/components/EditRoomSectionTable';

const CollapsibleContainer = Styled.View`
  border-radius: 4px;
  border: 1px solid ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.interactive : colors.gray.border)};
  overflow: hidden;
`;
const HeaderContainer = Styled.ButtonV2`
  flex-direction: row;
  height: 48px;
  padding: 12px 24px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.accent : colors.gray.background)};
  justify-content: space-between;
  align-items: center;
  border-bottom-width: ${({
    // @ts-expect-error TS(2339): Property 'isExpanded' does not exist on type 'Them... Remove this comment to see the full error message
    isExpanded,
  }) => (isExpanded ? '1px' : '0px')};
  border-bottom-color: ${colors.gray.border};
`;

const HeaderTitle = Styled.Text`
  ${Typography.Subheading}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const HeaderSection = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const IconButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
`;

const InfoTitle = Styled.Text`
  ${Typography.Micro}
`;

const InfoValue = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const InfoTextContainer = Styled.View`
  width: 50px;
  align-items: center;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ActionsPopover = ({popover, handleRemove, setIsEditingRoomName}: any) => {
  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ActionMenu
        width={148}
        actions={[
          {
            text: 'Edit',
            onPress: () => {
              setIsEditingRoomName(true);
            },
          },
          {
            text: 'Remove',
            onPress: handleRemove,
          },
        ]}
        handleClose={popover.handleClose}
      />
    </Popover>
  );
};

const InfoTextV1 = ({title, value}: any) => {
  return (
    <InfoTextContainer>
      <InfoTitle>{title}</InfoTitle>
      <Space width={8} />
      <InfoValue>{value}</InfoValue>
    </InfoTextContainer>
  );
};

const InfoText = ({title, value}: any) => {
  return (
    <Row>
      <InfoTitle>{title}</InfoTitle>
      <Space width={8} />
      <InfoValue>{value}</InfoValue>
    </Row>
  );
};

const RoomSummaryInfo = ({
  roomItemsForm,
  itemTypeKindFilters,
  isEnabledResponsiveSurveysForDesktop,
}: any) => {
  const {takeCount, leaveCount, volume, weight, price} = RoomItemsForm.getInfo(
    roomItemsForm,
    itemTypeKindFilters,
  );
  return isEnabledResponsiveSurveysForDesktop ? (
    <React.Fragment>
      <InfoText title={'TAKE'} value={takeCount} />
      <Space width={32} />
      <InfoText title={'LEAVE'} value={leaveCount} />
      <Space width={32} />
      <InfoText title={'VOLUME'} value={`${volume} cu ft`} />
      <Space width={32} />
      <InfoText title={'WEIGHT'} value={`${weight} lbs`} />
      <Space width={32} />
      <InfoText title={'EST. PRICE'} value={Currency.display(price)} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <InfoTextV1 title={'TAKE'} value={takeCount} />
      <Space width={32} />
      <InfoTextV1 title={'LEAVE'} value={leaveCount} />
      <Space width={32} />
      <InfoTextV1 title={'VOLUME'} value={volume} />
      <Space width={32} />
      <InfoTextV1 title={'WEIGHT'} value={weight} />
    </React.Fragment>
  );
};

const EditRoomSection = ({
  isSelected,
  onPress,
  inventoryRoomsForm,
  index,
  defaultDensityFactor,
  itemTypeKindFilters,
  setActiveRoomRef,
  isEnabledResponsiveSurveysForDesktop,
  handleScrollRoomToBottom,
  isEnabledSurveysTimeAdditives,
  isEnabledSurveysItemPhotos,
  organizationId,
  viewer,
  editRoomAttachmentsSidebar,
  isEnabledSurveysVirtualWalkthroughSupport,
  isVirtualWalkthrough,
}: {
  isSelected: boolean;
  onPress: () => void;
  inventoryRoomsForm: Form<{inventoryRoomsForm: InventoryRoomsFormType}>;
  index: number;
  defaultDensityFactor: number;
  itemTypeKindFilters: string[];
  setActiveRoomRef: (ref: GetRefType<React.ComponentType>) => void;
  isEnabledResponsiveSurveysForDesktop: boolean;
  handleScrollRoomToBottom: () => void;
  isEnabledSurveysTimeAdditives: boolean;
  isEnabledSurveysItemPhotos: boolean;
  organizationId: number;
  viewer: UserModel;
  editRoomAttachmentsSidebar: DrawerType;
  isEnabledSurveysVirtualWalkthroughSupport: boolean;
  isVirtualWalkthrough: boolean;
}) => {
  const roomItemsForm = _.get(
    inventoryRoomsForm,
    `values.inventoryRoomsForm.roomItemsForms.${index}`,
  );
  const virtualizedListRef = useRef(null);
  const noNameErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Room name required',
  });
  const [roomName, setRoomName] = useState(roomItemsForm.name);
  const isNewCustomRoom =
    roomItemsForm.roomId === undefined && roomItemsForm.name === 'Custom Room';
  const [isEditingRoomName, setIsEditingRoomName] = useState(isNewCustomRoom);
  const popover = usePopover();
  const filteredItemForms = roomItemsForm.itemForms.filter(
    (itemForm: any) => !itemForm.isDeleted && _.includes(itemTypeKindFilters, itemForm.kind),
  );

  // Make sure the active room ref is set to the selectedRoomIndex
  // Specifically handles case where page loads for first time
  useEffect(() => {
    if (index === inventoryRoomsForm.values.inventoryRoomsForm.selectedRoomIndex) {
      setActiveRoomRef(virtualizedListRef);
    }
  }, [index, inventoryRoomsForm.values.inventoryRoomsForm.selectedRoomIndex, setActiveRoomRef]);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <CollapsibleContainer isSelected={isSelected}>
      <HeaderContainer
        isSelected={isSelected}
        isExpanded={
          !_.get(
            inventoryRoomsForm.values,
            `inventoryRoomsForm.roomItemsForms.${index}.isCollapsed`,
          )
        }
        onPress={() => {
          onPress();
        }}
      >
        <HeaderSection>
          <IconButton
            onPress={() => {
              inventoryRoomsForm.setFieldValue(
                `inventoryRoomsForm.roomItemsForms.${index}.isCollapsed`,
                !roomItemsForm.isCollapsed,
              );
            }}
            style={{height: 24, width: 24}}
          >
            <Icon
              source={roomItemsForm.isCollapsed ? Icon.ChevronDown : Icon.ChevronUp}
              color={colors.gray.primary}
              size={14}
            />
          </IconButton>
          <Space width={8} />
          {isEditingRoomName ? (
            <FieldInput
              {...inventoryRoomsForm}
              value={roomName}
              handleChange={(event) => {
                const text = event.target.value;
                setRoomName(text);
              }}
              setFieldValue={() => {
                // Override the default setFieldValue behavior to do nothing
              }}
              handleBlur={(event) => {
                const text = event.target.value;
                if (text === '') {
                  noNameErrorToast.handleToast();
                  setRoomName(roomItemsForm.name);
                  setIsEditingRoomName(false);
                  return;
                }
                inventoryRoomsForm.setFieldValue(
                  `inventoryRoomsForm.roomItemsForms.${index}.name`,
                  text,
                );
                inventoryRoomsForm.setFieldValue(
                  `inventoryRoomsForm.roomItemsForms.${index}.isDirty`,
                  true,
                );
                setIsEditingRoomName(false);
              }}
              input={{
                autoFocus: true,
              }}
            />
          ) : (
            <HeaderTitle color={isSelected ? colors.gray.primary : colors.gray.secondary}>
              {roomItemsForm.name}
            </HeaderTitle>
          )}
          <Space width={8} />
          <Popover.Content innerRef={popover.ref}>
            <IconButton onPress={popover.handleToggle} style={{width: 16, height: 16}}>
              <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
            </IconButton>
          </Popover.Content>
        </HeaderSection>
        <HeaderSection>
          <RoomSummaryInfo
            roomItemsForm={roomItemsForm}
            itemTypeKindFilters={itemTypeKindFilters}
            isEnabledResponsiveSurveysForDesktop={isEnabledResponsiveSurveysForDesktop}
          />
          <Space width={16} />
          {isEnabledSurveysVirtualWalkthroughSupport && isVirtualWalkthrough && (
            <EditRoomAttachmentsButton
              roomItemsForm={roomItemsForm}
              handleOnPress={() => {
                isSelected
                  ? editRoomAttachmentsSidebar.handleToggle()
                  : editRoomAttachmentsSidebar.handleOpen();
                onPress();
              }}
            />
          )}
        </HeaderSection>
      </HeaderContainer>
      {!roomItemsForm.isCollapsed && (
        <EditRoomSectionTable
          inventoryRoomsForm={inventoryRoomsForm}
          index={index}
          defaultDensityFactor={defaultDensityFactor}
          isEnabledResponsiveSurveysForDesktop={isEnabledResponsiveSurveysForDesktop}
          virtualizedListRef={virtualizedListRef}
          filteredItemForms={filteredItemForms}
          handleScrollRoomToBottom={handleScrollRoomToBottom}
          isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
          isEnabledSurveysItemPhotos={isEnabledSurveysItemPhotos}
          organizationId={organizationId}
          viewer={viewer}
        />
      )}
      <ActionsPopover
        key={popover.key}
        popover={popover}
        handleRemove={() => {
          InventoryRoomsForm.handleDeleteRoom({inventoryRoomsForm, index});
        }}
        setIsEditingRoomName={setIsEditingRoomName}
      />
    </CollapsibleContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditRoomSection.fragment = gql`
  ${EditRoomSectionTable.fragment}
  fragment EditRoomSection on User {
    id
    ...EditRoomSectionTable
  }

  fragment EditRoomSection_Inventory on Inventory {
    id
    surveyMethod {
      id
      kind
    }
  }
`;

export default EditRoomSection;
