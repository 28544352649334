// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import DocumentV2Pdf from '@shared/modules/Document/components/DocumentV2Pdf';
import CustomerDocumentV2 from 'modules/Document/DocumentV2/CustomerDocumentV2';

const DocumentContainer = Styled.View`
  padding-vertical: 16px;
  padding-horizontal: 24px;
  background-color: ${colors.white};
`;

const DocumentV2Inline = ({uuid}: any) => {
  const {loading, data} = useQuery(DocumentV2Inline.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid,
    },
  });

  return (
    <Loading loading={loading}>
      {() =>
        data.document.isUploaded ? (
          <DocumentV2Pdf document={data.document} viewerWidth={788} />
        ) : (
          <DocumentContainer>
            <CustomerDocumentV2 document={data.document} />
          </DocumentContainer>
        )
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2Inline.query = gql`
  ${CustomerDocumentV2.fragment}
  ${DocumentV2Pdf.fragment}
  query DocumentV2Inline($uuid: String!) {
    ${gql.query}
    document(uuid: $uuid) {
      id
      isUploaded
      ...CustomerDocumentV2
      ...DocumentV2Pdf
    }
  }
`;

export default DocumentV2Inline;
