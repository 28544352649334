// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, Form, ResponsiveType} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ProjectAttachmentUploader from '@shared/modules/File/components/ProjectAttachmentUploader';
import {UpdateAttachmentMutationWrapper} from '@shared/modules/File/hooks/useUpdateAttachmentsMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const EmailAttachmentDrawerUploadFiles = ({
  form,
  projectUuid,
  responsive,
}: {
  form: Form<UpdateAttachmentMutationWrapper>;
  projectUuid: string;
  responsive: ResponsiveType;
}) => {
  const {data, loading} = useQuery(EmailAttachmentDrawerUploadFiles.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
  });

  return (
    <Loading loading={loading || !data} as={PageLoadingIndicator}>
      {() => {
        return (
          <ProjectAttachmentUploader
            form={form}
            field={'updateAttachmentsForm.updateAttachmentForms'}
            project={data.project}
            isEnabledIsVisibleToCrewColumn={false}
            isEnabledHardDeleteAttachments
            dragInputContainerStyle={responsive.mobile ? {backgroundColor: colors.white} : {}}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachmentDrawerUploadFiles.query = gql`
  ${ProjectAttachmentUploader.fragment}

  query EmailAttachmentDrawerUploadFiles($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...ProjectAttachmentUploader
    }
  }
`;

export default EmailAttachmentDrawerUploadFiles;
