// Libraries
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job, JobModel, Project} from '@supermove/models';
import {fontWeight} from '@supermove/styles';

const Container = Styled.View<{color: string}>`
  align-items: center;
  justify-content: center;
  width: 180px;
  padding-vertical: 2px;
  border-width: 2px;
  border-color: ${({color}) => color};
  border-radius: 30px;
`;

const Section = Styled.View`
`;

const Primary = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const Secondary = Styled.H8`
  font-size: 11px;
  color: ${(props) => (props as any).color};
`;

const getPrimaryStatusText = ({job}: {job: JobModel}) => {
  const salesStatus = Project.getSalesStatus(job.project);
  const salesStatusText = Project.getSalesStatusText(job.project);
  const operationsStatusText = Job.getOperationsStatusText(job);

  if (job.isCancelled) {
    return 'Removed';
  }
  if (job.project.isCancelled) {
    return 'Cancelled';
  }
  switch (salesStatus) {
    case 'LEAD':
    case 'HOLD':
      return salesStatusText;
    case 'BOOKED':
    default:
      return operationsStatusText;
  }
};

const JobStatusBadge = ({job, style}: any) => {
  const color = Job.getCalendarPrimaryStatusColor(job, {showCancelledProject: true});
  const statusIcon = Job.getCalendarSecondaryStatusIcon(job, {showCancelledProject: true});
  const {isCancelled} = job.project;

  return (
    <Container color={color} style={style}>
      <Section>
        <Primary color={color}>{getPrimaryStatusText({job})}</Primary>
      </Section>
      <Section>
        {!isCancelled && !!Job.getCalendarSecondaryStatusText(job) && (
          <Secondary color={color}>{Job.getCalendarSecondaryStatusText(job)}</Secondary>
        )}
        {!!statusIcon && <Icon color={color} size={Icon.Sizes.Small} source={statusIcon} />}
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobStatusBadge.fragment = gql`
  ${Job.getCalendarPrimaryStatusColor.fragment}
  ${Job.getCalendarPrimaryStatusText.fragment}
  ${Job.getCalendarSecondaryStatusText.fragment}
  ${Job.getOperationsStatusText.fragment}
  ${Job.getCalendarSecondaryStatusIcon.fragment}
  ${Project.getSalesStatus.fragment}
  ${Project.getSalesStatusText.fragment}

  fragment JobStatusBadge on Job {
    id
    isCancelled
    project {
      id
      isCancelled
      ...Project_getSalesStatus
      ...Project_getSalesStatusText
    }
    ...Job_getCalendarPrimaryStatusColor
    ...Job_getCalendarPrimaryStatusText
    ...Job_getCalendarSecondaryStatusIcon
    ...Job_getCalendarSecondaryStatusText
    ...Job_getOperationsStatusText
  }
`;

export default JobStatusBadge;
