import {Icon, IconSource} from '@supermove/components';

export const getIconFromExtension = (extension?: string): IconSource => {
  if (!extension) {
    return Icon.File;
  }
  if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg', 'tif'].includes(extension)) {
    return Icon.Image;
  }
  if (['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', '3pg'].includes(extension)) {
    return Icon.Play;
  }
  return Icon.FileLines;
};
