// Supermove

import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobTypeStepDocumentTemplateForm, {
  JobTypeStepDocumentTemplateFormType,
} from '@shared/modules/Job/forms/JobTypeStepDocumentTemplateForm';

export interface JobTypeStepDocumentTemplateFormWrapperType {
  jobTypeStepDocumentTemplateForm: JobTypeStepDocumentTemplateFormType;
}

const useUpdateJobTypeStepDocumentTemplateMutation = ({
  jobTypeStepDocumentTemplateForm,
  onSuccess,
  onError,
}: {
  jobTypeStepDocumentTemplateForm: JobTypeStepDocumentTemplateFormType;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      jobTypeStepDocumentTemplateForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateJobTypeStepDocumentTemplateMutation.mutation,
    variables: {
      jobTypeStepDocumentTemplateForm: JobTypeStepDocumentTemplateForm.toMutation(
        form.values.jobTypeStepDocumentTemplateForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateJobTypeStepDocumentTemplateMutation.mutation = gql`
  mutation useUpdateJobTypeStepDocumentTemplateMutation($jobTypeStepDocumentTemplateForm: JobTypeStepDocumentTemplateForm!){
    response: updateJobTypeStepDocumentTemplate(jobTypeStepDocumentTemplateForm: $jobTypeStepDocumentTemplateForm) {
      ${gql.errors}
    }
  }
`;

export default useUpdateJobTypeStepDocumentTemplateMutation;
