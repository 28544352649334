// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, usePagination, useModal, useToast} from '@supermove/hooks';
import {WorkflowModel} from '@supermove/models';
import {URL, pluralize} from '@supermove/utils';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Button from '@shared/design/components/Button';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import useCancelWorkflowRunsForWorkflow from '@shared/modules/Workflow/hooks/useCancelWorkflowRunsForWorkflowMutation';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import WorkflowRunsFilters from 'modules/Organization/Settings/Workflow/components/WorkflowRunsFilters';
import WorkflowRunsTable from 'modules/Organization/Settings/Workflow/components/WorkflowRunsTable';

const Section = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.View`
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  padding: 24px;
  flex: 1;
  min-width: 1200px;
`;

const getPageUrl = ({params, page}: any) => {
  const {workflowUuid} = params;
  const variables = {page};
  return URL.getUrlFromVariables(`/settings/automations/${workflowUuid}/runs`, variables);
};

const PageHeader = ({workflowRunName}: any) => {
  const {navigator} = useNavigationDOM();
  const breadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {
      name: 'Automations',
      onPress: () => navigator.push('/settings/automations'),
      isBeta: true,
    },
    {name: workflowRunName},
  ];

  return (
    <Container>
      <StandardOfficeHeader title={''} showLine={false}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </StandardOfficeHeader>
    </Container>
  );
};

const CancelAutomationRunsButton = ({
  workflow,
  handlePress,
}: {
  workflow: WorkflowModel;
  handlePress: () => void;
}) => {
  const isEmpty = workflow.cancellableWorkflowRunCount === 0;
  const emptyText = 'Cancel Automation Runs';

  if (isEmpty) {
    return (
      <TextTooltip
        text={'Automation runs that are pending or in progress can be cancelled.'}
        placement={'bottom'}
      >
        <Container>
          <Button text={emptyText} isDisabled />
        </Container>
      </TextTooltip>
    );
  }

  return (
    <Button
      onPress={handlePress}
      text={`Cancel (${workflow.cancellableWorkflowRunCount}) ${pluralize('Automation Run', workflow.cancellableWorkflowRunCount)}`}
    />
  );
};

const CancelWorkflowRunsForWorkflowModal = ({isOpen, onClose, workflow, refetch}: any) => {
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Workflow runs scheduled for cancellation.',
  });
  const {submitting, handleSubmit} = useCancelWorkflowRunsForWorkflow({
    workflowForm: WorkflowForm.new({
      workflowId: workflow.id,
      organizationId: workflow.organizationId,
    }),
    onSuccess: () => {
      refetch();
      successToast.handleToast();
      onClose();
    },
    onError: () => {
      onClose();
    },
  });

  return (
    <ActionModal
      isOpen={isOpen}
      title={`Cancel ${pluralize('Automation Run', workflow.cancellableWorkflowRunCount)}`}
      message={`Are you sure you want to cancel ${workflow.cancellableWorkflowRunCount} pending or in-progress ${pluralize('automation run', workflow.cancellableWorkflowRunCount)} for ${workflow.name}?`}
      handlePressOutside={onClose}
      handleSecondaryAction={onClose}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Close'}
      primaryActionText={`Cancel ${pluralize('Run', workflow.cancellableWorkflowRunCount)}`}
      isSubmitting={submitting}
    />
  );
};

const OrganizationSettingsWorkflowRunsPageContent = ({
  workflowRuns,
  pagination,
  refetch,
  workflow,
}: any) => {
  const cancelWorkflowRunsForWorkflowModal = useModal({name: 'CancelWorkflowRunsForWorkflowModal'});

  return (
    <PageContentContainer>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={0}>
        <Row>
          <WorkflowRunsFilters />
          <CancelAutomationRunsButton
            workflow={workflow}
            handlePress={cancelWorkflowRunsForWorkflowModal.handleOpen}
          />
        </Row>
      </Section>
      <Space height={32} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={1} style={{flex: 1}}>
        <WorkflowRunsTable workflowRuns={workflowRuns} refetch={refetch} />
      </Section>
      <Space height={32} />
      <PaginationBar pagination={pagination} />
      <CancelWorkflowRunsForWorkflowModal
        isOpen={cancelWorkflowRunsForWorkflowModal.isOpen}
        onClose={cancelWorkflowRunsForWorkflowModal.handleClose}
        workflow={workflow}
        refetch={refetch}
      />
    </PageContentContainer>
  );
};

const OrganizationSettingsWorkflowRunsPage = () => {
  const {params, navigator} = useNavigationDOM();

  const {loading, data, refetch} = useQuery(OrganizationSettingsWorkflowRunsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      workflowUuid: params.workflowUuid,
      pagination: {
        page: params.page,
        resultsPerPage: 20,
      },
      searchQuery: params.query,
      fromDate: params.fromDate,
      toDate: params.toDate,
      statuses: params.statuses,
    },
  });

  const pagination = usePagination({
    currentPage: _.toNumber(params.page || 1),
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      navigator.replace(getPageUrl({params, page}));
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading}>
        {() => (
          <PageContainer>
            <PageHeader workflowRunName={data.workflow.name} />
            <OrganizationSettingsWorkflowRunsPageContent
              workflowRuns={data.paginatedList.workflowRuns}
              pagination={pagination}
              refetch={refetch}
              workflow={data.workflow}
            />
          </PageContainer>
        )}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsWorkflowRunsPage.query = gql`
  ${usePagination.fragment}
  ${WorkflowRunsTable.fragment}
  query OrganizationSettingsWorkflowRunsPage(
    $pagination: PaginationInput!
    $workflowUuid: String!
    $searchQuery: String
    $fromDate: String
    $toDate: String
    $statuses: [String]
  ) {
    ${gql.query}
    paginatedList: filteredWorkflowRunsPaginatedList(
      pagination: $pagination,
      workflowUuid: $workflowUuid,
      searchQuery: $searchQuery,
      fromDate: $fromDate,
      toDate: $toDate,
      statuses: $statuses,
    ) {
      workflowRuns: results {
        id
        ...WorkflowRunsTable
      }
      paginationMetadata {
        ...usePagination
      }
    }
    workflow(uuid: $workflowUuid) {
      id
      name
      cancellableWorkflowRunCount
      organizationId
    }
  }
`;

export default OrganizationSettingsWorkflowRunsPage;
