// Libraries
import React from 'react';

// Supermove
import {Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useModal,
  useNavigationDOM,
  usePopover,
  useResponsive,
  useSheet,
  useState,
} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import CreateStorageProjectFromMoveProjectModal from 'modules/Job/V2/Storage/components/CreateStorageProjectFromMoveProjectModal';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import BlockDuplicateProjectModal from 'modules/Project/V2/Show/components/BlockDuplicateProjectModal';
import CancelProjectModal from 'modules/Project/V2/Show/components/CancelProjectModal';
import ConvertToTrainingModal from 'modules/Project/V2/Show/components/ConvertToTrainingModal';
import DuplicateProjectDrawer from 'modules/Project/V2/Show/components/DuplicateProjectDrawer';
import EditProjectCancellationReasonModal from 'modules/Project/V2/Show/components/EditProjectCancellationReasonModal';
import EditProjectInformationDrawer from 'modules/Project/V2/Show/components/EditProjectInformationDrawer';
import ProjectActionsPopover from 'modules/Project/V2/Show/components/ProjectActionsPopover';
import ProjectActionsSheet from 'modules/Project/V2/Show/components/ProjectActionsSheet';
import RestoreProjectModal from 'modules/Project/V2/Show/components/RestoreProjectModal';
import CreateProjectSuccessModal from 'modules/Project/components/CreateProjectSuccessModal';

const getProjectActionSections = ({
  project,
  editProjectInformationDrawer,
  duplicateProjectDrawer,
  blockDuplicateProjectModal,
  editProjectVariablesModal,
  createStorageProjectForMoveProjectModal,
  convertToTrainingModal,
  cancelProjectModal,
  restoreProjectModal,
  editProjectCancellationReasonModal,
  handleClose,
  responsive,
  navigator,
}: any) => {
  const {
    isEnabledUpdateProjectAndJobTypesForProject,
    isEnabledEscapeProjectPageV2,
    isEnabledEscapeProjectPageV2Mobile,
    isEnabledCancelProject,
  } = project.organization.features;
  const {isStorageEnabled} = project.organization.settings;

  return [
    {
      label: 'Project',
      actions: [
        {
          label: 'Edit project name & description',
          onPress: editProjectInformationDrawer.handleOpen,
          handleClose,
        },
        ...List.insertIf(isEnabledUpdateProjectAndJobTypesForProject, {
          label: 'Edit project type & job types',
          onPress: () => navigator.push(`/projects/${project.uuid}/edit/types`),
          handleClose,
        }),
        {
          label: 'Duplicate project',
          onPress: Project.getIsAtMaxJobs(project)
            ? blockDuplicateProjectModal.handleOpen
            : duplicateProjectDrawer.handleOpen,
          handleClose,
        },
        {
          label: 'Edit variables',
          onPress: editProjectVariablesModal.handleOpen,
          handleClose,
          isDisabled: project.mainMoveInvoice.status === InvoiceStatus.FINALIZED,
          disabledExplanation: 'Cannot edit variables after the invoice has been finalized.',
        },
        ...List.insertIf(
          isEnabledCancelProject,
          project.isCancelled
            ? {
                label: 'Restore project',
                onPress: restoreProjectModal.handleOpen,
                handleClose,
              }
            : {
                label: 'Cancel project',
                onPress: cancelProjectModal.handleOpen,
                handleClose,
                isDisabled: project.isComplete,
                disabledExplanation: 'This project is completed and cannot be cancelled.',
                color: colors.red.warning,
              },
        ),
        ...List.insertIf(isEnabledCancelProject && project.isCancelled, {
          label: 'Edit cancellation reason',
          onPress: editProjectCancellationReasonModal.handleOpen,
          handleClose,
        }),
      ],
    },
    ...List.insertIf(isStorageEnabled, {
      label: 'Storage',
      actions: [
        {
          label: 'Create storage project',
          onPress: createStorageProjectForMoveProjectModal.handleOpen,
          handleClose,
        },
      ],
    }),
    {
      label: 'Admin',
      actions: [
        {
          label: `Convert to ${project.isTest ? 'real' : 'training'}`,
          onPress: convertToTrainingModal.handleOpen,
          handleClose,
        },
        ...List.insertIf(
          isEnabledEscapeProjectPageV2 ||
            (!responsive.desktop && isEnabledEscapeProjectPageV2Mobile),
          {
            label: 'Revert to previous view',
            onPress: () => navigator.push(`/projects/${project.uuid}?overrideV1`),
            handleClose,
          },
        ),
      ],
    },
  ];
};

const ProjectHeaderActions = ({project, refetch}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const projectActionPopover = usePopover({name: 'Project Action Popover'});
  const projectActionsSheet = useSheet({name: 'Project Actions Sheet', enableTracking: true});
  const editProjectInformationDrawer = useDrawer({name: 'Edit Project Information Drawer'});
  const convertToTrainingModal = useModal({name: 'Convert to Training Modal'});
  const createStorageProjectForMoveProjectModal = useModal({
    name: 'Project Actions Create Storage Project Modal',
    enableTracking: true,
  });
  const createProjectSuccessModal = useModal({
    name: 'Project Actions Create Project Success Modal',
  });
  const duplicateProjectDrawer = useDrawer({
    name: 'Duplicate Project Drawer',
    enableTracking: true,
  });
  const editProjectVariablesModal = useModal({
    name: 'Edit Project Variables Modal',
    enableTracking: true,
  });
  const cancelProjectModal = useModal({
    name: 'Cancel Project Modal',
    enableTracking: true,
  });
  const restoreProjectModal = useModal({
    name: 'Restore Project Modal',
    enableTracking: true,
  });
  const editProjectCancellationReasonModal = useModal({
    name: 'Edit Cancellation Reason Modal',
    enableTracking: true,
  });
  const updateValuesForm = UpdateValuesForm.edit(project);
  const updateValuesMutation = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editProjectVariablesModal.handleClose();
      refetch();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });
  const blockDuplicateProjectModal = useDrawer({
    name: 'Block Duplicate Project Modal',
    enableTracking: true,
  });
  const [storageProjectUuid, setStorageProjectUuid] = useState();
  const projectActionSections = getProjectActionSections({
    project,
    editProjectInformationDrawer,
    duplicateProjectDrawer,
    blockDuplicateProjectModal,
    editProjectVariablesModal,
    createStorageProjectForMoveProjectModal,
    convertToTrainingModal,
    cancelProjectModal,
    restoreProjectModal,
    editProjectCancellationReasonModal,
    handleClose: responsive.desktop
      ? projectActionPopover.handleClose
      : projectActionsSheet.handleClose,
    responsive,
    navigator,
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={projectActionPopover.ref}>
        <TertiaryButton
          onPress={
            responsive.desktop ? projectActionPopover.handleToggle : projectActionsSheet.handleOpen
          }
          style={{
            width: responsive.desktop ? 24 : 32,
            height: responsive.desktop ? 24 : 32,
          }}
        >
          <Icon
            source={Icon.EllipsisV}
            size={responsive.desktop ? 12 : 16}
            color={colors.gray.secondary}
          />
        </TertiaryButton>
      </Popover.Content>
      {responsive.desktop ? (
        <ProjectActionsPopover
          projectActionPopover={projectActionPopover}
          projectActionSections={projectActionSections}
        />
      ) : (
        <ProjectActionsSheet
          isOpen={projectActionsSheet.isOpen}
          handleClose={projectActionsSheet.handleClose}
          projectActionSections={projectActionSections}
        />
      )}
      <EditProjectInformationDrawer
        key={editProjectInformationDrawer.key}
        project={project}
        isOpen={editProjectInformationDrawer.isOpen}
        handleClose={editProjectInformationDrawer.handleClose}
      />
      <ConvertToTrainingModal
        key={convertToTrainingModal.key}
        project={project}
        isOpen={convertToTrainingModal.isOpen}
        handleClose={convertToTrainingModal.handleClose}
        refetch={refetch}
      />
      <CreateStorageProjectFromMoveProjectModal
        key={createStorageProjectForMoveProjectModal.key}
        isOpen={createStorageProjectForMoveProjectModal.isOpen}
        handleClose={createStorageProjectForMoveProjectModal.handleClose}
        projectUuid={project.uuid}
        isDisabledClientFields
        createProjectSuccessModal={createProjectSuccessModal}
        handleSuccess={({uuid}: any) => {
          setStorageProjectUuid(uuid);
          createStorageProjectForMoveProjectModal.handleClose();
          createProjectSuccessModal.handleOpen();
        }}
      />
      <CreateProjectSuccessModal
        key={createProjectSuccessModal.key}
        projectUuid={storageProjectUuid}
        isOpen={createProjectSuccessModal.isOpen}
        handleClose={createProjectSuccessModal.handleClose}
        title={'Storage project created!'}
        backButtonText={'Close'}
        handleViewProject={() => navigator.push(`/storage/projects/${storageProjectUuid}`)}
      />
      <DuplicateProjectDrawer
        key={duplicateProjectDrawer.key}
        projectUuid={project.uuid}
        isOpen={duplicateProjectDrawer.isOpen}
        handleClose={duplicateProjectDrawer.handleClose}
      />
      <EditBillingValuesModal
        key={editProjectVariablesModal.key}
        isOpen={editProjectVariablesModal.isOpen}
        handleClose={editProjectVariablesModal.handleClose}
        updateValuesForm={updateValuesMutation.form}
        handleSubmit={updateValuesMutation.handleSubmit}
        projectUuid={project.uuid}
      />
      <BlockDuplicateProjectModal
        key={blockDuplicateProjectModal.key}
        isOpen={blockDuplicateProjectModal.isOpen}
        handleClose={blockDuplicateProjectModal.handleClose}
      />
      <CancelProjectModal
        key={cancelProjectModal.key}
        project={project}
        isOpen={cancelProjectModal.isOpen}
        handleClose={cancelProjectModal.handleClose}
        refetch={refetch}
      />
      <RestoreProjectModal
        key={restoreProjectModal.key}
        project={project}
        isOpen={restoreProjectModal.isOpen}
        handleClose={restoreProjectModal.handleClose}
        refetch={refetch}
      />
      <EditProjectCancellationReasonModal
        key={editProjectCancellationReasonModal.key}
        project={project}
        isOpen={editProjectCancellationReasonModal.isOpen}
        handleClose={editProjectCancellationReasonModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectHeaderActions.fragment = gql`
  ${EditProjectInformationDrawer.fragment}
  ${UpdateValuesForm.edit.fragment}
  ${CancelProjectModal.fragment}
  ${ConvertToTrainingModal.fragment}
  ${Project.getIsAtMaxJobs.fragment}
  ${EditProjectCancellationReasonModal.fragment}

  fragment ProjectHeaderActions on Project {
    id
    isComplete
    isCancelled
    isTest
    uuid
    mainMoveInvoice {
      id
      status
    }
    activeJobsCount
    organization {
      id
      slug
      features {
        isEnabledEscapeProjectPageV2: isEnabled(feature: "ESCAPE_PROJECT_PAGE_V2")
        isEnabledEscapeProjectPageV2Mobile: isEnabled(feature: "ESCAPE_PROJECT_PAGE_V2_MOBILE")
        isEnabledUpdateProjectAndJobTypesForProject: isEnabled(
          feature: "UPDATE_PROJECT_AND_JOB_TYPES_FOR_PROJECT"
        )
        isEnabledCancelProject: isEnabled(feature: "CANCEL_PROJECT")
      }
      settings {
        id
        isStorageEnabled
      }
    }
    ...EditProjectInformationDrawer
    ...UpdateValuesForm_edit
    ...CancelProjectModal
    ...ConvertToTrainingModal
    ...Project_getIsAtMaxJobs
    ...EditProjectCancellationReasonModal
  }
`;

export default ProjectHeaderActions;
