// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover, Link} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useHover, useResponsive, useSheet} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, downloadFromUrl} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Table from '@shared/design/components/TableV2Deprecated';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import ProjectAttachmentSheet from 'modules/Project/Attachments/ProjectAttachmentSheet';
import AttachmentsFileDropzone from 'modules/Project/Attachments/components/AttachmentsFileDropzone';
import DeleteAttachmentModal from 'modules/Storage/components/DeleteAttachmentModal';

const CellContainer = Styled.View`
  flex: 1;
`;

const CenteredCellContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TableContainer = Styled.View`
  min-width: 100%;
  display: block;
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
  overflow: hidden;
`;

const SmallTableContainer = Styled.View`
  width: 100%;
`;

const SmallTableContentContainer = Styled.View`
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${colors.white};
`;

const SmallAttachmentItemContainer = Styled.View`
  flex-direction: column;
  padding: 12px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const Text = Styled.Text`
  ${Typography.Body}
  width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => ((props as any).isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body}
`;

const LinkContainer = Styled.View`
  flex: 1;
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const VerticalSeparator = Styled.Text`
  height: 100%;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const HorizonalSeparator = Styled.Text`
  width: 100%;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const AttachmentName = ({attachment, projectAttachmentSheet, responsive}: any) => {
  if (responsive.desktop) {
    return (
      <LinkContainer>
        <Link
          to={`/projects/${attachment.project.uuid}/attachments/${attachment.uuid}?status=view`}
          style={{width: '100%'}}
        >
          <Table.CellLink numberOfLines={1}>{attachment.file.name}</Table.CellLink>
        </Link>
      </LinkContainer>
    );
  }

  return (
    <LinkContainer>
      <Table.CellLink
        numberOfLines={1}
        onPress={projectAttachmentSheet.handleOpen}
        responsive={responsive}
      >
        {attachment.file.name}
      </Table.CellLink>
    </LinkContainer>
  );
};

const getAttachmentsTableColumnDefinitions = ({refetch}: any) => {
  return [
    // File Name
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>File Name</HeaderText>;
      },
      cellContent: ({item: attachment, responsive}: any) => (
        <AttachmentName attachment={attachment} responsive={responsive} />
      ),
    },
    // Notes
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Notes</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'attachment' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: attachment}) => {
        return (
          <CellContainer>
            {attachment.description ? (
              <Text numberOfLines={2}>{attachment.description}</Text>
            ) : (
              <Text>-</Text>
            )}
          </CellContainer>
        );
      },
    },
    // Visible To Crew
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Visible To Crew</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'attachment' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: attachment}) => {
        return (
          <CenteredCellContainer>
            {attachment.isVisibleToCrew && (
              <Icon color={colors.green.status} size={16} source={Icon.Check} />
            )}
          </CenteredCellContainer>
        );
      },
    },
    // Uploaded By
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Uploaded By</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'attachment' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: attachment}) => {
        return (
          <CellContainer>
            <Text>{attachment.file.creator?.fullName ?? ''}</Text>
          </CellContainer>
        );
      },
    },
    // Uploaded At
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Uploaded On</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'attachment' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: attachment}) => {
        return (
          <CellContainer>
            <Text>
              {Datetime.convertToDisplayDatetime(
                attachment.file.uploadedAt,
                Datetime.DISPLAY_SHORT_DATE,
              )}
            </Text>
          </CellContainer>
        );
      },
    },
    // Actions
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Actions</HeaderText>;
      },
      cellContent: ({item: attachment, responsive}: any) => {
        return (
          <AttachmentsActions attachment={attachment} refetch={refetch} responsive={responsive} />
        );
      },
    },
  ];
};

const MenuOptionButtonContainer = ({onPress, menuOptionText}: any) => {
  const {ref, isHovered} = useHover();

  return (
    <MenuOptionButton onPress={onPress} isHovered={isHovered} ref={ref}>
      <MenuItemText>{menuOptionText}</MenuItemText>
    </MenuOptionButton>
  );
};

const AttachmentActionsPopoverMenuOptions = ({
  attachment,
  deleteAttachmentModal,
  attachmentsActionsPopover,
}: any) => {
  return (
    <React.Fragment>
      <MenuOptionButtonContainer
        onPress={() => {
          attachmentsActionsPopover.handleClose();
          downloadFromUrl(attachment.file.downloadUrl);
        }}
        menuOptionText={'Download'}
      />
      <Space height={4} />
      <MenuOptionButtonContainer
        onPress={() => {
          attachmentsActionsPopover.handleClose();
          deleteAttachmentModal.handleOpen();
        }}
        menuOptionText={'Delete'}
      />
    </React.Fragment>
  );
};

const AttachmentActionsPopover = ({
  attachmentsActionsPopover,
  attachment,
  refetch,
  deleteAttachmentModal,
}: any) => {
  return (
    <React.Fragment>
      <Popover
        placement={Popover.Positions.LeftStart}
        isOpen={attachmentsActionsPopover.isOpen}
        handleOpen={attachmentsActionsPopover.handleOpen}
        handleClose={attachmentsActionsPopover.handleClose}
        reference={attachmentsActionsPopover.ref}
        offset={[0, 4]}
      >
        <ResponsivePopover.StaticContainer width={160}>
          <Space height={8} />
          <AttachmentActionsPopoverMenuOptions
            attachment={attachment}
            attachmentsActionsPopover={attachmentsActionsPopover}
            deleteAttachmentModal={deleteAttachmentModal}
          />
          <Space height={8} />
        </ResponsivePopover.StaticContainer>
      </Popover>
      <DeleteAttachmentModal
        attachment={attachment}
        deleteAttachmentModal={deleteAttachmentModal}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const AttachmentsActions = ({attachment, refetch, responsive}: any) => {
  const attachmentsActionsPopover = usePopover();
  const deleteAttachmentModal = useModal({name: 'Delete Attachment Modal', enableTracking: true});

  return (
    <React.Fragment>
      <Row>
        {responsive.desktop && (
          <Link
            to={`/projects/${attachment.project.uuid}/attachments/${attachment.uuid}?status=edit`}
          >
            <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
          </Link>
        )}
        <Space width={8} />
        <Popover.Content innerRef={attachmentsActionsPopover.ref}>
          <TertiaryButton
            onPress={attachmentsActionsPopover.handleToggle}
            style={{paddingHorizontal: 8}}
          >
            <Icon
              source={Icon.EllipsisV}
              color={colors.gray.secondary}
              size={responsive.desktop ? 16 : 18}
            />
          </TertiaryButton>
        </Popover.Content>
      </Row>
      <AttachmentActionsPopover
        key={`ATTACHMENT_ACTIONS_POPOVER-${attachmentsActionsPopover.isOpen}`}
        attachmentsActionsPopover={attachmentsActionsPopover}
        attachment={attachment}
        refetch={refetch}
        deleteAttachmentModal={deleteAttachmentModal}
      />
    </React.Fragment>
  );
};

const TableWithAttachments = ({refetch, attachments}: any) => {
  return (
    <TableContainer>
      <Table.FixedHeaderScroll
        columnDefinitions={getAttachmentsTableColumnDefinitions({refetch})}
        emptyStateText='No files to display'
        items={!_.isEmpty(attachments) ? attachments : []}
        style={{maxHeight: 350}}
        containerStyle={{borderWidth: 0}}
        headerStyle={{
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          borderBottomColor: colors.gray.border,
        }}
        isDense
      />
    </TableContainer>
  );
};

const SmallAttachmentItem = ({attachment, refetch, responsive}: any) => {
  const projectAttachmentSheet = useSheet({name: 'Project Attachment Sheet'});

  return (
    <SmallAttachmentItemContainer>
      <Row>
        <AttachmentName
          attachment={attachment}
          responsive={responsive}
          projectAttachmentSheet={projectAttachmentSheet}
        />
        <Space width={16} />
        <AttachmentsActions attachment={attachment} refetch={refetch} responsive={responsive} />
      </Row>
      {attachment.description !== '' && (
        <React.Fragment>
          <Space height={12} />
          <Row>
            <MicroText responsive={responsive} numberOfLines={1}>
              {attachment.description}
            </MicroText>
          </Row>
        </React.Fragment>
      )}
      <Space height={12} />
      <Row>
        <MicroText responsive={responsive}>{attachment.file.creator?.fullName ?? ''}</MicroText>
        <Space width={12} />
        <VerticalSeparator />
        <Space width={12} />
        <MicroText responsive={responsive}>
          Uploaded On:{' '}
          {Datetime.convertToDisplayDatetime(
            attachment.file.uploadedAt,
            Datetime.DISPLAY_SHORT_DATE,
          )}
        </MicroText>
      </Row>
      <ProjectAttachmentSheet
        key={projectAttachmentSheet.key}
        isOpen={projectAttachmentSheet.isOpen}
        handleClose={projectAttachmentSheet.handleClose}
        attachment={attachment}
        refetch={refetch}
      />
    </SmallAttachmentItemContainer>
  );
};

const SmallAttachmentsTable = ({refetch, attachments}: any) => {
  const responsive = useResponsive();
  return (
    <SmallTableContainer>
      <SmallTableContentContainer>
        {attachments.map((attachment: any, index: any) => (
          <React.Fragment key={attachment.id}>
            {index > 0 && <HorizonalSeparator />}
            <SmallAttachmentItem
              attachment={attachment}
              refetch={refetch}
              responsive={responsive}
            />
          </React.Fragment>
        ))}
      </SmallTableContentContainer>
    </SmallTableContainer>
  );
};

const AttachmentsListView = ({isDragActive, attachments, refetch, isWidget}: any) => {
  const responsive = useResponsive();
  const isSmallParentContainer = isWidget || responsive.mobile;

  if (isDragActive || _.isEmpty(attachments)) {
    return (
      <AttachmentsFileDropzone
        attachments={attachments}
        isTableHeaderVisible={!isSmallParentContainer}
        isDragActive={isDragActive}
      />
    );
  }
  if (isSmallParentContainer) {
    return <SmallAttachmentsTable refetch={refetch} attachments={attachments} />;
  }
  return <TableWithAttachments refetch={refetch} attachments={attachments} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AttachmentsListView.fragment = gql`
  ${DeleteAttachmentModal.fragment}
  ${ProjectAttachmentSheet.fragment}

  fragment AttachmentsListView on Attachment {
    id
    uuid
    description
    isVisibleToCrew
    project {
      id
      uuid
    }
    file {
      id
      name
      uploadedAt
      downloadUrl
      creator {
        id
        fullName
      }
    }
    ...DeleteAttachmentModal
    ...ProjectAttachmentSheet
  }
`;

export default AttachmentsListView;
