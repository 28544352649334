// Libraries
import React from 'react';

// App
import {MapView} from '@supermove/components';
import {colors} from '@supermove/styles';
import {Location as LocationUtils} from '@supermove/utils';

// Components
import {MapMarker} from 'modules/App/Map/components';
import {Panel} from 'modules/App/components';

const getDirections = ({pickUpLocation, routeType, truckLastLocation}: any) => {
  if (!truckLastLocation) {
    return [];
  }
  // If we are routing to the pick up location (1st location),
  // we can generate a route. Otherwise we cannot because
  // we do not know which stop we are going to.
  if (routeType === 'ROUTE_TO_PICK_UP') {
    return [truckLastLocation, pickUpLocation];
  } else {
    return [];
  }
};

const getLocations = ({points, truckLastLocation}: any) => {
  return [...points, truckLastLocation].filter(Boolean);
};

type OwnCustomerJobMapProps = {
  locations: any[];
  routeType: string;
  truckLastLocation?: any;
};

// @ts-expect-error TS(2456): Type alias 'CustomerJobMapProps' circularly refere... Remove this comment to see the full error message
type CustomerJobMapProps = OwnCustomerJobMapProps & typeof CustomerJobMap.defaultProps;

// @ts-expect-error TS(7022): 'CustomerJobMap' implicitly has type 'any' because... Remove this comment to see the full error message
const CustomerJobMap = ({
  locations,
  pickUpLocation,
  routeType,
  truckLastLocation,
}: CustomerJobMapProps) => {
  const points = locations.map((location: any) => LocationUtils.create(location)).filter(Boolean);
  return (
    <Panel>
      <MapView
        mapTypeControl={false}
        bounds={getLocations({
          points,
          truckLastLocation,
        })}
        directions={getDirections({
          locations,
          pickUpLocation: LocationUtils.create(pickUpLocation),
          routeType,
          truckLastLocation,
        })}
        style={{
          flex: 1,
        }}
      >
        {({route, ...props}) => (
          <React.Fragment>
            {points.map((point: any, index: any) => (
              <MapMarker
                key={index}
                kind={MapMarker.DEFAULT}
                name={`Stop ${index + 1}`}
                label={MapMarker.createMarkerLabel(index + 1)}
                position={point}
                {...props}
              />
            ))}
            {truckLastLocation && (
              <React.Fragment>
                {route && route.length > 0 && (
                  <MapView.Polyline
                    {...props}
                    path={route}
                    strokeColor={colors.gray.primary}
                    strokeWeight={5}
                  />
                )}
                <MapMarker
                  kind={MapMarker.TRUCK}
                  name={'Truck'}
                  position={truckLastLocation}
                  {...props}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </MapView>
    </Panel>
  );
};

CustomerJobMap.defaultProps = {
  truckLastLocation: null,
};

export default CustomerJobMap;
