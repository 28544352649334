// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UrlFiltersType, ModalType} from '@supermove/hooks';
import {ProjectModel, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'components/Line';
import ProjectTags from 'modules/Project/V2/Show/components/ProjectTags';
import ProjectType from 'modules/Project/V2/Show/components/ProjectType';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const MobileSubheadingText = Styled.Text`
  ${Typography.Mobile.Subheading}
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const projectOrgAndTypeMarginBottom = 8;
const ProjectOrgText = Styled.Text`
  ${Typography.Mobile.Micro}
  margin-right: 24px;
  margin-bottom: ${projectOrgAndTypeMarginBottom}px;
`;

const IconContainer = Styled.View<{size: number}>`
  justify-content: center;
  align-items: center;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
`;

const ProjectDetailButton = ({
  icon,
  text,
  onPress,
}: {
  icon: IconSource;
  text: string;
  onPress: () => void;
}) => {
  return (
    <TertiaryButton
      style={{
        flex: 1,
        justifyContent: 'flex-start',
        paddingVertical: 12,
      }}
      onPress={onPress}
    >
      <IconContainer size={16}>
        <Icon source={icon} size={16} color={colors.blue.interactive} />
      </IconContainer>
      <Space width={8} />
      <MobileBodyText>{text}</MobileBodyText>
    </TertiaryButton>
  );
};

const ProjectOverviewContentMobile = ({
  project,
  urlFilters,
  refetchAndReset,
  setShouldCloseOverviewSheetOnClickOutside,
  handleClose,
  editBillingValuesModal,
}: {
  project: ProjectModel;
  urlFilters: UrlFiltersType;
  refetchAndReset: () => void;
  setShouldCloseOverviewSheetOnClickOutside?: (value: boolean) => void;
  handleClose?: () => void;
  editBillingValuesModal: ModalType;
}) => {
  return (
    <React.Fragment>
      <MobileSubheadingText>{project.name || project.client.name}</MobileSubheadingText>
      <Space height={8} />
      <Row>
        <ProjectOrgText>{project.organization.name}</ProjectOrgText>
        <ProjectType
          projectType={project.projectType}
          style={{marginBottom: projectOrgAndTypeMarginBottom}}
        />
      </Row>
      {project.isCancelled && (
        <React.Fragment>
          <Space height={8} />
          <ProjectOrgText>{`Cancelled at: ${Datetime.convertToDisplayDatetime(project.cancelledAt)}`}</ProjectOrgText>
        </React.Fragment>
      )}
      {project.description && (
        <React.Fragment>
          <Space height={8} />
          <MobileBodyText>{project.description}</MobileBodyText>
        </React.Fragment>
      )}
      <Space height={16} />
      <ProjectTags
        project={project}
        refetchAndReset={refetchAndReset}
        setShouldCloseOverviewSheetOnClickOutside={setShouldCloseOverviewSheetOnClickOutside}
      />
      <Space height={8} />
      <ProjectDetailButton
        icon={Icon.Calendar}
        text={Project.getDate(project)}
        onPress={() => {
          urlFilters.handleReset({block: ProjectBlockKind.JOBS});
          handleClose && handleClose();
        }}
      />
      <Line />
      {project.size ? (
        <React.Fragment>
          <ProjectDetailButton
            icon={Icon.HouseBuilding}
            text={project.size}
            onPress={() => {
              editBillingValuesModal.handleOpen();
              handleClose && handleClose();
            }}
          />
          <Line />
        </React.Fragment>
      ) : null}
      <ProjectDetailButton
        icon={Icon.WeightHanging}
        text={`${project.weight} lbs`}
        onPress={() => {
          editBillingValuesModal.handleOpen();
          handleClose && handleClose();
        }}
      />
      <Line />
      <ProjectDetailButton
        icon={Icon.RulerTriangle}
        text={`${project.volume} ft³`}
        onPress={() => {
          editBillingValuesModal.handleOpen();
          handleClose && handleClose();
        }}
      />
      <Line />
      <ProjectDetailButton
        icon={Icon.MapPin}
        text={Project.getLocation(project)}
        onPress={() => {
          urlFilters.handleReset({block: ProjectBlockKind.LOCATIONS});
          handleClose && handleClose();
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectOverviewContentMobile.fragment = gql`
  ${ProjectType.fragment}
  ${ProjectTags.fragment}
  ${Project.getDate.fragment}
  ${Project.getLocation.fragment}

  fragment ProjectOverviewContentMobile on Project {
    id
    name
    description
    weight
    volume
    size
    isCancelled
    cancelledAt
    organization {
      id
      name
    }
    client {
      id
      name
    }
    ...ProjectType
    ...ProjectTags
    ...Project_getDate
    ...Project_getLocation
  }
`;

export default ProjectOverviewContentMobile;
