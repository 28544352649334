// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Inventory, AttachmentModel, FileModel, RoomModel} from '@supermove/models';
import {uuid, withFragment} from '@supermove/utils';

// App
import FileViewer from '@shared/modules/File/components/FileViewer';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import ItemFormV2, {
  ItemFormV2Type,
  ItemFormV2ToMutationType,
} from '@shared/modules/Inventory/forms/ItemFormV2';

interface VideoAttachmentType {
  id: string;
  file: FileModel;
}

export interface RoomItemsFormType {
  roomId?: string;
  inventoryId: number;
  roomTypeId: number | string | null;
  name: string;
  description: string;
  isDeleted: boolean;
  itemForms: ItemFormV2Type[];
  collectionId?: number;
  primaryCategoryId?: number;
  uuid: string;
  latestVideoAttachment?: VideoAttachmentType | null;
  photoAttachments: AttachmentModel[];
  customerNotes: string;
  // private
  isDirty: boolean;
  isCollapsed: boolean;
}

interface RoomItemsFormToMutationType {
  roomId?: string;
  inventoryId: number;
  roomTypeId: number | string | null;
  name: string;
  description: string;
  isDeleted: boolean;
  itemForms: ItemFormV2ToMutationType[];
  collectionId?: number;
  primaryCategoryId?: number;
  uuid: string;
}

const edit = withFragment(
  (room: RoomModel, {isCollapsed = true}: {isCollapsed: boolean}): RoomItemsFormType => ({
    roomId: room.id,
    inventoryId: room.inventoryId,
    roomTypeId: room.roomTypeId,
    name: room.name,
    description: room.description,
    isDeleted: room.isDeleted,
    itemForms: room.collection.filteredItemsForJobUuids.map((item: any, index: any) =>
      ItemFormV2.edit(item, {index}),
    ),
    collectionId: room.collectionId,
    primaryCategoryId: room?.roomType?.primaryCategoryId,
    uuid: room.uuid,
    latestVideoAttachment: room.latestVideoAttachment,
    photoAttachments: room.filteredAttachments,
    customerNotes: room.customerNotes,
    // private
    isDirty: false,
    isCollapsed,
  }),
  gql`
    ${ItemFormV2.edit.fragment}
    ${FileViewer.fragment}

    fragment RoomItemsForm_edit on Room {
      id
      uuid
      inventoryId
      roomTypeId
      name
      description
      isDeleted
      collectionId
      customerNotes
      roomType {
        id
        primaryCategoryId
      }
      collection {
        id
        # NOTE(cooper): We use the jobUuids to filter items to only those that are associated with the current job
        # Only applicable for driver inventory
        filteredItemsForJobUuids(jobUuids: $jobUuids) {
          id
          takeCount
          leaveCount
          ...ItemFormV2_edit
        }
      }
      latestVideoAttachment {
        id
        file {
          id
          uploadedAt
          creator {
            id
            fullName
          }
          ...FileViewer
        }
      }
      filteredAttachments(attachmentCategoryKinds: ["ROOM_PHOTO"]) {
        id
        file {
          id
          name
          downloadUrl
          uploadedAt
          creator {
            id
            fullName
          }
        }
      }
    }
  `,
);

const _new = ({
  inventoryId,
  roomTypeId,
  primaryCategoryId = undefined,
  name = '',
  description = '',
}: {
  inventoryId: number;
  roomTypeId: number | string | null;
  primaryCategoryId?: number;
  name?: string;
  description?: string;
}): RoomItemsFormType => ({
  roomId: undefined,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted: false,
  itemForms: [],
  collectionId: undefined,
  primaryCategoryId,
  uuid: uuid(),
  latestVideoAttachment: undefined,
  photoAttachments: [],
  customerNotes: '',

  // private
  isDirty: true,
  isCollapsed: false,
});

const toForm = ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted,
  itemForms,
  collectionId,
  primaryCategoryId,
  uuid,
  latestVideoAttachment,
  photoAttachments,
  customerNotes,
  // private
  isDirty,
  isCollapsed,
}: RoomItemsFormType): RoomItemsFormType => ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted,
  itemForms,
  collectionId,
  primaryCategoryId,
  uuid,
  latestVideoAttachment,
  photoAttachments,
  customerNotes,
  // private
  isDirty,
  isCollapsed,
});

const toMutation = ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted,
  itemForms,
  collectionId,
  uuid,
}: RoomItemsFormType): RoomItemsFormToMutationType => ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted,
  collectionId,
  uuid,
  itemForms: _.reduce(
    itemForms,
    // @ts-expect-error TS(2769): No overload matches this call.
    (filteredForms, itemForm) => {
      // Do not send to mutation if form hasn't changed
      if (!itemForm.isDirty) {
        return filteredForms;
      }
      return [...filteredForms, ItemFormV2.toMutation(itemForm)];
    },
    [],
  ),
});

const getInfo = (
  roomItemsForm: any,
  itemTypeKindFilters = [ItemTypeKind.CARTON, ItemTypeKind.ITEM],
) => {
  let takeCount = 0;
  let leaveCount = 0;
  let volume = 0;
  let weight = 0;
  let price = 0;
  let hasUnsavedChanges = false;
  let itemCount = 0;
  let cartonCount = 0;
  roomItemsForm.itemForms.forEach((itemForm: any) => {
    if (itemForm.isDirty) {
      hasUnsavedChanges = true;
    }
    if (
      !roomItemsForm.isDeleted &&
      !itemForm.isDeleted &&
      _.includes(itemTypeKindFilters, itemForm.kind)
    ) {
      if (itemForm.take) {
        takeCount += Inventory.getFloatValue(itemForm.takeCount);
        if (!itemForm.isVoid) {
          volume +=
            Inventory.getFloatValue(itemForm.takeCount) * Inventory.getFloatValue(itemForm.volume);
          weight +=
            Inventory.getFloatValue(itemForm.takeCount) * Inventory.getFloatValue(itemForm.weight);
          price += _.toNumber(
            (Inventory.getFloatValue(itemForm.takeCount) * itemForm.price).toFixed(0),
          );
        }
        if (itemForm.kind === ItemTypeKind.ITEM) {
          itemCount += Inventory.getFloatValue(itemForm.takeCount);
        }
        if (itemForm.kind === ItemTypeKind.CARTON) {
          cartonCount += Inventory.getFloatValue(itemForm.takeCount);
        }
      } else {
        leaveCount += Inventory.getFloatValue(itemForm.leaveCount);
      }
    }
  });
  return {
    takeCount,
    leaveCount,
    volume: _.round(volume, 2),
    weight: _.round(weight, 2),
    price,
    hasUnsavedChanges,
    itemCount,
    cartonCount,
  };
};

const getLotAndRangeInfo = (roomItemsForm: any) => {
  const {itemForms} = roomItemsForm;

  // Separate out items for each lot/color combo
  const itemFormsByLotNumberAndColor = _.groupBy(itemForms, (itemForm) => {
    const {lotNumber, color} = itemForm;
    return `${lotNumber}_${color}`;
  });

  // Get the smallest and largest itemNumber within each lot/color combo
  return _.map(itemFormsByLotNumberAndColor, (itemForms, key) => {
    const {itemNumber: smallest} = _.minBy(itemForms, (itemForm) => {
      return _.toNumber(itemForm.itemNumber);
    });
    const {itemNumber: largest} = _.maxBy(itemForms, (itemForm) => {
      return _.toNumber(itemForm.itemNumber);
    });

    // Grab the lotNumber and color from the first itemForm, they should all be the same
    const {lotNumber, color} = itemForms[0];
    const range = smallest === largest ? smallest : `${smallest} - ${largest}`;
    return {lotNumber, color, range};
  });
};

const RoomItemsForm = {
  edit,
  new: _new,
  toForm,
  toMutation,

  // Helpers
  getInfo,
  getLotAndRangeInfo,
};

export default RoomItemsForm;
