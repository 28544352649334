// Libraries
import React from 'react';

// Supermove
import {IconSource, DataLoader, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import SettingsPageTemplate from 'modules/Organization/Settings/components/SettingsPageTemplate';

const ContentContainer = Styled.View`
`;

const CalloutContainer = Styled.View`
  padding: 24px;
`;

const ProjectSettingsPageContent = ({
  viewer,
  title,
  description,
  actionText,
  actionIcon,
  handleAction,
  isLoadingAction,
  isCompanySettings,
  children,
}: {
  viewer: UserModel;
  title: string;
  description: string;
  actionText?: string;
  actionIcon?: IconSource;
  handleAction?: () => void;
  isLoadingAction?: boolean;
  isCompanySettings?: boolean;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const organization = viewer.viewingOrganization;
  const {isEnabledCancelProject} = organization.features;
  const isDisabledAction = isCompanySettings
    ? !organization.isPrimary
    : !organization.isOwnerOfSettings;

  return (
    <SettingsPageTemplate
      title={title}
      description={description}
      actionText={actionText}
      actionIcon={actionIcon}
      handleAction={handleAction}
      isLoadingAction={isLoadingAction}
      isDisabledAction={isDisabledAction}
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Projects'},
      ]}
      tabs={[
        {
          label: 'Project Types',
          url: '/settings/projects/project-types',
        },
        {
          label: 'Tags',
          url: '/settings/projects/tags',
        },
        ...List.insertIf(isEnabledCancelProject, {
          label: 'Cancellations',
          url: '/settings/projects/cancellations',
        }),
      ]}
    >
      <ContentContainer>
        {isDisabledAction ? (
          <CalloutContainer>
            <Callout
              width={responsive.desktop ? 780 : undefined}
              text={
                `These settings are set by the company. Please contact the admins at ` +
                `${organization.company.primaryOrganization.name} to make adjustments.`
              }
            />
          </CalloutContainer>
        ) : (
          children
        )}
        <Space height={32} />
      </ContentContainer>
    </SettingsPageTemplate>
  );
};

const ProjectSettingsPage = ({
  title,
  description,
  actionText,
  actionIcon,
  handleAction,
  isLoadingAction,
  isCompanySettings,
  children,
}: {
  title: string;
  description: string;
  actionText?: string;
  actionIcon?: IconSource;
  handleAction?: () => void;
  isLoadingAction?: boolean;
  isCompanySettings?: boolean;
  children: React.ReactNode;
}) => {
  const {loading, data} = useQuery(ProjectSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <DataLoader loading={loading}>
      {() => {
        return (
          <ProjectSettingsPageContent
            viewer={data.viewer}
            title={title}
            description={description}
            actionText={actionText}
            actionIcon={actionIcon}
            handleAction={handleAction}
            isLoadingAction={isLoadingAction}
            isCompanySettings={isCompanySettings}
          >
            {children}
          </ProjectSettingsPageContent>
        );
      }}
    </DataLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSettingsPage.query = gql`
  query ProjectSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        isOwnerOfSettings
        features {
          isEnabledCancelProject: isEnabled(feature: "CANCEL_PROJECT")
        }
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
      }
    }
  }
`;

export default ProjectSettingsPage;
