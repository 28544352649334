// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView, PreventPropagation, Popover, Icon} from '@supermove/components';
import {
  useResponsive,
  ResponsiveType,
  Form,
  usePopover,
  useModal,
  ModalType,
  useState,
} from '@supermove/hooks';
import {AttachmentModel, FileModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, downloadFromUrl} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import FileViewer from '@shared/modules/File/components/FileViewer';
import {InventoryRoomsFormType} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import {RoomItemsFormType} from '@shared/modules/Inventory/forms/RoomItemsForm';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const MediaDetailsContainer = Styled.View`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const NotesContentContainer = Styled.View`
  padding: 12px;
`;

const VideoAttachmentContentContainer = Styled.View`
  padding: 12px;
`;

const VideoViewerContainer = Styled.View`
  height: 720px;
  background-color: ${colors.black};
`;

const PhotoAttachmentContentContainer = Styled.View`
  padding: 12px;
`;

const Image = Styled.Image`
  height: 80px;
  width: 80px;
  background-color: ${colors.gray.border};
`;

const PhotoDetailsImageContainer = Styled.View`
  padding-horizontal: 24px;
`;

const PhotoDetailsImage = Styled.Image`
  height: 400px;
  background-color: ${colors.gray.border};
`;

const LinkContainer = Styled.View`
  flex: 1;
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const HeadingText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.primary};
`;

const Label = Styled.Text`
  ${Typography.Responsive.Label}
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const PhotoDetailsNavigationContainer = Styled.View`
  flex-direction: row;
  justify-content: center;
  padding-vertical: 24px;
`;

const NavigationControlsContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const NavigationControlsBodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const getUploadText = (file: FileModel) => {
  const formattedDate = Datetime.convertToDisplayDatetime(
    file.uploadedAt,
    Datetime.DISPLAY_SHORT_DATE,
  );

  const creatorName = file.creator?.fullName;
  if (!creatorName) {
    return `Uploaded on ${formattedDate}`;
  }

  return `Uploaded by ${creatorName} on ${formattedDate}`;
};

const RoomNotes = ({selectedRoomForm}: {selectedRoomForm: RoomItemsFormType}) => {
  const responsive = useResponsive();
  return (
    <MediaDetailsContainer>
      <NotesContentContainer>
        <Label responsive={responsive}>{'Customer Notes'}</Label>
        <Space height={8} />
        <Text responsive={responsive}>{selectedRoomForm.customerNotes}</Text>
      </NotesContentContainer>
    </MediaDetailsContainer>
  );
};

const VideoAttachmentActions = ({file}: {file: FileModel}) => {
  const responsive = useResponsive();
  const fileActionsPopover = usePopover();
  return (
    <ActionMenuPopover
      popover={fileActionsPopover}
      width={200}
      sheetLabel={'Actions'}
      shouldMobileSheetCloseOnClickOutside={false}
      placement={Popover.Positions.AutoStart}
      actions={[
        {
          text: 'Download',
          onPress: () => {
            downloadFromUrl(file.downloadUrl);
          },
        },
      ]}
    >
      <PreventPropagation>
        <IconButton
          source={'ellipsis-v'}
          isSecondary
          size={responsive.desktop ? 16 : 18}
          onPress={fileActionsPopover.handleOpen}
        />
      </PreventPropagation>
    </ActionMenuPopover>
  );
};

const VideoAttachment = ({
  selectedRoomForm,
  responsive,
}: {
  selectedRoomForm: RoomItemsFormType;
  responsive: ResponsiveType;
}) => {
  if (!selectedRoomForm.latestVideoAttachment) {
    return null;
  }

  const {file} = selectedRoomForm.latestVideoAttachment;
  const uploadedByText = getUploadText(file);
  return (
    <MediaDetailsContainer>
      <VideoAttachmentContentContainer>
        <Row>
          <Container>
            <Label responsive={responsive}>{file.filename}</Label>
          </Container>
          <Space width={16} />
          <VideoAttachmentActions file={file} />
        </Row>
        <Space height={8} />
        <VideoViewerContainer>
          <FileViewer height={720} file={file} width={'100%'} />
        </VideoViewerContainer>
        <Space height={16} />
        <MicroText responsive={responsive}>{uploadedByText}</MicroText>
      </VideoAttachmentContentContainer>
    </MediaDetailsContainer>
  );
};

const PhotoAttachmentActions = ({
  file,
  photoAttachmentIndex,
  handleOpenPhotoDetailsModal,
  responsive,
}: {
  file: FileModel;
  photoAttachmentIndex: number;
  handleOpenPhotoDetailsModal: (index: number) => void;
  responsive: ResponsiveType;
}) => {
  const fileActionsPopover = usePopover();
  return (
    <ActionMenuPopover
      popover={fileActionsPopover}
      width={200}
      sheetLabel={'Actions'}
      shouldMobileSheetCloseOnClickOutside={false}
      placement={Popover.Positions.AutoStart}
      actions={[
        {
          text: 'View',
          onPress: () => {
            handleOpenPhotoDetailsModal(photoAttachmentIndex);
          },
        },
        {
          text: 'Download',
          onPress: () => {
            downloadFromUrl(file.downloadUrl);
          },
        },
      ]}
    >
      <PreventPropagation>
        <IconButton
          source={'ellipsis-v'}
          isSecondary
          size={responsive.desktop ? 16 : 18}
          onPress={fileActionsPopover.handleOpen}
        />
      </PreventPropagation>
    </ActionMenuPopover>
  );
};

const PhotoAttachment = ({
  photoAttachment,
  photoAttachmentIndex,
  handleOpenPhotoDetailsModal,
  responsive,
}: {
  photoAttachment: AttachmentModel;
  photoAttachmentIndex: number;
  handleOpenPhotoDetailsModal: (index: number) => void;
  responsive: ResponsiveType;
}) => {
  const {file} = photoAttachment;
  const uploadedByText = getUploadText(file);

  return (
    <React.Fragment key={file.id}>
      {photoAttachmentIndex > 0 && <Line />}
      <PhotoAttachmentContentContainer>
        <Row>
          <TertiaryButton
            onPress={() => {
              handleOpenPhotoDetailsModal(photoAttachmentIndex);
            }}
          >
            <Image
              resizeMode={'contain'}
              source={{uri: file.downloadUrl}}
              accessibilityLabel={file.filename}
            />
          </TertiaryButton>
          <Space width={16} />
          <LinkContainer>
            <LinkText
              responsive={responsive}
              numberOfLines={1}
              onClick={() => {
                handleOpenPhotoDetailsModal(photoAttachmentIndex);
              }}
            >
              {file.name}
            </LinkText>
          </LinkContainer>
          <Space width={16} />
          <PhotoAttachmentActions
            file={file}
            photoAttachmentIndex={photoAttachmentIndex}
            handleOpenPhotoDetailsModal={handleOpenPhotoDetailsModal}
            responsive={responsive}
          />
        </Row>
        <Space height={12} />
        <Row style={{alignItems: 'center'}}>
          <MicroText responsive={responsive}>{uploadedByText}</MicroText>
        </Row>
      </PhotoAttachmentContentContainer>
    </React.Fragment>
  );
};

const NoAttachments = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <Container style={{justifyContent: 'center', alignItems: 'center'}}>
      <HeadingText responsive={responsive}>{'No media uploads'}</HeadingText>
      <Space height={12} />
      <DescriptionText responsive={responsive} style={{textAlign: 'center'}}>
        {'Customer did not upload any media for this room'}
      </DescriptionText>
    </Container>
  );
};

const PhotoDetailsModalHeader = ({
  selectedPhoto,
  photoDetailsModal,
  responsive,
}: {
  selectedPhoto: AttachmentModel;
  photoDetailsModal: ModalType;
  responsive: ResponsiveType;
}) => {
  const {file} = selectedPhoto;

  return (
    <LargeModal.Header isResponsive>
      <LargeModal.HeaderText responsive={responsive} numberOfLines={1}>
        {file.name}
      </LargeModal.HeaderText>
      <Space flex={1} />
      <Button
        isResponsive
        iconLeft={Icon.FileArrowDown}
        text={'Download'}
        onPress={() => downloadFromUrl(file.downloadUrl)}
      />
      <Space width={16} />
      <TertiaryButton
        onPress={photoDetailsModal.handleClose}
        style={{height: 24, width: 24}}
        isHitSlop
      >
        <Icon source={Icon.Xmark} size={20} color={colors.gray.secondary} />
      </TertiaryButton>
    </LargeModal.Header>
  );
};

const PhotoDetailsModal = ({
  selectedRoomForm,
  selectedPhoto,
  selectedPhotoIndex,
  setSelectedPhotoIndex,
  photoDetailsModal,
  responsive,
}: {
  selectedRoomForm: RoomItemsFormType;
  selectedPhoto: AttachmentModel;
  selectedPhotoIndex: number;
  setSelectedPhotoIndex: (index: number) => void;
  photoDetailsModal: ModalType;
  responsive: ResponsiveType;
}) => {
  return (
    <LargeModal
      isOpen={photoDetailsModal.isOpen}
      isResponsive
      bodyStyle={{padding: 0, flex: 1}}
      HeaderComponent={() => (
        <PhotoDetailsModalHeader
          selectedPhoto={selectedPhoto}
          photoDetailsModal={photoDetailsModal}
          responsive={responsive}
        />
      )}
    >
      <Space height={24} />
      <PhotoDetailsImageContainer>
        <PhotoDetailsImage
          resizeMode={'contain'}
          source={{uri: selectedPhoto.file.downloadUrl}}
          accessibilityLabel={selectedPhoto.file.filename}
        />
      </PhotoDetailsImageContainer>
      <Space flex={1} />
      <Space height={24} />
      <PhotoDetailsNavigationContainer>
        <NavigationControlsContainer>
          <IconButton
            source={Icon.AngleLeft}
            size={responsive.desktop ? 16 : 18}
            isDisabled={selectedPhotoIndex === 0}
            onPress={() => setSelectedPhotoIndex(selectedPhotoIndex - 1)}
          />
          <Space width={16} />
          <NavigationControlsBodyText>{`${selectedPhotoIndex + 1} of ${selectedRoomForm.photoAttachments.length}`}</NavigationControlsBodyText>
          <Space width={16} />
          <IconButton
            source={Icon.AngleRight}
            isDisabled={selectedPhotoIndex + 1 === selectedRoomForm.photoAttachments.length}
            size={responsive.desktop ? 16 : 18}
            onPress={() => setSelectedPhotoIndex(selectedPhotoIndex + 1)}
          />
        </NavigationControlsContainer>
      </PhotoDetailsNavigationContainer>
      <Space height={24} />
    </LargeModal>
  );
};

const EditRoomAttachmentsDrawerContent = ({
  inventoryRoomsForm,
}: {
  inventoryRoomsForm: Form<{inventoryRoomsForm: InventoryRoomsFormType}>;
}) => {
  const {selectedRoomIndex, roomItemsForms} = inventoryRoomsForm.values.inventoryRoomsForm;
  const selectedRoomForm = roomItemsForms[selectedRoomIndex];
  const responsive = useResponsive();
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const selectedPhoto = selectedRoomForm.photoAttachments[selectedPhotoIndex];
  const photoDetailsModal = useModal({name: 'View Room Attachments Photo Details Modal'});
  const hasNoMedia =
    _.isEmpty(selectedRoomForm.latestVideoAttachment) &&
    _.isEmpty(selectedRoomForm.photoAttachments);

  const handleOpenPhotoDetailsModal = (index: number) => {
    photoDetailsModal.handleOpen();
    setSelectedPhotoIndex(index);
  };

  return (
    <Container>
      <ScrollView style={{flex: 1}} contentContainerStyle={{paddingHorizontal: 16, flex: 1}}>
        <Space height={16} />
        {!_.isEmpty(selectedRoomForm.customerNotes) && (
          <React.Fragment>
            <RoomNotes selectedRoomForm={selectedRoomForm} />
            <Space height={16} />
          </React.Fragment>
        )}
        {hasNoMedia && <NoAttachments responsive={responsive} />}
        {selectedRoomForm.latestVideoAttachment && (
          <React.Fragment>
            <VideoAttachment selectedRoomForm={selectedRoomForm} responsive={responsive} />
            <Space height={16} />
          </React.Fragment>
        )}
        {_.some(selectedRoomForm.photoAttachments) && (
          <MediaDetailsContainer>
            {selectedRoomForm.photoAttachments.map(
              (photoAttachment: AttachmentModel, photoAttachmentIndex: number) => {
                return (
                  <PhotoAttachment
                    photoAttachment={photoAttachment}
                    photoAttachmentIndex={photoAttachmentIndex}
                    handleOpenPhotoDetailsModal={handleOpenPhotoDetailsModal}
                    responsive={responsive}
                  />
                );
              },
            )}
          </MediaDetailsContainer>
        )}
        <Space height={16} />
      </ScrollView>
      {selectedPhoto && (
        <PhotoDetailsModal
          selectedRoomForm={selectedRoomForm}
          selectedPhoto={selectedPhoto}
          selectedPhotoIndex={selectedPhotoIndex}
          setSelectedPhotoIndex={setSelectedPhotoIndex}
          photoDetailsModal={photoDetailsModal}
          responsive={responsive}
        />
      )}
    </Container>
  );
};

export default EditRoomAttachmentsDrawerContent;
