// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, ScrollView, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useEffect, useResponsive, useRef, useScrollView} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const CategoryPill = Styled.ButtonV2<{isSelected: boolean}>`
  padding-vertical: 6px;
  padding-horizontal: 12px;
  background-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.white)};
  border-radius: 24px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  margin-right: 8px;
  flex-direction: row;
  align-items: center;
`;

const CategoryText = Styled.Text<{vars: {isSelected: boolean}}>`
  ${Typography.Responsive.Micro}
  color: ${({vars: {isSelected}}) => (isSelected ? colors.white : colors.gray.secondary)};
`;

const Category = ({
  label,
  categoryId,
  setFilteredCategoryIds,
  filteredCategoryIds,
  setActiveCategoryRef,
}: any) => {
  const categoryRef = useRef();
  const responsive = useResponsive();
  const isSelected = categoryId
    ? _.includes(filteredCategoryIds, categoryId)
    : _.isEmpty(filteredCategoryIds);

  useEffect(() => {
    if (isSelected) {
      setActiveCategoryRef(categoryId ? categoryRef : null);
    }
  }, [isSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CategoryPill
      ref={categoryRef}
      isSelected={isSelected}
      onPress={() => {
        setFilteredCategoryIds(categoryId && !isSelected ? [categoryId] : []);
      }}
    >
      <CategoryText vars={{isSelected}} responsive={responsive}>
        {label}
      </CategoryText>
      {isSelected && categoryId && (
        <Icon source={Icon.Xmark} size={12} color={colors.white} style={{marginLeft: 8}} />
      )}
    </CategoryPill>
  );
};

const CategoryFilters = ({inventoryLibrary, setFilteredCategoryIds, filteredCategoryIds}: any) => {
  const {categories} = inventoryLibrary;
  const defaultCategoriesToRemove = [
    '**All',
    '*A',
    '*B',
    '*C',
    '*D',
    '*E-F',
    '*G-H',
    '*I-K',
    '*L',
    '*M-N',
    '*O',
    '*P',
    '*Q-R',
    '*S',
    '*T',
    '*U-V',
    '*W',
    '*X',
    '*Y',
    '*Z',
  ];

  const categoryScrollView = useScrollView();
  const [activeCategoryRef, setActiveCategoryRef] = useState(null);
  useEffect(() => {
    if (activeCategoryRef && (activeCategoryRef as any).current) {
      (activeCategoryRef as any).current.measure(
        (x: any, y: any, width: any, height: any, pageX: any, pageY: any) => {
          categoryScrollView.handleScrollTo({x: x - 10});
        },
      );
    } else {
      categoryScrollView.handleScrollTo({x: 0});
    }
  }, [activeCategoryRef]); // eslint-disable-line react-hooks/exhaustive-deps
  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <ScrollView
        ref={categoryScrollView.ref}
        horizontal
        contentContainerStyle={{paddingHorizontal: 16, paddingVertical: 16, flexDirection: 'row'}}
        scrollEventThrottle={1}
      >
        <Category
          label={'All'}
          categoryId={null}
          setFilteredCategoryIds={setFilteredCategoryIds}
          filteredCategoryIds={filteredCategoryIds}
          setActiveCategoryRef={setActiveCategoryRef}
        />
        {categories
          .filter((category: any) => !_.includes(defaultCategoriesToRemove, category.name))
          .map((category: any) => {
            return (
              <Category
                key={category.id}
                label={category.name}
                categoryId={category.id}
                setFilteredCategoryIds={setFilteredCategoryIds}
                filteredCategoryIds={filteredCategoryIds}
                setActiveCategoryRef={setActiveCategoryRef}
              />
            );
          })}
      </ScrollView>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CategoryFilters.fragment = gql`
  fragment CategoryFilters on InventoryLibrary {
    id
    categories {
      id
      name
    }
  }
`;

export default CategoryFilters;
