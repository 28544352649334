// Libraries
import React from 'react';

// Supermove
import {IconSource} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

const MessageModal = ({
  icon,
  iconColor,
  title,
  message,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  isDisabled,
  isSubmitting,
  isLoading,
}: {
  icon?: IconSource;
  iconColor?: string;
  title: string;
  message: string;
  isOpen: boolean;
  handlePressOutside?: () => void;
  handlePrimaryAction: () => void;
  handleSecondaryAction?: () => void;
  primaryActionText: string;
  secondaryActionText?: string;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <ActionModal
      icon={icon}
      iconColor={iconColor}
      titleColor={colors.gray.primary}
      title={title}
      message={message}
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      handlePrimaryAction={handlePrimaryAction}
      handleSecondaryAction={handleSecondaryAction}
      primaryActionText={primaryActionText}
      secondaryActionText={secondaryActionText}
      isDisabled={isDisabled}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
    />
  );
};

export default MessageModal;
